import React, { ReactNode } from "react";
import { reportError } from "../../utils/logger";

type ErrorBoundaryProps = {
  children: ReactNode;
};

export type ErrorBoundaryState = {
  error: any;
  errorInfo: any;
};

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error, errorInfo });
    reportError(error, errorInfo);
    // Raven.captureException(error, { extra: errorInfo, });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="text-center my-5">
          <h1>Something went wrong.</h1>
          <p>
            Unexpected Error, please report it to{" "}
            <span>
              <a href={"mailto: hjelp@exigo.no"}>hjelp@exigo.no</a>
            </span>{" "}
            or open a case with PI Developers as group
          </p>

          <p>
            <a href="/">Back to dashboard.</a>
          </p>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
