import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@progress/kendo-react-inputs";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { History } from "history";
import { Accordion, Button, Card, Col, Form } from "react-bootstrap";
import {
  faCamera,
  faCommentAlt,
  faFileArchive,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";

import { IImage } from "../../models/image";
import { IIssue } from "../../models/issue";
import { IQcCheckpoint } from "../../models/QualityControl/qcCheckpoint";
import { IQcInspection } from "../../models/QualityControl/qcInspection";
import { isQcAdmin, IUser } from "../../models/user";
import { ParseMessageIds } from "./IssueMessage";
import { API, authorizationHeader, tryAjax } from "store/actions/request";
import dateTimeFormat from "../../utils/dateTimeFormat";
import QcCheckpointAttachments from "../QualityControl/QcCheckpoint/QcCheckpoinAttachements";
import Upload from "components/Common/Upload";
import TaggableInputField from "components/Common/TaggableInputField";
import { IAuditEntry } from "models/audit";

export interface IProps {
  issue: IIssue;
  checkpoint: IQcCheckpoint;
  inspection: IQcInspection | undefined;
  inspectionsAudits: IAuditEntry[];
  currentUser: IUser;
  isNew: boolean;
  history: History;
  disabled: boolean;
  handleItemSelected: (selected: any) => any;
  onCheckboxChange: (value: boolean) => void;
  onNoteChange: (value: string, checkpoint: IQcCheckpoint) => void;
  viewImage: (image: IImage) => void;
  handleNotApplicableChange: (value: boolean, checkpoint: IQcCheckpoint) => any;
  handleFileAdd: (file: IImage, checkpoint: IQcCheckpoint) => any;
  handleFileRemove: (file: UploadFileInfo, checkpoint: IQcCheckpoint) => any;
  handleImageRemove: (file: IImage, checkpoint: IQcCheckpoint) => any;
  setAudits: (auditEntries: IAuditEntry[]) => any;
  editCaseInspection: (inspection: IQcInspection) => any;
}

const IssueCheckpointCard = (props: IProps) => {
  const {
    issue,
    checkpoint,
    inspection,
    inspectionsAudits,
    currentUser,
    isNew,
    history,
    disabled,
    setAudits,
    handleItemSelected,
    onCheckboxChange,
    onNoteChange,
    viewImage,
    handleNotApplicableChange,
    handleFileAdd,
    handleFileRemove,
    handleImageRemove,
  } = props;

  const [viewNotes, setViewNotes] = useState<boolean>(false);
  const [viewAttachements, setViewAttachements] = useState<boolean>(
    checkpoint.notCheckable
  );
  const [notApplicable, setNotApplicable] = useState<boolean>(false);
  const [noteFilesView, setNoteFilesView] = useState<boolean>(false);
  const [NoteAudit, setNoteAudit] = useState<IAuditEntry | null>(null);
  const [checkedAudit, setCheckedAudit] = useState<IAuditEntry | null>(null);
  const [images, setImages] = useState<IImage[]>([]);
  const [files, setFiles] = useState<UploadFileInfo[]>([]);
  const [initialNote, setInitialNote] = useState<string>(" ");

  const mobile = window.matchMedia("(max-width: 1150px)");

  useEffect(() => {
    if (inspection && inspection.images && inspection.images.length > 0) {
      setViewNotes(true);
    }
    if (inspection && inspection.notApplicable) {
      setNotApplicable(true);
    } else {
      setNotApplicable(false);
    }
    setImages(inspection?.images ? inspection.images : []);
    if (inspection === undefined || inspection.id > 0) setFiles([]);
    setInitialNote(inspection?.note || "");
  }, [inspection]);

  useEffect(() => {
    if (initialNote && initialNote.length > 0) {
      setViewNotes(true);
    } else if (
      (inspection && inspection.images && inspection.images.length === 0) ||
      inspection === undefined
    ) {
      setViewNotes(false);
    }
  }, [initialNote]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inspection && inspection.id > 0 && inspectionsAudits) {
      if (
        inspectionsAudits.filter(
          (audit) => audit.objectId === inspection.id && audit.field === "note"
        ).length > 0
      ) {
        setNoteAudit(
          inspectionsAudits
            .filter(
              (audit) =>
                audit.objectId === inspection.id && audit.field === "note"
            )
            .sort(
              (a, b) =>
                new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
            )
            .slice(-1)[0]
        );
      }
      if (
        inspectionsAudits.filter(
          (audit) =>
            audit.objectId === inspection.id && audit.field === "qcstatusid"
        ).length > 0
      ) {
        setCheckedAudit(
          inspectionsAudits
            .filter(
              (audit) =>
                audit.objectId === inspection.id && audit.field === "qcstatusid"
            )
            .sort(
              (a, b) =>
                new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
            )
            .slice(-1)[0]
        );
      }
    }
  }, [inspectionsAudits, inspection]); // eslint-disable-line react-hooks/exhaustive-deps

  const mql = window.matchMedia("(min-width: 600px)");
  let checklistColClassName = "p-1";
  if (mql.matches) {
    checklistColClassName = "p-1";
  }

  const addImage = (image: IImage) => {
    handleFileAdd(image, checkpoint);
  };
  const removeImage = (file: UploadFileInfo) => {
    handleFileRemove(file, checkpoint);
  };
  const attachmentClicked = () => {
    if (
      (checkpoint.images && checkpoint.images.length > 1) ||
      (checkpoint.note && checkpoint.note.length > 0) ||
      (checkpoint.links && checkpoint.links.length > 0)
    ) {
      setViewAttachements(!viewAttachements);
    } else {
      viewImage(checkpoint.images[0]);
    }
  };

  const postImageRequest = (data: any): Promise<any> =>
    tryAjax(() => {
      return fetch(`${API}/images`, {
        method: "POST",
        headers: {
          ...authorizationHeader(),
        },
        body: data,
      });
    });

  const handleImageChange = (event) => {
    if (event.target.files !== undefined) {
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("userId", currentUser.id.toString());
      postImageRequest(formData).then((response) => {
        if (response.status === 200) {
          const imageResponse = response.json;
          const newImage: IImage = {
            ...imageResponse,
          };
          handleFileAdd(newImage, checkpoint);
          const newImages = images.concat(newImage);
          setImages(newImages);
          if (mobile.matches) {
            const data = response.json;
            if (data === null) return;
            fetch(data)
              .then((res) => res.blob())
              .then((blob) => {
                const blobUrl = URL.createObjectURL(blob);
                const title = response.json?.name ?? "undefined";

                const save = document.createElement("a");
                save.href = blobUrl;
                save.target = "_blank";
                save.download = title;

                var evt = new MouseEvent("click", {
                  view: window,
                  bubbles: true,
                  cancelable: false,
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
                save.remove();
              });
          }
        }
      });
    }
  };

  return (
    <Card
      className={
        checklistColClassName + `${isNew ? " my-1 border border-danger" : ""}`
      }
      key={checkpoint.id}
      bg="secondary"
    >
      <Card.Body className={checklistColClassName}>
        <div className="d-flex flex-row justify-content-between">
          <div style={{ flexGrow: 1 }}>
            {!checkpoint.notCheckable ? (
              <Checkbox
                className={
                  "checklist-checkpoint " +
                  (inspection?.notApplicable ? "text-muted" : "")
                }
                name="inspection"
                id={checkpoint.id.toString()}
                label={checkpoint.name}
                style={{ height: "23px", width: "23px" }}
                checked={
                  inspection?.qcStatusId === 2
                    ? inspection.notApplicable
                      ? false
                      : true
                    : false
                }
                onChange={(e) => {
                  onCheckboxChange(e.value);
                  setCheckedAudit(null);
                }}
                disabled={
                  disabled ||
                  inspection?.notApplicable ||
                  (checkpoint.imageRequired &&
                    ((inspection &&
                      (!inspection.images || inspection.images.length === 0)) ||
                      !inspection))
                }
              />
            ) : (
              <p className="px-2 pt-2 mb-0">{checkpoint.name}</p>
            )}
            {!checkpoint.notCheckable && (
              <Col
                xs={18}
                className="text-muted text-start"
                style={{ width: "100%" }}
              >
                <small>
                  {inspection &&
                  inspection.userName &&
                  (inspection.qcStatusId === 2 || inspection?.notApplicable) ? (
                    <em>
                      {inspection?.notApplicable
                        ? "Set to not applicable by: "
                        : "Checked by: "}{" "}
                      {inspection.userName}{" "}
                      {dateTimeFormat.format(
                        new Date(
                          inspection?.notApplicable
                            ? inspection.updatedAt
                            : checkedAudit !== null &&
                              checkedAudit.value === "2" &&
                              checkedAudit.dateTime > inspection.updatedAt
                            ? checkedAudit.dateTime
                            : inspection.updatedAt
                        )
                      )}
                    </em>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </small>
              </Col>
            )}
          </div>
          <div className="d-inline-flex" style={{ height: "40px" }}>
            {checkpoint.imageRequired &&
              ((inspection &&
                inspection.images &&
                inspection.images.length === 0) ||
                !inspection) && (
                <p
                  className="me-1 text-warning"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Image required
                </p>
              )}
          </div>
          <div className="d-inline-flex" style={{ height: "40px" }}>
            {mobile.matches &&
              !checkpoint.notCheckable &&
              checkpoint.imageRequired && (
                <Button
                  variant="secondary"
                  className="ms-1"
                  style={{ width: "40px", height: "40px" }}
                >
                  <input
                    accept="image/*"
                    id={"icon-button-file " + checkpoint.id}
                    type="file"
                    capture="environment"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <label htmlFor={"icon-button-file " + checkpoint.id}>
                    <span aria-label="upload picture">
                      <FontAwesomeIcon
                        icon={faCamera}
                        color={images && images.length > 0 ? "grey" : "white"}
                      />
                    </span>
                  </label>
                </Button>
              )}
            {!checkpoint.notCheckable && (
              <Button
                variant="secondary"
                onClick={() => {
                  setViewNotes(!viewNotes);
                }}
                style={{ float: "right" }}
              >
                {inspection &&
                ((inspection.note && inspection.note.length > 0) ||
                  inspection?.notApplicable ||
                  (inspection.images && inspection.images.length > 0)) ? (
                  <FontAwesomeIcon icon={faCommentAlt} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faCommentAlt} color="grey" />
                )}
              </Button>
            )}
            <Button
              onClick={() => attachmentClicked()}
              variant="secondary"
              className="ms-1"
              disabled={
                (checkpoint.images
                  ? checkpoint.images.length <= 0
                    ? true
                    : false
                  : true) &&
                (checkpoint.note
                  ? checkpoint.note.length <= 0
                    ? true
                    : false
                  : true) &&
                (checkpoint.links
                  ? checkpoint.links.length <= 0
                    ? true
                    : false
                  : true)
              }
              style={{ float: "right", width: "40px" }}
            >
              {(checkpoint.images && checkpoint.images.length > 0) ||
              (checkpoint.note && checkpoint.note.length > 0) ||
              (checkpoint.links && checkpoint.links.length > 0) ? (
                <div>
                  <span
                    style={{
                      position: "absolute",
                      marginTop: "-0.70em",
                      marginLeft: "0.70em",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {((checkpoint.images && checkpoint.images.length > 0) ||
                      (checkpoint.note && checkpoint.note.length > 0) ||
                      (checkpoint.links && checkpoint.links.length > 0)) &&
                      (
                        (checkpoint.images && checkpoint.images.length > 0
                          ? checkpoint.images.length
                          : 0) +
                        (checkpoint.note && checkpoint.note.length > 0
                          ? 1
                          : 0) +
                        (checkpoint.links && checkpoint.links.length > 0
                          ? checkpoint.links.length
                          : 0)
                      ).toString()}
                  </span>
                  <div>
                    <FontAwesomeIcon icon={faPaperclip} color="white" />
                  </div>
                </div>
              ) : (
                <FontAwesomeIcon icon={faPaperclip} color="grey" />
              )}
            </Button>
          </div>
        </div>
        <div className={viewNotes || viewAttachements ? "" : "hide-content"}>
          <Col
            className="col-12 col-md-6 col-lg-9 m-0 p-0 mb-0 pb-0"
            style={{ float: "right" }}
          >
            <Form.Group className="mb-3">
              <Accordion>
                <Accordion.Collapse
                  eventKey={checkpoint.id.toString()}
                  in={viewNotes}
                >
                  <div>
                    <div className="d-flex">
                      <div style={{ width: "100%" }}>
                        <div className="d-flex">
                          {viewNotes && (
                            <TaggableInputField
                              handleItemSelected={handleItemSelected}
                              onChange={(e) => {
                                if (
                                  inspection === undefined ||
                                  (inspection !== undefined &&
                                    inspection.note !== e.target.value)
                                ) {
                                  onNoteChange(e.target.value, checkpoint);
                                }
                              }}
                              value={inspection?.note ?? initialNote}
                              className="form-control"
                              placeholder="Inspection Notes"
                            />
                          )}
                          <Button
                            variant="dark"
                            onClick={() => setNoteFilesView(!noteFilesView)}
                            style={{ height: "40px" }}
                          >
                            <FontAwesomeIcon icon={faPaperclip} />
                          </Button>
                        </div>
                        <Accordion>
                          <Accordion.Collapse in={noteFilesView} eventKey="2">
                            <Upload
                              images={images}
                              currentUser={currentUser}
                              files={files}
                              disabled={false}
                              setFiles={setFiles}
                              setImages={setImages}
                              handleFileAdd={addImage}
                              handleFileRemove={removeImage}
                            />
                          </Accordion.Collapse>
                        </Accordion>
                      </div>
                    </div>
                    <p className="mb-0">
                      {ParseMessageIds(
                        initialNote,
                        issue.products,
                        issue.mainCauseParts,
                        issue.qcChassis,
                        issue.relIssues,
                        issue.orders,
                        issue.jigs,
                        [],
                        currentUser,
                        history,
                        true
                      )}
                    </p>
                    {NoteAudit !== null && (
                      <Col
                        xs={18}
                        className="text-muted text-start"
                        style={{ width: "100%" }}
                      >
                        <small>
                          {"Note " +
                            (NoteAudit.action === "NEW"
                              ? "added "
                              : "edited ") +
                            "by: " +
                            NoteAudit.editedByName +
                            " at " +
                            dateTimeFormat.format(new Date(NoteAudit.dateTime))}
                        </small>
                      </Col>
                    )}
                    <QcCheckpointAttachments
                      images={inspection ? inspection.images : []}
                      viewFile={(i) => viewImage(i)}
                      canTrash={isQcAdmin(currentUser)}
                      removeImage={(i) => handleImageRemove(i, checkpoint)}
                      bg="dark"
                    />
                    <Checkbox
                      className="checklist-checkpoint"
                      name="notApplicable"
                      checked={notApplicable}
                      onChange={(e) => {
                        handleNotApplicableChange(e.value, checkpoint);
                        setNotApplicable(!notApplicable);
                      }}
                      label="Not Applicable"
                      style={{ height: "23px", width: "23px" }}
                    />
                    {inspection &&
                      inspection.id > 0 &&
                      inspectionsAudits &&
                      inspectionsAudits.filter(
                        (audit) => audit.objectId === inspection.id
                      ) && (
                        <Button
                          variant="secondary"
                          className="float-end"
                          onClick={() =>
                            setAudits(
                              inspectionsAudits.filter(
                                (audit) => audit.objectId === inspection.id
                              )
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faFileArchive} /> View Log
                        </Button>
                      )}
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </Form.Group>
            <Form.Group className="mb-3">
              <Accordion>
                <Accordion.Collapse
                  in={viewAttachements}
                  eventKey="0"
                  style={{ width: "100%" }}
                >
                  <div>
                    <QcCheckpointAttachments
                      images={checkpoint.images ?? []}
                      viewFile={(i) => viewImage(i)}
                      canTrash={false}
                      bg="dark"
                    />
                    {checkpoint.note && checkpoint.note.length > 0 && (
                      <div>
                        <h6 className="mb-0 pb-0">Checkpoint description:</h6>
                        <pre className="mt-0 mb-1 pt-0 pb-1">
                          {checkpoint.note}
                        </pre>
                      </div>
                    )}
                    {checkpoint.links && checkpoint.links.length > 0 && (
                      <div>
                        <h6 className="my-0 py-0">External Links:</h6>
                        {checkpoint.links.map((l) => {
                          return (
                            <div key={l.id}>
                              <a
                                href={
                                  l.url.startsWith("https")
                                    ? l.url
                                    : "https://" + l.url
                                }
                                target="blank"
                              >
                                {l.title}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </Form.Group>
          </Col>
        </div>
      </Card.Body>
    </Card>
  );
};

export default IssueCheckpointCard;
