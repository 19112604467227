import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "./request";
import { ITimeTrack } from "../../models/timetrack";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";

export const FETCH_TIMETRACK_SUCCESS = "FETCH_TIMETRACK_SUCCESS";
export const FETCH_TIMETRACKS_SUCCESS = "FETCH_TIMETRACKS_SUCCESS";
export const FETCH_FILTERED_TIMETRACKS_SUCCESS =
  "FETCH_FILTERED_TIMETRACKS_SUCCESS";
export const TIMETRACK_REQUEST_FAILURE = "TIMETRACK_REQUEST_FAILURE";
export const CREATE_TIMETRACK_SUCCESS = "CREATE_TIMETRACK_SUCCESS";
export const TIMETRACK_REQUEST_ERRORS = "TIMETRACK_REQUEST_ERRORS";
export const DELETE_TIMETRACK_SUCCESS = "DELETE_TIMETRACK_SUCCESS";
export const EDIT_TIMETRACK_SUCCESS = "EDIT_TIMETRACK_SUCCESS";

export type Actions = {
  FETCH_TIMETRACK_SUCCESS: {
    type: typeof FETCH_TIMETRACK_SUCCESS;
    timeTrack: ITimeTrack;
  };
  FETCH_TIMETRACKS_SUCCESS: {
    type: typeof FETCH_TIMETRACKS_SUCCESS;
    timeTracks: ITimeTrack[];
  };
  FETCH_FILTERED_TIMETRACKS_SUCCESS: {
    type: typeof FETCH_FILTERED_TIMETRACKS_SUCCESS;
    timeTracks: DataResult;
  };
  CREATE_TIMETRACK_SUCCESS: {
    type: typeof CREATE_TIMETRACK_SUCCESS;
    timeTrack: ITimeTrack;
  };
  DELETE_TIMETRACK_SUCCESS: {
    type: typeof DELETE_TIMETRACK_SUCCESS;
  };
  EDIT_TIMETRACK_SUCCESS: {
    type: typeof EDIT_TIMETRACK_SUCCESS;
    timeTrack: ITimeTrack;
  };
  TIMETRACK_REQUEST_ERRORS: {
    type: typeof TIMETRACK_REQUEST_ERRORS;
    errors: any;
  };
  TIMETRACK_REQUEST_FAILURE: {
    type: typeof TIMETRACK_REQUEST_FAILURE;
    errors: any;
  };
};

export const actionCreators = {
  fetchTimeTrackSuccess: (
    timeTrack: ITimeTrack
  ): Actions[typeof FETCH_TIMETRACK_SUCCESS] => ({
    type: FETCH_TIMETRACK_SUCCESS,
    timeTrack: timeTrack,
  }),
  fetchTimeTracksSuccess: (
    timeTracks: ITimeTrack[]
  ): Actions[typeof FETCH_TIMETRACKS_SUCCESS] => ({
    type: FETCH_TIMETRACKS_SUCCESS,
    timeTracks: timeTracks,
  }),
  fetchFilteredTimeTracksSuccess: (
    timeTracks: DataResult
  ): Actions[typeof FETCH_FILTERED_TIMETRACKS_SUCCESS] => ({
    type: FETCH_FILTERED_TIMETRACKS_SUCCESS,
    timeTracks: timeTracks,
  }),
  createTimeTrackSuccess: (
    timeTrack: ITimeTrack
  ): Actions[typeof CREATE_TIMETRACK_SUCCESS] => ({
    type: CREATE_TIMETRACK_SUCCESS,
    timeTrack: timeTrack,
  }),
  deleteTimeTrackSuccess: (): Actions[typeof DELETE_TIMETRACK_SUCCESS] => ({
    type: DELETE_TIMETRACK_SUCCESS,
  }),
  editTimeTrackSuccess: (
    timeTrack: ITimeTrack
  ): Actions[typeof EDIT_TIMETRACK_SUCCESS] => ({
    type: EDIT_TIMETRACK_SUCCESS,
    timeTrack: timeTrack,
  }),
  timeTrackRequestErrors: (
    errors: any
  ): Actions[typeof TIMETRACK_REQUEST_ERRORS] => ({
    type: TIMETRACK_REQUEST_ERRORS,
    errors: errors,
  }),
  timeTrackRequestFailure: (
    status: number
  ): Actions[typeof TIMETRACK_REQUEST_FAILURE] => ({
    type: TIMETRACK_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};
export const fetchTimeTrack = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/timeTracks/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchTimeTrackSuccess(json))
      : dispatch(actionCreators.timeTrackRequestFailure(status));
  };
};
export function fetchTimeTracks() {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest("/timeTracks");
    return dispatch(actionCreators.fetchTimeTracksSuccess(json));
  };
}
export function fetchFilteredTimeTracks(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_TIMETRACKS_SUCCESS));
    const { status, json } = await getRequest(
      `/timeTracks/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_TIMETRACKS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredTimeTracksSuccess(json))
      : dispatch(actionCreators.timeTrackRequestFailure(status));
  };
}
export const createTimeTrack = (timeTrack: ITimeTrack) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/timeTracks`, timeTrack);
    switch (status) {
      case 200:
        dispatch(saveSuccess(timeTrack.orderNo.toString()));
        return dispatch(actionCreators.createTimeTrackSuccess(json));
      case 400:
        dispatch(saveFailed(timeTrack.orderNo.toString()));
        return dispatch(actionCreators.timeTrackRequestErrors(json));
      default:
        dispatch(saveFailed(timeTrack.orderNo.toString()));
        return dispatch(actionCreators.timeTrackRequestFailure(status));
    }
  };
};
export const editTimeTrack = (id: number, timeTrack: ITimeTrack) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/timeTracks/${id}`, timeTrack);
    switch (status) {
      case 200:
        dispatch(saveSuccess(timeTrack.orderNo.toString()));
        return dispatch(actionCreators.editTimeTrackSuccess(json));
      case 400:
        dispatch(saveFailed(timeTrack.orderNo.toString()));
        return dispatch(actionCreators.timeTrackRequestErrors(json));
      default:
        dispatch(saveFailed(timeTrack.orderNo.toString()));
        return dispatch(actionCreators.timeTrackRequestFailure(status));
    }
  };
};
export const deleteTimeTrack = (id: number, timeTrack: ITimeTrack) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/timeTracks/${id}`, timeTrack);
    if (status === 200) {
      dispatch(deleteSuccess(timeTrack.orderNo.toString()));
      return dispatch(actionCreators.deleteTimeTrackSuccess());
    }
    dispatch(saveFailed(timeTrack.orderNo.toString()));
    return dispatch(actionCreators.timeTrackRequestFailure(status));
  };
};
