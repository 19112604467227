import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../request";
import { IQcProdType } from "../../../models/QualityControl/qcProdType";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../alerts";

export const FETCH_QC_PRODTYPES_SUCCESS = "FETCH_QC_PRODTYPES_SUCCESS";
export const FETCH_QC_PRODTYPE_SUCCESS = "FETCH_QC_PRODTYPE_SUCCESS";
export const FETCH_FILTERED_QC_PRODTYPES_SUCCESS =
  "FETCH_FILTERED_QC_PRODTYPES_SUCCESS";
export const CREATE_QC_PRODTYPE_SUCCESS = "CREATE_QC_PRODTYPE_SUCCESS";
export const EDIT_QC_PRODTYPE_SUCCESS = "EDIT_QC_PRODTYPE_SUCCESS";
export const DELETE_QC_PRODTYPE_SUCCESS = "DELETE_QC_PRODTYPE_SUCCESS";
export const QC_PRODTYPE_REQUEST_FAILURE = "QC_PRODTYPE_REQUEST_FAILURE";
export const QC_PRODTYPE_REQUEST_ERROR = "QC_PRODTYPE_REQUEST_ERROR";

export interface QcProdTypeActions {
  FETCH_QC_PRODTYPES_SUCCESS: {
    type: typeof FETCH_QC_PRODTYPES_SUCCESS;
    qcProdTypes: IQcProdType[];
  };
  FETCH_QC_PRODTYPE_SUCCESS: {
    type: typeof FETCH_QC_PRODTYPE_SUCCESS;
    qcProdType: IQcProdType;
  };
  FETCH_FILTERED_QC_PRODTYPES_SUCCESS: {
    type: typeof FETCH_FILTERED_QC_PRODTYPES_SUCCESS;
    qcProdTypes: DataResult;
  };
  CREATE_QC_PRODTYPE_SUCCESS: {
    type: typeof CREATE_QC_PRODTYPE_SUCCESS;
    qcProdType: IQcProdType;
  };
  EDIT_QC_PRODTYPE_SUCCESS: {
    type: typeof EDIT_QC_PRODTYPE_SUCCESS;
    qcProdType: IQcProdType;
  };
  DELETE_QC_PRODTYPE_SUCCESS: {
    type: typeof DELETE_QC_PRODTYPE_SUCCESS;
  };
  QC_PRODTYPE_REQUEST_FAILURE: {
    type: typeof QC_PRODTYPE_REQUEST_FAILURE;
    errors: any;
  };
  QC_PRODTYPE_REQUEST_ERROR: {
    type: typeof QC_PRODTYPE_REQUEST_ERROR;
    errors: any;
  };
}

export type QcProdTypeActionTypes =
  | QcProdTypeActions[typeof FETCH_QC_PRODTYPES_SUCCESS]
  | QcProdTypeActions[typeof FETCH_QC_PRODTYPE_SUCCESS]
  | QcProdTypeActions[typeof FETCH_FILTERED_QC_PRODTYPES_SUCCESS]
  | QcProdTypeActions[typeof CREATE_QC_PRODTYPE_SUCCESS]
  | QcProdTypeActions[typeof EDIT_QC_PRODTYPE_SUCCESS]
  | QcProdTypeActions[typeof DELETE_QC_PRODTYPE_SUCCESS]
  | QcProdTypeActions[typeof QC_PRODTYPE_REQUEST_FAILURE]
  | QcProdTypeActions[typeof QC_PRODTYPE_REQUEST_ERROR];

export const actionCreators = {
  fetchQcProdTypesSuccess: (
    qcProdTypes: IQcProdType[]
  ): QcProdTypeActions[typeof FETCH_QC_PRODTYPES_SUCCESS] => ({
    type: FETCH_QC_PRODTYPES_SUCCESS,
    qcProdTypes: qcProdTypes,
  }),
  fetchQcProdTypeSuccess: (
    qcProdType: IQcProdType
  ): QcProdTypeActions[typeof FETCH_QC_PRODTYPE_SUCCESS] => ({
    type: FETCH_QC_PRODTYPE_SUCCESS,
    qcProdType: qcProdType,
  }),
  fetchFilteredQcProdTypesSuccess: (
    qcProdTypes: DataResult
  ): QcProdTypeActions[typeof FETCH_FILTERED_QC_PRODTYPES_SUCCESS] => ({
    type: FETCH_FILTERED_QC_PRODTYPES_SUCCESS,
    qcProdTypes: qcProdTypes,
  }),
  createQcProdTypeSuccess: (
    qcProdType: IQcProdType
  ): QcProdTypeActions[typeof CREATE_QC_PRODTYPE_SUCCESS] => ({
    type: CREATE_QC_PRODTYPE_SUCCESS,
    qcProdType: qcProdType,
  }),
  editQcProdTypeSuccess: (
    qcProdType: IQcProdType
  ): QcProdTypeActions[typeof EDIT_QC_PRODTYPE_SUCCESS] => ({
    type: EDIT_QC_PRODTYPE_SUCCESS,
    qcProdType: qcProdType,
  }),
  deleteQcProdTypeSuccess: (): QcProdTypeActions[typeof DELETE_QC_PRODTYPE_SUCCESS] => ({
    type: DELETE_QC_PRODTYPE_SUCCESS,
  }),
  qcProdTypeRequestFailure: (
    status: number
  ): QcProdTypeActions[typeof QC_PRODTYPE_REQUEST_FAILURE] => ({
    type: QC_PRODTYPE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  qcProdTypeRequestError: (
    errors: any
  ): QcProdTypeActions[typeof QC_PRODTYPE_REQUEST_ERROR] => ({
    type: QC_PRODTYPE_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchQcProdTypes() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_QC_PRODTYPES_SUCCESS));
    const { status, json } = await getRequest("/qcProdTypes");
    dispatch(requestEnd(FETCH_QC_PRODTYPES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchQcProdTypesSuccess(json))
      : dispatch(actionCreators.qcProdTypeRequestFailure(status));
  };
}

export const fetchQcProdType = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcProdTypes/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchQcProdTypeSuccess(json))
      : dispatch(actionCreators.qcProdTypeRequestFailure(status));
  };
};

export function fetchFilteredQcProdTypes(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_QC_PRODTYPES_SUCCESS));
    const { status, json } = await getRequest(
      `/qcProdTypes/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_QC_PRODTYPES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredQcProdTypesSuccess(json))
      : dispatch(actionCreators.qcProdTypeRequestFailure(status));
  };
}

export const createQcProdType = (qcProdType: IQcProdType) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/qcProdTypes", qcProdType);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcProdType.name));
        return dispatch(actionCreators.createQcProdTypeSuccess(json));
      case 400:
        dispatch(saveFailed(qcProdType.name));
        return dispatch(actionCreators.qcProdTypeRequestError(json));
      default:
        dispatch(saveFailed(qcProdType.name));
        return dispatch(actionCreators.qcProdTypeRequestFailure(status));
    }
  };
};

export const editQcProdType = (id: number, qcProdType: IQcProdType) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/qcProdTypes/${id}`, qcProdType);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcProdType.name));
        return dispatch(actionCreators.editQcProdTypeSuccess(json));
      case 400:
        dispatch(saveFailed(qcProdType.name));
        return dispatch(actionCreators.qcProdTypeRequestError(json));
      default:
        dispatch(saveFailed(qcProdType.name));
        return dispatch(actionCreators.qcProdTypeRequestFailure(status));
    }
  };
};

export const deleteQcProdType = (id: number, qcProdType: IQcProdType) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/qcProdTypes/${id}`, qcProdType);
    if (status === 200) {
      dispatch(deleteSuccess(qcProdType.name));
      return dispatch(actionCreators.deleteQcProdTypeSuccess());
    }
    dispatch(saveFailed(qcProdType.name));
    return dispatch(actionCreators.qcProdTypeRequestFailure(status));
  };
};
