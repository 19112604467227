import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import { IAuditEntry } from "models/audit";
import { IPriority } from "models/priority";
import { IQcStatus } from "models/QualityControl/qcStatus";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IUser } from "../../models/user";
import { IRootState } from "../../store";
import Aux from "../../utils/auxiliary";

export interface IProps {
  entry: IAuditEntry;
  color?: string;
  icon?: IconProp;
  history?: History;
  current?: IUser;

  qcStatuses: IQcStatus[];
  priorities: IPriority[];
  maxWidth?: number;
  truncateText: boolean;
}

const AuditEntryLine = (props: IProps) => {
  const { entry, color, icon, history, maxWidth, truncateText } = props;

  const { t } = useTranslation();

  const dateTimeFormat = Intl.DateTimeFormat("nb-NO", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  const cardBorderColor = "timeline-badge-card";

  const borderColor =
    history &&
    (history.location.pathname === "/" ||
      history.location.pathname === "/issues")
      ? cardBorderColor
      : "";

  var translated = t(
    `audit.${entry.objectType}.${entry.action}.${entry.field}`,
    {
      user: entry.editedByName,
      value: entry.value
        ? ["responsibleid", "groupid"].includes(entry.field)
          ? entry.value
          : entry.value.toLowerCase()
        : "none",
      oldValue: entry.oldValue
        ? ["responsibleid", "groupid"].includes(entry.field)
          ? entry.oldValue
          : entry.oldValue.toLowerCase()
        : "none",
      objectId: entry.objectId,
    }
  );

  return (
    <div className={icon ? "timeline ms-0" : ""}>
      {icon && (
        <div
          className={"timeline-badge " + borderColor}
          style={{ backgroundColor: color }}
        >
          <FontAwesomeIcon icon={icon} className="timeline-icon" />
        </div>
      )}

      <div className="timeline-message">
        <Aux>
          <span
            className={truncateText ? "d-inline-block text-truncate" : ""}
            style={
              truncateText
                ? {
                    maxWidth: maxWidth ? maxWidth : "800px",
                    wordWrap: "break-word",
                  }
                : {}
            }
            title={translated}
          >
            <em>
              {entry.dateTime &&
                dateTimeFormat.format(new Date(entry.dateTime))}
            </em>
            <span> - </span>
            {translated}
          </span>
        </Aux>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  current: state.authReducer.user,
  qcStatuses: state.qcStatusReducer.qcStatuses,
  priorities: state.prioritiesReducer.priorities,
});
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuditEntryLine);
