import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { IRootState } from "../../store";
import { startAuthentication, validateAuth } from "../../store/actions/auth";

export interface IProps {
  startAuthentication: () => any;
  validateAuth: () => Promise<any>;
}

const Unauthorized: React.FC<IProps> = (props) => {
  const { startAuthentication } = props;

  // Since we are not on PrivateRoute, validateAuth will not get executed until we set it here.
  /*
  useEffect(() => {
    validateAuth();
  }, [validateAuth]);*/

  return (
    <div className="text-center">
      <h1>Unauthorized</h1>
      <p>You are not authorized to access this resource.</p>
      <p>
        If you were given a new role you might need to re-login to get access
      </p>
      <Button
        variant="primary"
        href="/login"
        onClick={(e) => {
          e.preventDefault();
          startAuthentication();
        }}
      >
        Log in
      </Button>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

const mapDispatchToProps = (dispatch: any) => ({
  startAuthentication: () => dispatch(startAuthentication()),
  validateAuth: () => dispatch(validateAuth()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized);
