import {
  getRequest,
  postRequest,
  putRequest,
} from "../../store/actions/request";
import { Dispatch } from "redux";
import { saveFailed, saveSuccess } from "./alerts";
import { ITagCategory } from "../../models/tag";

export const FETCH_TAG_CATEGORIES_SUCCESS = "FETCH_TAG_CATEGORIES_SUCCESS";
export const FETCH_TAG_CATEGORY_SUCCESS = "FETCH_TAG_CATEGORY_SUCCESS";
export const CREATE_TAG_CATEGORY_SUCCESS = "CREATE_TAG_CATEGORY_SUCCESS";
export const EDIT_TAG_CATEGORY_SUCCESS = "EDIT_TAG_CATEGORY_SUCCESS";
export const TAG_CATEGORY_REQUEST_ERRORS = "TAG_CATEGORY_REQUEST_ERRORS";
export const TAG_CATEGORY_REQUEST_FAILURE = "TAG_CATEGORY_REQUEST_FAILURE";

export type TagCategoryActions = {
  FETCH_TAG_CATEGORIES_SUCCESS: {
    type: typeof FETCH_TAG_CATEGORIES_SUCCESS;
    tagCategories: ITagCategory[];
  };
  FETCH_TAG_CATEGORY_SUCCESS: {
    type: typeof FETCH_TAG_CATEGORY_SUCCESS;
    tagCategory: ITagCategory;
  };
  CREATE_TAG_CATEGORY_SUCCESS: {
    type: typeof CREATE_TAG_CATEGORY_SUCCESS;
    tagCategory: ITagCategory;
  };
  EDIT_TAG_CATEGORY_SUCCESS: {
    type: typeof EDIT_TAG_CATEGORY_SUCCESS;
    tagCategory: ITagCategory;
  };
  TAG_CATEGORY_REQUEST_ERRORS: {
    type: typeof TAG_CATEGORY_REQUEST_ERRORS;
    errors: any;
  };
  TAG_CATEGORY_REQUEST_FAILURE: {
    type: typeof TAG_CATEGORY_REQUEST_FAILURE;
    errors: any;
  };
};

export type TagCategoryActionsTypes =
  | TagCategoryActions[typeof FETCH_TAG_CATEGORIES_SUCCESS]
  | TagCategoryActions[typeof FETCH_TAG_CATEGORY_SUCCESS]
  | TagCategoryActions[typeof CREATE_TAG_CATEGORY_SUCCESS]
  | TagCategoryActions[typeof EDIT_TAG_CATEGORY_SUCCESS]
  | TagCategoryActions[typeof TAG_CATEGORY_REQUEST_ERRORS]
  | TagCategoryActions[typeof TAG_CATEGORY_REQUEST_FAILURE];

export const actionCreators = {
  fetchTagCategoriesSuccess: (
    tagCategories: ITagCategory[]
  ): TagCategoryActions[typeof FETCH_TAG_CATEGORIES_SUCCESS] => ({
    type: FETCH_TAG_CATEGORIES_SUCCESS,
    tagCategories: tagCategories,
  }),
  fetchTagCategorySuccess: (
    tagCategory: ITagCategory
  ): TagCategoryActions[typeof FETCH_TAG_CATEGORY_SUCCESS] => ({
    type: FETCH_TAG_CATEGORY_SUCCESS,
    tagCategory: tagCategory,
  }),
  createTagCategorySuccess: (
    tagCategory: ITagCategory
  ): TagCategoryActions[typeof CREATE_TAG_CATEGORY_SUCCESS] => ({
    type: CREATE_TAG_CATEGORY_SUCCESS,
    tagCategory: tagCategory,
  }),
  editTagCategorySuccess: (
    tagCategory: ITagCategory
  ): TagCategoryActions[typeof EDIT_TAG_CATEGORY_SUCCESS] => ({
    type: EDIT_TAG_CATEGORY_SUCCESS,
    tagCategory: tagCategory,
  }),
  tagCategoryRequestErrors: (
    errors: any
  ): TagCategoryActions[typeof TAG_CATEGORY_REQUEST_ERRORS] => ({
    type: TAG_CATEGORY_REQUEST_ERRORS,
    errors: errors,
  }),
  tagCategoryRequestFailure: (
    status: number
  ): TagCategoryActions[typeof TAG_CATEGORY_REQUEST_FAILURE] => ({
    type: TAG_CATEGORY_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export function fetchTagCategories() {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest("/tagcategories");
    return status === 200
      ? dispatch(actionCreators.fetchTagCategoriesSuccess(json))
      : dispatch(actionCreators.tagCategoryRequestFailure(status));
  };
}

export const fetchTagCategory = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/tagcategories/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchTagCategorySuccess(json))
      : dispatch(actionCreators.tagCategoryRequestFailure(status));
  };
};

export const createTagCategory = (tagCategory: ITagCategory) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/tagcategories`, tagCategory);
    switch (status) {
      case 200:
        dispatch(saveSuccess(tagCategory.name));
        return dispatch(actionCreators.createTagCategorySuccess(json));
      case 400:
        dispatch(saveFailed(tagCategory.name));
        return dispatch(actionCreators.tagCategoryRequestErrors(json));
      default:
        dispatch(saveFailed(tagCategory.name));
        return dispatch(actionCreators.tagCategoryRequestFailure(status));
    }
  };
};

export const editTagCategory = (id: number, tagCategory: ITagCategory) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/tagcategories/${id}`,
      tagCategory
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(tagCategory.name));
        return dispatch(actionCreators.editTagCategorySuccess(json));
      case 400:
        dispatch(saveFailed(tagCategory.name));
        return dispatch(actionCreators.tagCategoryRequestErrors(json));
      default:
        dispatch(saveFailed(tagCategory.name));
        return dispatch(actionCreators.tagCategoryRequestFailure(status));
    }
  };
};
