import { rootReducer } from "./../index";
import { AnyAction } from "redux";
import {
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
  SignalAction,
  SignalDispatch,
  signalMiddleware,
  withCallbacks,
} from "redux-signalr";
import { IMessage } from "../../models/message";
import { userManager } from "./auth";
import { IIssue } from "models/issue";
import { IUpdatedQcCheclist } from "models/QualityControl/qcChecklist";
import { IQcInspection } from "models/QualityControl/qcInspection";

export type RootState = ReturnType<typeof rootReducer>;

export const RECIEVED_MESSAGE = "RECIEVED_MESSAGE";
export const RECIEVED_ISSUE_COUNT = "RECIEVED_ISSUE_COUNT";
export const RECIEVED_UPDATED_ISSUE = "RECIEVED_UPDATED_ISSUE";
export const RECIEVED_UPDATED_QC_CHECKLIST = "RECIEVED_UPDATED_QC_CHECKLIST";
export const RECIEVED_UPDATED_TT_CHECKLIST = "RECIEVED_UPDATED_TT_CHECKLIST";
export const RECIEVED_UPDATED_CASE_CHECKLIST =
  "RECIEVED_UPDATED_CASE_CHECKLIST";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const RESET_UPDATED_ISSUES = "RESET_UPDATED_ISSUES";
export const UPDATE_SIGNALR_ISSUE = "UPDATE_SIGNALR_ISSUE";

export const CONNECTED = "CONNECTED";
export const CONNECT_FAILED = "CONNECT_FAILED";

export type SignalActions = {
  RECIEVED_MESSAGE: {
    type: typeof RECIEVED_MESSAGE;
    message: IMessage;
  };
  RECIEVED_ISSUE_COUNT: {
    type: typeof RECIEVED_ISSUE_COUNT;
    count: number;
  };
  RECIEVED_UPDATED_ISSUE: {
    type: typeof RECIEVED_UPDATED_ISSUE;
    issue: IIssue;
  };
  RECIEVED_UPDATED_QC_CHECKLIST: {
    type: typeof RECIEVED_UPDATED_QC_CHECKLIST;
    updatedChecklist: IUpdatedQcCheclist;
  };
  RECIEVED_UPDATED_CASE_CHECKLIST: {
    type: typeof RECIEVED_UPDATED_CASE_CHECKLIST;
    inspections: IQcInspection[];
  };
  RECIEVED_UPDATED_TT_CHECKLIST: {
    type: typeof RECIEVED_UPDATED_TT_CHECKLIST;
    inspections: IQcInspection[];
  };
  RESET_MESSAGES: {
    type: typeof RESET_MESSAGES;
  };
  RESET_UPDATED_ISSUES: {
    type: typeof RESET_UPDATED_ISSUES;
  };
  UPDATE_SIGNALR_ISSUE: {
    type: typeof UPDATE_SIGNALR_ISSUE;
    issueId: number;
  };
  CONNECTED: {
    type: typeof CONNECTED;
  };
  CONNECT_FAILED: {
    type: typeof CONNECT_FAILED;
  };
};

export const actionCreators = {
  recievedMessage: (
    message: IMessage
  ): SignalActions[typeof RECIEVED_MESSAGE] => ({
    type: RECIEVED_MESSAGE,
    message: message,
  }),
  recievedIssueCount: (
    count: number
  ): SignalActions[typeof RECIEVED_ISSUE_COUNT] => ({
    type: RECIEVED_ISSUE_COUNT,
    count: count,
  }),
  recievedUpdatedIssue: (
    issue: IIssue
  ): SignalActions[typeof RECIEVED_UPDATED_ISSUE] => ({
    type: RECIEVED_UPDATED_ISSUE,
    issue: issue,
  }),
  recievedUpdatedQcChecklist: (
    updatedChecklist: IUpdatedQcCheclist
  ): SignalActions[typeof RECIEVED_UPDATED_QC_CHECKLIST] => ({
    type: RECIEVED_UPDATED_QC_CHECKLIST,
    updatedChecklist: updatedChecklist,
  }),
  recievedUpdatedCaseChecklist: (
    inspections: IQcInspection[]
  ): SignalActions[typeof RECIEVED_UPDATED_CASE_CHECKLIST] => ({
    type: RECIEVED_UPDATED_CASE_CHECKLIST,
    inspections: inspections,
  }),
  recievedUpdatedTTChecklist: (
    inspections: IQcInspection[]
  ): SignalActions[typeof RECIEVED_UPDATED_TT_CHECKLIST] => ({
    type: RECIEVED_UPDATED_TT_CHECKLIST,
    inspections: inspections,
  }),
  clearMessages: (): SignalActions[typeof RESET_MESSAGES] => ({
    type: RESET_MESSAGES,
  }),
  clearUpdatedCases: (): SignalActions[typeof RESET_UPDATED_ISSUES] => ({
    type: RESET_UPDATED_ISSUES,
  }),
  updateSignalRCase: (
    issueId: number
  ): SignalActions[typeof UPDATE_SIGNALR_ISSUE] => ({
    type: UPDATE_SIGNALR_ISSUE,
    issueId: issueId,
  }),
  connected: (): SignalActions[typeof CONNECTED] => ({
    type: CONNECTED,
  }),
  connectFailed: (): SignalActions[typeof CONNECT_FAILED] => ({
    type: CONNECT_FAILED,
  }),
};

export type SignalActionTypes =
  | SignalActions[typeof RECIEVED_MESSAGE]
  | SignalActions[typeof RECIEVED_ISSUE_COUNT]
  | SignalActions[typeof RECIEVED_UPDATED_ISSUE]
  | SignalActions[typeof RECIEVED_UPDATED_QC_CHECKLIST]
  | SignalActions[typeof RECIEVED_UPDATED_TT_CHECKLIST]
  | SignalActions[typeof RECIEVED_UPDATED_CASE_CHECKLIST]
  | SignalActions[typeof RESET_MESSAGES]
  | SignalActions[typeof RESET_UPDATED_ISSUES]
  | SignalActions[typeof UPDATE_SIGNALR_ISSUE]
  | SignalActions[typeof CONNECTED]
  | SignalActions[typeof CONNECT_FAILED];

export type Action<ReturnValue = void> = SignalAction<
  ReturnValue,
  RootState,
  AnyAction
>;

export type Dispatch<Actions extends AnyAction = AnyAction> = SignalDispatch<
  RootState,
  Actions
>;

const logLevel =
  process.env.NODE_ENV === "production" ? LogLevel.Error : LogLevel.Debug;

const reconnectAttempts = [5, 5, 10, 10, 30, 30, 60, 60, 120, 120];

export const connection = new HubConnectionBuilder()
  .configureLogging(logLevel)
  .withUrl(process.env.REACT_APP_API + "/hub", {
    accessTokenFactory: (): Promise<string> => {
      return new Promise<string>(async (resolve) => {
        var user = await userManager.getUser();
        resolve(user?.access_token || "");
      });
    },
  })
  .withAutomaticReconnect(reconnectAttempts)
  .withHubProtocol(new JsonHubProtocol())
  .build();

export const callbacks = withCallbacks<Dispatch, RootState>()
  .add(
    "issueRecieved",
    (message: IMessage) => async (dispatch, getState, invoke) => {
      dispatch(actionCreators.recievedMessage(message));
    }
  )
  .add("issueCount", (count: number) => async (dispatch) => {
    dispatch(actionCreators.recievedIssueCount(count));
  })
  .add("qcChecklistCount", (count: number) => async () => {
    console.log("Users connected:" + count);
  })
  .add("issueCount", (count: number) => async () => {
    console.log("Users connected:" + count);
  })
  .add("issueUpdate", (issue: IIssue) => async (dispatch) => {
    console.log("Revieced issue: " + issue.title);
    dispatch(actionCreators.recievedUpdatedIssue(issue));
  })
  .add(
    "qcChecklistUpdate",
    (updatedChecklist: IUpdatedQcCheclist) => async (dispatch) => {
      console.log("Revieced chassis: " + updatedChecklist.qcChassisId);
      console.log(updatedChecklist);
      dispatch(actionCreators.recievedUpdatedQcChecklist(updatedChecklist));
    }
  )
  .add(
    "caseChecklistUpdate",
    (inspections: IQcInspection[]) => async (dispatch) => {
      console.log("Revieced case inspections");
      console.log(inspections);
      dispatch(actionCreators.recievedUpdatedCaseChecklist(inspections));
    }
  )
  .add(
    "ttChecklistUpdate",
    (inspections: IQcInspection[]) => async (dispatch) => {
      console.log("Revieced timetrack inspections");
      console.log(inspections);
      dispatch(actionCreators.recievedUpdatedTTChecklist(inspections));
    }
  );

export const signal = signalMiddleware({
  callbacks,
  connection,
  shouldConnectionStartImmediately: false,
});

export const startConnection = () => {
  return async (dispatch: Dispatch) => {
    if (connection.state === HubConnectionState.Connected) {
      return dispatch(actionCreators.connectFailed());
    }

    connection
      .start()
      .then(() => {
        return dispatch(actionCreators.connected());
      })
      .catch(() => {
        console.error("Failed to connect to signalr hub");
        return dispatch(actionCreators.connectFailed());
      });
  };
};

export const sendIssueMessage = (
  message: IMessage,
  sendExternal: boolean = false
): Action => (dispatch, getState, invoke) => {
  invoke("sendIssueMessage", message.id?.toString(), sendExternal);
};

export const sendUpdatedIssue = (issue: IIssue): Action => (
  dispatch,
  getState,
  invoke
) => {
  invoke("sendUpdatedIssue", issue.id.toString());
};

export const joinIssue = (issueId: number): Action => (
  dispatch,
  getState,
  invoke
) => {
  console.log("Joining Issue: ", issueId);
  dispatch(actionCreators.clearMessages());

  invoke("joinIssue", issueId.toString());
};

export const leaveIssue = (): Action => (dispatch, getState, invoke) => {
  dispatch(actionCreators.clearMessages());
  invoke("leaveIssue");
};

export const clearUpdatedCases = (): Action => (dispatch, getState, invoke) => {
  dispatch(actionCreators.clearUpdatedCases());
};

export const updateSignalRCase = (issueId: number): Action => (
  dispatch,
  getState,
  invoke
) => {
  dispatch(actionCreators.updateSignalRCase(issueId));
};

export const joinQcChecklist = (qcChassisId: number): Action => (
  dispatch,
  getState,
  invoke
) => {
  console.log("Joining Checklist: ", qcChassisId);
  dispatch(actionCreators.clearMessages());

  invoke("joinQcChecklist", qcChassisId.toString());
};

export const leaveQcChecklist = (): Action => (dispatch, getState, invoke) => {
  dispatch(actionCreators.clearMessages());
  invoke("leaveQcChecklist");
};

export const sendUpdatedQcChecklist = (
  qcChassisId: number,
  stationId: number
): Action => (dispatch, getState, invoke) => {
  invoke(
    "sendUpdatedQcChecklist",
    qcChassisId.toString(),
    stationId.toString()
  );
};

export const joinTTChecklist = (orderNo: number): Action => (
  dispatch,
  getState,
  invoke
) => {
  console.log("Joining Checklist: ", orderNo);
  dispatch(actionCreators.clearMessages());

  invoke("joinTTChecklist", orderNo.toString());
};

export const leaveTTChecklist = (): Action => (dispatch, getState, invoke) => {
  dispatch(actionCreators.clearMessages());
  invoke("leaveTTChecklist");
};

export const sendUpdatedTTChecklist = (orderNo: number): Action => (
  dispatch,
  getState,
  invoke
) => {
  invoke("sendUpdatedTTChecklist", orderNo.toString());
};
