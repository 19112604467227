import {
  faLock,
  faLockOpen,
  faUser,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import TimeAgo from "react-timeago";
import IssueFormContainer from "../../containers/IssueFormContainer";
import { IIssue } from "../../models/issue";
import dateTimeFormat from "../../utils/dateTimeFormat";

export interface IProps {
  issue: IIssue;
  history: any;
  internal: boolean;
  selectedIssue: number;
  caseView: number;
  handleIssueSubmit: () => void;
  setSelectedIssue: (issue: number) => void;
}

const IssueAccordionCard: React.FC<IProps> = (props) => {
  const {
    issue,
    history,
    internal,
    selectedIssue,
    caseView,
    handleIssueSubmit,
    setSelectedIssue,
  } = props;

  const renderSwitchPriority = (param: any) => {
    switch (param) {
      case 1:
        return "danger";
      case 2:
        return "warning";
      case 3:
        return "info";
      case 4:
        return "success";
      default:
        return "success";
    }
  };

  const [render, setRender] = useState<boolean>(false);
  var [cancelToken, setCancelToken] = useState<NodeJS.Timeout>();
  const isSelected = () => selectedIssue === issue.id;
  const isMountedRef = useRef(false);

  const updateRender = (selected?: boolean) => {
    isMountedRef.current = true;
    if (isSelected() || selected) setRender(true);
    else {
      setCancelToken(
        setTimeout(() => {
          if (isMountedRef.current) setRender(false);
        }, 500)
      );
      isMountedRef.current = false;
    }
  };

  useEffect(() => {
    if (cancelToken) clearTimeout(cancelToken);
    updateRender();
  }, [selectedIssue]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isSelected() && !render) setRender(true);

  return (
    <Card className="d-flex">
      <Accordion.Header
        as={Card.Header}
        eventkey={issue.id.toString()}
        onClick={() => {
          setSelectedIssue(isSelected() ? 0 : issue.id);
        }}
        className="card-header"
      >
        <div className="container-fluid px-0">
          <Row>
            <Col xs={8}>
              <h6>
                {issue.id +
                  " - " +
                  issue.title +
                  (issue.numberOfBales !== undefined && issue.numberOfBales > 0
                    ? " - " + issue.numberOfBales + " bales"
                    : "")}
              </h6>
            </Col>
            <Col xs={4} className="ps-0">
              {internal && (
                <span
                  className={`btn float-end btn-sm btn-${renderSwitchPriority(
                    issue.priorityId
                  )}`}
                >
                  {issue.priorityName}
                </span>
              )}
              <FontAwesomeIcon
                icon={issue.status === "Closed" ? faLock : faLockOpen}
                className="float-end mx-1 my-2"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <small>
                {issue.products.map(
                  (p, i) => (i ? ", " : "") + "#" + p.productNo
                )}
                {issue.qcChassis.map(
                  (c, i) =>
                    (issue.products && issue.products.length > 0
                      ? ", "
                      : i
                      ? ", "
                      : "") +
                    "%" +
                    c.no
                )}
                {issue.relIssues.map(
                  (ri, i) =>
                    (issue.products.length > 0 || issue.qcChassis.length > 0
                      ? ", "
                      : i
                      ? ", "
                      : "") +
                    "&" +
                    ri.id
                )}
                {issue.orders.map(
                  (o, i) =>
                    (issue.products.length > 0 ||
                    issue.qcChassis.length > 0 ||
                    issue.relIssues.length > 0
                      ? ", "
                      : i
                      ? ", "
                      : "") +
                    "$" +
                    o.orderNo
                )}
                {issue.salesforceCases.map(
                  (sf, i) =>
                    (issue.products.length > 0 ||
                    issue.qcChassis.length > 0 ||
                    issue.relIssues.length > 0 ||
                    issue.orders.length > 0
                      ? ", "
                      : i
                      ? ", "
                      : "") +
                    "*" +
                    sf.caseNumber
                )}
                {issue.jigs.map(
                  (j, i) =>
                    (issue.products.length > 0 ||
                    issue.qcChassis.length > 0 ||
                    issue.relIssues.length > 0 ||
                    issue.orders.length > 0 ||
                    issue.salesforceCases.length > 0
                      ? ", "
                      : i
                      ? ", "
                      : "") +
                    "£" +
                    j.jignr
                )}
                <br />
                {internal
                  ? (caseView !== 2 && caseView !== 3
                      ? issue.groupId
                        ? issue.groupName
                        : "No group"
                      : "") +
                    (issue.issueTypeId ? " - " + issue.issueTypeName : "")
                  : ""}
                <br />
                <FontAwesomeIcon
                  icon={issue.responsibleId ? faUser : faUserSlash}
                />{" "}
                {issue.responsibleId ? issue.responsibleFullName : ""}
              </small>
            </Col>
            <Col xs={6} className="text-end text-muted">
              <small>
                <em>
                  Updated: <TimeAgo date={issue.updatedAt} />
                </em>
                <br />
                {issue.userId && (
                  <em>
                    Created by: {issue.userFullName}{" "}
                    {dateTimeFormat.format(new Date(issue.createdAt))}
                  </em>
                )}
              </small>
            </Col>
          </Row>
        </div>
      </Accordion.Header>
      <Accordion.Collapse
        eventKey={issue.id.toString()}
        className="p-3"
        in={isSelected()}
      >
        {render && isSelected() ? (
          <IssueFormContainer
            issueId={issue.id}
            history={history}
            embed={true}
            onSubmit={handleIssueSubmit}
          />
        ) : (
          <div></div>
        )}
      </Accordion.Collapse>
    </Card>
  );
};

export default IssueAccordionCard;
