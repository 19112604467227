import { History } from "history";
import React from "react";
import DrawerContainer from "../../containers/DrawerContainer";
import routes from "../../routes";

export interface IProps {
  history: History;
}

const Layout: React.FC<IProps> = (props) => {
  const { history } = props;

  return <DrawerContainer history={history}>{routes}</DrawerContainer>;
};

export default Layout;
