import {
  QcInspectionActionTypes,
  FETCH_QC_INSPECTIONS_SUCCESS,
  FETCH_QC_INSPECTION_SUCCESS,
  FETCH_FAT_INSPECTION_LIST_SUCCESS,
  FETCH_FILTERED_QC_INSPECTIONS_SUCCESS,
  CREATE_QC_INSPECTION_SUCCESS,
  EDIT_QC_INSPECTION_SUCCESS,
  DELETE_QC_INSPECTION_SUCCESS,
} from "../../actions/QualityControl/qcInspection";
import { IQcInspection, IQcInspectionList } from "../../../models/QualityControl/qcInspection";
import { DataList } from "../../../utils/DataList";

export interface QcInspectionState {
  readonly qcInspections: IQcInspection[];
  readonly inspectionList: IQcInspectionList | null;
  readonly filteredQcInspections: DataList<IQcInspection>;
  readonly qcInspection: IQcInspection | null;
  readonly errors: any;
}

export const initialState: QcInspectionState = {
  qcInspections: [],
  filteredQcInspections: { data: [], total: 0 },
  qcInspection: null,
  inspectionList: null,
  errors: null,
};

const qcInspectionReducer = (
  state = initialState,
  action: QcInspectionActionTypes
): QcInspectionState => {
  switch (action.type) {
    case FETCH_QC_INSPECTIONS_SUCCESS:
      return { ...state, qcInspections: action.qcInspections };
    case FETCH_QC_INSPECTION_SUCCESS:
      return { ...state, qcInspection: action.qcInspection };
    case FETCH_FAT_INSPECTION_LIST_SUCCESS:
      return { ...state, inspectionList: action.inspectionList };
    case FETCH_FILTERED_QC_INSPECTIONS_SUCCESS:
      return { ...state, filteredQcInspections: action.qcInspections };
    case CREATE_QC_INSPECTION_SUCCESS:
      return {
        ...state,
        qcInspection: action.qcInspection,
        qcInspections:
          state.qcInspections.length > 0
            ? [...state.qcInspections.concat([action.qcInspection])]
            : [],
      };
    case EDIT_QC_INSPECTION_SUCCESS:
      if (state.qcInspections.length > 0) {
        state.qcInspections.splice(
          state.qcInspections.findIndex((t) => t.id === action.qcInspection.id),
          1,
          action.qcInspection
        );
      }
      return {
        ...state,
        qcInspection: action.qcInspection,
        qcInspections:
          state.qcInspections.length > 0 ? state.qcInspections : [],
      };
    case DELETE_QC_INSPECTION_SUCCESS:
      return { ...state, qcInspection: null, qcInspections: [] };
    default:
      return state;
  }
};

export default qcInspectionReducer;
