import {
  faFile,
  faPlayCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Card, Image } from "react-bootstrap";
import { IImage } from "../../models/image";
import { getRequest } from "../../store/actions/request";
import Aux from "../../utils/auxiliary";
import { isAdmin, IUser } from "../../models/user";
import { IMessage } from "../../models/message";

export interface IProps {
  image: IImage;
  editMode: boolean;
  currentUser: IUser;
  message: IMessage | null;
  setImageShow?: (image: IImage) => void;
  onDeleteClick?: ({ image, message }) => void;
}

const IssueImage: React.FC<IProps> = (props) => {
  const {
    image,
    editMode,
    currentUser,
    message,
    setImageShow,
    onDeleteClick,
  } = props;

  const [thumbContent, setThumbContent] = useState<string>();

  const [error, setError] = useState<string>("");
  const [hide, setHide] = useState<boolean>(false);

  useEffect(() => {
    if (image.id) {
      getRequest(`/images/thumb/${image.id}`).then((response) => {
        if (response.status !== 200) {
          if (response.status === 410) return;

          setError(response.status);
          if (response.status === 500) setHide(true);
          return;
        }

        if (response.json) {
          setThumbContent(response.json);
        } else {
          setThumbContent("");
        }
      });
    }
  }, [image]);

  const ImageView = () => {
    return (
      <span
        className="me-2 mb-0 d-inline-block"
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (setImageShow) setImageShow(image);
        }}
        title={image.name === undefined ? "undefined" : image.name}
      >
        {thumbContent ? (
          <Aux>
            {image.name.includes(".mp4") && (
              <FontAwesomeIcon
                icon={faPlayCircle}
                size="4x"
                className="m-2 position-absolute"
              />
            )}
            <Image src={thumbContent} className="img-fluid" />
          </Aux>
        ) : (
          <FontAwesomeIcon icon={faFile} size="2x" className="m-2" />
        )}

        {error !== "" && (
          <Alert variant="danger">Failed to load image {image.id}</Alert>
        )}
      </span>
    );
  };

  return (
    <Aux>
      {!hide && !editMode && <ImageView />}

      {!hide && editMode && message !== null && (
        <Card className="mb-0 w-100">
          <Card.Header className="d-flex flex-row hovernone">
            <span style={{ flex: 1 }}>{image.name}</span>
            {(isAdmin(currentUser) || currentUser.id === message.userId) && (
              <FontAwesomeIcon
                className="mt-1 ms-2 text-danger cursor"
                icon={faTrash}
                onClick={() => {
                  if (onDeleteClick) onDeleteClick({ image, message });
                }}
              />
            )}
          </Card.Header>
          <Card.Body>
            <ImageView />
          </Card.Body>
        </Card>
      )}
    </Aux>
  );
};

export default IssueImage;
