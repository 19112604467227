import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { History } from "history";

import Aux from "../../utils/auxiliary";
import QcCheckpointGrid from "../../components/QualityControl/QcCheckpoint/QcCheckpointGrid";
import { store } from "../../index";
import { useAsyncErrorHandler } from "../../utils/asyncErrorHandler";
import { fetchFilteredQcCheckpoints } from "../../store/actions/QualityControl/qcCheckpoint";
import { IQcCheckpoint } from "../../models/QualityControl/qcCheckpoint";
import { Paths } from "../../routes";
import { IRootState } from "../../store";
import { fetchFilteredJigs } from "store/actions/jigs";
import { IJig } from "models/jig";
import AddNewButton from "components/Common/AddNewButton";

export interface IProps {
  jig?: IJig;
  qcCheckpoints: DataResult;
  history: History;
  jigs: DataResult;
  fetchFilteredQcCheckpoints: (queryStr: string) => any;
  fetchFilteredJigs: (query: string) => any;
}

export const JigCheckpointContainer: React.FC<IProps> = (props) => {
  const {
    jig,
    qcCheckpoints,
    history,
    jigs,
    fetchFilteredQcCheckpoints,
    fetchFilteredJigs,
  } = props;

  useAsyncErrorHandler(store.getState().qcCheckpointReducer.errors);
  const [loading, setloading] = useState<boolean>(true);
  const [
    jigRequestState,
    setJigRequestState,
  ] = useState<DataSourceRequestState>({
    skip: 0,
    take: 20,
  });

  useEffect(() => {
    setloading(false);
  }, [qcCheckpoints]);

  useEffect(() => {
    if (jig !== undefined) {
      localStorage.setItem("jigNr", jig.jignr);
    }
  }, [jig]);

  const handleFetch = (query: string) => {
    setloading(true);
    fetchFilteredQcCheckpoints(query);
  };

  const editQcCheckpoint = (qcCheckpoint: IQcCheckpoint) => {
    history.push(Paths.equipment.gCheckpointsId(qcCheckpoint.id));
  };

  const newQcCheckpoint = () => {
    history.push(Paths.equipment.checkpointsCreate);
  };

  const handleJigRequestChange = (event) => {
    let field = "jignr";
    const value = event.filter.value;

    const request = {
      ...jigRequestState,
      filter: {
        filters: [
          {
            field: field,
            operator: "startswith",
            value: value,
          },
        ],
        logic: "and" as const,
      },
    };
    setJigRequestState(request);
    fetchJigData(request);
  };

  const fetchJigData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredJigs) {
      if (!request) {
        request = jigRequestState;
      }
      return fetchFilteredJigs(toDataSourceRequestString(request));
    }
  };

  return (
    <Aux>
      <h3>Equipment Checkpoints</h3>
      <QcCheckpointGrid
        jig={jig}
        qcCheckpointsData={qcCheckpoints}
        jigs={jigs.data}
        typeId={4}
        handleFetch={handleFetch}
        onEditClick={editQcCheckpoint}
        onJigRequestChange={handleJigRequestChange}
        newQcCheckpoint={newQcCheckpoint}
      />

      {jig === undefined && <AddNewButton onCLick={() => newQcCheckpoint()} />}
      {loading && (
        <p className="text-center m-3">
          <FontAwesomeIcon
            icon={faSpinner}
            size="3x"
            spin
            style={{
              top: "50%",
              left: "50%",
              position: "fixed",
              zIndex: 999,
            }}
          />
        </p>
      )}
    </Aux>
  );
};

const mapStateToProps = (state: IRootState) => ({
  qcCheckpoints: state.qcCheckpointReducer.filteredQcCheckpoints,
  jigs: state.jigsReducer.filteredJigs,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchFilteredQcCheckpoints: (queryStr: string) =>
    dispatch(fetchFilteredQcCheckpoints(queryStr)),
  fetchFilteredJigs: (query: string) => dispatch(fetchFilteredJigs(query)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigCheckpointContainer);
