import {
  faFile,
  faPlayCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Image } from "react-bootstrap";
import { IImage } from "../../models/image";
import { isJigAdmin, IUser } from "../../models/user";
import { getRequest } from "../../store/actions/request";
import Aux from "../../utils/auxiliary";

export interface IProps {
  current: IUser;
  image: IImage;
  setImageShow?: (image: IImage) => void;
  onDeleteClick: (image: IImage) => void;
  card?: boolean;
}

const JigImage: React.FC<IProps> = (props) => {
  const { current, card, image, setImageShow, onDeleteClick } = props;

  const [thumbContent, setThumbContent] = useState<string>();
  const [imageId, setImageId] = useState<number | null>(null);

  useEffect(() => {
    if (image.id && imageId !== image.id) {
      setImageId(image.id);
      getRequest(`/images/thumb/${image.id}`).then((response) => {
        if (response.status !== 200) return;

        if (response.json) {
          setThumbContent(response.json);
        } else {
          setThumbContent("");
        }
      });
    }
  }, [image, imageId]);

  const ImageView = () => {
    return (
      <span className="me-2 mb-2 d-inline-block">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (setImageShow) setImageShow(image);
          }}
          title={image.name}
        >
          {thumbContent ? (
            <Aux>
              {thumbContent.startsWith("data:video") && (
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  size="4x"
                  className="m-2 position-absolute"
                />
              )}
              <Image src={thumbContent} className="img-fluid" />
            </Aux>
          ) : (
            <span>
              <FontAwesomeIcon icon={faFile} size="2x" className="m-2" />
            </span>
          )}
        </span>
      </span>
    );
  };

  if (!card) return <ImageView />;

  return (
    <Aux>
      <Card className="mb-1 w-100">
        <Card.Header className="d-flex flex-row hovernone">
          <span style={{ flex: 1 }}>{image.name}</span>
          {isJigAdmin(current) && (
            <FontAwesomeIcon
              className="mt-1 ms-2 text-danger cursor"
              onClick={() => onDeleteClick(image)}
              icon={faTrash}
            />
          )}
        </Card.Header>
        <Card.Body>
          <ImageView />
        </Card.Body>
      </Card>
    </Aux>
  );
};

export default JigImage;
