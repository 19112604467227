import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CompositeFilterDescriptor,
  DataSourceRequestState,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { DatePicker, SelectionRange } from "@progress/kendo-react-dateinputs";
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  DropDownList,
  DropDownListFilterChangeEvent,
  MultiSelect,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnMenuItemGroup,
  GridDetailRowProps,
  GridFilterCellProps,
} from "@progress/kendo-react-grid";
import { GridColumnMenuFilterProps } from "@progress/kendo-react-grid/dist/npm/columnMenu/GridColumnMenuFilter";
import { History } from "history";
import { IQcProd } from "models/QualityControl/qcProd";
import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import ReactDOM from "react-dom";
import { IQcAttrType } from "../../models/QualityControl/qcAttrType";
import { IQcAttrValue } from "../../models/QualityControl/qcAttrValue";
import { Paths } from "../../routes";
import Aux from "../../utils/auxiliary";
import dateTimeFormat from "../../utils/dateTimeFormat";

interface ICellProps {
  dataItem: any;
  field?: string;
}

interface INameProps {
  firstName: string;
  lastName: string;
}

export interface IBoolProps extends GridFilterCellProps {
  boolValue: { name: string; value: boolean } | undefined;
  setBoolValue: (value: { name: string; value: boolean } | undefined) => any;
  trueName: string;
  falseName: string;
}

export interface IDateTimeCellProps extends GridFilterCellProps {
  rangedValue: SelectionRange;
  onDateTimeChange: (value: Date | null, operator: string) => any;
  onDateTimeClear: () => any;
}

export interface IDateTimeMenuProps extends GridColumnMenuFilterProps {
  rangedValue: SelectionRange;
  onDateTimeChange: (value: Date | null, operator: string) => any;
  onDateTimeClear: () => any;
  onSubmit: () => any;
}

export const BooleanCell: React.FC<ICellProps> = (props) => {
  const { dataItem, field } = props;
  return (
    <td>
      {field && dataItem[field] ? (
        <i
          style={{ color: "rgba(130, 200, 160, 1)" }}
          className="fa fa-check"
        />
      ) : (
        <i style={{ color: "rgba(221, 65, 50, 1)" }} className="fa fa-times" />
      )}
    </td>
  );
};

export const Name: React.FC<INameProps> = (props) => {
  const { firstName, lastName } = props;
  if (firstName !== undefined) {
    return <td> {firstName + " " + lastName}</td>;
  } else {
    return <td />;
  }
};

export const DateCell: React.FC<ICellProps> = (props) => {
  const { dataItem, field } = props;

  return (
    <td>
      {field &&
        dataItem[field] &&
        dateTimeFormat.format(new Date(dataItem[field]))}
    </td>
  );
};

export const DateTimeCell: React.FC<ICellProps> = (props) => {
  const { dataItem, field } = props;

  return (
    <td>
      {field &&
        dataItem[field] &&
        dateTimeFormat.format(new Date(dataItem[field]))}
    </td>
  );
};

export const StatusCell: React.FC<ICellProps> = (props) => {
  const { dataItem } = props;
  switch (dataItem.status.id) {
    case 1:
      return (
        <td style={{ backgroundColor: "rgba(46, 204, 64, 0.8)" }}>
          {dataItem.status.name}
        </td>
      );
    case 2:
      return (
        <td style={{ backgroundColor: "rgba(255, 220, 0, 0.8)" }}>
          {dataItem.status.name}
        </td>
      );
    case 3:
      return (
        <td style={{ backgroundColor: "rgba(255, 65, 54, 0.8)" }}>
          {dataItem.status.name}
        </td>
      );
    case 4:
      return (
        <td style={{ backgroundColor: "rgba(50, 55, 50, 0.8)", color: "#fff" }}>
          {dataItem.status.name}
        </td>
      );
    default:
      return <td>{dataItem.status.name}</td>;
  }
};

export const LoadingPanel: React.FC<{}> = (props) => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );

  const gridContent = document && document.getElementById("");
  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
};

export const DropDownFilterCell = (
  props: GridFilterCellProps,
  data: any,
  onChange?: any,
  value?: any
) => {
  const [filtered, setFiltered] = useState(data);

  const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    const filterdData = data;
    return filterBy(filterdData, filter);
  };

  const filterChange = (event: DropDownListFilterChangeEvent) => {
    setFiltered(filterData(event.filter));
  };

  return (
    <div className="k-filtercell">
      <DropDownList
        {...props}
        value={value ? value : props.value}
        popupSettings={{ width: "auto" }}
        data={filtered}
        filterable={true}
        onFilterChange={filterChange}
        onChange={(event) => {
          if (onChange) {
            onChange(event);
          } else {
            props.onChange({
              value: event.target.value,
              operator: "eq",
              syntheticEvent: event.syntheticEvent,
            });
          }
        }}
      />
      {((value !== undefined && value !== "") || props.value !== "") && (
        <button
          title="Clear"
          type="button"
          className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
          onClick={(event) => {
            event.preventDefault();
            if (onChange) {
              onChange({
                value: "",
                operator: "",
                syntheticEvent: event,
              });
            } else {
              props.onChange({
                value: "",
                operator: "",
                syntheticEvent: event,
              });
            }
          }}
        >
          <span className="k-svg-icon k-svg-i-filter-clear k-button-icon">
            <svg
              aria-hidden="true"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="m143.5 64 168.2 168.2L288 256v160l-64 64V256L64 96V64h79.5zm236.1 100.4L448 96V64H279.3l-64-64L192 22l298 298 22-23.3-132.4-132.3z"></path>
            </svg>
          </span>
        </button>
      )}
    </div>
  );
};

export const DropDownFilterBooleanCell: React.FC<IBoolProps> = (
  props: IBoolProps
) => {
  const data = [
    { name: props.trueName, value: true },
    { name: props.falseName, value: false },
  ];

  return (
    <div className="k-filtercell">
      <DropDownList
        {...props}
        value={props.boolValue}
        data={data}
        dataItemKey="value"
        textField="name"
        onChange={(event) => {
          props.setBoolValue(event.target.value);
          props.onChange({
            value: event.target.value.value,
            operator: "eq",
            syntheticEvent: event.syntheticEvent,
          });
        }}
      />
      {props.boolValue !== undefined && (
        <button
          title="Clear"
          type="button"
          className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
          onClick={(event) => {
            event.preventDefault();
            props.setBoolValue(undefined);
            props.onChange({
              value: "",
              operator: "",
              syntheticEvent: event,
            });
          }}
        >
          <span className="k-svg-icon k-svg-i-filter-clear k-button-icon">
            <svg
              aria-hidden="true"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="m143.5 64 168.2 168.2L288 256v160l-64 64V256L64 96V64h79.5zm236.1 100.4L448 96V64H279.3l-64-64L192 22l298 298 22-23.3-132.4-132.3z"></path>
            </svg>
          </span>
        </button>
      )}
    </div>
  );
};

export const DateTimeRangeFilterCell: React.FC<IDateTimeCellProps> = (
  props: IDateTimeCellProps,
  data?: any
) => {
  return (
    <div className="k-filtercell">
      <DatePicker
        {...props}
        format="dd/MM/yyyy"
        value={props.rangedValue.start}
        onChange={(event) => {
          props.onDateTimeChange(event.value, "gt");
        }}
      />
      <br></br>
      <DatePicker
        {...props}
        format="dd/MM/yyyy"
        value={props.rangedValue.end}
        onChange={(event) => {
          props.onDateTimeChange(event.value, "lt");
        }}
      />
      <br></br>
      {(props.rangedValue.start !== null || props.rangedValue.end !== null) && (
        <button
          title="Clear"
          type="button"
          className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
          onClick={(event) => {
            event.preventDefault();
            props.onDateTimeClear();
          }}
        >
          <span className="k-svg-icon k-svg-i-filter-clear k-button-icon">
            <svg
              aria-hidden="true"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="m143.5 64 168.2 168.2L288 256v160l-64 64V256L64 96V64h79.5zm236.1 100.4L448 96V64H279.3l-64-64L192 22l298 298 22-23.3-132.4-132.3z"></path>
            </svg>
          </span>
        </button>
      )}
    </div>
  );
};

export const DateTimeRangeMenu: React.FC<IDateTimeMenuProps> = (
  props: IDateTimeMenuProps,
  data?: any
) => {
  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    props.onSubmit();
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const onReset = (event) => {
    if (event) event.preventDefault();
    props.onDateTimeClear();
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  return (
    <div>
      <GridColumnMenuItemGroup>
        <div className="k-column-list-wrapper">
          <form onSubmit={onSubmit} onReset={onReset}>
            <div className={"k-column-list"}>
              <div key={0} className={"k-column-list-item"}>
                <span>
                  After:
                  <DatePicker
                    {...props}
                    format="dd/MM/yyyy"
                    value={props.rangedValue.start}
                    onChange={(event) => {
                      props.onDateTimeChange(event.value, "gt");
                    }}
                  />
                </span>
              </div>

              <div key={1} className={"k-column-list-item"}>
                <span>
                  Before:
                  <DatePicker
                    {...props}
                    format="dd/MM/yyyy"
                    value={props.rangedValue.end}
                    onChange={(event) => {
                      props.onDateTimeChange(event.value, "lt");
                    }}
                  />
                </span>
              </div>
            </div>
            <ButtonToolbar className="k-columnmenu-actions">
              <ButtonGroup>
                <Button type="submit" variant="primary">
                  Save
                </Button>
                <Button type="reset" variant="secondary">
                  Reset
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </form>
        </div>
      </GridColumnMenuItemGroup>
    </div>
  );
};

export const DropDownMultiSelectFilterCell = (
  props: GridFilterCellProps,
  data: any,
  value: any[],
  setValue: (value: any[]) => void,
  requestState?: DataSourceRequestState,
  setRequestChange?: (request: DataSourceRequestState) => any
) => {
  const [filtered, setFiltered] = useState<any[]>(data.slice());

  const onFilterChange = (event: MultiSelectFilterChangeEvent) => {
    setFiltered(filterData(event.filter));
  };

  const filterData = (filter: FilterDescriptor) => {
    const filterData = data.slice();
    return filterBy(filterData, filter);
  };

  return (
    <div className="k-filtercell">
      <MultiSelect
        {...props}
        data={filtered}
        value={value}
        filterable={true}
        onFilterChange={onFilterChange}
        onChange={(event) => {
          setValue(event.target.value);
          if (setRequestChange && requestState) {
            setRequestChange({ ...requestState, skip: 0 });
          }
        }}
      />
      {value.length > 0 && (
        <button
          title="Clear"
          type="button"
          className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
          onClick={(event) => {
            event.preventDefault();
            setValue([]);
            if (setRequestChange && requestState) {
              setRequestChange({ ...requestState, skip: 0 });
            }
          }}
        >
          <span className="k-svg-icon k-svg-i-filter-clear k-button-icon">
            <svg
              aria-hidden="true"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="m143.5 64 168.2 168.2L288 256v160l-64 64V256L64 96V64h79.5zm236.1 100.4L448 96V64H279.3l-64-64L192 22l298 298 22-23.3-132.4-132.3z"></path>
            </svg>
          </span>
        </button>
      )}
    </div>
  );
};

export interface DragCellProps extends GridCellProps {
  dataItem: any;
  isActive: boolean;
  arrows: boolean;
  reOrder: (data: any) => void;
  reOrderPosition: (data: any, position: number) => void;
  dragStart: (data: any) => void;
  dragEnd: (data: any) => void;
}

export const DragCell: React.FC<DragCellProps> = (props: DragCellProps) => {
  const {
    dataItem,
    arrows,
    reOrder,
    reOrderPosition,
    dragStart,
    dragEnd,
  } = props;

  return (
    <Aux>
      {arrows ? (
        <td>
          <span
            className="me-3 ms-1"
            style={{ cursor: "pointer" }}
            onClick={() => reOrderPosition(dataItem, 1)}
          >
            <FontAwesomeIcon icon={faArrowDown} />
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => reOrderPosition(dataItem, -1)}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </span>
        </td>
      ) : (
        <td
          onDragOver={(e) => {
            reOrder(dataItem);
            e.preventDefault();
            e.dataTransfer.dropEffect = "copy";
          }}
          className={props.className}
        >
          <span
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              margin: 0,
              cursor: "move",
            }}
            draggable="true"
            onDragStart={(e) => {
              e.dataTransfer.setData("dragging", "");
              dragStart(dataItem);
            }}
            onClick={(e) => {
              e.preventDefault();
              dragStart(dataItem);
            }}
            onDragEnd={(e) => {
              dragEnd(dataItem);
              e.preventDefault();
            }}
            onDrop={(e) => {
              dragEnd(dataItem);
            }}
          >
            <span className="k-icon k-i-reorder" />
          </span>
        </td>
      )}
    </Aux>
  );
};

export interface IDetailsQcTypeGrid extends GridDetailRowProps {
  dataItem: IQcAttrType;
  setDirty?: (value: boolean) => void;
  history: History;
  viewHidden: boolean;
  selectedQcProd: IQcProd | null;
  arrows: boolean;
}

export const DetailsQcTypeGrid: React.FC<IDetailsQcTypeGrid> = (
  props: IDetailsQcTypeGrid
) => {
  const {
    dataItem,
    history,
    viewHidden,
    selectedQcProd,
    arrows,
    setDirty,
  } = props;

  const [attributeValueData, setAttributeValueData] = useState<
    IQcAttrValue[]
  >();
  const [activeItem, setActiveItem] = useState<IQcAttrValue | null>(null);

  useEffect(() => {
    setAttributeValueData(
      dataItem.qcAttrValues.sort((a, b) => a.sort - b.sort)
    );
  }, [dataItem.qcAttrValues]);

  useEffect(() => {
    if (attributeValueData === undefined) return;

    attributeValueData.forEach((attr, index) => {
      attr.sort = index;
    });
  }, [attributeValueData, dataItem]);

  const reOrder = (dataItem: IQcAttrValue) => {
    if (attributeValueData === undefined) return;
    if (activeItem === null) return;
    if (activeItem?.id === dataItem.id) {
      return;
    }

    var reorderedData = [...attributeValueData];
    var prevIndex = reorderedData.findIndex((p) => p.id === activeItem?.id);
    var nextIndex = reorderedData.findIndex((p) => p.id === dataItem.id);
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, activeItem);

    if (setDirty) setDirty(true);
    setAttributeValueData(reorderedData);
  };

  const reOrderPosition = (dataItem: IQcAttrValue, position: number) => {
    if (attributeValueData === undefined) return;
    if (position > 0) position = 1;
    if (position < 0) position = -1;

    var reorderedData = [...attributeValueData];
    var prevIndex = reorderedData.findIndex((p) => p.id === dataItem.id);
    if (prevIndex + position >= reorderedData.length) return;
    if (prevIndex + position < 0) return;

    var nextIndex = prevIndex + position;
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, dataItem);

    setActiveItem(dataItem);
    if (setDirty) setDirty(true);
    setAttributeValueData(reorderedData);
  };

  const dragStart = (dataItem: IQcAttrValue) => {
    setActiveItem(dataItem);
  };

  const dragEnd = (dataItem: IQcAttrValue) => {
    if (activeItem?.id === dataItem.id) setActiveItem(null);
  };

  return (
    <div>
      <Grid
        data={attributeValueData?.filter(
          (x) =>
            (!x.hide || viewHidden) &&
            (selectedQcProd === null ||
              x.qcProds.some((p) => p.id === selectedQcProd.id))
        )}
        groupable={false}
        rowRender={(props, item) => {
          const propProps = { ...props.props };
          const active = item.dataItem.id === activeItem?.id;
          const wasHidden = item.dataItem.hide;

          var newClassName = "";
          if (wasHidden) newClassName += "text-danger ";
          if (active) newClassName += "bg-secondary";
          propProps.className = newClassName;

          return { ...props, props: { ...propProps } };
        }}
      >
        <GridColumn
          title=""
          width="80px"
          cell={(cell) => {
            return DragCell({
              ...cell,
              dragEnd: (item) => dragEnd(item),
              dragStart: (item) => dragStart(item),
              reOrder: (item) => reOrder(item),
              reOrderPosition: (item, position) =>
                reOrderPosition(item, position),
              arrows: arrows,
              dataItem: cell.dataItem,
              isActive: cell.dataItem.id === activeItem?.id,
            } as DragCellProps);
          }}
        />
        <GridColumn
          field="name"
          title="Attribute Value Name"
          cell={(cell) => {
            return (
              <td>
                <span>{cell.dataItem.name}</span>
                <Button
                  className="float-end"
                  onClick={() =>
                    history.push(Paths.qc.gAttrValuesId(cell.dataItem.id))
                  }
                >
                  Edit
                </Button>
              </td>
            );
          }}
        />
        <GridColumn field="prodNo" title="Product number" />
        <GridColumn
          field="qcProds"
          title="Qc Products"
          cell={(cell) => {
            const isActive = cell.dataItem.id === activeItem?.id;
            return (
              <td className={isActive ? "bg-secondary" : ""}>
                <span>
                  {cell.dataItem.qcProds.map((p) => p.name).join(", ")}
                </span>
              </td>
            );
          }}
        />
      </Grid>
    </div>
  );
};

export interface FilterableComboBoxProps extends ComboBoxProps {
  data: any[];
  textField: string;
  delay?: number;
}

export const FilterableComboBox = (props: FilterableComboBoxProps) => {
  const { delay, ...rest } = props;

  const filterTimeout = useRef<NodeJS.Timeout>();
  const [filterLoading, setFiterLoading] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<any[]>(props.data.slice());

  const onFilterChange = (event: ComboBoxFilterChangeEvent) => {
    if (filterTimeout.current) clearTimeout(filterTimeout.current);
    setFiterLoading(true);

    const setFilter = () => {
      setFiltered(filterData(event.filter));
      setFiterLoading(false);
    };

    if (delay !== undefined && delay === 0) {
      setFilter();
      return;
    }

    filterTimeout.current = setTimeout(setFilter, delay ?? 200);
  };

  const filterData = (filter: FilterDescriptor) => {
    const data = props.data.slice();
    return filterBy(data, filter);
  };

  return (
    <ComboBox
      {...rest}
      className="w-100"
      textField={props.textField}
      data={filtered}
      filterable={true}
      onFilterChange={onFilterChange}
      loading={filterLoading}
    />
  );
};
