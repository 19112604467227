import { ITag } from "models/tag";
import { IUser } from "../../models/user";
import { IIssue } from "models/issue";
import { IGroup } from "models/group";
import { IIssueTemplate } from "models/issueTemplate";
import { IPriority } from "models/priority";
import { IIssueType } from "models/issuetype";

export const ParseUrlLinks = (
  url: string,
  users: IUser[],
  tags: ITag[],
  groups: IGroup[],
  issue: IIssue,
  issueTemplates: IIssueTemplate[],
  priorities: IPriority[],
  issueTypes: IIssueType[]
) => {
  if (url !== undefined) {
    const matchingTemplate = issueTemplates.find(
      (t) => t.templateName.toLowerCase() === url.toLocaleLowerCase()
    );
    if (matchingTemplate) {
      const responsible = users.find(
        (u) => u.id === matchingTemplate.responsibleId
      );
      const group = groups.find((g) => g.id === matchingTemplate.groupId);
      const issueType = issueTypes.find(
        (it) => it.id === matchingTemplate.issueTypeId
      );
      const priority = priorities.find(
        (p) => p.id === matchingTemplate.priorityId
      );
      const casetags = tags.filter((t) =>
        matchingTemplate.tags.some((tt) => tt.id === t.id)
      );
      return {
        ...issue,
        title: matchingTemplate.title || "",
        issueTypeId: issueType?.id || 4,
        group: group,
        responsible: responsible,
        priority: priority
          ? priority
          : priorities.length > 2
          ? priorities[2]
          : priorities[0],
        tags: casetags ? casetags : [],
      };
    }
  }
};
