import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Form,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { Prompt } from "react-router";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import {
  faArrowDown,
  faArrowUp,
  faCheckSquare,
  faChevronDown,
  faChevronUp,
  faCog,
  faCrop,
  faDollyFlatbed,
  faMinusCircle,
  faSave,
  faSpinner,
  faTicketAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@progress/kendo-react-inputs";

import Aux from "../../utils/auxiliary";
import ProductImage from "./ProductImage";
import AddNewJigModal from "../TimeTracks/AddNewJigModal";
import ProductStructureView from "components/TimeTracks/productStructureView";
import ProductStructureImage from "components/TimeTracks/ProductStructureImage";
import { IUser, isSupplier, isSuper, isProtek } from "../../models/user";
import { IProductImage } from "../../models/image";
import {
  getProductLanguage,
  IProduct,
  IProductStructure,
  IProductUser,
} from "../../models/product";
import { Paths } from "../../routes";
import { IJig } from "../../models/jig";
import { IIssue } from "../../models/issue";
import { CreateIssueModal } from "../QualityControl/QcChecklist/CreateIssueModal";
import { ProductPrintLabel } from "./ProductPrintLabel";
import { IQcCheckpoint } from "models/QualityControl/qcCheckpoint";

export interface IProps {
  product: IProduct;
  currentUser: IUser;
  sortedImages: IProductImage[];
  users: IUser[];
  isModal?: boolean;
  defaultRevision?: string;
  structureLoading: boolean;
  setProduct: (product: IProduct) => any;
  onSubmit: (product: IProduct) => any;
  onCancel?: () => void;
  onUserRequestChange?: (token: any) => any;
  checkForProductImage: (productNo: string) => any;
}

export const ProductForm: React.FC<IProps> = (props) => {
  const {
    product,
    currentUser,
    sortedImages,
    users,
    isModal,
    defaultRevision,
    structureLoading,
    setProduct,
    onSubmit,
    onCancel,
    onUserRequestChange,
    checkForProductImage,
  } = props;

  const history = useHistory();

  const [selectedRevision, setSelectedRevision] = useState<string | undefined>(
    defaultRevision ?? "A"
  );

  const [showParents, setShowParents] = useState<boolean>(false);
  const [showContains, setShowContains] = useState<boolean>(false);
  const [showJigs, setShowJigs] = useState<boolean>(false);
  const [showCarriers, setShowCarriers] = useState<boolean>(false);
  const [addCarrierJig, setAddCarrierJig] = useState<boolean>(false);
  const [productClone, setProductClone] = useState<IProduct>(product);
  const [showCases, setShowCases] = useState<boolean>(false);
  const [showWarrantyCases, setShowWarrantyCases] = useState<boolean>(false);
  const [showMissingPart, setShowMissingPart] = useState<boolean>(false);
  const [showQcCheckpoints, setShowQcCheckpoints] = useState<boolean>(false);
  const [ProdCheckpoints, setProdCheckpoints] = useState<boolean>(false);
  const [showTraining, setShowTraining] = useState<boolean>(false);
  const [productUsers, setProductUsers] = useState<IProductUser[]>([]);
  const [structureImage, setStructureImage] = useState<IProductImage>();
  const [structureImages, setStructureImages] = useState<IProductImage[]>([]);
  const [structureImageShow, setStructureImageSHow] = useState<boolean>(false);
  const [jigModalShow, setJigModalShow] = useState<boolean>(false);
  const [changes, setChanges] = useState<boolean>(false);
  const [createWarrantyCase, SetCreateWarrantyCase] = useState<boolean>(false);
  const [createIssueModalShow, setCreateIssueModalShow] = useState<boolean>(
    false
  );
  const [createMissingpartCase, setCreateMissingPartCase] = useState<boolean>(
    false
  );

  React.useEffect(() => {
    setProductUsers(product.productUsers);
  }, [product]);

  useEffect(() => {
    setProductClone(product);
    setChanges(false);
    setShowParents(false);
    setShowContains(false);
  }, [product]);

  useEffect(() => {
    if (defaultRevision) {
      setSelectedRevision(defaultRevision);
    } else if (product.revision && product.revision.trim().length > 0) {
      setSelectedRevision(product.revision);
    } else {
      const revisions = sortedImages.map((i) => i.revision);
      if (revisions.length > 0) setSelectedRevision(revisions[0]);
    }
  }, [sortedImages, defaultRevision, product]);

  const isInternal = () => {
    const internalRoles = process.env.REACT_APP_INTERNAL_ROLES;
    return (
      internalRoles && currentUser?.roles.some((r) => internalRoles.includes(r))
    );
  };

  const handleJigClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    jig: IJig
  ) => {
    event.preventDefault();
    history.push(Paths.gEquipmentsId(jig.id));
  };

  const handleIssueClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    issue: IIssue
  ) => {
    event.preventDefault();
    history.push(Paths.gIssuesId(issue.id));
  };

  const handleCheckpointClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    checkpoint: IQcCheckpoint
  ) => {
    event.preventDefault();
    history.push(Paths.qc.gCheckpointsId(checkpoint.id));
  };

  const handleStructureImageClick = (productStructure: IProductStructure) => {
    const currentRevisionImage = productStructure.images.find(
      (i) => i.revision === productStructure.revision
    );
    const sortedImages = productStructure.images.sort((a, b) => {
      if (a.revision !== undefined && b.revision !== undefined) {
        return -a.revision.localeCompare(b.revision);
      }
      return 1;
    });
    setStructureImage(currentRevisionImage || sortedImages[0]);
    setStructureImages(sortedImages);
    setStructureImageSHow(true);
  };

  const selectedImage = sortedImages.find(
    (i) => i.revision === selectedRevision
  );

  const onUserChange = (users: IUser[], type: number) => {
    let otherTypeUsers: IProductUser[] = productUsers
      ? productUsers.filter((us) => us.trainingUserTypeId !== type)
      : [];

    let newUsers: IProductUser[] = [];

    users.forEach((u) => {
      const newProduct = {
        userId: u.id,
        productNo: product.productNo,
        trainingUserTypeId: type,
      } as IProductUser;
      newUsers.push(newProduct);
    });

    const newProductUsersList = otherTypeUsers.concat(newUsers);
    setProductUsers(newProductUsersList);
    setChanges(true);
  };

  return (
    <Aux>
      <Form
        className="mb-5"
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmit({ ...productClone, productUsers: productUsers });
        }}
      >
        <Prompt
          when={changes}
          message="Are you sure you don't want to save before exiting?"
        />
        <div className="mb-5">
          <h1>{getProductLanguage(currentUser, product)}</h1>
          <br />
          <h5>Product No: {product.productNo}</h5>
          {product.needsApproval && (
            <p className="mb-0 text-warning">This product is a critical part</p>
          )}
          {product.revision && product.revision.trim().length > 0 && (
            <p className="mb-0">{"Current revision: " + product.revision}</p>
          )}
          {product.completedStation && product.completedStation.length > 0 && (
            <p className="mb-3">Assembly station: {product.completedStation}</p>
          )}

          {(isInternal() || isSupplier(currentUser)) &&
            sortedImages?.length > 0 && (
              <div className="my-2">
                <DropdownButton
                  id="select-product"
                  title={"Revision: " + (selectedRevision ?? "None")}
                  className="mb-2"
                >
                  {sortedImages
                    .map((i) => i.revision)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => (
                      <Dropdown.Item
                        key={"prodrevision-" + i}
                        onClick={() => {
                          setSelectedRevision(i);
                        }}
                        active={i === selectedRevision}
                      >
                        {i}
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
                <Col className="mt-2 ms-0 ps-0" md={6} lg={6} xl={4}>
                  {selectedImage && (
                    <ProductImage
                      image={selectedImage}
                      currentUser={currentUser}
                      key={"image-" + selectedImage.id}
                      checkForProductImage={checkForProductImage}
                    />
                  )}
                </Col>
                <div className="mt-2">
                  {selectedRevision !== product.revision && (
                    <small className="text-warning mb-0">
                      Shown product drawing is not the current revision
                    </small>
                  )}
                </div>
              </div>
            )}

          <Col
            className="mt-2 ms-0 ps-0"
            md={isModal ? 12 : 6}
            lg={isModal ? 12 : 6}
            xl={isModal ? 12 : 4}
          >
            <Accordion>
              <Card>
                <Accordion.Header
                  as={Card.Header}
                  eventKey="a"
                  onClick={() => setShowParents(!showParents)}
                >
                  <div className="container-fluid">
                    <FontAwesomeIcon icon={faArrowUp} /> Used in (
                    {product.parentProductStructures !== undefined
                      ? product.parentProductStructures.length
                      : 0}
                    )
                    {structureLoading && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        size="1x"
                        spin
                        className="mx-3 text-warning"
                      />
                    )}
                    <span className="float-end">
                      <FontAwesomeIcon
                        icon={showParents ? faChevronUp : faChevronDown}
                      />{" "}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Collapse eventKey="a" in={showParents}>
                  <ProductStructureView
                    productStructures={product.parentProductStructures}
                    ShowParents={true}
                    history={history}
                    handleStructureImageClick={handleStructureImageClick}
                  />
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col
            className="mt-2 ms-0 ps-0"
            md={isModal ? 12 : 6}
            lg={isModal ? 12 : 6}
            xl={isModal ? 12 : 4}
          >
            <Accordion>
              <Card>
                <Accordion.Header
                  as={Card.Header}
                  eventKey="b"
                  onClick={() => setShowContains(!showContains)}
                >
                  <div className="container-fluid">
                    <FontAwesomeIcon icon={faArrowDown} /> Contains (
                    {product.childrenProductStructures !== undefined
                      ? product.childrenProductStructures.length
                      : 0}
                    )
                    {structureLoading && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        size="1x"
                        spin
                        className="mx-3 text-warning"
                      />
                    )}
                    <span className="float-end">
                      <FontAwesomeIcon
                        icon={showContains ? faChevronUp : faChevronDown}
                      />{" "}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Collapse eventKey="b" in={showContains}>
                  <ProductStructureView
                    productStructures={product.childrenProductStructures}
                    ShowParents={false}
                    history={history}
                    handleStructureImageClick={handleStructureImageClick}
                  />
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          {isInternal() && (
            <Col
              className="mt-2 ms-0 ps-0"
              md={isModal ? 12 : 6}
              lg={isModal ? 12 : 6}
              xl={isModal ? 12 : 4}
            >
              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Header
                    eventKey="c"
                    as={Card.Header}
                    onClick={() => setShowJigs(!showJigs)}
                  >
                    <div className="container-fluid">
                      <FontAwesomeIcon icon={faCrop} /> Equipment (
                      {product.jigs.filter((j) => j.jigCategoryId !== 5).length}
                      )
                      <span className="float-end">
                        <FontAwesomeIcon
                          icon={showJigs ? faChevronUp : faChevronDown}
                        />{" "}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Collapse
                    eventKey="c"
                    as={Card.Body}
                    in={showJigs}
                    className="p-0"
                  >
                    <div>
                      {product.jigs &&
                      product.jigs.filter((j) => j.jigCategoryId !== 5).length >
                        0 ? (
                        product.jigs
                          .filter((j) => j.jigCategoryId !== 5)
                          .map((j) => {
                            return (
                              <Card key={j.id}>
                                <a
                                  href={"/equipments/" + j.id}
                                  onClick={(e) => handleJigClick(e, j)}
                                  style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                  }}
                                  title={j.jignr}
                                >
                                  Equipment No: {j.jignr}
                                </a>
                              </Card>
                            );
                          })
                      ) : (
                        <Card.Body>No related Equipment</Card.Body>
                      )}
                      {!isModal && (
                        <Card>
                          <Button
                            variant="primary"
                            style={{ height: "40px", width: "100%" }}
                            className="p-1 mb-1"
                            onClick={() => setJigModalShow(true)}
                          >
                            <span style={{ fontSize: "14px" }}>
                              Add new Equipment
                            </span>
                          </Button>
                        </Card>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          )}
          {isInternal() && (
            <Col
              className="mt-2 ms-0 ps-0"
              md={isModal ? 12 : 6}
              lg={isModal ? 12 : 6}
              xl={isModal ? 12 : 4}
            >
              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Header
                    eventKey="d"
                    as={Card.Header}
                    onClick={() => setShowCarriers(!showCarriers)}
                  >
                    <div className="container-fluid">
                      <FontAwesomeIcon icon={faDollyFlatbed} /> Carriers (
                      {product.jigs.filter((j) => j.jigCategoryId === 5).length}
                      )
                      <span className="float-end">
                        <FontAwesomeIcon
                          icon={showCarriers ? faChevronUp : faChevronDown}
                        />{" "}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Collapse
                    eventKey="d"
                    as={Card.Body}
                    in={showCarriers}
                    className="p-0"
                  >
                    <div>
                      {product.jigs &&
                      product.jigs.filter((j) => j.jigCategoryId === 5).length >
                        0 ? (
                        product.jigs
                          .filter((j) => j.jigCategoryId === 5)
                          .map((j) => {
                            return (
                              <Card key={j.id}>
                                <a
                                  href={"/jigs/" + j.id}
                                  onClick={(e) => handleJigClick(e, j)}
                                  style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                  }}
                                  title={j.jignr}
                                >
                                  JigNo: {j.jignr}
                                </a>
                              </Card>
                            );
                          })
                      ) : (
                        <Card.Body>No related Carriers</Card.Body>
                      )}
                      {!isModal && (
                        <Card>
                          <Button
                            variant="primary"
                            style={{ height: "40px", width: "100%" }}
                            className="p-1 mb-1"
                            onClick={() => {
                              setJigModalShow(true);
                              setAddCarrierJig(true);
                            }}
                          >
                            <span style={{ fontSize: "14px" }}>
                              Add new Carrier
                            </span>
                          </Button>
                        </Card>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          )}
          <Col
            className="mt-2 ms-0 ps-0"
            md={isModal ? 12 : 6}
            lg={isModal ? 12 : 6}
            xl={isModal ? 12 : 4}
          >
            <Accordion>
              <Card>
                <Accordion.Header
                  as={Card.Header}
                  eventKey="e"
                  onClick={() => setShowCases(!showCases)}
                >
                  <div className="container-fluid">
                    <FontAwesomeIcon icon={faTicketAlt} /> Cases (
                    {
                      product.issues.filter(
                        (i) => i.issueTypeId !== 1 && i.issueTypeId !== 3
                      ).length
                    }
                    )
                    <span className="float-end">
                      <FontAwesomeIcon
                        icon={showCases ? faChevronUp : faChevronDown}
                      />{" "}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Collapse eventKey="e" in={showCases} className="p-0">
                  <div>
                    {product.issues &&
                    product.issues.filter(
                      (i) => i.issueTypeId !== 1 && i.issueTypeId !== 3
                    ).length > 0 ? (
                      product.issues
                        .filter(
                          (i) => i.issueTypeId !== 1 && i.issueTypeId !== 3
                        )
                        .map((i) => {
                          return (
                            <Card key={i.id}>
                              <a
                                href={"/issues/" + i.id}
                                onClick={(e) => handleIssueClick(e, i)}
                                style={{
                                  textDecoration: "none",
                                  padding: "20px",
                                }}
                                title={i.title}
                              >
                                {i.title}{" "}
                                <span
                                  style={{ float: "right" }}
                                  className={
                                    i.status === "Open" ? "text-warning" : ""
                                  }
                                >
                                  Status: {i.status}
                                </span>
                              </a>
                            </Card>
                          );
                        })
                    ) : (
                      <Card.Body>No related Cases</Card.Body>
                    )}
                    {!isModal && (
                      <Card>
                        <Button
                          variant="primary"
                          style={{ height: "40px", width: "100%" }}
                          className="p-1 mb-1"
                          onClick={() => setCreateIssueModalShow(true)}
                        >
                          <span style={{ fontSize: "14px" }}>Open Case</span>
                        </Button>
                      </Card>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col
            className="mt-2 ml-0 pl-0"
            md={isModal ? 12 : 6}
            lg={isModal ? 12 : 6}
            xl={isModal ? 12 : 4}
          >
            <Accordion>
              <Card>
                <Accordion.Header
                  as={Card.Header}
                  eventKey="b"
                  onClick={() => setShowWarrantyCases(!showWarrantyCases)}
                >
                  <div className="container-fluid">
                    <FontAwesomeIcon icon={faTicketAlt} /> Warranty Cases (
                    {product.issues.filter((i) => i.issueTypeId === 3).length})
                    <span className="float-end">
                      <FontAwesomeIcon
                        icon={showWarrantyCases ? faChevronUp : faChevronDown}
                      />{" "}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Collapse eventKey="b" in={showWarrantyCases}>
                  <div>
                    {product.issues &&
                    product.issues.filter((i) => i.issueTypeId === 3).length >
                      0 ? (
                      product.issues
                        .filter((i) => i.issueTypeId === 3)
                        .map((i) => {
                          return (
                            <Card key={i.id}>
                              <a
                                href={"/issues/" + i.id}
                                onClick={(e) => handleIssueClick(e, i)}
                                style={{
                                  textDecoration: "none",
                                  padding: "20px",
                                }}
                                title={i.title}
                              >
                                {i.title}{" "}
                                <span
                                  style={{ float: "right" }}
                                  className={
                                    i.status === "Open" ? "text-warning" : ""
                                  }
                                >
                                  Status: {i.status}
                                </span>
                              </a>
                            </Card>
                          );
                        })
                    ) : (
                      <Card.Body>No warranty Cases</Card.Body>
                    )}
                    {!isModal && (
                      <Card>
                        <Button
                          variant="primary"
                          style={{ height: "40px", width: "100%" }}
                          className="p-1 mb-1"
                          onClick={() => {
                            SetCreateWarrantyCase(true);
                            setCreateIssueModalShow(true);
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>Open Case</span>
                        </Button>
                      </Card>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col
            className="my-2 ms-0 ps-0"
            md={isModal ? 12 : 6}
            lg={isModal ? 12 : 6}
            xl={isModal ? 12 : 4}
          >
            <Accordion>
              <Card>
                <Accordion.Header
                  as={Card.Header}
                  eventKey="f"
                  onClick={() => setShowMissingPart(!showMissingPart)}
                >
                  <div className="container-fluid">
                    <FontAwesomeIcon icon={faMinusCircle} /> Part Request (
                    {product.issues.filter((i) => i.issueTypeId === 1).length})
                    <span className="float-end">
                      <FontAwesomeIcon
                        icon={showMissingPart ? faChevronUp : faChevronDown}
                      />{" "}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Collapse
                  eventKey="f"
                  in={showMissingPart}
                  className="p-0"
                >
                  <div>
                    {product.issues &&
                    product.issues.filter((i) => i.issueTypeId === 1).length >
                      0 ? (
                      product.issues
                        .filter((i) => i.issueTypeId === 1)
                        .map((i) => {
                          return (
                            <Card key={i.id}>
                              <a
                                href={"/issues/" + i.id}
                                onClick={(e) => handleIssueClick(e, i)}
                                style={{
                                  textDecoration: "none",
                                  padding: "20px",
                                }}
                                title={i.title}
                              >
                                {i.title}{" "}
                                <span
                                  style={{ float: "right" }}
                                  className={
                                    i.status === "Open" ? "text-warning" : ""
                                  }
                                >
                                  Status: {i.status}
                                </span>
                              </a>
                            </Card>
                          );
                        })
                    ) : (
                      <Card.Body>No Part Request cases</Card.Body>
                    )}
                    {!isModal && (
                      <Card>
                        <Button
                          variant="primary"
                          style={{ height: "40px", width: "100%" }}
                          className="p-1 mb-1"
                          onClick={() => {
                            setCreateMissingPartCase(true);
                            setCreateIssueModalShow(true);
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>
                            Open Part Request Case
                          </span>
                        </Button>
                      </Card>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col
            className="my-2 ml-0 pl-0"
            md={isModal ? 12 : 6}
            lg={isModal ? 12 : 6}
            xl={isModal ? 12 : 4}
          >
            <Accordion>
              <Card>
                <Accordion.Header
                  as={Card.Header}
                  eventKey="b"
                  onClick={() => setShowQcCheckpoints(!showQcCheckpoints)}
                >
                  <div className="container-fluid">
                    <FontAwesomeIcon icon={faCheckSquare} /> Qc Checkpoints (
                    {product.checkpoints.filter((c) => c.typeId === 1).length})
                    <span className="float-end">
                      <FontAwesomeIcon
                        icon={showQcCheckpoints ? faChevronUp : faChevronDown}
                      />{" "}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Collapse eventKey="b" in={showQcCheckpoints}>
                  <div>
                    {product.checkpoints &&
                    product.checkpoints.filter((c) => c.typeId === 1).length >
                      0 ? (
                      product.checkpoints
                        .filter((c) => c.typeId === 1)
                        .map((c) => {
                          return (
                            <Card key={c.id}>
                              <a
                                href={"/qc/checkpoints/" + c.id}
                                onClick={(e) => handleCheckpointClick(e, c)}
                                style={{
                                  textDecoration: "none",
                                  padding: "20px",
                                }}
                                title={c.name}
                              >
                                {c.name}{" "}
                              </a>
                            </Card>
                          );
                        })
                    ) : (
                      <Card.Body>No qc checkpoints</Card.Body>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col
            className="my-2 ml-0 pl-0"
            md={isModal ? 12 : 6}
            lg={isModal ? 12 : 6}
            xl={isModal ? 12 : 4}
          >
            <Accordion>
              <Card>
                <Accordion.Header
                  as={Card.Header}
                  eventKey="b"
                  onClick={() => setProdCheckpoints(!ProdCheckpoints)}
                >
                  <div className="container-fluid">
                    <FontAwesomeIcon icon={faCheckSquare} /> Product Checkpoints
                    ({product.checkpoints.filter((c) => c.typeId === 3).length})
                    <span className="float-end">
                      <FontAwesomeIcon
                        icon={ProdCheckpoints ? faChevronUp : faChevronDown}
                      />{" "}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Collapse eventKey="b" in={ProdCheckpoints}>
                  <div>
                    {product.checkpoints &&
                    product.checkpoints.filter((c) => c.typeId === 3).length >
                      0 ? (
                      product.checkpoints
                        .filter((c) => c.typeId === 3)
                        .map((c) => {
                          return (
                            <Card key={c.id}>
                              <a
                                href={"/qc/checkpoints/" + c.id}
                                onClick={(e) => handleCheckpointClick(e, c)}
                                style={{
                                  textDecoration: "none",
                                  padding: "20px",
                                }}
                                title={c.name}
                              >
                                {c.name}{" "}
                              </a>
                            </Card>
                          );
                        })
                    ) : (
                      <Card.Body>No product checkpoints</Card.Body>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          {isProtek(currentUser) && !isModal && (
            <Col
              className="mt-2 ms-0 ps-0"
              md={isModal ? 12 : 6}
              lg={isModal ? 12 : 6}
              xl={isModal ? 12 : 4}
            >
              <Accordion>
                <Card>
                  <Accordion.Header
                    as={Card.Header}
                    eventKey="c"
                    onClick={() => setShowTraining(!showTraining)}
                  >
                    <div className="container-fluid">
                      <FontAwesomeIcon icon={faCog} /> Settings
                      <span className="float-end">
                        <FontAwesomeIcon
                          icon={showTraining ? faChevronUp : faChevronDown}
                        />{" "}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Collapse
                    eventKey="c"
                    in={showTraining}
                    className="p-0"
                  >
                    <div className="my-4">
                      <Row className="mx-1">
                        <Col>
                          <Form.Group className="mb-3">
                            <Checkbox
                              className="checklist-checkpoint"
                              name="needsApproval"
                              label="Training required"
                              value={productClone.needsApproval}
                              onChange={() => {
                                setProductClone({
                                  ...productClone,
                                  needsApproval: !productClone.needsApproval,
                                });
                                setChanges(true);
                              }}
                              style={{ height: "20px", width: "20px" }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Checkbox
                              className="checklist-checkpoint"
                              name="weldInspectionRequired"
                              label="weldment requires inspection"
                              value={productClone.weldInspectionRequired}
                              onChange={() => {
                                setProductClone({
                                  ...productClone,
                                  weldInspectionRequired: !productClone.weldInspectionRequired,
                                });
                                setChanges(true);
                              }}
                              style={{ height: "20px", width: "20px" }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {!product.needsApproval && (
                        <p className="text-warning mb-0 ms-3">
                          Disabled for products where training is not required
                        </p>
                      )}
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Required Training Days</Form.Label>
                          <Form.Control
                            type="number"
                            name="requiredTrainingDays"
                            placeholder="Required Training Days"
                            defaultValue={productClone.requiredTrainingDays}
                            min={0}
                            onChange={(e) => {
                              setChanges(true);
                              setProductClone({
                                ...productClone,
                                requiredTrainingDays: parseInt(e.target.value),
                              });
                            }}
                            disabled={!productClone.needsApproval}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Qualified users</Form.Label>
                          <br></br>
                          <MultiSelect
                            id="qualified"
                            name="stationUsers"
                            textField="fullNameWithTitle"
                            dataItemKey="id"
                            data={users}
                            defaultValue={
                              productUsers
                                ? productUsers
                                    .filter((us) => us.trainingUserTypeId === 1)
                                    .map((us) => us.user)
                                : []
                            }
                            onChange={(e) => onUserChange(e.value, 1)}
                            filterable
                            onFilterChange={onUserRequestChange}
                            disabled={
                              !isSuper(currentUser) ||
                              !productClone.needsApproval
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Teachers</Form.Label>
                          <br></br>
                          <MultiSelect
                            id="teachers"
                            name="stationUsers"
                            textField="fullNameWithTitle"
                            dataItemKey="id"
                            data={
                              isSuper(currentUser)
                                ? users
                                : productUsers
                                ? productUsers
                                    .filter((us) => us.trainingUserTypeId === 1)
                                    .map((us) => us.user)
                                : []
                            }
                            defaultValue={
                              productUsers
                                ? productUsers
                                    .filter((us) => us.trainingUserTypeId === 2)
                                    .map((us) => us.user)
                                : []
                            }
                            disabled={
                              !isProtek(currentUser) ||
                              !productClone.needsApproval
                            }
                            autoClose={false}
                            filterable
                            onFilterChange={onUserRequestChange}
                            onChange={(e) => onUserChange(e.value, 2)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Trainees</Form.Label>
                          <br></br>
                          <MultiSelect
                            id="trainee"
                            name="trainee"
                            textField="fullNameWithTitle"
                            dataItemKey="id"
                            defaultValue={
                              productUsers
                                ? productUsers
                                    .filter((us) => us.trainingUserTypeId === 3)
                                    .map((us) => us.user)
                                : []
                            }
                            disabled={true}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Notification users</Form.Label>
                          <br></br>
                          <MultiSelect
                            id="notification"
                            name="notificationUsers"
                            textField="fullNameWithTitle"
                            dataItemKey="id"
                            data={users}
                            defaultValue={productClone.notificationUsers}
                            onChange={(e) => {
                              setProductClone({
                                ...productClone,
                                notificationUsers: e.value,
                              });
                              setChanges(true);
                            }}
                            filterable
                            onFilterChange={onUserRequestChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <ButtonToolbar>
                          <ButtonGroup>
                            <Button
                              type="submit"
                              variant="success"
                              disabled={!changes}
                            >
                              <FontAwesomeIcon icon={faSave} /> Save
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          )}
        </div>
        <ProductPrintLabel product={product} />
      </Form>
      {isModal && (
        <ButtonToolbar>
          <ButtonGroup>
            <Button variant="danger" onClick={() => onCancel && onCancel()}>
              <FontAwesomeIcon icon={faTimesCircle} /> Close
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      )}
      {isInternal() && (
        <AddNewJigModal
          show={jigModalShow}
          addCarrier={addCarrierJig}
          product={product}
          currentUser={currentUser}
          setProduct={setProduct}
          onHide={() => {
            setJigModalShow(false);
            setAddCarrierJig(false);
          }}
        />
      )}
      <CreateIssueModal
        show={createIssueModalShow}
        onHide={() => {
          setCreateMissingPartCase(false);
          SetCreateWarrantyCase(false);
          setCreateIssueModalShow(false);
        }}
        setModalShow={setCreateIssueModalShow}
        history={history}
        timetrackProd={product}
        timetrackIssue={true}
        missingPart={createMissingpartCase}
        warranty={createWarrantyCase}
        setProduct={setProduct}
      />
      {structureImage !== undefined && (
        <ProductStructureImage
          show={structureImageShow}
          image={structureImage}
          images={structureImages}
          navigateImages={false}
          setStructureImage={setStructureImage}
          onHide={() => {
            setStructureImage(undefined);
            setStructureImageSHow(false);
          }}
        />
      )}
    </Aux>
  );
};

export default ProductForm;
