import React from "react";
import { ReactNode } from "react";
import { Accordion, Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";

export interface IProps {
  show: boolean;
  disabled?: boolean;

  hideSave?: boolean;
  hideCancel?: boolean;

  saveTitle?: ReactNode;
  cancelTitle?: ReactNode;

  onSubmit?: () => void;
  onCancel?: () => void;
  goToMachineSpec?: () => any;
}

const SaveBottomBar = (props: IProps) => {
  const {
    show,
    disabled,
    hideSave,
    hideCancel,
    saveTitle,
    cancelTitle,
    goToMachineSpec,
    onSubmit,
    onCancel,
  } = props;

  return (
    <div className="savebottombar">
      <Accordion>
        <Accordion.Collapse eventKey="save-bottom-bar" in={show}>
          <ButtonToolbar className="p-2">
            <ButtonGroup>
              {!hideSave && (
                <Button
                  type="submit"
                  variant="success"
                  onClick={onSubmit}
                  disabled={disabled}
                >
                  <span
                    className="k-icon k-i-save k-i-floppy"
                    style={{ top: "-9%" }}
                  />{" "}
                  {saveTitle ?? "Save"}
                </Button>
              )}
              {!hideCancel && (
                <Button
                  variant="secondary"
                  onClick={onCancel}
                  disabled={disabled}
                >
                  <span
                    className="k-icon k-i-cancel-outline"
                    style={{ top: "-9%" }}
                  />{" "}
                  {cancelTitle ?? "Cancel"}
                </Button>
              )}
            </ButtonGroup>
            <ButtonGroup className="ps-5">
              {goToMachineSpec && (
                <Button onClick={() => goToMachineSpec()} variant="info">
                  Machine Spec
                </Button>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};

export default SaveBottomBar;
