import React, { useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router";
import { connect } from "react-redux";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import {
  CompositeFilterDescriptor,
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";

import Aux from "../utils/auxiliary";
import JigForm from "../components/Jigs/JigForm";
import JigImage from "../components/Jigs/JigImage";
import JigImageDelete from "../components/Jigs/JigImageDelete";
import CloseButton from "components/Common/CloseButton";
import { IJig, IJigCategory } from "../models/jig";
import { IImage, IJigImage } from "../models/image";
import { IProduct } from "../models/product";
import { isJigAdmin, IUser } from "../models/user";
import { IRootState } from "../store";
import {
  createJig,
  createJigImage,
  editJig,
  fetchJig,
  fetchJigCategories,
} from "../store/actions/jigs";
import { fetchFilteredProducts } from "../store/actions/products";
import { deleteRequest, getRequest } from "../store/actions/request";
import { Paths } from "routes";
import { DataList } from "utils/DataList";
import ImageModal from "components/Common/ImageModal";
import { fetchFilteredUsers } from "store/actions/users";
import { fetchGroups } from "store/actions/groups";
import { IGroup } from "models/group";
import JigCheckpointContainer from "./JigCheckpoints/JigCheckpointContainer";
import { ITag } from "models/tag";
import { fetchTags } from "store/actions/tag";

export interface MatchParams {
  id: string;
}

export interface IProps {
  products: DataList<IProduct>;
  current: IUser;
  embeded: boolean;
  usersData: DataResult;
  timetrackProd: IProduct;
  jigCategories: IJigCategory[];
  groups: IGroup[];
  tags: ITag[];
  fetchJig: (id: number) => any;
  editJig: (id: number, jig: IJig) => any;
  createJig: (jig: IJig) => any;
  fetchJigCategories: () => any;
  createJigImage: (jigImage: IJigImage) => any;
  fetchFilteredProducts: (queryStr: string) => any;
  closeModal: () => any;
  setTimetrackProd: (product: IProduct) => any;
  fetchFilteredUsers: (queryStr: string) => any;
  fetchGroups: () => any;
  fetchTags: () => any;
}

const JigFormContainer: React.FC<IProps> = (props) => {
  const {
    current,
    products,
    embeded,
    timetrackProd,
    jigCategories,
    usersData,
    groups,
    tags,
    fetchJig,
    editJig,
    createJig,
    fetchJigCategories,
    createJigImage,
    fetchFilteredProducts,
    closeModal,
    setTimetrackProd,
    fetchFilteredUsers,
    fetchGroups,
    fetchTags,
  } = props;

  const match = useRouteMatch<MatchParams>();
  const history = useHistory();

  const [jig, setJig] = React.useState<IJig>({} as IJig);
  const [modalImage, setModalImage] = useState<IImage>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>();
  const [fileState, setFileState] = useState<UploadFileInfo[]>([]);
  const [userFIlterToken, setUserFilterToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [saveloading, setSaveLoading] = useState<boolean>(false);
  const [requestState, setRequestState] = useState<DataSourceRequestState>({
    skip: 0,
    take: 20,
  });

  const [images, setImages] = useState<IImage[]>([]);
  const [imageOnDelete, setImageOnDelete] = useState<IImage | null>(null);

  useEffect(() => {
    let mounted = true;
    if (!embeded) {
      const id = parseInt(match.params.id);
      if (!isNaN(id)) {
        fetchJig(id).then(({ jig }: { jig: any }) => {
          if (mounted) {
            setJig(jig);
            setImages(jig.images);
          }
        });
      } else {
        setLoading(false);
      }
    }
    if (jigCategories.length === 0) fetchJigCategories();
    if (groups.length === 0) fetchGroups();
    if (tags.length === 0) fetchTags();
    fetchFilteredProducts(toDataSourceRequestString(requestState));
    return () => {
      mounted = false;
    };
  }, [match]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (jig && jig.id > 0) {
      setLoading(false);
    }
  }, [jig]);

  useEffect(() => {
    if (timetrackProd && timetrackProd.productNo !== null) {
      setJig({ ...jig, products: [timetrackProd] });
    }
  }, [timetrackProd]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (modalContent) {
      setModalShow(true);
    } else {
      setModalShow(false);
    }
  }, [modalContent]);

  const fetchImageData = (image: IImage) => {
    if (image.id) {
      getRequest(`/images/show/${image.id}`).then((response) => {
        const data = response.json;
        if (
          data &&
          (data.startsWith("data:image") || data.startsWith("data:video"))
        ) {
          setModalContent(data);
          setModalImage(image);
        } else {
          fetch(data)
            .then((res) => res.blob())
            .then((blob) => {
              const blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl, "_blank");
            });
        }
      });
    }
  };

  const handleFileAdd = (file) => {
    const image: IJigImage = {
      imageId: file.id,
      jigId: jig.id,
    };
    createJigImage(image).then((data) => {
      if (data.errors !== undefined) return;
      const tempImages = images === undefined ? [] : [...images];
      tempImages.push({ id: data.jigImage.imageId, name: file.name } as IImage);
      setImages(tempImages);
      setFileState([]);
    });
  };

  const handleImageDelete = (image: IImage) => {
    setImageOnDelete(image);
  };

  const handleActualDelete = (image: IImage) => {
    deleteRequest(`/jigs/images/${image.id}`, null).then((res) => {
      if (res.status !== 200) return;

      setImageOnDelete(null);
      setImages(images.filter((i) => i.id !== image.id));
    });
  };

  const onSubmit = async (jig: IJig) => {
    setSaveLoading(true);
    if ((match && match.params.id === "new") || embeded) {
      const result = await createJig(jig);
      if (result) {
        if (result.errors !== undefined) return;
        if (!embeded) {
          setJig(result.jig);
          history.replace(Paths.gEquipmentsId(result.jig.id));
          setSaveLoading(false);
        }
        if (embeded) {
          setSaveLoading(false);
          setTimetrackProd({
            ...timetrackProd,
            jigs: [...timetrackProd.jigs, result.jig],
          });
          closeModal();
        }
      }
    } else if (jig.id) {
      const result = await editJig(jig.id, jig);
      if (result) {
        if (result.errors !== undefined) return;
        setJig(result.jig);
        setSaveLoading(false);
      }
    }
  };

  const handleProductRequestChange = (event) => {
    let field = "productNo";
    const value = event.filter.value;

    const request = {
      ...requestState,
      filter: {
        filters: [
          {
            field: field,
            operator: "startswith",
            value: value,
          },
        ],
        logic: "and" as const,
      },
    };
    setRequestState(request);
    fetchProductData(request);
  };

  const fetchProductData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredProducts) {
      if (!request) {
        request = requestState;
      }
      return fetchFilteredProducts(toDataSourceRequestString(request));
    }
  };

  const handleUserRequestChange = (token: any) => {
    if (token.length > 1 || userFIlterToken.length > 0) {
      setUserFilterToken(token);
      const userFilter: CompositeFilterDescriptor = {
        filters: [
          {
            field: "firstname",
            operator: "startswith",
            value: token,
          },
          {
            field: "lastname",
            operator: "startswith",
            value: token,
          },
        ],
        logic: "or" as const,
      };
      const inactiveFilter: CompositeFilterDescriptor = {
        filters: [
          {
            field: "inactive",
            operator: "eq",
            value: 0,
          },
        ],
        logic: "and" as const,
      };
      const filters = [
        userFilter,
        inactiveFilter,
      ] as CompositeFilterDescriptor[];
      const request = {
        ...requestState,
        filter: {
          filters,
          logic: "and" as const,
        },
      };
      setRequestState(request);
      return fetchUserData(request);
    }
  };

  const fetchUserData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredUsers) {
      if (!request) {
        request = requestState;
      }
      return fetchFilteredUsers(toDataSourceRequestString(request));
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const matchMedSize = window.matchMedia("(min-width: 300px)");
  const matchBigSize = window.matchMedia("(min-width: 600px)");

  return (
    <Aux>
      {!embeded && <CloseButton onCLick={onCancel} />}
      {loading ? (
        <p className="text-center m-3">
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        </p>
      ) : (
        <div>
          <br />
          <Row>
            <Col className={embeded ? "col-12 col-md-12" : "col-12 col-md-6"}>
              <JigForm
                jig={jig}
                categories={jigCategories}
                files={fileState}
                history={history}
                products={products.data}
                current={current}
                embeded={embeded}
                usersData={usersData}
                groups={groups
                  .filter((g) => !g.hide)
                  .sort((a, b) => a.name.localeCompare(b.name))}
                tags={tags.sort((a, b) => a.name.localeCompare(b.name))}
                editable={isJigAdmin(current)}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onFileAdd={handleFileAdd}
                setFiles={setFileState}
                onProductRequestChange={handleProductRequestChange}
                onUserRequestChange={handleUserRequestChange}
              />
            </Col>
            {!embeded && jig.id > 0 && (
              <Col className="col-12 col-md-6">
                {images && images.length > 0 ? (
                  <Card bg="secondary" className="border-light mb-2">
                    <Card.Body className="container">
                      <Row>
                        {images.map((image, i) => (
                          <Aux key={"image-" + image.id}>
                            {matchBigSize && i % 3 === 0 ? (
                              <div className="w-100"></div>
                            ) : (
                              matchMedSize &&
                              !matchBigSize &&
                              i % 2 === 0 && <div className="w-100"></div>
                            )}

                            <Col className="d-flex">
                              <JigImage
                                current={current}
                                image={image}
                                setImageShow={(image) => fetchImageData(image)}
                                onDeleteClick={handleImageDelete}
                                card={true}
                              />
                            </Col>
                          </Aux>
                        ))}
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <Card bg="secondary" className="border-light mb-2">
                    <Card.Body>No images found for this equipment</Card.Body>
                  </Card>
                )}
              </Col>
            )}
          </Row>
          {saveloading && (
            <p className="text-center m-3">
              <FontAwesomeIcon
                icon={faSpinner}
                size="3x"
                spin
                style={{
                  top: "50%",
                  left: "50%",
                  position: "fixed",
                  zIndex: 999,
                }}
              />
            </p>
          )}
          {jig.id > 0 && <JigCheckpointContainer history={history} jig={jig} />}
        </div>
      )}

      <ImageModal
        image={modalImage}
        content={modalContent}
        show={modalShow}
        images={images}
        setImageShow={(image) => fetchImageData(image)}
        handleHide={() => {
          setModalContent("");
          setModalShow(false);
        }}
      />
      {imageOnDelete !== null && (
        <JigImageDelete
          image={imageOnDelete}
          show={imageOnDelete !== null}
          handleHide={() => setImageOnDelete(null)}
          onConfirmed={(image) => {
            handleActualDelete(image);
          }}
        />
      )}
    </Aux>
  );
};
const mapStateToProps = (state: IRootState) => ({
  products: state.productsReducer.filteredProducts,
  current: state.authReducer.user,
  jigCategories: state.jigsReducer.jigCategories,
  usersData: state.usersReducer.filteredUsers,
  groups: state.groupsReducer.groups,
  tags: state.tagReducer.tags,
});

const mapDisptachToProps = (dispatch: any) => ({
  fetchJig: (id: number) => dispatch(fetchJig(id)),
  editJig: (id: number, jig: IJig) => dispatch(editJig(id, jig)),
  createJig: (jig: IJig) => dispatch(createJig(jig)),
  fetchJigCategories: () => dispatch(fetchJigCategories()),
  createJigImage: (JigImage: IJigImage) => dispatch(createJigImage(JigImage)),
  fetchFilteredProducts: (queryStr: string) =>
    dispatch(fetchFilteredProducts(queryStr)),
  fetchFilteredUsers: (queryStr: string) =>
    dispatch(fetchFilteredUsers(queryStr)),
  fetchGroups: () => dispatch(fetchGroups()),
  fetchTags: () => dispatch(fetchTags()),
});

const jigForm = JigFormContainer as (props: IProps) => JSX.Element;

export default connect(mapStateToProps, mapDisptachToProps)(jigForm);
