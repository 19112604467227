import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../actions/request";
import { IOrderStatus } from "../../models/order";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../actions/alerts";

export const FETCH_ORDER_STATUSES_SUCCESS = "FETCH_ORDER_STATUSES_SUCCESS";
export const FETCH_ORDER_STATUS_SUCCESS = "FETCH_ORDER_STATUS_SUCCESS";
export const FETCH_FILTERED_ORDER_STATUSES_SUCCESS =
  "FETCH_FILTERED_ORDER_STATUSES_SUCCESS";
export const CREATE_ORDER_STATUS_SUCCESS = "CREATE_ORDER_STATUS_SUCCESS";
export const EDIT_ORDER_STATUS_SUCCESS = "EDIT_ORDER_STATUS_SUCCESS";
export const DELETE_ORDER_STATUS_SUCCESS = "DELETE_ORDER_STATUS_SUCCESS";
export const ORDER_STATUS_REQUEST_FAILURE = "ORDER_STATUS_REQUEST_FAILURE";
export const ORDER_STATUS_REQUEST_ERROR = "ORDER_STATUS_REQUEST_ERROR";

export interface OrderStatusActions {
  FETCH_ORDER_STATUSES_SUCCESS: {
    type: typeof FETCH_ORDER_STATUSES_SUCCESS;
    orderStatuses: IOrderStatus[];
  };
  FETCH_ORDER_STATUS_SUCCESS: {
    type: typeof FETCH_ORDER_STATUS_SUCCESS;
    orderStatus: IOrderStatus;
  };
  FETCH_FILTERED_ORDER_STATUSES_SUCCESS: {
    type: typeof FETCH_FILTERED_ORDER_STATUSES_SUCCESS;
    orderStatuses: DataResult;
  };
  CREATE_ORDER_STATUS_SUCCESS: {
    type: typeof CREATE_ORDER_STATUS_SUCCESS;
    orderStatus: IOrderStatus;
  };
  EDIT_ORDER_STATUS_SUCCESS: {
    type: typeof EDIT_ORDER_STATUS_SUCCESS;
    orderStatus: IOrderStatus;
  };
  DELETE_ORDER_STATUS_SUCCESS: {
    type: typeof DELETE_ORDER_STATUS_SUCCESS;
  };
  ORDER_STATUS_REQUEST_FAILURE: {
    type: typeof ORDER_STATUS_REQUEST_FAILURE;
    errors: any;
  };
  ORDER_STATUS_REQUEST_ERROR: {
    type: typeof ORDER_STATUS_REQUEST_ERROR;
    errors: any;
  };
}

export type OrderStatusActionTypes =
  | OrderStatusActions[typeof FETCH_ORDER_STATUSES_SUCCESS]
  | OrderStatusActions[typeof FETCH_ORDER_STATUS_SUCCESS]
  | OrderStatusActions[typeof FETCH_FILTERED_ORDER_STATUSES_SUCCESS]
  | OrderStatusActions[typeof CREATE_ORDER_STATUS_SUCCESS]
  | OrderStatusActions[typeof EDIT_ORDER_STATUS_SUCCESS]
  | OrderStatusActions[typeof DELETE_ORDER_STATUS_SUCCESS]
  | OrderStatusActions[typeof ORDER_STATUS_REQUEST_FAILURE]
  | OrderStatusActions[typeof ORDER_STATUS_REQUEST_ERROR];

export const actionCreators = {
  fetchOrderStatusesSuccess: (
    orderStatuses: IOrderStatus[]
  ): OrderStatusActions[typeof FETCH_ORDER_STATUSES_SUCCESS] => ({
    type: FETCH_ORDER_STATUSES_SUCCESS,
    orderStatuses: orderStatuses,
  }),
  fetchOrderStatusSuccess: (
    orderStatus: IOrderStatus
  ): OrderStatusActions[typeof FETCH_ORDER_STATUS_SUCCESS] => ({
    type: FETCH_ORDER_STATUS_SUCCESS,
    orderStatus: orderStatus,
  }),
  fetchFilteredOrderStatusesSuccess: (
    orderStatuses: DataResult
  ): OrderStatusActions[typeof FETCH_FILTERED_ORDER_STATUSES_SUCCESS] => ({
    type: FETCH_FILTERED_ORDER_STATUSES_SUCCESS,
    orderStatuses: orderStatuses,
  }),
  createOrderStatusSuccess: (
    orderStatus: IOrderStatus
  ): OrderStatusActions[typeof CREATE_ORDER_STATUS_SUCCESS] => ({
    type: CREATE_ORDER_STATUS_SUCCESS,
    orderStatus: orderStatus,
  }),
  editOrderStatusSuccess: (
    orderStatus: IOrderStatus
  ): OrderStatusActions[typeof EDIT_ORDER_STATUS_SUCCESS] => ({
    type: EDIT_ORDER_STATUS_SUCCESS,
    orderStatus: orderStatus,
  }),
  deleteOrderStatusSuccess: (): OrderStatusActions[typeof DELETE_ORDER_STATUS_SUCCESS] => ({
    type: DELETE_ORDER_STATUS_SUCCESS,
  }),
  orderStatusRequestFailure: (
    status: number
  ): OrderStatusActions[typeof ORDER_STATUS_REQUEST_FAILURE] => ({
    type: ORDER_STATUS_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  orderStatusRequestError: (
    errors: any
  ): OrderStatusActions[typeof ORDER_STATUS_REQUEST_ERROR] => ({
    type: ORDER_STATUS_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchOrderStatuses() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_ORDER_STATUSES_SUCCESS));
    const { status, json } = await getRequest("/vismaOrderStatuses");
    dispatch(requestEnd(FETCH_ORDER_STATUSES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchOrderStatusesSuccess(json))
      : dispatch(actionCreators.orderStatusRequestFailure(status));
  };
}

export const fetchOrderStatus = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/vismaOrderStatuses/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchOrderStatusSuccess(json))
      : dispatch(actionCreators.orderStatusRequestFailure(status));
  };
};

export function fetchFilteredOrderStatuses(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_ORDER_STATUSES_SUCCESS));
    const { status, json } = await getRequest(
      `/vismaOrderStatuses/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_ORDER_STATUSES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredOrderStatusesSuccess(json))
      : dispatch(actionCreators.orderStatusRequestFailure(status));
  };
}

export const createOrderStatus = (orderStatus: IOrderStatus) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(
      "/vismaOrderStatuses",
      orderStatus
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(orderStatus.name));
        return dispatch(actionCreators.createOrderStatusSuccess(json));
      case 400:
        return dispatch(actionCreators.orderStatusRequestError(json));
      default:
        dispatch(saveFailed(orderStatus.name));
        return dispatch(actionCreators.orderStatusRequestFailure(status));
    }
  };
};

export const editOrderStatus = (id: number, orderStatus: IOrderStatus) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/vismaOrderStatuses/${id}`,
      orderStatus
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(orderStatus.name));
        return dispatch(actionCreators.editOrderStatusSuccess(json));
      case 400:
        dispatch(saveFailed(orderStatus.name));
        return dispatch(actionCreators.orderStatusRequestError(json));
      default:
        dispatch(saveFailed(orderStatus.name));
        return dispatch(actionCreators.orderStatusRequestFailure(status));
    }
  };
};

export const deleteOrderStatus = (id: number, orderStatus: IOrderStatus) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(
      `/vismaOrderStatuses/${id}`,
      orderStatus
    );
    if (status === 200) {
      dispatch(deleteSuccess(orderStatus.name));
      return dispatch(actionCreators.deleteOrderStatusSuccess());
    }
    dispatch(saveFailed(orderStatus.name));
    return dispatch(actionCreators.orderStatusRequestFailure(status));
  };
};
