import React from "react";
import { Button, Modal } from "react-bootstrap";

export interface IProps {
  show: boolean;
  onUpdateClick: () => void;
}

const UpdateDialog = (props: IProps) => {
  const { show, onUpdateClick } = props;
  return (
    <div className="updatemodal ms-2 mb-2">
      {show && (
        <Modal.Dialog className="m-0">
          <Modal.Header>Update Available</Modal.Header>
          <Modal.Footer>
            <Button
              className="mx-auto"
              variant="warning"
              onClick={onUpdateClick}
            >
              <span className="text-dark">Reload Site</span>
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      )}
    </div>
  );
};

export default UpdateDialog;
