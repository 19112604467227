import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { routerReducer } from "react-router-redux";
import { createBrowserHistory } from "history";
import { signal } from "./actions/signalr";

/** Import custom reducers */
import uiReducer, { UIState } from "./reducers/ui";
import loadingReducer, { LoadingState } from "../store/reducers/loading";
import alertsReducer, { AlertState } from "./reducers/alerts";
import authReducer, { AuthState } from "./reducers/auth";
import usersReducer, { UserState } from "./reducers/users";
import productsReducer, { ProductState } from "./reducers/products";
import issuesReducer, { IssueState } from "./reducers/issues";
import jigsReducer, { JigState } from "./reducers/jigs";
import groupsReducer, { GroupState } from "./reducers/groups";
import prioritiesReducer, { PriorityState } from "./reducers/priorities";
import issueTypesReducer, { IssueTypeState } from "./reducers/issuetypes";
import messagesReducer, { MessageState } from "./reducers/messages";
import stationsReducer, { StationState } from "./reducers/stations";
import tagReducer, { TagState } from "./reducers/tag";
import tagCategoryReducer, { TagCategoryState } from "./reducers/tagCategory";
import linkReducer, { LinkState } from "./reducers/links";
import measurementUnitReducer, {
  MeasurementUnitState,
} from "./reducers/measurementunits";
import qcChassisReducer, {
  QcChassiState,
} from "./reducers/QualityControl/qcChassis";
import qcStatusReducer, {
  QcStatusState,
} from "./reducers/QualityControl/qcStatus";
import qcSpecReducer, { QcSpecState } from "./reducers/QualityControl/qcSpec";
import qcProdTypeReducer, {
  QcProdTypeState,
} from "./reducers/QualityControl/qcProdType";
import qcProdReducer, { QcProdState } from "./reducers/QualityControl/qcProd";
import qcAttrTypeReducer, {
  QcAttrTypeState,
} from "./reducers/QualityControl/qcAttrType";
import qcAttrValueReducer, {
  QcAttrValueState,
} from "./reducers/QualityControl/qcAttrValue";
import qcCheckpointReducer, {
  QcCheckpointState,
} from "./reducers/QualityControl/qcCheckpoint";
import qcInspectionReducer, {
  QcInspectionState,
} from "./reducers/QualityControl/qcInspection";
import ordersReducer, { OrderState } from "./reducers/orders";
import orderLinesReducer, { OrderLineState } from "./reducers/orderlines";
import qcCheklistReducer, {
  QcChecklistState,
} from "./reducers/QualityControl/qcChecklist";
import qcMachineSpecReducer, {
  QcMachineSpecState,
} from "./reducers/QualityControl/qcMachineSpec";
import trainingReducer, { TrainingState } from "./reducers/training";
import timeTracksReducer from "./reducers/timetracks";
import orderStatusReducer, { OrderStatusState } from "./reducers/orderStatus";
import resourceReducer, { ResourceState } from "./reducers/resources";
import signalReducer, { SignalState } from "./reducers/signalr";
import issueTemplateReducer, {
  IssueTemplateState,
} from "./reducers/issuetemplates";

export interface IRootState {
  router: any;
  routing: any;
  authReducer: AuthState;
  uiReducer: UIState;
  loadingReducer: LoadingState;
  alertsReducer: AlertState;
  usersReducer: UserState;
  productsReducer: ProductState;
  issuesReducer: IssueState;
  jigsReducer: JigState;
  groupsReducer: GroupState;
  prioritiesReducer: PriorityState;
  issueTypesReducer: IssueTypeState;
  messagesReducer: MessageState;
  measurementUnitReducer: MeasurementUnitState;
  stationsReducer: StationState;
  tagReducer: TagState;
  tagCategoryReducer: TagCategoryState;
  qcChassisReducer: QcChassiState;
  qcStatusReducer: QcStatusState;
  qcSpecReducer: QcSpecState;
  qcProdTypeReducer: QcProdTypeState;
  qcProdReducer: QcProdState;
  qcAttrTypeReducer: QcAttrTypeState;
  qcAttrValueReducer: QcAttrValueState;
  qcCheckpointReducer: QcCheckpointState;
  qcInspectionReducer: QcInspectionState;
  ordersReducer: OrderState;
  orderLinesReducer: OrderLineState;
  qcCheklistReducer: QcChecklistState;
  qcMachineSpecReducer: QcMachineSpecState;
  trainingReducer: TrainingState;
  timeTracksReducer: any;
  orderStatusReducer: OrderStatusState;
  linkReducer: LinkState;
  resourceReducer: ResourceState;
  signalReducer: SignalState;
  issueTemplateReducer: IssueTemplateState;
}

const history = createBrowserHistory();

export const rootReducer = combineReducers<IRootState>({
  router: connectRouter(history),
  routing: routerReducer,
  authReducer,
  uiReducer,
  loadingReducer,
  alertsReducer,
  usersReducer,
  productsReducer,
  issuesReducer,
  jigsReducer,
  groupsReducer,
  prioritiesReducer,
  issueTypesReducer,
  messagesReducer,
  stationsReducer,
  tagReducer,
  tagCategoryReducer,
  measurementUnitReducer,
  qcChassisReducer,
  qcStatusReducer,
  qcSpecReducer,
  qcProdTypeReducer,
  qcProdReducer,
  qcAttrTypeReducer,
  qcAttrValueReducer,
  qcCheckpointReducer,
  qcInspectionReducer,
  ordersReducer,
  orderLinesReducer,
  qcCheklistReducer,
  signalReducer,
  timeTracksReducer,
  qcMachineSpecReducer,
  trainingReducer,
  orderStatusReducer,
  linkReducer,
  resourceReducer,
  issueTemplateReducer,
});

// rehydrating state on app start: implement here...
const recoverState = (): IRootState => ({} as IRootState);

export const configureStore = () => {
  const store = createStore(
    rootReducer,
    recoverState(),
    compose(applyMiddleware(routerMiddleware(history), signal, thunkMiddleware))
  );
  return store;
};

export type Store = { getState: () => IRootState; dispatch: () => void };

// systemjs-hot-reloader hook, rehydrating the state of redux store
export const __reload = (exports: any) => {
  console.log(exports.store.getState());
};
