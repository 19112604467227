import React from "react";
import { Button } from "react-bootstrap";

export interface IProps {
  onCLick: () => any;
}

export const CloseButton: React.FC<IProps> = (props) => {
  const { onCLick } = props;

  return (
    <div className="text-end pb-2">
      <Button
        variant="danger"
        title="Close window"
        onClick={onCLick}
        className="m-0 px-2 py-1"
      >
        <span className="k-icon k-i-close k-i-x mx-1 my-2" />
      </Button>
    </div>
  );
};

export default CloseButton;
