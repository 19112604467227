import { getRequest } from "../request";
import { Dispatch } from "redux";
import { IQcMachineSpec } from "../../../models/QualityControl/qcMachineSpec";

export const FETCH_QC_MACHINESPEC_SUCCESS = "FETCH_QC_MACHINESPEC_SUCCESS";
export const QC_MACHINESPEC_REQUEST_FAILURE = "QC_MACHINESPEC_REQUEST_FAILURE";

export interface QcMachineSpecActions {
  FETCH_QC_MACHINESPEC_SUCCESS: {
    type: typeof FETCH_QC_MACHINESPEC_SUCCESS;
    qcMachineSpec: IQcMachineSpec;
  };
  QC_MACHINESPEC_REQUEST_FAILURE: {
    type: typeof QC_MACHINESPEC_REQUEST_FAILURE;
    errors: any;
  };
}

export type QcMachineSpecActionTypes =
  | QcMachineSpecActions[typeof FETCH_QC_MACHINESPEC_SUCCESS]
  | QcMachineSpecActions[typeof QC_MACHINESPEC_REQUEST_FAILURE];

export const actionCreators = {
  fetchQcMachineSpecSuccess: (
    qcMachineSpec: IQcMachineSpec
  ): QcMachineSpecActions[typeof FETCH_QC_MACHINESPEC_SUCCESS] => ({
    type: FETCH_QC_MACHINESPEC_SUCCESS,
    qcMachineSpec: qcMachineSpec,
  }),
  qcMachineSpecRequestFailure: (
    status: number
  ): QcMachineSpecActions[typeof QC_MACHINESPEC_REQUEST_FAILURE] => ({
    type: QC_MACHINESPEC_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export const fetchQcMachineSpec = (
  qcChassiId: number,
  qcSpecId: number | null
) => {
  return async (dispatch: Dispatch) => {
    let query = `/qcMachineSpec/${qcChassiId}`;
    if (qcSpecId !== null) {
      query = query + `/${qcSpecId}`;
    }
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchQcMachineSpecSuccess(json))
      : dispatch(actionCreators.qcMachineSpecRequestFailure(status));
  };
};
