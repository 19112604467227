import {
  QcAttrTypeActionTypes,
  FETCH_QC_ATTR_TYPES_SUCCESS,
  FETCH_QC_ATTR_TYPE_SUCCESS,
  FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS,
  CREATE_QC_ATTR_TYPE_SUCCESS,
  EDIT_QC_ATTR_TYPE_SUCCESS,
  DELETE_QC_ATTR_TYPE_SUCCESS,
  EDIT_QC_ATTR_TYPE_ORDERS_SUCCESS,
} from "../../actions/QualityControl/qcAttrType";
import { IQcAttrType } from "../../../models/QualityControl/qcAttrType";
import { DataList } from "../../../utils/DataList";

export interface QcAttrTypeState {
  readonly qcAttrTypes: IQcAttrType[];
  readonly filteredQcAttrTypes: DataList<IQcAttrType>;
  readonly qcAttrType: IQcAttrType | null;
  readonly errors: any;
}

export const initialState: QcAttrTypeState = {
  qcAttrTypes: [],
  filteredQcAttrTypes: { data: [], total: 0 },
  qcAttrType: null,
  errors: null,
};

const qcAttrTypeReducer = (
  state = initialState,
  action: QcAttrTypeActionTypes
): QcAttrTypeState => {
  switch (action.type) {
    case FETCH_QC_ATTR_TYPES_SUCCESS:
      return { ...state, qcAttrTypes: action.qcAttrTypes };
    case FETCH_QC_ATTR_TYPE_SUCCESS:
      return { ...state, qcAttrType: action.qcAttrType };
    case FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS:
      return { ...state, filteredQcAttrTypes: action.qcAttrTypes };
    case CREATE_QC_ATTR_TYPE_SUCCESS:
      return {
        ...state,
        qcAttrType: action.qcAttrType,
        qcAttrTypes:
          state.qcAttrTypes.length > 0
            ? [...state.qcAttrTypes.concat([action.qcAttrType])]
            : [],
      };
    case EDIT_QC_ATTR_TYPE_SUCCESS:
      if (state.qcAttrTypes.length > 0) {
        state.qcAttrTypes.splice(
          state.qcAttrTypes.findIndex((t) => t.id === action.qcAttrType.id),
          1,
          action.qcAttrType
        );
      }
      return {
        ...state,
        qcAttrType: action.qcAttrType,
        qcAttrTypes: state.qcAttrTypes.length > 0 ? state.qcAttrTypes : [],
      };
    case EDIT_QC_ATTR_TYPE_ORDERS_SUCCESS:
      return { ...state, qcAttrTypes: action.qcAttrTypes };
    case DELETE_QC_ATTR_TYPE_SUCCESS:
      return { ...state, qcAttrType: null, qcAttrTypes: [] };
    default:
      return state;
  }
};

export default qcAttrTypeReducer;
