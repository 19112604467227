import React, { useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";

import Aux from "../utils/auxiliary";
import ProductForm from "../components/Products/ProductForm";
import { IProduct } from "../models/product";
import { IProductImage } from "../models/image";
import { IUser } from "../models/user";
import {
  fetchFilteredProducts,
  fetchProduct,
  editProduct,
  fetchProductStructures,
} from "../store/actions/products";
import { IRootState } from "../store/index";
import { fetchFilteredUsers } from "store/actions/users";
import {
  DataResult,
  DataSourceRequestState,
  FilterDescriptor,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";
import CloseButton from "components/Common/CloseButton";
import { checkForProductImage } from "store/actions/productsimages";

export interface MatchParams {
  id: string;
}

export interface IProps {
  productId?: any;
  embed?: boolean;
  isModal?: boolean;
  currentUser: IUser;
  defaultRevision?: string;
  onClose?: () => void;
  fetchProduct: (id: string) => any;
  fetchFilteredProducts: (queryStr: string) => any;
  editProduct: (id: string, product: IProduct) => any;
  fetchFilteredUsers: (query: string) => any;
  fetchProductStructures: (productNo: string) => any;
  checkForProductImage: (productNo: string) => any;
}

export const ProductFormContainer: React.FC<IProps> = (props) => {
  const {
    productId,
    embed,
    isModal,
    currentUser,
    defaultRevision,
    onClose,
    fetchProduct,
    editProduct,
    fetchFilteredUsers,
    fetchProductStructures,
    checkForProductImage,
  } = props;

  const match = useRouteMatch<MatchParams>();
  const history = useHistory();

  const [product, setProduct] = useState<IProduct>({} as IProduct);
  const [sortedImages, setImages] = useState<IProductImage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [structureLoading, setStructureLoading] = useState<boolean>(true);
  const [usersData, setUserData] = useState<DataResult>({
    data: [],
    total: 0,
  } as DataResult);
  const [
    userRequestState,
    setUserRequestState,
  ] = useState<DataSourceRequestState>({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    setLoading(true);
    let mounted = true;
    let id = "";
    if (productId) {
      id = productId;
    } else if (match) {
      id = match.params.id;
    }
    if (fetchProduct) {
      fetchProduct(id).then(({ product }: { product: IProduct }) => {
        if (mounted) {
          setProduct(product);

          const sortImages = product.images?.sort((a, b) => {
            if (a.revision !== undefined && b.revision !== undefined) {
              return -a.revision.localeCompare(b.revision);
            }
            return 1;
          });
          setImages(sortImages);
          setLoading(false);
          setStructureLoading(true);
          fetchProductStructures(product.productNo).then((result) => {
            setProduct({
              ...product,
              childrenProductStructures:
                result.product.childrenProductStructures,
              parentProductStructures: result.product.parentProductStructures,
            });
            setStructureLoading(false);
          });
        }
      });
    } else {
      setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (product: IProduct) => {
    const result = await editProduct(product.productNo, product);
    if (result) {
      if (result.errors !== undefined) return;
      setProduct(result.product);
    }
  };

  const handleCancel = () => {
    if (isModal) {
      if (onClose) onClose();
      return;
    }
    history.goBack();
  };

  const handleUserRequestChange = (token: any) => {
    let filter = {} as FilterDescriptor;
    if (token.filter) {
      filter = token.filter;
    } else {
      filter = {
        field: "fullname",
        operator: "contains",
        value: token,
      };
    }
    const request = {
      ...userRequestState,
      filter: {
        filters: [
          filter,
          {
            field: "inactive",
            operator: "eq",
            value: 0,
          },
        ],
        logic: "and" as const,
      },
    };
    setUserRequestState(request);
  };

  useEffect(() => {
    let canceled = false;

    fetchUserData().then((result: any) => {
      if (!canceled) {
        if (result.type === "FETCH_FILTEREDUSERS_SUCCESS") {
          setUserData(result.usersData);
        }
      }
    });

    return () => {
      canceled = true;
    };
  }, [userRequestState]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUserData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredUsers) {
      if (!request) {
        request = userRequestState;
      }
      return fetchFilteredUsers(toDataSourceRequestString(request));
    }
  };

  return (
    <Aux>
      {!embed && <CloseButton onCLick={handleCancel} />}
      {loading ? (
        <p className="text-center m-3">
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        </p>
      ) : (
        <div>
          {product ? (
            <ProductForm
              product={product}
              currentUser={currentUser}
              sortedImages={sortedImages}
              defaultRevision={defaultRevision ?? product.revision}
              isModal={isModal}
              users={usersData ? usersData.data : []}
              structureLoading={structureLoading}
              setProduct={setProduct}
              onSubmit={onSubmit}
              onCancel={handleCancel}
              onUserRequestChange={handleUserRequestChange}
              checkForProductImage={checkForProductImage}
            />
          ) : (
            <h1>Product Not Found</h1>
          )}
        </div>
      )}
    </Aux>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentUser: state.authReducer.user,
  products: state.productsReducer.filteredProducts.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchProduct: (id: string) => dispatch(fetchProduct(id)),
  fetchFilteredProducts: (queryStr: string) =>
    dispatch(fetchFilteredProducts(queryStr)),
  editProduct: (id: string, product: IProduct) =>
    dispatch(editProduct(id, product)),
  fetchFilteredUsers: (query: string) => dispatch(fetchFilteredUsers(query)),
  fetchProductStructures: (productNo: string) =>
    dispatch(fetchProductStructures(productNo)),
  checkForProductImage: (productNo: string) =>
    dispatch(checkForProductImage(productNo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductFormContainer);
