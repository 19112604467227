import { History } from "history";
import { getAuditColor, getAuditIcon, IAuditEntry } from "models/audit";
import React from "react";
import AuditEntryLine from "./AuditEntryLine";

export interface IProps {
  entries: IAuditEntry[];
  history: History;
}

const AuditIssueEntries = (props: IProps) => {
  const { entries, history } = props;

  return (
    <div>
      {entries
        .sort(
          (a, b) =>
            new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
        )
        .map((entry, i) => (
          <AuditEntryLine
            key={"entry-cat-" + i}
            color={getAuditColor(entry)}
            icon={getAuditIcon(entry)}
            entry={entry}
            history={history}
            truncateText={false}
          />
        ))}
    </div>
  );
};

export default AuditIssueEntries;
