import izitoast from "izitoast";
import {
  AlertActionTypes,
  SAVE_SUCCESS,
  SAVE_FAILED,
  DELETE_SUCCESS,
} from "../actions/alerts";

export type AlertState = {};

export const initialState: AlertState = {};

export default function alertsReducer(
  state = initialState,
  action: AlertActionTypes
): AlertState {
  switch (action.type) {
    case SAVE_SUCCESS:
      izitoast.success({ message: `${action.text} saved successfully.` });
      return state;
    case SAVE_FAILED:
      izitoast.error({ message: `Failed saving ${action.text}.` });
      return state;
    case DELETE_SUCCESS:
      izitoast.success({ message: `${action.text} deleted successfully.` });
      return state;
    default:
      return state;
  }
}
