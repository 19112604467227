import React, { useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";

import { IResource } from "models/resource";
import Aux from "../../utils/auxiliary";

export interface IProps {
  resources: DataResult;
  viewHidden: boolean;
  onResourceSelect: (resource: IResource) => void;
  handleFetch: (query: string) => void;
}

const ResourceGrid: React.FC<IProps> = (props: IProps) => {
  const { resources, viewHidden, onResourceSelect, handleFetch } = props;

  const fetchTempRequestState = () => {
    const request = localStorage.getItem("temp_resources_req_state");
    if (!request) return null;
    return JSON.parse(request) as {
      requestState: DataSourceRequestState;
    };
  };

  const [tempReqState] = useState(fetchTempRequestState());
  const [viewHiddenState, setViewHiddenState] = useState<boolean>(viewHidden);

  const [requestState, setRequestState] = useState<DataSourceRequestState>(
    tempReqState?.requestState || {
      skip: 0,
      take: 20,
      filter: {
        filters: [
          {
            field: "hide",
            operator: "eq",
            value: viewHidden,
          },
        ],
        logic: "and" as const,
      },
    }
  );

  useEffect(() => {
    handleFetch(`${toDataSourceRequestString(requestState)}`);
  }, [requestState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewHiddenState !== viewHidden) {
      setViewHiddenState(viewHidden);
      setRequestState({
        skip: 0,
        take: 20,
        filter: {
          filters: [
            {
              field: "hide",
              operator: "eq",
              value: viewHidden,
            },
          ],
          logic: "and" as const,
        },
      });
    }
  }, [viewHidden]); // eslint-disable-line react-hooks/exhaustive-deps

  const mql = window.matchMedia("(min-width: 800px)");

  return (
    <Aux>
      <Grid
        {...requestState}
        {...resources}
        sortable
        filterable
        pageable={{
          info: true,
          pageSizes: [5, 10, 15, 20, 30, 40, 50],
          buttonCount: 10,
        }}
        onRowClick={(event: GridRowClickEvent) => {
          localStorage.setItem(
            "temp_resources_req_state",
            JSON.stringify({ requestState })
          );
          onResourceSelect(event.dataItem);
        }}
        onDataStateChange={(event: GridDataStateChangeEvent) => {
          setRequestState(event.dataState);
        }}
        rowRender={(props, item) => {
          const propProps = { ...props.props };
          propProps.className =
            propProps.className + (item.dataItem.hide ? " text-danger" : "");
          return { ...props, props: { ...propProps } };
        }}
        className={mql.matches ? "" : "mobile-grid"}
      >
        <GridColumn
          title="License Name"
          field="name"
          filter="text"
          width={mql.matches ? undefined : 200}
        />
        <GridColumn
          title="Number of licenses"
          field="quantity"
          filter="numeric"
          width={mql.matches ? undefined : 200}
        />
        <GridColumn
          title="Number of users"
          field="userCount"
          filter="numeric"
          width={mql.matches ? undefined : 200}
        />
      </Grid>
    </Aux>
  );
};

export default ResourceGrid;
