export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const SAVE_FAILED = "SAVE_FAILED";
export const DELETE_SUCCESS = "DELETE_SUCCESS";

export type AlertActions = {
  SAVE_SUCCESS: {
    type: typeof SAVE_SUCCESS;
    text: string;
  };
  SAVE_FAILED: {
    type: typeof SAVE_FAILED;
    text: string;
  };
  DELETE_SUCCESS: {
    type: typeof DELETE_SUCCESS;
    text: string;
  };
};

export type AlertActionTypes =
  | AlertActions[typeof SAVE_SUCCESS]
  | AlertActions[typeof SAVE_FAILED]
  | AlertActions[typeof DELETE_SUCCESS];

export const saveSuccess = (
  text: string
): AlertActions[typeof SAVE_SUCCESS] => ({
  type: SAVE_SUCCESS,
  text: text,
});

export const saveFailed = (text: string): AlertActions[typeof SAVE_FAILED] => ({
  type: SAVE_FAILED,
  text: text,
});

export const deleteSuccess = (
  text: string
): AlertActions[typeof DELETE_SUCCESS] => ({
  type: DELETE_SUCCESS,
  text: text,
});
