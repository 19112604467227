import {
  QcChassisActionTypes,
  FETCH_QC_CHASSIS_SUCCESS,
  FETCH_QC_CHASSI_SUCCESS,
  FETCH_FILTERED_QC_CHASSIS_SUCCESS,
  FETCH_FILTERED_BASIC_CHASSIS_SUCCESS,
  FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS,
  CREATE_QC_CHASSI_SUCCESS,
  EDIT_QC_CHASSI_SUCCESS,
  DELETE_QC_CHASSI_SUCCESS,
  FETCH_QC_CHASSI_AUDIT_SUCCESS,
} from "../../actions/QualityControl/qcChassis";
import {
  IQcChassi,
  IQcChassisProgress,
} from "../../../models/QualityControl/qcChassis";
import { DataList } from "../../../utils/DataList";
import { IAuditEntry } from "models/audit";

export interface QcChassiState {
  readonly qcChassis: IQcChassi[];
  readonly qcChassisProgress: IQcChassisProgress | null;
  readonly audit: IAuditEntry[];
  readonly filteredQcChassis: DataList<IQcChassi>;
  readonly basicChassis: IQcChassi[];
  readonly filteredBasicChassis: DataList<IQcChassi>;
  readonly qcChassi: IQcChassi | null;
  readonly errors: any;
}

export const initialState: QcChassiState = {
  qcChassis: [],
  qcChassisProgress: null,
  filteredQcChassis: { data: [], total: 0 },
  basicChassis: [],
  filteredBasicChassis: { data: [], total: 0 },
  audit: [],
  qcChassi: null,
  errors: null,
};

const qcChassisReducer = (
  state = initialState,
  action: QcChassisActionTypes
): QcChassiState => {
  switch (action.type) {
    case FETCH_QC_CHASSIS_SUCCESS:
      return { ...state, qcChassis: action.qcChassis };
    case FETCH_QC_CHASSI_SUCCESS:
      return { ...state, qcChassi: action.qcChassi };
    case FETCH_QC_CHASSI_AUDIT_SUCCESS:
      return { ...state, audit: action.audit };
    case FETCH_FILTERED_QC_CHASSIS_SUCCESS:
      return { ...state, filteredQcChassis: action.qcChassis };
    case FETCH_FILTERED_BASIC_CHASSIS_SUCCESS:
      return { ...state, filteredBasicChassis: action.qcChassis };
    case FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS:
      return { ...state, qcChassisProgress: action.qcChassizProgress };
    case CREATE_QC_CHASSI_SUCCESS:
      return {
        ...state,
        qcChassi: action.qcChassi,
        qcChassis:
          state.qcChassis.length > 0
            ? [...state.qcChassis.concat([action.qcChassi])]
            : [],
      };
    case EDIT_QC_CHASSI_SUCCESS:
      if (state.qcChassis.length > 0) {
        state.qcChassis.splice(
          state.qcChassis.findIndex((t) => t.id === action.qcChassi.id),
          1,
          action.qcChassi
        );
      }
      return {
        ...state,
        qcChassi: action.qcChassi,
        qcChassis: state.qcChassis.length > 0 ? state.qcChassis : [],
      };
    case DELETE_QC_CHASSI_SUCCESS:
      return { ...state, qcChassi: null, qcChassis: [] };
    default:
      return state;
  }
};

export default qcChassisReducer;
