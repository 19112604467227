import React from "react";
import { FormRenderProps } from "@progress/kendo-react-form";
import { Button, Card, DropdownButton, Dropdown, Image } from "react-bootstrap";

import { IUserPrefs } from "../../models/userprefs";

export interface IProps {
  userPrefs: IUserPrefs[];
  formRenderProps: FormRenderProps;
  onSelectLanguage: (userPrefs: IUserPrefs[], lang: string) => any;
}

const UserPreference: React.FC<IProps> = (props) => {
  const { userPrefs, formRenderProps, onSelectLanguage } = props;

  return (
    <Card.Body style={{ height: "5em" }}>
      Language:
      <DropdownButton
        id="dropdown-prefs-lang"
        title={
          <Image
            src={"assets/images/flags/" + userPrefs[0].lang + ".png"}
            style={{ height: "1em" }}
          ></Image>
        }
        style={{ display: "inline-block", left: "1em" }}
      >
        <Dropdown.Item
          as={Button}
          onClick={() => {
            onSelectLanguage(userPrefs, "no");
            formRenderProps.onChange("langpref", { value: "no" });
          }}
        >
          <Image
            src="assets/images/flags/no.png"
            style={{ height: "1em" }}
          ></Image>{" "}
          NO
        </Dropdown.Item>
        <Dropdown.Item
          as={Button}
          onClick={() => {
            onSelectLanguage(userPrefs, "en");
            formRenderProps.onChange("langpref", { value: "en" });
          }}
        >
          <Image
            src="assets/images/flags/en.png"
            style={{ height: "1em" }}
          ></Image>{" "}
          EN/US
        </Dropdown.Item>
      </DropdownButton>
    </Card.Body>
  );
};

export default UserPreference;
