import {
  MessageActionsTypes,
  EDIT_MESSAGE_SUCCESS,
  MESSAGE_REQUEST_ERRORS,
  MESSAGE_REQUEST_FAILURE,
} from "../actions/messages";
import { IMessage } from "../../models/message";

export interface MessageState {
  readonly messages: IMessage[];
  readonly message: IMessage | null;
  readonly errors: any;
}

export const initialState: MessageState = {
  messages: [],
  message: null,
  errors: null,
};

const messagesReducer = (
  state = initialState,
  action: MessageActionsTypes
): MessageState => {
  switch (action.type) {
    case EDIT_MESSAGE_SUCCESS:
      return { ...state, message: action.message };
    case MESSAGE_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case MESSAGE_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default messagesReducer;
