import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../request";
import {
  ICheckpointTeacher,
  IQcCheckpoint,
  IUserCheckpoint,
} from "../../../models/QualityControl/qcCheckpoint";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../alerts";

export const FETCH_QC_CHECKPOINTS_SUCCESS = "FETCH_QC_CHECKPOINTS_SUCCESS";
export const FETCH_QC_CHECKPOINT_SUCCESS = "FETCH_QC_CHECKPOINT_SUCCESS";
export const FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS =
  "FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS";
export const CREATE_QC_CHECKPOINT_SUCCESS = "CREATE_QC_CHECKPOINT_SUCCESS";
export const EDIT_QC_CHECKPOINT_SUCCESS = "EDIT_QC_CHECKPOINT_SUCCESS";
export const EDIT_QC_CHECKPOINT_USERS_SUCCESS =
  "EDIT_QC_CHECKPOINT_USERS_SUCCESS";
export const EDIT_QC_CHECKPOINTS_ORDER_SUCCESS =
  "EDIT_QC_CHECKPOINTS_ORDER_SUCCESS";
export const DELETE_QC_CHECKPOINT_SUCCESS = "DELETE_QC_CHECKPOINT_SUCCESS";
export const QC_CHECKPOINT_REQUEST_FAILURE = "QC_CHECKPOINT_REQUEST_FAILURE";
export const QC_CHECKPOINT_REQUEST_ERROR = "QC_CHECKPOINT_REQUEST_ERROR";
export const FETCH_USER_CHECKPOINTS_SUCCESS = "FETCH_USER_CHECKPOINTS_SUCCESS";
export const FETCH_TEACHER_CHECKPOINTS_SUCCESS =
  "FETCH_TEACHER_CHECKPOINTS_SUCCESS";
export const FETCH_CHECKPOINT_TEACHERS_SUCCESS =
  "FETCH_CHECKPOINT_TEACHERS_SUCCESS";

export interface QcCheckpointActions {
  FETCH_QC_CHECKPOINTS_SUCCESS: {
    type: typeof FETCH_QC_CHECKPOINTS_SUCCESS;
    qcCheckpoints: IQcCheckpoint[];
  };
  FETCH_USER_CHECKPOINTS_SUCCESS: {
    type: typeof FETCH_USER_CHECKPOINTS_SUCCESS;
    userCheckpoints: IUserCheckpoint[];
  };
  FETCH_TEACHER_CHECKPOINTS_SUCCESS: {
    type: typeof FETCH_TEACHER_CHECKPOINTS_SUCCESS;
    teacherCheckpoints: ICheckpointTeacher[];
  };
  FETCH_CHECKPOINT_TEACHERS_SUCCESS: {
    type: typeof FETCH_CHECKPOINT_TEACHERS_SUCCESS;
    checkpointTeachers: ICheckpointTeacher[];
  };
  FETCH_QC_CHECKPOINT_SUCCESS: {
    type: typeof FETCH_QC_CHECKPOINT_SUCCESS;
    qcCheckpoint: IQcCheckpoint;
  };
  FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS: {
    type: typeof FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS;
    qcCheckpoints: DataResult;
  };
  CREATE_QC_CHECKPOINT_SUCCESS: {
    type: typeof CREATE_QC_CHECKPOINT_SUCCESS;
    qcCheckpoint: IQcCheckpoint;
  };
  EDIT_QC_CHECKPOINTS_ORDER_SUCCESS: {
    type: typeof EDIT_QC_CHECKPOINTS_ORDER_SUCCESS;
    qcCheckpoints: IQcCheckpoint[];
  };
  EDIT_QC_CHECKPOINT_SUCCESS: {
    type: typeof EDIT_QC_CHECKPOINT_SUCCESS;
    qcCheckpoint: IQcCheckpoint;
  };
  EDIT_QC_CHECKPOINT_USERS_SUCCESS: {
    type: typeof EDIT_QC_CHECKPOINT_USERS_SUCCESS;
    qcCheckpoint: IQcCheckpoint;
  };
  DELETE_QC_CHECKPOINT_SUCCESS: {
    type: typeof DELETE_QC_CHECKPOINT_SUCCESS;
  };
  QC_CHECKPOINT_REQUEST_FAILURE: {
    type: typeof QC_CHECKPOINT_REQUEST_FAILURE;
    errors: any;
  };
  QC_CHECKPOINT_REQUEST_ERROR: {
    type: typeof QC_CHECKPOINT_REQUEST_ERROR;
    errors: any;
  };
}

export type QcCheckpointActionTypes =
  | QcCheckpointActions[typeof FETCH_QC_CHECKPOINTS_SUCCESS]
  | QcCheckpointActions[typeof FETCH_QC_CHECKPOINT_SUCCESS]
  | QcCheckpointActions[typeof FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS]
  | QcCheckpointActions[typeof FETCH_USER_CHECKPOINTS_SUCCESS]
  | QcCheckpointActions[typeof FETCH_TEACHER_CHECKPOINTS_SUCCESS]
  | QcCheckpointActions[typeof FETCH_CHECKPOINT_TEACHERS_SUCCESS]
  | QcCheckpointActions[typeof CREATE_QC_CHECKPOINT_SUCCESS]
  | QcCheckpointActions[typeof EDIT_QC_CHECKPOINTS_ORDER_SUCCESS]
  | QcCheckpointActions[typeof EDIT_QC_CHECKPOINT_SUCCESS]
  | QcCheckpointActions[typeof EDIT_QC_CHECKPOINT_USERS_SUCCESS]
  | QcCheckpointActions[typeof DELETE_QC_CHECKPOINT_SUCCESS]
  | QcCheckpointActions[typeof QC_CHECKPOINT_REQUEST_FAILURE]
  | QcCheckpointActions[typeof QC_CHECKPOINT_REQUEST_ERROR];

export const actionCreators = {
  fetchQcCheckpointsSuccess: (
    qcCheckpoints: IQcCheckpoint[]
  ): QcCheckpointActions[typeof FETCH_QC_CHECKPOINTS_SUCCESS] => ({
    type: FETCH_QC_CHECKPOINTS_SUCCESS,
    qcCheckpoints: qcCheckpoints,
  }),
  fetchUserCheckpointsSuccess: (
    userCheckpoints: IUserCheckpoint[]
  ): QcCheckpointActions[typeof FETCH_USER_CHECKPOINTS_SUCCESS] => ({
    type: FETCH_USER_CHECKPOINTS_SUCCESS,
    userCheckpoints: userCheckpoints,
  }),
  fetchTeacherCheckpointsSuccess: (
    teacherCheckpoints: ICheckpointTeacher[]
  ): QcCheckpointActions[typeof FETCH_TEACHER_CHECKPOINTS_SUCCESS] => ({
    type: FETCH_TEACHER_CHECKPOINTS_SUCCESS,
    teacherCheckpoints: teacherCheckpoints,
  }),
  fetchCheckpointTeachersSuccess: (
    checkpointTeachers: ICheckpointTeacher[]
  ): QcCheckpointActions[typeof FETCH_CHECKPOINT_TEACHERS_SUCCESS] => ({
    type: FETCH_CHECKPOINT_TEACHERS_SUCCESS,
    checkpointTeachers: checkpointTeachers,
  }),
  fetchQcCheckpointSuccess: (
    qcCheckpoint: IQcCheckpoint
  ): QcCheckpointActions[typeof FETCH_QC_CHECKPOINT_SUCCESS] => ({
    type: FETCH_QC_CHECKPOINT_SUCCESS,
    qcCheckpoint: qcCheckpoint,
  }),
  fetchFilteredQcCheckpointsSuccess: (
    qcCheckpoints: DataResult
  ): QcCheckpointActions[typeof FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS] => ({
    type: FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS,
    qcCheckpoints: qcCheckpoints,
  }),
  createQcCheckpointSuccess: (
    qcCheckpoint: IQcCheckpoint
  ): QcCheckpointActions[typeof CREATE_QC_CHECKPOINT_SUCCESS] => ({
    type: CREATE_QC_CHECKPOINT_SUCCESS,
    qcCheckpoint: qcCheckpoint,
  }),
  editQcCheckpointsOrderSuccess: (
    qcCheckpoints: IQcCheckpoint[]
  ): QcCheckpointActions[typeof EDIT_QC_CHECKPOINTS_ORDER_SUCCESS] => ({
    type: EDIT_QC_CHECKPOINTS_ORDER_SUCCESS,
    qcCheckpoints: qcCheckpoints,
  }),
  editQcCheckpointSuccess: (
    qcCheckpoint: IQcCheckpoint
  ): QcCheckpointActions[typeof EDIT_QC_CHECKPOINT_SUCCESS] => ({
    type: EDIT_QC_CHECKPOINT_SUCCESS,
    qcCheckpoint: qcCheckpoint,
  }),
  editQcCheckpointUsersSuccess: (
    qcCheckpoint: IQcCheckpoint
  ): QcCheckpointActions[typeof EDIT_QC_CHECKPOINT_USERS_SUCCESS] => ({
    type: EDIT_QC_CHECKPOINT_USERS_SUCCESS,
    qcCheckpoint: qcCheckpoint,
  }),
  deleteQcCheckpointSuccess: (): QcCheckpointActions[typeof DELETE_QC_CHECKPOINT_SUCCESS] => ({
    type: DELETE_QC_CHECKPOINT_SUCCESS,
  }),
  qcCheckpointRequestFailure: (
    status: number
  ): QcCheckpointActions[typeof QC_CHECKPOINT_REQUEST_FAILURE] => ({
    type: QC_CHECKPOINT_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  qcCheckpointRequestError: (
    errors: any
  ): QcCheckpointActions[typeof QC_CHECKPOINT_REQUEST_ERROR] => ({
    type: QC_CHECKPOINT_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchQcCheckpoints() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_QC_CHECKPOINTS_SUCCESS));
    const { status, json } = await getRequest("/qcCheckpoints");
    dispatch(requestEnd(FETCH_QC_CHECKPOINTS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchQcCheckpointsSuccess(json))
      : dispatch(actionCreators.qcCheckpointRequestFailure(status));
  };
}

export const fetchUserCheckpoints = (
  userId: number,
  stationId: number | null
) => {
  return async (dispatch: Dispatch) => {
    let query = `/qcCheckpoints/usercheckpoints/${userId}`;
    if (stationId !== null) {
      query = query + `/${stationId}`;
    }
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchUserCheckpointsSuccess(json))
      : dispatch(actionCreators.qcCheckpointRequestFailure(status));
  };
};

export const fetchTeacherCheckpoints = (
  userId: number,
  stationId: number | null
) => {
  return async (dispatch: Dispatch) => {
    let query = `/qcCheckpoints/teachercheckpoints/${userId}`;
    if (stationId !== null) {
      query = query + `/${stationId}`;
    }
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchTeacherCheckpointsSuccess(json))
      : dispatch(actionCreators.qcCheckpointRequestFailure(status));
  };
};

export const fetchCheckpointTeachers = (
  checkpointId: number,
  stationId: number | null
) => {
  return async (dispatch: Dispatch) => {
    let query = `/qcCheckpoints/checkpointteachers/${checkpointId}`;
    if (stationId !== null) {
      query = query + `/${stationId}`;
    }
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchCheckpointTeachersSuccess(json))
      : dispatch(actionCreators.qcCheckpointRequestFailure(status));
  };
};

export const fetchQcCheckpoint = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcCheckpoints/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchQcCheckpointSuccess(json))
      : dispatch(actionCreators.qcCheckpointRequestFailure(status));
  };
};

export function fetchFilteredQcCheckpoints(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS));
    const { status, json } = await getRequest(
      `/qcCheckpoints/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredQcCheckpointsSuccess(json))
      : dispatch(actionCreators.qcCheckpointRequestFailure(status));
  };
}

export const createQcCheckpoint = (qcCheckpoint: IQcCheckpoint) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/qcCheckpoints", qcCheckpoint);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcCheckpoint.name));
        return dispatch(actionCreators.createQcCheckpointSuccess(json));
      case 400:
        dispatch(saveFailed(qcCheckpoint.name));
        return dispatch(actionCreators.qcCheckpointRequestError(json));
      default:
        dispatch(saveFailed(qcCheckpoint.name));
        return dispatch(actionCreators.qcCheckpointRequestFailure(status));
    }
  };
};

export const editQcCheckpointsOrder = (
  qcCheckpoints: IQcCheckpoint[],
  stationId?: number,
  tagId?: number
) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      stationId
        ? `/qcCheckpoints/order/${stationId}`
        : tagId
        ? `/qcCheckpoints/order/tag/${tagId}`
        : `/qcCheckpoints/order`,
      qcCheckpoints
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess("Checkpoints Order"));
        return dispatch(actionCreators.editQcCheckpointsOrderSuccess(json));
      case 400:
        dispatch(saveFailed("Checkpoints Order"));
        return dispatch(actionCreators.qcCheckpointRequestError(json));
      default:
        dispatch(saveFailed("Checkpoints Order"));
        return dispatch(actionCreators.qcCheckpointRequestFailure(status));
    }
  };
};

export const editQcCheckpoint = (id: number, qcCheckpoint: IQcCheckpoint) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/qcCheckpoints/${id}`,
      qcCheckpoint
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcCheckpoint.name));
        return dispatch(actionCreators.editQcCheckpointSuccess(json));
      case 400:
        dispatch(saveFailed(qcCheckpoint.name));
        return dispatch(actionCreators.qcCheckpointRequestError(json));
      default:
        dispatch(saveFailed(qcCheckpoint.name));
        return dispatch(actionCreators.qcCheckpointRequestFailure(status));
    }
  };
};

export const editQcCheckpointUsers = (
  id: number,
  qcCheckpoint: IQcCheckpoint
) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/qcCheckpoints/users/${id}`,
      qcCheckpoint
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcCheckpoint.name));
        return dispatch(actionCreators.editQcCheckpointUsersSuccess(json));
      case 400:
        dispatch(saveFailed(qcCheckpoint.name));
        return dispatch(actionCreators.qcCheckpointRequestError(json));
      default:
        dispatch(saveFailed(qcCheckpoint.name));
        return dispatch(actionCreators.qcCheckpointRequestFailure(status));
    }
  };
};

export const deleteQcCheckpoint = (id: number, qcCheckpoint: IQcCheckpoint) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(
      `/qcCheckpoints/${id}`,
      qcCheckpoint
    );
    if (status === 200) {
      dispatch(deleteSuccess(qcCheckpoint.name));
      return dispatch(actionCreators.deleteQcCheckpointSuccess());
    }
    dispatch(saveFailed(qcCheckpoint.name));
    return dispatch(actionCreators.qcCheckpointRequestFailure(status));
  };
};
