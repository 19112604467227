import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import Forbidden from "./components/Auth/Forbidden";
import PrivateRoute from "./components/Auth/PrivateRoute";
import SilentRefresh from "./components/Auth/SilentRefresh";
import Unauthorized from "./components/Auth/Unauthorized";
import LoginContainer from "./containers/LoginContainer";
import LogoutContainer from "./containers/LogoutContainer";
import IssueFormContainer from "./containers/IssueFormContainer";
import IssueContainer from "./containers/IssuesContainer";
import ProductFormContainer from "./containers/ProductFormContainer";
import ProductsContainer from "./containers/ProductsContainer";
import JigFormContainer from "./containers/JigFormContainer";
import JigsContainer from "./containers/JigsContainer";
import UserProfileContainer from "./containers/UserProfileContainer";
import SalesforceCaseRedirect from "./containers/Redirects/Salesforce/SalesforceCaseContainer";
import OrderImagesFormContainer from "containers/Orders/OrderImagesFormContainer";
import ResourceContainer from "containers/ResourceContainer";
import ResourceFormContainer from "containers/ResourceFormContainer";

const TagContainer = lazy(() => import("./containers/TagContainer"));
const RoleContainer = lazy(() => import("./containers/RoleContainer"));
const UsersContainer = lazy(() => import("./containers/UsersContainer"));
const AdminContainer = lazy(() => import("./containers/AdminContainer"));
const GroupsContainer = lazy(() => import("./containers/GroupsContainer"));
const TagFormContainer = lazy(() => import("./containers/TagFormContainer"));
const KittingContainer = lazy(() => import("./containers/KittingContainer"));
const StationContainer = lazy(() => import("./containers/StationsContainer"));
const UserFormContainer = lazy(() => import("./containers/UserFormContainer"));
const TrainingContainer = lazy(() => import("./containers/TrainingsContainer"));
const GroupFormContainer = lazy(() => import("./containers/GroupFormContainer"));
const OrdersContainer = lazy(() => import("./containers/Orders/OrdersContainer"));
const IssueTypesContainer = lazy(() => import("./containers/IssueTypesContainer"));
const JigCategoryContainer = lazy(() => import("./containers/JigCategoryContainer"));
const KittingFormContainer = lazy(() => import("./containers/KittingFormContainer"));
const StationFormContainer = lazy(() => import("./containers/StationFormContainer"));
const TrainingFormContainer = lazy(() => import("./containers/TrainingFormContainer"));
const OrderFormContainer = lazy(() => import("./containers/Orders/OrderFormContainer"));
const IssueTypeFormContainer = lazy(() => import("./containers/IssueTypeFormContainer"));
const IssueTemplateContainer = lazy(() => import("./containers/IssueTemplateContainer"));
const IssueTemplateFormContainer = lazy(() => import("./containers/IssueTemplateFormContainer"));
const MyOrdersContainer = lazy(() => import("./containers/TimeTracks/MyOrdersContainer"));
const TimeTrackContainer = lazy(() => import("./containers/TimeTracks/TimeTrackContainer"));
const OrderWorkListContainer = lazy(() => import("./containers/Orders/OrderWorkListContainer"));
const QcSpecContainer = lazy(() => import("./containers/QualityControl/QcSpec/QcSpecContainer"));
const TimeTrackFormContainer = lazy(() => import("./containers/TimeTracks/TimeTrackFormContainer"));
const TimeTrackGridContainer = lazy(() => import("./containers/TimeTracks/TimeTrackGridContainer"));
const JigCheckpointContainer = lazy(() => import("containers/JigCheckpoints/JigCheckpointContainer"));
const QcStatusContainer = lazy(() => import("./containers/QualityControl/QcStatus/QcStatusContainer"));
const QcSpecFormContainer = lazy(() => import("./containers/QualityControl/QcSpec/QcSpecFormContainer"));
const QcProdFormContainer = lazy(() => import("./containers/QualityControl/QcProd/QcProdFormContainer"));
const QcChassisContainer = lazy(() => import("./containers/QualityControl/QcChassis/QcChassisContainer"));
const QcProdTypeContainer = lazy(() => import("./containers/QualityControl/QcProdType/QcProdTypeContainer"));
const QcSettingsContainer = lazy(() => import("./containers/QualityControl/QcSettings/QcSettingsContainer"));
const QcAttrTypeContainer = lazy(() => import("./containers/QualityControl/QcAttrType/QcAttrTypeContainer"));
const IssueCheckpointContainer = lazy(() => import("./containers/IssueCheckpoints/IssueCheckpointContainer"));
const JigCheckpointFormContainer = lazy(() => import("containers/JigCheckpoints/JigCheckpointFormContainer"));
const KittingStatuskListContainer = lazy(() => import("./containers/Orders/OrderKittingStatusListContainer"));
const QcStatusFormContainer = lazy(() => import("./containers/QualityControl/QcStatus/QcStatusFormContainer"));
const QcAttrValueContainer = lazy(() => import("./containers/QualityControl/QcAttrValue/QcAttrValueContainer"));
const QcChecklistContainer = lazy(() => import("./containers/QualityControl/QcChecklist/QcChecklistContainer"));
const QcChassisFormContainer = lazy(() => import("./containers/QualityControl/QcChassis/QcChassisFormContainer"));
const QcCheckpointContainer = lazy(() => import("./containers/QualityControl/QcCheckpoint/QcCheckpointContainer"));
const QcInspectionContainer = lazy(() => import("./containers/QualityControl/QcInspection/QcInspectionContainer"));
const ProductCheckpointContainer = lazy(() => import("./containers/ProductCheckpoints/ProductCheckpointContainer"));
const QcProdTypeFormContainer = lazy(() => import("./containers/QualityControl/QcProdType/QcProdTypeFormContainer"));
const QcAttrTypeFormContainer = lazy(() => import("./containers/QualityControl/QcAttrType/QcAttrTypeFormContainer"));
const IssueCheckpointFormContainer = lazy(() => import("./containers/IssueCheckpoints/IssueCheckpointFormContainer"));
const QcMachineSpecContainer = lazy(() => import("./containers/QualityControl/QcMachineSpec/QcMachineSpecContainer"));
const QcAttrValueFromContainer = lazy(() => import("./containers/QualityControl/QcAttrValue/QcAttrValueFormContainer"));
const QcChassisProgressContainer = lazy(() => import("./containers/QualityControl/QcChassis/QcChassisProgressContainer"));
const QcCheckpointFormContainer = lazy(() => import("./containers/QualityControl/QcCheckpoint/QcCheckpointFormContainer"));
const QcInspectionFormContainer = lazy(() => import("./containers/QualityControl/QcInspection/QcInspectionFormContainer"));
const ProductCheckpointFormContainer = lazy(() => import("./containers/ProductCheckpoints/ProductCheckpointFormContainer"));
const QcMachineSpecFormContainer = lazy(() => import("./containers/QualityControl/QcMachineSpec/QcMachineSpecFormContainer"));
//const ApproveQcChassisFormContainer = lazy(() => import("./containers/QualityControl/QcChassis/ApproveQcChassisFormContainer"));

export const Paths = {
  root: "/",
  admin: "/admin",
  profile: "/profile",

  roles: "/roles",
  users: "/users",
  usersCreate: "/users/create",
  usersId: "/users/:id",
  gUsersId: (id: number) => `${Paths.users}/${id}`,

  products: "/products",
  productsId: "/products/:id",
  gProductsId: (id: string) => `${Paths.products}/${id}`,

  orders: "/orders",
  orderId: "/orders/:id",
  gOrderId: (id: number) => `${Paths.orders}/${id}`,

  issues: "/issues",
  partrequest: "/partrequest",
  administration: "/administration",
  warranty: "/warranty",
  technicalSupport: "/technicalsupport",
  orkelDirekte: "/orkeldirekte",
  maintenance: "/maintenance",
  hms: "/hms",
  issuesCreate: "/issues/new",
  issuesId: "/issues/:id",
  gIssuesId: (id: number) => `${Paths.issues}/${id}`,

  issue: {
    tags: "/issue/tags",
    tagsCreate: "/issue/tags/new",
    tagsId: "/issue/tags/:id",
    gTagsId: (id: number) => `${Paths.issue.tags}/${id}`,

    types: "/issue/types",
    typeId: "/issue/types/:id",
    gTypeId: (id: number) => `${Paths.issue.types}/${id}`,

    templates: "/issue/templates",
    templatesCreate: "/issue/templates/new",
    templateId: "/issue/templates/:id",
    gTemplateId: (id: number) => `${Paths.issue.templates}/${id}`,

    checkpoints: "/issue/checkpoints",
    checkpointsCreate: "/issue/checkpoints/new",
    checkpointsId: "/issue/checkpoints/:id",
    gCheckpointsId: (id: number) => `${Paths.issue.checkpoints}/${id}`,

    inspections: "/issue/inspections",
  },

  resources: "/resources",
  resourcesCreate: "/resources/create",
  resourcesId: "/resources/:id",
  gResourcesId: (id: number) => `${Paths.resources}/${id}`,

  stations: "/stations",
  stationsCreate: "/stations/create",
  stationsId: "/stations/:id",
  gStationsId: (id: number) => `${Paths.stations}/${id}`,

  groups: "/groups",
  groupsCreate: "/groups/new",
  groupsId: "/groups/:id",
  gGroupsId: (id: number) => `${Paths.groups}/${id}`,

  equipments: "/equipments",
  equipmentsCreate: "/equipments/new",
  equipmentsId: "/equipments/:id",
  gEquipmentsId: (id: number) => `${Paths.equipments}/${id}`,

  equipment: {
    checkpoints: "/equipment/checkpoints",
    checkpointsCreate: "/equipment/checkpoints/new",
    checkpointsId: "/equipment/checkpoints/:id",
    gCheckpointsId: (id: number) => `${Paths.equipment.checkpoints}/${id}`,
    categories: "/equipment/categories",
    categoryCreate: "/equipment/categories/new",
    categoryId: "/equipment/categories/:id",
    gCategoryId: (id: number) => `${Paths.equipment.categories}/${id}`,
  },

  tranings: "/trainings",
  trainingCreate: "/trainings/new",
  trainingsId: "/trainings/:id",
  gTrainingsId: (id: number) => `${Paths.tranings}/${id}`,

  order: {
    kitting: "/orders/kitting",
    kittingId: "/orders/kitting/:id",
    gKittingId: (id: number) => `${Paths.order.kitting}/${id}`,

    TimeTracks: "/orders/timetrack",
    MyOrders: "/orders/myorders",
    Checkpoints: "/orders/checkpoints",
    worklist: "/orders/worklist",
    assembly: "/orders/assembly",
    kittingstatus: "/orders/kittingstatus",
    ArchivedTimeTracks: "/orders/archivedtimetracks",
    TimeTracksInProcess: "/orders/timetrack_in_process",
    timetrackId: "/orders/timetrack/:id",
    gTimeTrackId: (id: number) => `${Paths.order.TimeTracks}/${id}`,

    checkpoints: "/order/checkpoints",
    checkpointsCreate: "/order/checkpoints/new",
    checkpointsId: "/order/checkpoints/:id",
    gCheckpointsId: (id: number) => `${Paths.order.checkpoints}/${id}`,

    inspections: "/orders/inspections",

    images: "/order/images",
    imageId: "/order/images/:id",
    gOrderImagesId: (orderNo: number) => `${Paths.order.images}/${orderNo}`,
    weldInspections: "/orders/weldinspections",
  },

  qc: {
    default: "/qc",
    settings: "/qc/settings",
    settingsId: "/qc/settings/:id",
    gSettingsId: (id: string) => `${Paths.qc.settings}/${id}`,

    chassis: "/qc/chassis",
    chassisCreate: "/qc/chassis/new",
    chassisId: "/qc/chassis/:id",
    //chassisIdApprove: "/qc/chassis/:id/approve",
    gChassisId: (id: number) => `${Paths.qc.chassis}/${id}`,
    gChassisIdApprove: (id: number) => `${Paths.qc.chassis}/${id}/approve`,

    prodTypes: "/qc/prodtypes",
    prodTypesCreate: "/qc/prodtypes/new",
    prodTypesId: "/qc/prodtypes/:id",
    gProdTypesId: (id: number) => `${Paths.qc.prodTypes}/${id}`,

    prods: "/qc/prods",
    prodsCreate: "/qc/prods/new",
    prodsId: "/qc/prods/:id",
    gProdsId: (id: number) => `${Paths.qc.prods}/${id}`,

    attrTypes: "/qc/attrtypes",
    attrTypesCreate: "/qc/attrtypes/new",
    attrTypesId: "/qc/attrtypes/:id",
    gAttrTypesId: (id: number) => `${Paths.qc.attrTypes}/${id}`,

    attrValues: "/qc/attrvalues",
    attrValuesCreate: "/qc/attrvalues/new",
    attrValuesId: "/qc/attrvalues/:id",
    gAttrValuesId: (id: number) => `${Paths.qc.attrValues}/${id}`,

    specs: "/qc/specs",
    specsCreate: "/qc/specs/new",
    specsId: "/qc/specs/:id",
    gSpecsId: (id: number) => `${Paths.qc.specs}/${id}`,

    machineSpecs: "/qc/machinespecs",
    machineSpecId: "/qc/machinespecs/:id",
    gMachineSpecId: (id: number) => `${Paths.qc.machineSpecs}/${id}`,

    status: "/qc/status",
    statusCreate: "/qc/status/new",
    statusId: "/qc/status/:id",
    gStatusId: (id: number) => `${Paths.qc.status}/${id}`,

    checkpoints: "/qc/checkpoints",
    checkpointsCreate: "/qc/checkpoints/new",
    checkpointsId: "/qc/checkpoints/:id",
    gCheckpointsId: (id: number) => `${Paths.qc.checkpoints}/${id}`,

    inspections: "/qc/inspections",
    inspectionsCreate: "/qc/inspections/new",
    inspectionsId: "/qc/inspections/:id",
    inspectionsList: "/qc/inspectionsList/",
    gInspectionsId: (id: number) => `${Paths.qc.inspections}/${id}`,

    checklist: "/qc/checklist",
    progress: "/qc/progress",
  },

  salesforce: {
    caseId: "/salesforce/case/:id",
  },

  logout: "/logout",
  login: "/login",
  unauthorized: "/unauthorized",
  forbidden: "/forbidden",
  silentRefresh: "/silent-refresh",
};

const internalRoles = process.env.REACT_APP_INTERNAL_ROLES;

const internalRolesArray = internalRoles !== undefined ? (JSON.parse(internalRoles) as string[]) : [];

export default (
  <Suspense fallback={<div></div>}>
    <Switch>
      <PrivateRoute exact path={Paths.root} component={IssueContainer} />
      <PrivateRoute exact path={Paths.admin} component={AdminContainer} />
      <PrivateRoute exact path={Paths.profile} component={UserProfileContainer} />
      <PrivateRoute exact path={Paths.users} component={UsersContainer} roles={["admin", "user-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.roles} component={RoleContainer} roles={["admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.usersCreate} component={UserFormContainer} roles={["admin", "user-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.usersId} component={UserFormContainer} roles={["admin", "user-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.products} component={ProductsContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.productsId} component={ProductFormContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.issues} component={IssueContainer} />
      <PrivateRoute exact path={Paths.partrequest} component={IssueContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.administration} component={IssueContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.warranty} component={IssueContainer} />
      <PrivateRoute exact path={Paths.technicalSupport} component={IssueContainer} />
      <PrivateRoute exact path={Paths.orkelDirekte} component={IssueContainer} />
      <PrivateRoute exact path={Paths.maintenance} component={IssueContainer} />
      <PrivateRoute exact path={Paths.hms} component={IssueContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.issuesId} component={IssueFormContainer} />

      <PrivateRoute exact path={Paths.stations} component={StationContainer} roles={["admin", "chassis-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.stationsCreate} component={StationFormContainer} roles={["admin", "chassis-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.stationsId} component={StationFormContainer} roles={["admin", "chassis-admin"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.groups} component={GroupsContainer} roles={["admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.groupsId} component={GroupFormContainer} roles={["admin"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.equipments} component={JigsContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.equipmentsId} component={JigFormContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.equipment.checkpoints} component={JigCheckpointContainer} roles={["admin", "jig-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.equipment.checkpointsId} component={JigCheckpointFormContainer} roles={["admin", "jig-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.equipment.categories} component={JigCategoryContainer} roles={["admin", "jig-admin"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.resources} component={ResourceContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.resourcesId} component={ResourceFormContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />

      {/* Kitting Prioritized cause else id on order will be invalid */}
      <PrivateRoute exact path={Paths.order.kitting} component={KittingContainer} roles={["admin", "kitter", "kitting-admin", "basic"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.order.kittingId} component={KittingFormContainer} roles={["admin", "kitter", "kitting-admin", "basic"]} fallbackComponent={Forbidden} />

      <PrivateRoute
        exact
        path={Paths.order.TimeTracks}
        component={TimeTrackContainer}
        roles={["admin", "tt-admin", "welder", "kitter", "kitting-admin", "qc-inspector"]}
        fallbackComponent={Forbidden}
      />
      <PrivateRoute exact path={Paths.order.ArchivedTimeTracks} component={TimeTrackGridContainer} roles={["admin", "tt-admin", "purchaser"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.order.TimeTracksInProcess} component={TimeTrackGridContainer} roles={["admin", "tt-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute
        exact
        path={Paths.order.timetrackId}
        component={TimeTrackFormContainer}
        roles={["admin", "tt-admin", "welder", "kitter", "kitting-admin", "qc-inspector"]}
        fallbackComponent={Forbidden}
      />
      <PrivateRoute exact path={Paths.order.checkpoints} component={ProductCheckpointContainer} roles={["admin", "qc-admin", "tt-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.order.checkpointsId} component={ProductCheckpointFormContainer} roles={["admin", "qc-admin", "tt-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.order.worklist} component={OrderWorkListContainer} roles={["welder-admin", "admin", "purchaser", "kitter", "qc-teamleader"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.order.assembly} component={OrderWorkListContainer} roles={["welder-admin", "admin", "purchaser", "kitter", "qc-teamleader"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.order.kittingstatus} component={KittingStatuskListContainer} roles={["admin", "kitter", "kitting-admin", "welder-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute
        exact
        path={Paths.order.MyOrders}
        component={MyOrdersContainer}
        roles={["admin", "tt-admin", "welder", "welder-admin", "kitter", "kitting-admin", "qc-inspector"]}
        fallbackComponent={Forbidden}
      />
      <PrivateRoute exact path={Paths.order.inspections} component={QcInspectionContainer} roles={["admin", "qc-admin", "tt-admin", "weldment-inspector"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.order.weldInspections} component={TimeTrackGridContainer} roles={["admin", "qc-admin", "tt-admin", "weldment-inspector"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.order.images} component={KittingContainer} roles={["admin", "kitter", "kitting-admin", "supplier", "purchaser"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.order.imageId} component={OrderImagesFormContainer} roles={["admin", "kitter", "kitting-admin", "supplier", "purchaser"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.orders} component={OrdersContainer} />
      <PrivateRoute exact path={Paths.orderId} component={OrderFormContainer} />

      <PrivateRoute exact path={Paths.issue.tags} component={TagContainer} roles={["admin", "case-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.issue.tagsCreate} component={TagFormContainer} roles={["admin", "case-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.issue.tagsId} component={TagFormContainer} roles={["admin", "case-admin"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.issue.types} component={IssueTypesContainer} roles={["admin", "case-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.issue.typeId} component={IssueTypeFormContainer} roles={["admin", "case-admin"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.issue.templates} component={IssueTemplateContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.issue.templateId} component={IssueTemplateFormContainer} roles={["admin", "case-admin"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.issue.checkpoints} component={IssueCheckpointContainer} roles={["admin", "case-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.issue.checkpointsId} component={IssueCheckpointFormContainer} roles={["admin", "case-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.issue.inspections} component={QcInspectionContainer} roles={["admin", "case-admin"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.default} component={QcChecklistContainer} roles={["admin", "qc-admin", "qc-inspector", "qc-teamleader"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.settings} component={QcSettingsContainer} roles={["admin", "qc-settings-admin", "qc-teamleader", "protek"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.settingsId} component={QcSettingsContainer} roles={["admin", "qc-settings-admin", "qc-teamleader", "protek"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.chassis} component={QcChassisContainer} roles={["admin", "qc-admin", "qc-teamleader", "chassis-admin"]} fallbackComponent={QcMachineSpecContainer} />
      <PrivateRoute
        exact
        path={Paths.qc.chassisId}
        component={QcChassisFormContainer}
        roles={["admin", "qc-admin", "qc-teamleader", "chassis-admin"]}
        fallbackComponent={QcMachineSpecFormContainer}
        fallbackRoles={["internal", "basic"]}
      />
      {/* <PrivateRoute exact path={Paths.qc.chassisIdApprove} component={ApproveQcChassisFormContainer} /> */}

      <PrivateRoute exact path={Paths.qc.prodTypes} component={QcProdTypeContainer} roles={["admin", "qc-settings-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.prodTypesId} component={QcProdTypeFormContainer} roles={["admin", "qc-settings-admin", "qc-teamleader", "protek"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.prodsId} component={QcProdFormContainer} roles={["admin", "qc-settings-admin", "qc-teamleader", "protek"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.attrTypes} component={QcAttrTypeContainer} roles={["admin", "qc-settings-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.attrTypesId} component={QcAttrTypeFormContainer} roles={["admin", "qc-settings-admin", "qc-teamleader", "protek"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.attrValues} component={QcAttrValueContainer} roles={["admin", "qc-settings-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.attrValuesId} component={QcAttrValueFromContainer} roles={["admin", "qc-settings-admin", "qc-teamleader", "protek"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.specs} component={QcSpecContainer} roles={["admin", "qc-admin", "qc-teamleader"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.specsId} component={QcSpecFormContainer} roles={["admin", "qc-admin", "qc-teamleader"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.machineSpecs} component={QcMachineSpecContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.machineSpecId} component={QcMachineSpecFormContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.status} component={QcStatusContainer} roles={["admin", "qc-admin"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.statusId} component={QcStatusFormContainer} roles={["admin", "qc-admin"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.checkpoints} component={QcCheckpointContainer} roles={["admin", "qc-admin", "qc-teamleader"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.checkpointsId} component={QcCheckpointFormContainer} roles={["admin", "qc-admin", "qc-teamleader"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.inspections} component={QcInspectionContainer} roles={["admin", "qc-admin", "qc-teamleader"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.inspectionsId} component={QcInspectionFormContainer} roles={["admin", "qc-admin", "qc-teamleader"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.inspectionsList} component={QcChecklistContainer} roles={["admin", "qc-admin", "qc-teamleader"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.qc.checklist} component={QcChecklistContainer} roles={["admin", "qc-admin", "qc-inspector", "qc-teamleader"]} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.qc.progress} component={QcChassisProgressContainer} roles={["admin", "qc-admin", "qc-inspector", "qc-teamleader"]} fallbackComponent={Forbidden} />

      <PrivateRoute exact path={Paths.salesforce.caseId} component={SalesforceCaseRedirect} />

      <PrivateRoute exact path={Paths.tranings} component={TrainingContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />
      <PrivateRoute exact path={Paths.trainingsId} component={TrainingFormContainer} roles={internalRolesArray} fallbackComponent={Forbidden} />

      <Route exact path={Paths.logout} component={LogoutContainer} />
      <Route exact path={Paths.login} component={LoginContainer} />
      <Route exact path={Paths.unauthorized} component={Unauthorized} />
      <Route exact path={Paths.forbidden} component={Forbidden} />
      <Route exact path={Paths.silentRefresh} component={SilentRefresh} />
    </Switch>
  </Suspense>
);
