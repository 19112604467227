const IssueGridColumns = [
  {
    title: "Id",
    show: true,
  },
  {
    title: "Priority",
    show: true,
  },
  {
    title: "Title",
    show: true,
  },
  {
    title: "Responsible",
    show: true,
  },
  {
    title: "Status",
    show: true,
  },
  {
    title: "Group",
    show: true,
  },
  {
    title: "Type",
    show: true,
  },
  {
    title: "Tag",
    show: true,
  },
  {
    title: "Root Cause",
    show: true,
  },
  {
    title: "Created By",
    show: true,
  },
  {
    title: "Number Of Bales",
    show: true,
  },
  {
    title: "Created",
    show: true,
  },
  {
    title: "Updated",
    show: true,
  },
];

export default IssueGridColumns;
