import {
  ResourceActionsTypes,
  FETCH_RESOURCES_SUCCESS,
  FETCH_FILTERED_RESOURCES_SUCCESS,
  RESOURCE_REQUEST_FAILURE,
  FETCH_RESOURCE_SUCCESS,
  RESOURCE_REQUEST_ERRORS,
  CREATE_RESOURCE_SUCCESS,
  DELETE_RESOURCE_SUCCESS,
  EDIT_RESOURCE_SUCCESS,
} from "../actions/resources";
import { DataList } from "../../utils/DataList";
import { IResource } from "models/resource";

export interface ResourceState {
  readonly resources: IResource[];
  readonly filteredResources: DataList<IResource>;
  readonly resource: IResource;
  readonly errors: any;
}

export const initialState: ResourceState = {
  resources: [],
  filteredResources: { data: [], total: 0 },
  resource: {} as IResource,
  errors: null,
};

const resourceReducer = (
  state = initialState,
  action: ResourceActionsTypes
): ResourceState => {
  switch (action.type) {
    case FETCH_RESOURCES_SUCCESS:
      return { ...state, resources: action.resources };
    case FETCH_FILTERED_RESOURCES_SUCCESS:
      return { ...state, filteredResources: action.resources };
    case RESOURCE_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case FETCH_RESOURCE_SUCCESS:
      return { ...state, resource: action.resource };
    case RESOURCE_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case CREATE_RESOURCE_SUCCESS:
      return {
        ...state,
        resource: action.resource,
        resources:
          state.resources.length > 0
            ? [...state.resources.concat([action.resource])]
            : [],
      };
    case DELETE_RESOURCE_SUCCESS:
      return { ...state, resource: {} as IResource };
    case EDIT_RESOURCE_SUCCESS:
      if (state.resources.length > 0) {
        state.resources.splice(
          state.resources.findIndex((t) => t.id === action.resource.id),
          1,
          action.resource
        );
      }
      return {
        ...state,
        resource: action.resource,
        resources: state.resources.length > 0 ? state.resources : [],
      };
    default:
      return state;
  }
};

export default resourceReducer;
