import { IIssueType } from "../../models/issuetype";
import {
  IssueTypeActionsTypes,
  FETCH_ISSUETYPES_SUCCESS,
  FETCH_ISSUETYPE_SUCCESS,
  EDIT_ISSUETYPE_SUCCESS,
  ISSUETYPE_REQUEST_ERRORS,
  ISSUETYPE_REQUEST_FAILURE,
} from "../actions/issuetypes";

export type IssueTypeState = {
  readonly issuetypes: IIssueType[];
  readonly issueType: IIssueType;
  readonly errors: any;
};

export const initialState: IssueTypeState = {
  issuetypes: [],
  issueType: {} as IIssueType,
  errors: null,
};

export default function issuetypesReducer(
  state = initialState,
  action: IssueTypeActionsTypes
): IssueTypeState {
  switch (action.type) {
    case FETCH_ISSUETYPES_SUCCESS:
      return { ...state, issuetypes: action.issuetypes };
    case FETCH_ISSUETYPE_SUCCESS:
      return { ...state, issueType: action.issueType };
    case EDIT_ISSUETYPE_SUCCESS:
      return { ...state, issueType: action.issueType };
    case ISSUETYPE_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case ISSUETYPE_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
}
