import {
  Upload as KendoUpload,
  UploadFileInfo,
  UploadOnProgressEvent,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import { Col } from "react-bootstrap";
import { IImage } from "models/image";
import { IUser } from "models/user";
import React from "react";
import { API, authorizationHeader } from "store/actions/request";

export interface IProps {
  images: IImage[];
  currentUser: IUser;
  files: UploadFileInfo[];
  disabled: boolean;
  jigId?: number;
  setFiles: (files: UploadFileInfo[]) => void;
  setImages: (images: IImage[]) => void;
  setUploading?: (value: boolean) => any;
  setDirty?: () => void;
  handleFileAdd?: (file: IImage) => any;
  handleFileRemove?: (file: UploadFileInfo) => any;
}

const Upload: React.FC<IProps> = (props: IProps) => {
  const {
    images,
    currentUser,
    files,
    disabled,
    jigId,
    setFiles,
    setImages,
    setUploading,
    setDirty,
    handleFileAdd,
    handleFileRemove,
  } = props;

  const cancelImage = (file: UploadFileInfo) => {
    if (handleFileRemove) handleFileRemove(file);
    const filtered = images.filter((i) => i.uid !== file.uid);
    setImages(filtered);
  };

  const addImage = (image: IImage) => {
    if (handleFileAdd) handleFileAdd(image);
    const newImages = images.concat(image);
    setImages(newImages);
  };

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    const file = event.affectedFiles[0];
    if (fileStatuses[file.status] === "Uploaded") {
      if (setUploading) setUploading(false);
    }
  };

  const onProgress = (event: UploadOnProgressEvent) => {
    const file = event.affectedFiles[0];
    if (fileStatuses[file.status] === "Uploading") {
      if (setUploading) setUploading(true);
    }
  };

  return (
    <div>
      <KendoUpload
        batch={false}
        multiple={true}
        files={files}
        onBeforeUpload={(event) => {
          event.headers = {
            ...event.headers,
            ...authorizationHeader(),
          };
          event.additionalData.userId = currentUser.id;
          if (jigId) event.additionalData.jigId = jigId;
        }}
        onBeforeRemove={(event) => {
          event.headers = {
            ...event.headers,
            ...authorizationHeader(),
          };

          if (event.files.length > 0) {
            const fileToRemove = event.files[0];
            const imageDel = images.find((i) => i.uid === fileToRemove.uid);
            event.additionalData.id = imageDel?.id;
          }
        }}
        onAdd={(event) => {
          if (
            event.newState[event.newState.length - 1].extension === ".pptx" ||
            event.newState[event.newState.length - 1].extension === ".docx"
          ) {
            if (
              window.confirm(
                "This file type is not supported on all devices/browers. We recomend converting to pdf before uploading \n\nDo you want to upload anyways?"
              )
            ) {
              setFiles(event.newState);
            }
          } else {
            setFiles(event.newState);
          }
        }}
        onRemove={(event) => {
          setFiles(event.newState);
          if (event.affectedFiles.length > 0) {
            cancelImage(event.affectedFiles[0]);
            if (setDirty) setDirty();
          }
        }}
        onProgress={(event) => {
          setFiles(event.newState);
          onProgress(event);
        }}
        onStatusChange={(event) => {
          setFiles(event.newState);
          onStatusChange(event);
          if (
            event.response &&
            event.response.status === 200 &&
            event.response.response.id
          ) {
            const newImage: IImage = {
              ...event.response.response,
              uid: event.affectedFiles[0].uid,
            };
            addImage(newImage);
            if (setDirty) setDirty();
          }
        }}
        saveUrl={`${API}/images`}
        saveMethod="POST"
        removeUrl={`${API}/images`}
        removeMethod="DELETE"
        disabled={disabled}
      />
      <Col xs={18}>
        <small>
          <em className="text-warning">Max size: 30 mb</em>
        </small>
      </Col>
    </div>
  );
};

export default Upload;
