import {
  ProductActionTypes,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_FILTEREDPRODUCTS_SUCCESS,
  FETCH_PRODUCT_BY_NO_SUCCESS,
  FETCH_PRODUCT_USERS,
  EDIT_PRODUCT_SUCCESS,
} from "../actions/products";
import { IProduct, IProductUser } from "../../models/product";
import { DataList } from "../../utils/DataList";

export type ProductState = {
  readonly products: IProduct[];
  readonly filteredProducts: DataList<IProduct>;
  readonly product: IProduct | null;
  readonly productUsers: IProductUser[];
  readonly errors: any;
};

export const initialState: ProductState = {
  products: [],
  filteredProducts: { data: [], total: 0 },
  product: null,
  productUsers: [],
  errors: null,
};

export default function productsReducer(
  state = initialState,
  action: ProductActionTypes
): ProductState {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return { ...state, products: action.products };
    case FETCH_FILTEREDPRODUCTS_SUCCESS:
      return { ...state, filteredProducts: action.products };
    case FETCH_PRODUCT_BY_NO_SUCCESS:
      return { ...state, product: action.product };
    case FETCH_PRODUCT_USERS:
      return { ...state, productUsers: action.productUsers };
    case EDIT_PRODUCT_SUCCESS:
      if (state.products.length > 0) {
        state.products.splice(
          state.products.findIndex(
            (p) => p.productNo === action.product.productNo
          ),
          1,
          action.product
        );
      }
      return {
        ...state,
        product: action.product,
        products: state.products.length > 0 ? state.products : [],
      };
    default:
      return state;
  }
}
