import React, { useEffect, useState } from "react";
import {
  faChevronDown,
  faChevronUp,
  faKey,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { process as filterProcess } from "@progress/kendo-data-query";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldRenderProps,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import {
  Accordion,
  Button,
  Card,
  Col,
  ProgressBar,
  Row,
} from "react-bootstrap";
import i18n from "i18next";
import { Prompt } from "react-router";

import { IGroup } from "../../models/group";
import { ITraining, ITrainingProgress } from "../../models/training";
import { IUser } from "../../models/user";
import { IUserPrefs } from "../../models/userprefs";
import { Paths } from "../../routes";
import { ITag } from "models/tag";
import { IIssueType } from "models/issuetype";
import Aux from "../../utils/auxiliary";
import UserPreference from "./UserPreference";

const FieldInput: React.FC<FieldRenderProps> = (props: FieldRenderProps) => {
  const { validationMessage, ...otherProps } = props;
  return (
    <div>
      <Input {...otherProps} />
      {props.value && props.validationMessage && !props.valid && (
        <Error>{props.validationMessage}</Error>
      )}
    </div>
  );
};

export interface IProps {
  user: IUser;
  groups: IGroup[];
  tags: ITag[];
  issueTypes: IIssueType[];
  history: any;
  trainingProgress: ITrainingProgress[];
  userTrainings: ITraining[];
  onSubmit: (user: IUser) => any;
}

const UserProfile: React.FC<IProps> = (props) => {
  const {
    user,
    groups,
    tags,
    issueTypes,
    history,
    trainingProgress,
    userTrainings,
    onSubmit,
  } = props;

  const [userPrefs, setUserPrefState] = useState<IUserPrefs[]>(user.userPrefs);
  const [teachingAccordionState, setTeachingAccordionState] = useState<boolean>(
    false
  );
  const [traineeAccordionState, setTraineeAccordionState] = useState<boolean>(
    false
  );
  const [notificationState, setNotificationState] = useState<boolean>(false);
  const [filteredGroups, setFilteredGroups] = useState<IGroup[]>([]);
  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);
  const [filteredTypes, setFilteredTypes] = useState<IIssueType[]>([]);
  const [selectedQcProds, setSelectedQcProds] = useState<number[]>([]);
  const [key, setKey] = useState<number>(1);

  const isInternal = () => {
    const internalRoles = process.env.REACT_APP_INTERNAL_ROLES;
    return internalRoles && user.roles.some((r) => internalRoles.includes(r));
  };

  const redirectToResetPassword = () => {
    const resetPassLink = user.isLdap
      ? "https://account.activedirectory.windowsazure.com/ChangePassword.aspx"
      : process.env.REACT_APP_AUTH + "/account/#/security/signingin";
    const resetPassWindow = window.open(resetPassLink, "_blank"); // Opens Reset Password Link to a new tab
    resetPassWindow?.focus();
  };

  const redirectToAccountManagment = () => {
    const accUrl = process.env.REACT_APP_AUTH + "/account";
    const accWindow = window.open(accUrl, "_blank");
    accWindow?.focus();
  };

  useEffect(() => {
    setKey(key + 1);
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredGroups(groups);
  }, [groups]);

  useEffect(() => {
    setFilteredTags(tags.sort((a, b) => a.name.localeCompare(b.name)));
  }, [tags]);

  useEffect(() => {
    setFilteredTypes(issueTypes);
  }, [issueTypes]);

  const onGroupFilterChange = (event: any) => {
    const tempFilteredGroups = filterProcess(groups, event);
    if (tempFilteredGroups.total > 0) {
      setFilteredGroups(tempFilteredGroups.data);
    }
  };

  const onTagFilterChange = (event: any) => {
    const tempFilteredTags = filterProcess(tags, event);
    if (tempFilteredTags.total > 0) {
      setFilteredTags(tempFilteredTags.data);
    }
  };

  const onTypeFilterChange = (event: any) => {
    const tempFilteredTypes = filterProcess(issueTypes, event);
    if (tempFilteredTypes.total > 0) {
      setFilteredTypes(tempFilteredTypes.data);
    }
  };

  const onTrainingClick = (training: ITraining) => {
    history.push(Paths.gTrainingsId(training.id));
  };

  return (
    <Form
      initialValues={user}
      onSubmit={(values: { [name: string]: any }) => {
        values.userPrefs = userPrefs;
        onSubmit(values as IUser);
        localStorage.setItem("language", userPrefs[0].lang);
        i18n.changeLanguage(userPrefs[0].lang);
      }}
      key={key}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <Prompt
            when={formRenderProps.allowSubmit}
            message="Are you sure you don't want to save before exiting?"
          />
          <div className="mb-2">
            <Button onClick={() => redirectToAccountManagment()}>
              Account Managment
            </Button>
          </div>
          <div>
            <Button
              disabled={user.isLdap}
              variant="primary"
              onClick={() => redirectToResetPassword()}
            >
              <FontAwesomeIcon icon={faKey} /> Set new password
            </Button>
            {user.isLdap && (
              <p style={{ paddingLeft: "1em", display: "inline-block" }}>
                LDAP users cannot reset password.
              </p>
            )}
          </div>
          <UserPreference
            formRenderProps={formRenderProps}
            userPrefs={userPrefs}
            onSelectLanguage={(prefs, lang) => {
              prefs[0].lang = lang;
              setUserPrefState(prefs);
            }}
          />
          {isInternal() && (
            <Row className="mb-3">
              <Col className="col-12 col-md-6">
                <Accordion>
                  <Card>
                    <Accordion.Header
                      eventKey="a"
                      as={Card.Header}
                      onClick={() => setNotificationState(!notificationState)}
                    >
                      <div className="container-fluid">
                        Notification Settings
                        <span className="float-end">
                          <FontAwesomeIcon
                            icon={
                              notificationState ? faChevronUp : faChevronDown
                            }
                          />{" "}
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Collapse
                      in={notificationState}
                      as={Card.Body}
                      eventKey="a"
                    >
                      <div className="my-3">
                        <Col className="mb-3">
                          <Checkbox
                            className="checklist-checkpoint"
                            name="missingPartEmail"
                            label="Receive Part Request email"
                            checked={userPrefs[0].missingPartEmail}
                            onChange={(e) => {
                              let tempPrefs = [...userPrefs];
                              tempPrefs[0].missingPartEmail = e.value;
                              setUserPrefState(tempPrefs);
                              formRenderProps.onChange("missingPartPrefs", {
                                value: !userPrefs[0].missingPartEmail,
                              });
                            }}
                            style={{ height: "20px", width: "20px" }}
                          />
                        </Col>
                        <Col className="mb-3">
                          <label>Tags</label>
                          <Field
                            name="notificationTags"
                            placeholder="Tags"
                            textField="name"
                            dataItemKey="id"
                            data={filteredTags}
                            defaultValue={user.notificationTags}
                            autoClose={false}
                            filterable={true}
                            onFilterChange={onTagFilterChange}
                            component={MultiSelect}
                          />
                        </Col>
                        <Col>
                          <label>Case Types</label>
                          <Field
                            name="notificationTypes"
                            placeholder="Case types"
                            textField="name"
                            dataItemKey="id"
                            data={filteredTypes}
                            defaultValue={user.notificationTypes}
                            autoClose={false}
                            filterable={true}
                            onFilterChange={onTypeFilterChange}
                            component={MultiSelect}
                          />
                        </Col>
                      </div>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
          )}
          <label>Title</label>
          <Field name="title" component={FieldInput} />
          {isInternal() && (
            <Aux>
              <Row className="my-3">
                <Col>
                  <label>Groups</label>
                  <Field
                    name="groups"
                    placeholder="groups"
                    textField="name"
                    dataItemKey="id"
                    data={filteredGroups}
                    defaultValue={user.groups}
                    autoClose={false}
                    filterable={true}
                    onFilterChange={onGroupFilterChange}
                    component={MultiSelect}
                  />
                </Col>
              </Row>
              <Row className="my-3">
                <Col className="col-12 col-md-6">
                  <label>Resources</label>
                  <MultiSelect
                    name="resources"
                    dataItemKey="id"
                    textField="name"
                    value={user.resources || []}
                    disabled
                  />
                </Col>
                <Col className="col-12 col-md-6">
                  <label>Administered Resources</label>
                  <MultiSelect
                    name="administeredResources"
                    dataItemKey="id"
                    textField="name"
                    value={user.administeredResources || []}
                    disabled
                  />
                </Col>
              </Row>
              <label className="mt-2">Training</label>
              <Row>
                <Col>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Header
                        eventKey="b"
                        as={Card.Header}
                        onClick={() =>
                          setTraineeAccordionState(!traineeAccordionState)
                        }
                      >
                        <div className="container-fluid">
                          My Training logs:{" "}
                          {userTrainings &&
                          userTrainings.filter((ut) => ut.traineeId === user.id)
                            .length > 0
                            ? userTrainings.filter(
                                (ut) => ut.traineeId === user.id
                              ).length
                            : 0}
                          <span className="float-end">
                            <FontAwesomeIcon
                              icon={
                                traineeAccordionState
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />{" "}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey="b"
                        as={Card.Body}
                        in={traineeAccordionState}
                      >
                        {userTrainings &&
                        userTrainings.filter((ut) => ut.traineeId === user.id)
                          .length > 0 ? (
                          <div>
                            {userTrainings
                              .filter((ut) => ut.traineeId === user.id)
                              .map((t) => {
                                return (
                                  <Card
                                    className="p-2 qcChassis-related-issues-card"
                                    key={t.id}
                                    onClick={() => onTrainingClick(t)}
                                  >
                                    <p style={{ color: "#00bc8c" }}>
                                      {t.stationName
                                        ? t.stationName
                                        : "Product No: " + t.productNo}{" "}
                                      <span
                                        style={{ float: "right" }}
                                        className={
                                          t.completedAt ? "" : "text-warning"
                                        }
                                      >
                                        status:{" "}
                                        {t.completedAt
                                          ? "Completed"
                                          : "In Process"}
                                      </span>
                                    </p>
                                  </Card>
                                );
                              })}
                          </div>
                        ) : (
                          <Card.Body>No Trainee logs found</Card.Body>
                        )}
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
                {userTrainings &&
                  userTrainings.filter((ut) => ut.teacherId === user.id) && (
                    <Col>
                      <Accordion defaultActiveKey="0">
                        <Card className="d-flex">
                          <Accordion.Header
                            eventKey="c"
                            as={Card.Header}
                            onClick={() =>
                              setTeachingAccordionState(!teachingAccordionState)
                            }
                          >
                            <div className="container-fluid">
                              My Teaching logs:{" "}
                              {userTrainings &&
                              userTrainings.filter(
                                (ut) => ut.teacherId === user.id
                              ).length > 0
                                ? userTrainings.filter(
                                    (ut) => ut.teacherId === user.id
                                  ).length
                                : 0}
                              <span className="float-end">
                                <FontAwesomeIcon
                                  icon={
                                    teachingAccordionState
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                />{" "}
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Collapse
                            eventKey="c"
                            as={Card.Body}
                            in={teachingAccordionState}
                          >
                            {userTrainings &&
                            userTrainings.filter(
                              (ut) => ut.teacherId === user.id
                            ).length > 0 ? (
                              <Col className="px-0">
                                {userTrainings &&
                                  userTrainings
                                    .filter((ut) => ut.teacherId === user.id)
                                    .map((t) => {
                                      return (
                                        <Card
                                          className="p-2 qcChassis-related-issues-card"
                                          key={t.id}
                                          onClick={() => onTrainingClick(t)}
                                        >
                                          <p style={{ color: "#00bc8c" }}>
                                            {t.traineeFullName + " in "}
                                            {t.stationName
                                              ? t.stationName
                                              : "Product No: " +
                                                t.productNo}{" "}
                                            <span
                                              style={{ float: "right" }}
                                              className={
                                                t.completedAt
                                                  ? ""
                                                  : "text-warning"
                                              }
                                            >
                                              status:{" "}
                                              {t.completedAt
                                                ? "Completed"
                                                : "In Process"}
                                            </span>
                                          </p>
                                        </Card>
                                      );
                                    })}
                              </Col>
                            ) : (
                              <Card.Body>No Training logs found</Card.Body>
                            )}
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </Col>
                  )}
              </Row>
            </Aux>
          )}
          <Button
            type="submit"
            variant="success"
            className="my-3"
            disabled={!formRenderProps.allowSubmit}
          >
            <FontAwesomeIcon icon={faSave} /> Save
          </Button>
          {isInternal() && (
            <Row>
              <Col>
                <h5>Training Progress</h5>
                {trainingProgress.map((tp) => {
                  return (
                    <div className="my-2" key={tp.qcProdId}>
                      <label className="mb-0">{tp.qcProd.name}</label>
                      <ProgressBar
                        now={tp.progress}
                        label={`${tp.progress.toFixed(2)}%`}
                        variant="info"
                        animated
                        striped
                      />
                      <p
                        onClick={() =>
                          setSelectedQcProds(
                            selectedQcProds.includes(tp.qcProdId)
                              ? selectedQcProds.filter((p) => p !== tp.qcProdId)
                              : [...selectedQcProds, tp.qcProdId]
                          )
                        }
                        className="text-success"
                        style={{ cursor: "pointer" }}
                      >
                        {selectedQcProds.includes(tp.qcProdId)
                          ? "Hide"
                          : "Show"}{" "}
                        station progress
                      </p>
                      <ul>
                        {selectedQcProds.includes(tp.qcProdId) &&
                          tp.stationsProgress.map((sp) => {
                            return (
                              <li key={sp.stationId}>
                                {sp.station.name}
                                <ProgressBar
                                  now={sp.progress}
                                  label={`${sp.progress.toFixed(2)}%`}
                                  variant="info"
                                  striped
                                  animated
                                />
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  );
                })}
              </Col>
            </Row>
          )}
        </FormElement>
      )}
    />
  );
};

export default UserProfile;
