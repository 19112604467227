import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IJig, IJigCategory } from "../../models/jig";
import { DropDownFilterCell } from "../Common/GridComponents";

export interface IProps {
  jigData: DataResult;
  jigCategories: IJigCategory[];
  viewHidden: boolean;
  elRef: any;
  handleFetch: (query: string) => void;
  onEditClick: (jig: IJig) => any;
  setElRef: (any: any) => any;
}

const JigGrid: React.FC<IProps> = (props) => {
  const {
    jigData,
    jigCategories,
    viewHidden,
    elRef,
    handleFetch,
    onEditClick,
    setElRef,
  } = props;

  const fetchTempRequestState = () => {
    const request = localStorage.getItem("temp_jig_req_state");
    if (!request) return null;
    return JSON.parse(request) as {
      requestState: DataSourceRequestState;
      prodNo: string;
    };
  };

  const [tempReqState] = useState(fetchTempRequestState());
  const [viewHiddenState, setViewHiddenState] = useState<boolean>(viewHidden);
  const [prodNo, setProdNo] = React.useState(tempReqState?.prodNo || "");
  const [requestState, setRequestState] = useState<DataSourceRequestState>(
    tempReqState?.requestState || {
      skip: 0,
      take: 20,
      filter: {
        filters: [
          {
            field: "hidden",
            operator: "eq",
            value: viewHidden,
          },
        ],
        logic: "and" as const,
      },
    }
  );

  const focusEl = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusEl.current !== null && elRef) {
      focusEl.current.focus();
    }
  }, [jigData, elRef]);

  useEffect(() => {
    handleFetch(`${toDataSourceRequestString(requestState)}&prodNo=${prodNo}`);
    localStorage.removeItem("jigs_hide_state");
    localStorage.removeItem("temp_jig_req_state");
  }, [requestState, prodNo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewHiddenState !== viewHidden) {
      setViewHiddenState(viewHidden);
      setRequestState({
        skip: 0,
        take: 20,
        filter: {
          filters: [
            {
              field: "hidden",
              operator: "eq",
              value: viewHidden,
            },
          ],
          logic: "and" as const,
        },
      });
    }
  }, [viewHidden]); // eslint-disable-line react-hooks/exhaustive-deps

  const mql = window.matchMedia("(min-width: 800px)");

  return (
    <Grid
      {...requestState}
      {...jigData}
      sortable
      filterable
      pageable={{
        info: true,
        pageSizes: [5, 10, 15, 20, 30, 40, 50],
        buttonCount: 10,
      }}
      rowRender={(props, item) => {
        const propProps = { ...props.props };
        propProps.className =
          propProps.className + (item.dataItem.hidden ? " text-danger" : "");
        return { ...props, props: { ...propProps } };
      }}
      onRowClick={(event: GridRowClickEvent) => {
        localStorage.setItem(
          "temp_jig_req_state",
          JSON.stringify({ requestState, prodNo })
        );
        viewHidden
          ? localStorage.setItem("jigs_hide_state", "true")
          : localStorage.removeItem("jigs_hide_state");
        onEditClick(event.dataItem);
      }}
      onDataStateChange={(event: GridDataStateChangeEvent) => {
        setRequestState(event.dataState);
      }}
      className={mql.matches ? "" : "mobile-grid"}
    >
      <GridColumn
        field="jignr"
        title="No"
        width={mql.matches ? undefined : 200}
      />
      <GridColumn
        field="description"
        title="Description"
        width={mql.matches ? undefined : 200}
      />
      <GridColumn
        field="location"
        title="Location"
        width={mql.matches ? undefined : 200}
      />
      <GridColumn
        field="jigCategoryName"
        title="Category"
        width={mql.matches ? undefined : 200}
        filterCell={(props) => {
          return DropDownFilterCell(
            props,
            jigCategories.map((jc) => jc.name)
          );
        }}
      />
      <GridColumn
        field="prodNos"
        title="Product Numbers"
        width={mql.matches ? undefined : 200}
        filterCell={() => {
          return (
            <Row>
              <Col className="col-12 col-md-10">
                <Form.Control
                  defaultValue={prodNo}
                  onChange={(e) => setProdNo(e.target.value)}
                  className="m-2 focused-element"
                  ref={focusEl}
                  onFocus={() => setElRef(focusEl)}
                  onBlur={() => setElRef(null)}
                />
              </Col>
              <Col className="col-12 col-md-2">
                <button
                  title="Clear"
                  type="button"
                  className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
                  onClick={(event) => {
                    setProdNo("");
                    event.preventDefault();
                  }}
                >
                  <span className="k-svg-icon k-svg-i-filter-clear k-button-icon">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="m143.5 64 168.2 168.2L288 256v160l-64 64V256L64 96V64h79.5zm236.1 100.4L448 96V64H279.3l-64-64L192 22l298 298 22-23.3-132.4-132.3z"></path>
                    </svg>
                  </span>
                </button>
              </Col>
            </Row>
          );
        }}
      />
      <GridColumn
        field="numberOfAttachments"
        title="Attachments"
        filter="numeric"
        width={mql.matches ? undefined : 200}
      />
    </Grid>
  );
};

export default JigGrid;
