import { Dispatch } from "redux";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "./request";
import { IIssueTemplate } from "models/issueTemplate";
import { DataResult } from "@progress/kendo-data-query";

export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const TEMPLATE_REQUEST_ERRORS = "TEMPLATE_REQUEST_ERRORS";
export const TEMPLATE_REQUEST_FAILURE = "TEMPLATE_REQUEST_FAILURE";
export const FETCH_FILTERED_TEMPLATES_SUCCESS =
  "FETCH_FILTERED_TEMPLATES_SUCCESS";

export interface TemplateActions {
  FETCH_TEMPLATES_SUCCESS: {
    type: typeof FETCH_TEMPLATES_SUCCESS;
    templates: IIssueTemplate[];
  };
  FETCH_FILTERED_TEMPLATES_SUCCESS: {
    type: typeof FETCH_FILTERED_TEMPLATES_SUCCESS;
    templates: DataResult;
  };
  FETCH_TEMPLATE_SUCCESS: {
    type: typeof FETCH_TEMPLATE_SUCCESS;
    template: IIssueTemplate;
  };
  CREATE_TEMPLATE_SUCCESS: {
    type: typeof CREATE_TEMPLATE_SUCCESS;
    template: IIssueTemplate;
  };
  EDIT_TEMPLATE_SUCCESS: {
    type: typeof EDIT_TEMPLATE_SUCCESS;
    template: IIssueTemplate;
  };
  DELETE_TEMPLATE_SUCCESS: {
    type: typeof DELETE_TEMPLATE_SUCCESS;
  };
  TEMPLATE_REQUEST_ERRORS: {
    type: typeof TEMPLATE_REQUEST_ERRORS;
    errors: any;
  };
  TEMPLATE_REQUEST_FAILURE: {
    type: typeof TEMPLATE_REQUEST_FAILURE;
    errors: any;
  };
}

export type TemplateActionTypes =
  | TemplateActions[typeof FETCH_TEMPLATES_SUCCESS]
  | TemplateActions[typeof FETCH_FILTERED_TEMPLATES_SUCCESS]
  | TemplateActions[typeof FETCH_TEMPLATE_SUCCESS]
  | TemplateActions[typeof CREATE_TEMPLATE_SUCCESS]
  | TemplateActions[typeof EDIT_TEMPLATE_SUCCESS]
  | TemplateActions[typeof DELETE_TEMPLATE_SUCCESS]
  | TemplateActions[typeof TEMPLATE_REQUEST_ERRORS]
  | TemplateActions[typeof TEMPLATE_REQUEST_FAILURE];

export const actionCreators = {
  fetchTemplatesSuccess: (
    templates: IIssueTemplate[]
  ): TemplateActions[typeof FETCH_TEMPLATES_SUCCESS] => ({
    type: FETCH_TEMPLATES_SUCCESS,
    templates: templates,
  }),
  fetchFilteredTemplatesSuccess: (
    templates: DataResult
  ): TemplateActions[typeof FETCH_FILTERED_TEMPLATES_SUCCESS] => ({
    type: FETCH_FILTERED_TEMPLATES_SUCCESS,
    templates: templates,
  }),
  fetchTemplateSuccess: (
    template: IIssueTemplate
  ): TemplateActions[typeof FETCH_TEMPLATE_SUCCESS] => ({
    type: FETCH_TEMPLATE_SUCCESS,
    template: template,
  }),
  createTemplateSuccess: (
    template: IIssueTemplate
  ): TemplateActions[typeof CREATE_TEMPLATE_SUCCESS] => ({
    type: CREATE_TEMPLATE_SUCCESS,
    template: template,
  }),
  editTemplateSuccess: (
    template: IIssueTemplate
  ): TemplateActions[typeof EDIT_TEMPLATE_SUCCESS] => ({
    type: EDIT_TEMPLATE_SUCCESS,
    template: template,
  }),
  deleteTemplateSuccess: (): TemplateActions[typeof DELETE_TEMPLATE_SUCCESS] => ({
    type: DELETE_TEMPLATE_SUCCESS,
  }),
  templateRequestErrors: (
    errors: any
  ): TemplateActions[typeof TEMPLATE_REQUEST_ERRORS] => ({
    type: TEMPLATE_REQUEST_ERRORS,
    errors: errors,
  }),
  templateRequestFailure: (
    status: number
  ): TemplateActions[typeof TEMPLATE_REQUEST_FAILURE] => ({
    type: TEMPLATE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export function fetchTemplates() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_TEMPLATES_SUCCESS));
    const { status, json } = await getRequest("/issueTemplates");
    dispatch(requestEnd(FETCH_TEMPLATES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchTemplatesSuccess(json))
      : null;
  };
}

export function fetchFilteredTemplates(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_TEMPLATES_SUCCESS));
    const { status, json } = await getRequest(
      `/issueTemplates/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_TEMPLATES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredTemplatesSuccess(json))
      : dispatch(actionCreators.templateRequestFailure(status));
  };
}

export const fetchTemplate = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/issueTemplates/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchTemplateSuccess(json))
      : dispatch(actionCreators.templateRequestFailure(status));
  };
};

export const createTemplate = (template: IIssueTemplate) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/issueTemplates", template);
    switch (status) {
      case 200:
        dispatch(saveSuccess(template.templateName));
        return dispatch(actionCreators.createTemplateSuccess(json));
      case 400:
        dispatch(saveFailed(template.templateName));
        return dispatch(actionCreators.templateRequestErrors(json));
      default:
        dispatch(saveFailed(template.templateName));
        return dispatch(actionCreators.templateRequestFailure(status));
    }
  };
};

export const editTemplate = (id: number, template: IIssueTemplate) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/issueTemplates/${id}`,
      template
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(template.templateName));
        return dispatch(actionCreators.editTemplateSuccess(json));
      case 400:
        dispatch(saveFailed(template.templateName));
        return dispatch(actionCreators.templateRequestErrors(json));
      default:
        dispatch(saveFailed(template.templateName));
        return dispatch(actionCreators.templateRequestFailure(status));
    }
  };
};

export const deleteTemplate = (id: number, template: IIssueTemplate) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/issueTemplates/${id}`, template);
    if (status === 200) {
      dispatch(deleteSuccess(template.templateName));
      return dispatch(actionCreators.deleteTemplateSuccess());
    }
    dispatch(saveFailed(template.title));
    return dispatch(actionCreators.templateRequestFailure(status));
  };
};
