import { deleteRequest, getRequest, postRequest, putRequest } from "./request";
import { IGroup } from "../../models/group";
import { Dispatch } from "redux";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";

export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUP_SUCCESS = "FETCH_GROUP_SUCCESS";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const GROUP_REQUEST_FAILURE = "GROUP_REQUEST_FAILURE";
export const GROUP_REQUEST_ERRORS = "GROUP_REQUEST_ERRORS";

export type GroupActions = {
  FETCH_GROUPS_SUCCESS: {
    type: typeof FETCH_GROUPS_SUCCESS;
    groups: IGroup[];
  };
  FETCH_GROUP_SUCCESS: {
    type: typeof FETCH_GROUP_SUCCESS;
    group: IGroup;
  };
  CREATE_GROUP_SUCCESS: {
    type: typeof CREATE_GROUP_SUCCESS;
    group: IGroup;
  };
  EDIT_GROUP_SUCCESS: {
    type: typeof EDIT_GROUP_SUCCESS;
    group: IGroup;
  };
  DELETE_GROUP_SUCCESS: {
    type: typeof DELETE_GROUP_SUCCESS;
  };
  GROUP_REQUEST_FAILURE: {
    type: typeof GROUP_REQUEST_FAILURE;
    errors: any;
  };
  GROUP_REQUEST_ERRORS: {
    type: typeof GROUP_REQUEST_ERRORS;
    errors: any;
  };
};

export type GroupActionTypes =
  | GroupActions[typeof FETCH_GROUPS_SUCCESS]
  | GroupActions[typeof FETCH_GROUP_SUCCESS]
  | GroupActions[typeof CREATE_GROUP_SUCCESS]
  | GroupActions[typeof EDIT_GROUP_SUCCESS]
  | GroupActions[typeof DELETE_GROUP_SUCCESS]
  | GroupActions[typeof GROUP_REQUEST_FAILURE]
  | GroupActions[typeof GROUP_REQUEST_ERRORS];

export const actionCreators = {
  fetchGroupsSuccess: (
    groups: IGroup[]
  ): GroupActions[typeof FETCH_GROUPS_SUCCESS] => ({
    type: FETCH_GROUPS_SUCCESS,
    groups: groups,
  }),
  fetchGroupSuccess: (
    group: IGroup
  ): GroupActions[typeof FETCH_GROUP_SUCCESS] => ({
    type: FETCH_GROUP_SUCCESS,
    group: group,
  }),
  createGroupSuccess: (
    group: IGroup
  ): GroupActions[typeof CREATE_GROUP_SUCCESS] => ({
    type: CREATE_GROUP_SUCCESS,
    group: group,
  }),
  editGroupSuccess: (
    group: IGroup
  ): GroupActions[typeof EDIT_GROUP_SUCCESS] => ({
    type: EDIT_GROUP_SUCCESS,
    group: group,
  }),
  deleteGroupSuccess: (): GroupActions[typeof DELETE_GROUP_SUCCESS] => ({
    type: DELETE_GROUP_SUCCESS,
  }),
  groupRequestFailure: (
    status: number
  ): GroupActions[typeof GROUP_REQUEST_FAILURE] => ({
    type: GROUP_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  groupRequestErrors: (
    errors: any
  ): GroupActions[typeof GROUP_REQUEST_ERRORS] => ({
    type: GROUP_REQUEST_ERRORS,
    errors: errors,
  }),
};

export function fetchGroups() {
  return async (dispatch) => {
    const { json } = await getRequest("/groups");
    return dispatch(actionCreators.fetchGroupsSuccess(json));
  };
}
export const fetchGroup = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/groups/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchGroupSuccess(json))
      : dispatch(actionCreators.fetchGroupSuccess(status));
  };
};
export const createGroup = (group: IGroup) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/groups", group);
    switch (status) {
      case 200:
        dispatch(saveSuccess(group.name));
        return dispatch(actionCreators.createGroupSuccess(json));
      case 400:
        dispatch(saveFailed(group.name));
        return dispatch(actionCreators.groupRequestErrors(json));
      default:
        dispatch(saveFailed(group.name));
        return dispatch(actionCreators.groupRequestFailure(status));
    }
  };
};
export const editGroup = (id: number, group: IGroup) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/groups/${id}`, group);
    switch (status) {
      case 200:
        dispatch(saveSuccess(group.name));
        return dispatch(actionCreators.editGroupSuccess(json));
      case 400:
        dispatch(saveFailed(group.name));
        return dispatch(actionCreators.groupRequestErrors(json));
      default:
        dispatch(saveFailed(group.name));
        return dispatch(actionCreators.groupRequestFailure(status));
    }
  };
};
export const deleteGroup = (id: number, group: IGroup) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/groups/${id}`, group);
    if (status === 200) {
      dispatch(deleteSuccess(group.name));
      return dispatch(actionCreators.deleteGroupSuccess());
    }
    dispatch(saveFailed(group.name));
    return dispatch(actionCreators.groupRequestFailure(status));
  };
};
