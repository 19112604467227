import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../../store/actions/request";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";
import { ITag } from "../../models/tag";

export const FETCH_TAG_SUCCESS = "FETCH_TAG_SUCCESS";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_FILTERED_TAGS_SUCCESS = "FETCH_FILTERED_TAGS_SUCCESS";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const TAG_REQUEST_ERRORS = "TAG_REQUEST_ERRORS";
export const TAG_REQUEST_FAILURE = "TAG_REQUEST_FAILURE";

export type TagActions = {
  FETCH_TAG_SUCCESS: {
    type: typeof FETCH_TAG_SUCCESS;
    tag: ITag;
  };
  FETCH_TAGS_SUCCESS: {
    type: typeof FETCH_TAGS_SUCCESS;
    tags: ITag[];
  };
  FETCH_FILTERED_TAGS_SUCCESS: {
    type: typeof FETCH_FILTERED_TAGS_SUCCESS;
    tags: DataResult;
  };
  CREATE_TAG_SUCCESS: {
    type: typeof CREATE_TAG_SUCCESS;
    tag: ITag;
  };
  DELETE_TAG_SUCCESS: {
    type: typeof DELETE_TAG_SUCCESS;
  };
  EDIT_TAG_SUCCESS: {
    type: typeof EDIT_TAG_SUCCESS;
    tag: ITag;
  };
  TAG_REQUEST_ERRORS: {
    type: typeof TAG_REQUEST_ERRORS;
    errors: any;
  };
  TAG_REQUEST_FAILURE: {
    type: typeof TAG_REQUEST_FAILURE;
    errors: any;
  };
};

export type TagActionsTypes =
  | TagActions[typeof FETCH_TAG_SUCCESS]
  | TagActions[typeof FETCH_TAGS_SUCCESS]
  | TagActions[typeof FETCH_FILTERED_TAGS_SUCCESS]
  | TagActions[typeof CREATE_TAG_SUCCESS]
  | TagActions[typeof DELETE_TAG_SUCCESS]
  | TagActions[typeof EDIT_TAG_SUCCESS]
  | TagActions[typeof TAG_REQUEST_ERRORS]
  | TagActions[typeof TAG_REQUEST_FAILURE];

export const actionCreators = {
  fetchTagSuccess: (tag: ITag): TagActions[typeof FETCH_TAG_SUCCESS] => ({
    type: FETCH_TAG_SUCCESS,
    tag: tag,
  }),
  fetchTagsSuccess: (tags: ITag[]): TagActions[typeof FETCH_TAGS_SUCCESS] => ({
    type: FETCH_TAGS_SUCCESS,
    tags: tags,
  }),
  fetchFilteredTagsSuccess: (
    tags: DataResult
  ): TagActions[typeof FETCH_FILTERED_TAGS_SUCCESS] => ({
    type: FETCH_FILTERED_TAGS_SUCCESS,
    tags: tags,
  }),
  createTagSuccess: (tag: ITag): TagActions[typeof CREATE_TAG_SUCCESS] => ({
    type: CREATE_TAG_SUCCESS,
    tag: tag,
  }),
  deleteTagSuccess: (): TagActions[typeof DELETE_TAG_SUCCESS] => ({
    type: DELETE_TAG_SUCCESS,
  }),
  editTagSuccess: (tag: ITag): TagActions[typeof EDIT_TAG_SUCCESS] => ({
    type: EDIT_TAG_SUCCESS,
    tag: tag,
  }),
  tagRequestErrors: (errors: any): TagActions[typeof TAG_REQUEST_ERRORS] => ({
    type: TAG_REQUEST_ERRORS,
    errors: errors,
  }),
  tagRequestFailure: (
    status: number
  ): TagActions[typeof TAG_REQUEST_FAILURE] => ({
    type: TAG_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export const fetchTag = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/tags/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchTagSuccess(json))
      : dispatch(actionCreators.tagRequestFailure(status));
  };
};

export function fetchTags() {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest("/tags");
    return dispatch(actionCreators.fetchTagsSuccess(json));
  };
}

export function fetchFilteredTags(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_TAGS_SUCCESS));
    const { status, json } = await getRequest(`/tags/filtered?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTERED_TAGS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredTagsSuccess(json))
      : dispatch(actionCreators.tagRequestFailure(status));
  };
}

export const createTag = (tag: ITag) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/tags`, tag);
    switch (status) {
      case 200:
        dispatch(saveSuccess(tag.name));
        return dispatch(actionCreators.createTagSuccess(json));
      case 400:
        dispatch(saveFailed(tag.name));
        return dispatch(actionCreators.tagRequestErrors(json));
      default:
        dispatch(saveFailed(tag.name));
        return dispatch(actionCreators.tagRequestFailure(status));
    }
  };
};

export const editTag = (id: number, tag: ITag) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/tags/${id}`, tag);
    switch (status) {
      case 200:
        dispatch(saveSuccess(tag.name));
        return dispatch(actionCreators.editTagSuccess(json));
      case 400:
        dispatch(saveFailed(tag.name));
        return dispatch(actionCreators.tagRequestErrors(json));
      default:
        dispatch(saveFailed(tag.name));
        return dispatch(actionCreators.tagRequestFailure(status));
    }
  };
};

export const deleteTag = (id: number, tag: ITag) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/tags/${id}`, tag);
    if (status === 200) {
      dispatch(deleteSuccess(tag.name));
      return dispatch(actionCreators.deleteTagSuccess());
    }
    dispatch(saveFailed(tag.name));
    return dispatch(actionCreators.tagRequestFailure(status));
  };
};
