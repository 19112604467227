import React, { useCallback, useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";

import { IIssue, IIssueChecklist } from "../models/issue";
import { IUser } from "../models/user";
import {
  fetchIssueChecklist,
  editCaseInspection,
} from "../store/actions/issues";
import { IRootState } from "../store";
import { connect } from "react-redux";
import { IQcCheckpoint } from "../models/QualityControl/qcCheckpoint";
import { IQcInspection } from "../models/QualityControl/qcInspection";
import IssueChecklistForm from "../components/Issues/IssueChecklistForm";
import Aux from "../utils/auxiliary";
import { IAuditEntry } from "models/audit";

export interface IProps {
  issue: IIssue;
  current: IUser;
  isOpen: boolean;
  didSave: boolean;
  saveLoading: boolean;
  history: History;
  signalRInspections: IQcInspection[];
  handleItemSelected: (selected: any) => any;
  setIssueCheckpoints: (checkpoints: IQcCheckpoint[]) => any;
  setDidSave: (value: boolean) => any;
  setIssueClone: (issue: IIssue) => any;
  fetchIssueChecklist: (issueId: number | null, tagIds: number[]) => any;
  editCaseInspection: (issueId: number, inspection: IQcInspection) => any;
}

const IssueChecklistContainer: React.FC<IProps> = (props) => {
  const {
    issue,
    current,
    isOpen,
    didSave,
    saveLoading,
    history,
    signalRInspections,
    handleItemSelected,
    setIssueCheckpoints,
    setDidSave,
    setIssueClone,
    fetchIssueChecklist,
    editCaseInspection,
  } = props;

  const [checkpoints, setCheckpoints] = useState<IQcCheckpoint[]>([]);
  const [jigCheckpoints, setJigCheckpoints] = useState<IQcCheckpoint[]>([]);
  const [newCheckpoints, setNewCheckpoints] = useState<IQcCheckpoint[]>([]);
  const [inspections, setInspections] = useState<IQcInspection[]>([]);
  const [inspectionsAudits, setInspectionsAudits] = useState<IAuditEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [saveBarloading, setSaveBarLoading] = useState<boolean>(false);

  const fetchChecklistCallback = useCallback(
    (issueId: number | null, tagIds: number[]) => {
      fetchIssueChecklist(issueId, tagIds).then(
        ({ checklist }: { checklist: IIssueChecklist }) => {
          setCheckpoints(checklist.checkpoints);
          setJigCheckpoints(checklist.jigCheckpoints);
          setIssueCheckpoints(checklist.checkpoints);
          setNewCheckpoints(checklist.newCheckpoints);
          setInspectionsAudits(checklist.inspectionsAudits);
          if (issueId !== null) setInspections(checklist.inspections);
          setLoading(false);
        }
      );
    },
    [fetchIssueChecklist] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setInspections([...inspections, ...signalRInspections]);
  }, [signalRInspections]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (issue.id && isOpen) {
      fetchChecklistCallback(issue.id, []);
    } else {
      setLoading(false);
    }
  }, [isOpen, fetchChecklistCallback]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !issue.id &&
      ((issue.tags && issue.tags.length > 0) ||
        (issue.jigId !== undefined && issue.jigId > 0))
    ) {
      let tagIds = issue.tags.map((t) => t.id);
      fetchChecklistCallback(null, tagIds);
      setInspections(issue.inspections !== undefined ? issue.inspections : []);
    }
  }, [issue.tags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (didSave && !saveLoading) {
      if (issue.id) {
        fetchChecklistCallback(issue.id, []);
      }
      setDidSave(false);
    }
  }, [issue.id, didSave, saveLoading, fetchChecklistCallback, setDidSave]);

  const onSubmitCheckpoint = async (inspection: IQcInspection) => {
    const result = await editCaseInspection(issue.id, {
      ...inspection,
      issueId: issue.id,
    });
    if (result) {
      if (result.errors !== undefined) {
        setSaveBarLoading(false);
        return;
      }
      setInspections([
        ...inspections.filter(
          (i) => i.qcCheckpointId !== result.inspection.qcCheckpointId
        ),
        result.inspection,
      ]);
    }
  };

  return (
    <Aux>
      {loading ? (
        <p className="text-center m-3">
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        </p>
      ) : (
        <IssueChecklistForm
          issue={issue}
          checkpoints={checkpoints}
          jigCheckpoints={jigCheckpoints}
          newCheckpoints={newCheckpoints}
          inspections={inspections}
          inspectionsAudits={inspectionsAudits}
          currentUser={current}
          history={history}
          handleItemSelected={handleItemSelected}
          setIssueClone={setIssueClone}
          editCaseInspection={onSubmitCheckpoint}
        />
      )}
      {saveBarloading && (
        <p className="text-center m-3">
          <FontAwesomeIcon
            icon={faSpinner}
            size="3x"
            spin
            style={{
              top: "50%",
              left: "50%",
              position: "fixed",
              zIndex: 999,
            }}
          />
        </p>
      )}
    </Aux>
  );
};

const mapStateToProps = (state: IRootState) => ({
  checklist: state.issuesReducer.checklist,
  current: state.authReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchIssueChecklist: (issueId: number | null, tagIds: number[]) =>
    dispatch(fetchIssueChecklist(issueId, tagIds)),
  editCaseInspection: (issueId: number, inspection: IQcInspection) =>
    dispatch(editCaseInspection(issueId, inspection)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueChecklistContainer);
