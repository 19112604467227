import {
  OrderStatusActionTypes,
  FETCH_ORDER_STATUSES_SUCCESS,
  FETCH_ORDER_STATUS_SUCCESS,
  FETCH_FILTERED_ORDER_STATUSES_SUCCESS,
  CREATE_ORDER_STATUS_SUCCESS,
  EDIT_ORDER_STATUS_SUCCESS,
  DELETE_ORDER_STATUS_SUCCESS,
} from "../../store/actions/orderStatus";
import { IOrderStatus } from "../../models/order";
import { DataList } from "../../utils/DataList";

export interface OrderStatusState {
  readonly orderStatuses: IOrderStatus[];
  readonly filteredOrderStatuses: DataList<IOrderStatus>;
  readonly orderStatus: IOrderStatus | null;
  readonly errors: any;
}

export const initialState: OrderStatusState = {
  orderStatuses: [],
  filteredOrderStatuses: { data: [], total: 0 },
  orderStatus: null,
  errors: null,
};

const orderStatusReducer = (
  state = initialState,
  action: OrderStatusActionTypes
): OrderStatusState => {
  switch (action.type) {
    case FETCH_ORDER_STATUSES_SUCCESS:
      return { ...state, orderStatuses: action.orderStatuses };
    case FETCH_ORDER_STATUS_SUCCESS:
      return { ...state, orderStatus: action.orderStatus };
    case FETCH_FILTERED_ORDER_STATUSES_SUCCESS:
      return { ...state, filteredOrderStatuses: action.orderStatuses };
    case CREATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderStatus: action.orderStatus,
        orderStatuses:
          state.orderStatuses.length > 0
            ? [...state.orderStatuses.concat([action.orderStatus])]
            : [],
      };
    case EDIT_ORDER_STATUS_SUCCESS:
      if (state.orderStatuses.length > 0) {
        state.orderStatuses.splice(
          state.orderStatuses.findIndex((t) => t.id === action.orderStatus.id),
          1,
          action.orderStatus
        );
      }
      return {
        ...state,
        orderStatus: action.orderStatus,
        orderStatuses:
          state.orderStatuses.length > 0 ? state.orderStatuses : [],
      };
    case DELETE_ORDER_STATUS_SUCCESS:
      return { ...state, orderStatus: null, orderStatuses: [] };
    default:
      return state;
  }
};

export default orderStatusReducer;
