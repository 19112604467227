import { History } from "history";
import { getAuditColor, getAuditIcon, IAuditEntry } from "models/audit";
import React from "react";
import { Modal } from "react-bootstrap";
import AuditEntryLine from "./AuditEntryLine";

export interface IProps {
  title: string;
  entries: IAuditEntry[] | null;
  show: boolean;
  history?: History;
  onClose: () => void;
}

const AuditModal = (props: IProps) => {
  const { title, show, onClose, entries, history } = props;

  return (
    <Modal
      show={show && entries !== undefined && entries !== null}
      onHide={onClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-truncate">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ overflow: "hidden" }}>
          {entries === undefined || entries === null ? (
            ""
          ) : entries.length > 0 ? (
            <div className="messages">
              {entries
                .sort(
                  (a, b) =>
                    new Date(a.dateTime).getTime() -
                    new Date(b.dateTime).getTime()
                )
                .map((entry, i) => (
                  <AuditEntryLine
                    key={"entry-cat-" + i}
                    color={getAuditColor(entry)}
                    icon={getAuditIcon(entry)}
                    entry={entry}
                    history={history}
                    truncateText={true}
                  />
                ))}
            </div>
          ) : (
            <div>No Audit Logs available</div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AuditModal;
