import { IIssue } from "models/issue";
import { IMessage } from "../../models/message";
import {
  CONNECTED,
  RECIEVED_MESSAGE,
  RECIEVED_ISSUE_COUNT,
  RECIEVED_UPDATED_ISSUE,
  UPDATE_SIGNALR_ISSUE,
  SignalActionTypes,
  RESET_MESSAGES,
  RESET_UPDATED_ISSUES,
  RECIEVED_UPDATED_QC_CHECKLIST,
  RECIEVED_UPDATED_TT_CHECKLIST,
  RECIEVED_UPDATED_CASE_CHECKLIST,
} from "../actions/signalr";
import { IUpdatedQcCheclist } from "models/QualityControl/qcChecklist";
import { IQcInspection } from "models/QualityControl/qcInspection";

export interface SignalState {
  signalRmessages: IMessage[];
  signalRIssues: IIssue[];
  updatedChecklists: IUpdatedQcCheclist[];
  issueInspections: IQcInspection[];
  orderInspections: IQcInspection[];
  issueCount?: number;
  connected: boolean;
}

export const initialState: SignalState = {
  signalRmessages: [],
  signalRIssues: [],
  updatedChecklists: [],
  issueInspections: [],
  orderInspections: [],
  issueCount: undefined,
  connected: false,
};

const signalReducer = (
  state = initialState,
  action: SignalActionTypes
): SignalState => {
  switch (action.type) {
    case RECIEVED_MESSAGE:
      const signalRmessages = [...state.signalRmessages];
      signalRmessages.push(action.message);
      return {
        ...state,
        signalRmessages,
      };
    case RECIEVED_UPDATED_ISSUE:
      let signalRIssues = [...state.signalRIssues];
      signalRIssues = signalRIssues.filter((i) => i.id !== action.issue.id);
      signalRIssues.push(action.issue);
      return {
        ...state,
        signalRIssues,
      };
    case UPDATE_SIGNALR_ISSUE:
      let signalRCases = [...state.signalRIssues];
      signalRCases = signalRCases.filter((i) => i.id !== action.issueId);
      return {
        ...state,
        signalRIssues: signalRCases,
      };
    case RECIEVED_UPDATED_QC_CHECKLIST:
      console.log(action);
      return {
        ...state,
        updatedChecklists: [action.updatedChecklist],
      };
    case RECIEVED_UPDATED_CASE_CHECKLIST:
      console.log(action);
      return {
        ...state,
        issueInspections: action.inspections,
      };
    case RECIEVED_UPDATED_TT_CHECKLIST:
      console.log(action);
      return {
        ...state,
        orderInspections: action.inspections,
      };
    case RECIEVED_ISSUE_COUNT:
      return { ...state, issueCount: action.count };
    case RESET_MESSAGES:
      return { ...state, issueCount: 0, signalRmessages: [] };
    case RESET_UPDATED_ISSUES:
      return { ...state, signalRIssues: [] };
    case CONNECTED:
      return { ...state, connected: true };
    default:
      return state;
  }
};

export default signalReducer;
