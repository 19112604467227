import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "./request";
import {
  IOrder,
  IOrderTimeTrack,
  ITimetrackChecklist,
} from "../../models/order";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";
import { IQcInspection } from "models/QualityControl/qcInspection";

export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_KITITNG_SUCCESS = "FETCH_ORDER_KITITNG_SUCCESS";
export const FETCH_ORDER_BY_NO_SUCCESS = "FETCH_ORDER_BY_NO_SUCCESS";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_WELDER_ORDERS_SUCCESS = "FETCH_WELDER_ORDERS_SUCCESS";
export const FETCH_FILTERED_ORDERS_SUCCESS = "FETCH_FILTERED_ORDERS_SUCCESS";
export const FETCH_WORKLIST_ORDERS_SUCCESS = "FETCH_WORKLIST_ORDERS_SUCCESS";
export const FETCH_FILTERED_DB_ORDERS_SUCCESS =
  "FETCH_FILTERED_DB_ORDERS_SUCCESS";
export const FETCH_FILTERED_TIMETRACK_ORDERS_SUCCESS =
  "FETCH_FILTERED_TIMETRACK_ORDERS_SUCCESS";
export const ORDER_REQUEST_FAILURE = "ORDER_REQUEST_FAILURE";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const ORDER_REQUEST_ERRORS = "ORDER_REQUEST_ERRORS";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_WORK_LIST_SUCCESS = "EDIT_ORDER_WORK_LIST_SUCCESS";
export const ORDER_LABEL_PRINT_SUCCESS = "ORDER_LABEL_PRINT_SUCCESS";
export const FETCH_ORDER_TIMETRACK_SUCCESS = "FETCH_ORDER_TIMETRACK_SUCCESS";
export const FETCH_TIMETRACK_CHECKLIST_SUCCESS =
  "FETCH_TIMETRACK_CHECKLIST_SUCCESS";
export const EDIT_KITTING_STATUS_SUCCESS = "EDIT_KITTING_STATUS_SUCCESS";
export const EDIT_ORDER_INSPECTION_SUCCESS = "EDIT_ORDER_INSPECTION_SUCCESS";

export type OrderActions = {
  FETCH_ORDER_SUCCESS: {
    type: typeof FETCH_ORDER_SUCCESS;
    order: IOrder;
  };
  FETCH_ORDER_KITITNG_SUCCESS: {
    type: typeof FETCH_ORDER_KITITNG_SUCCESS;
    order: IOrder;
  };
  FETCH_ORDER_BY_NO_SUCCESS: {
    type: typeof FETCH_ORDER_BY_NO_SUCCESS;
    order: IOrder;
  };
  FETCH_ORDERS_SUCCESS: {
    type: typeof FETCH_ORDERS_SUCCESS;
    orders: IOrder[];
  };
  FETCH_WELDER_ORDERS_SUCCESS: {
    type: typeof FETCH_WELDER_ORDERS_SUCCESS;
    orders: IOrder[];
  };
  FETCH_FILTERED_ORDERS_SUCCESS: {
    type: typeof FETCH_FILTERED_ORDERS_SUCCESS;
    orders: DataResult;
  };
  FETCH_WORKLIST_ORDERS_SUCCESS: {
    type: typeof FETCH_WORKLIST_ORDERS_SUCCESS;
    orders: DataResult;
  };
  FETCH_FILTERED_DB_ORDERS_SUCCESS: {
    type: typeof FETCH_FILTERED_DB_ORDERS_SUCCESS;
    orders: DataResult;
  };
  FETCH_FILTERED_TIMETRACK_ORDERS_SUCCESS: {
    type: typeof FETCH_FILTERED_TIMETRACK_ORDERS_SUCCESS;
    orders: DataResult;
  };
  CREATE_ORDER_SUCCESS: {
    type: typeof CREATE_ORDER_SUCCESS;
    order: IOrder;
  };
  DELETE_ORDER_SUCCESS: {
    type: typeof DELETE_ORDER_SUCCESS;
  };
  EDIT_ORDER_SUCCESS: {
    type: typeof EDIT_ORDER_SUCCESS;
    order: IOrder;
  };
  EDIT_KITTING_STATUS_SUCCESS: {
    type: typeof EDIT_KITTING_STATUS_SUCCESS;
    order: IOrder;
  };
  EDIT_ORDER_WORK_LIST_SUCCESS: {
    type: typeof EDIT_ORDER_WORK_LIST_SUCCESS;
    orders: IOrder[];
  };
  ORDER_LABEL_PRINT_SUCCESS: {
    type: typeof ORDER_LABEL_PRINT_SUCCESS;
    orders: IOrder[];
  };
  ORDER_REQUEST_ERRORS: {
    type: typeof ORDER_REQUEST_ERRORS;
    errors: any;
  };
  ORDER_REQUEST_FAILURE: {
    type: typeof ORDER_REQUEST_FAILURE;
    errors: any;
  };
  FETCH_ORDER_TIMETRACK_SUCCESS: {
    type: typeof FETCH_ORDER_TIMETRACK_SUCCESS;
    orderTimeTrack: IOrderTimeTrack;
  };
  FETCH_TIMETRACK_CHECKLIST_SUCCESS: {
    type: typeof FETCH_TIMETRACK_CHECKLIST_SUCCESS;
    timetrackChecklist: ITimetrackChecklist;
  };
  EDIT_ORDER_INSPECTION_SUCCESS: {
    type: typeof EDIT_ORDER_INSPECTION_SUCCESS;
    inspection: IQcInspection;
  };
};

export type OrderActionTypes =
  | OrderActions[typeof FETCH_ORDER_SUCCESS]
  | OrderActions[typeof FETCH_ORDER_KITITNG_SUCCESS]
  | OrderActions[typeof FETCH_ORDERS_SUCCESS]
  | OrderActions[typeof FETCH_WELDER_ORDERS_SUCCESS]
  | OrderActions[typeof FETCH_FILTERED_ORDERS_SUCCESS]
  | OrderActions[typeof FETCH_WORKLIST_ORDERS_SUCCESS]
  | OrderActions[typeof FETCH_FILTERED_DB_ORDERS_SUCCESS]
  | OrderActions[typeof FETCH_FILTERED_TIMETRACK_ORDERS_SUCCESS]
  | OrderActions[typeof CREATE_ORDER_SUCCESS]
  | OrderActions[typeof DELETE_ORDER_SUCCESS]
  | OrderActions[typeof EDIT_ORDER_SUCCESS]
  | OrderActions[typeof EDIT_KITTING_STATUS_SUCCESS]
  | OrderActions[typeof EDIT_ORDER_WORK_LIST_SUCCESS]
  | OrderActions[typeof ORDER_LABEL_PRINT_SUCCESS]
  | OrderActions[typeof ORDER_REQUEST_ERRORS]
  | OrderActions[typeof ORDER_REQUEST_FAILURE]
  | OrderActions[typeof FETCH_ORDER_BY_NO_SUCCESS]
  | OrderActions[typeof FETCH_ORDER_TIMETRACK_SUCCESS]
  | OrderActions[typeof FETCH_TIMETRACK_CHECKLIST_SUCCESS]
  | OrderActions[typeof EDIT_ORDER_INSPECTION_SUCCESS];

export const actionCreators = {
  fetchOrderSuccess: (
    order: IOrder
  ): OrderActions[typeof FETCH_ORDER_SUCCESS] => ({
    type: FETCH_ORDER_SUCCESS,
    order: order,
  }),
  fetchOrderKittingSuccess: (
    order: IOrder
  ): OrderActions[typeof FETCH_ORDER_KITITNG_SUCCESS] => ({
    type: FETCH_ORDER_KITITNG_SUCCESS,
    order: order,
  }),
  fetchOrderByNoSuccess: (
    order: IOrder
  ): OrderActions[typeof FETCH_ORDER_BY_NO_SUCCESS] => ({
    type: FETCH_ORDER_BY_NO_SUCCESS,
    order: order,
  }),
  fetchOrdersSuccess: (
    orders: IOrder[]
  ): OrderActions[typeof FETCH_ORDERS_SUCCESS] => ({
    type: FETCH_ORDERS_SUCCESS,
    orders: orders,
  }),
  fetchWelderOrdersSuccess: (
    orders: IOrder[]
  ): OrderActions[typeof FETCH_WELDER_ORDERS_SUCCESS] => ({
    type: FETCH_WELDER_ORDERS_SUCCESS,
    orders: orders,
  }),
  fetchFilteredOrdersSuccess: (
    orders: DataResult
  ): OrderActions[typeof FETCH_FILTERED_ORDERS_SUCCESS] => ({
    type: FETCH_FILTERED_ORDERS_SUCCESS,
    orders: orders,
  }),
  fetchworklistOrdersSuccess: (
    orders: DataResult
  ): OrderActions[typeof FETCH_WORKLIST_ORDERS_SUCCESS] => ({
    type: FETCH_WORKLIST_ORDERS_SUCCESS,
    orders: orders,
  }),
  fetchFilteredDbOrdersSuccess: (
    orders: DataResult
  ): OrderActions[typeof FETCH_FILTERED_DB_ORDERS_SUCCESS] => ({
    type: FETCH_FILTERED_DB_ORDERS_SUCCESS,
    orders: orders,
  }),
  fetchFilteredTimetrackOrdersSuccess: (
    orders: DataResult
  ): OrderActions[typeof FETCH_FILTERED_TIMETRACK_ORDERS_SUCCESS] => ({
    type: FETCH_FILTERED_TIMETRACK_ORDERS_SUCCESS,
    orders: orders,
  }),
  createOrderSuccess: (
    order: IOrder
  ): OrderActions[typeof CREATE_ORDER_SUCCESS] => ({
    type: CREATE_ORDER_SUCCESS,
    order: order,
  }),
  deleteOrderSuccess: (): OrderActions[typeof DELETE_ORDER_SUCCESS] => ({
    type: DELETE_ORDER_SUCCESS,
  }),
  editOrderSuccess: (
    order: IOrder
  ): OrderActions[typeof EDIT_ORDER_SUCCESS] => ({
    type: EDIT_ORDER_SUCCESS,
    order: order,
  }),
  editKittingStatusSuccess: (
    order: IOrder
  ): OrderActions[typeof EDIT_KITTING_STATUS_SUCCESS] => ({
    type: EDIT_KITTING_STATUS_SUCCESS,
    order: order,
  }),
  editWorkListSuccess: (
    orders: IOrder[]
  ): OrderActions[typeof EDIT_ORDER_WORK_LIST_SUCCESS] => ({
    type: EDIT_ORDER_WORK_LIST_SUCCESS,
    orders: orders,
  }),
  orderLabelPrintSuccess: (
    orders: IOrder[]
  ): OrderActions[typeof ORDER_LABEL_PRINT_SUCCESS] => ({
    type: ORDER_LABEL_PRINT_SUCCESS,
    orders: orders,
  }),
  orderRequestErrors: (
    errors: any
  ): OrderActions[typeof ORDER_REQUEST_ERRORS] => ({
    type: ORDER_REQUEST_ERRORS,
    errors: errors,
  }),
  orderRequestFailure: (
    status: number
  ): OrderActions[typeof ORDER_REQUEST_FAILURE] => ({
    type: ORDER_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  fetchOrderTimeTrackSuccess: (
    orderTimeTrack: IOrderTimeTrack
  ): OrderActions[typeof FETCH_ORDER_TIMETRACK_SUCCESS] => ({
    type: FETCH_ORDER_TIMETRACK_SUCCESS,
    orderTimeTrack: orderTimeTrack,
  }),
  fetchTimeTrackChecklistSuccess: (
    timetrackChecklist: ITimetrackChecklist
  ): OrderActions[typeof FETCH_TIMETRACK_CHECKLIST_SUCCESS] => ({
    type: FETCH_TIMETRACK_CHECKLIST_SUCCESS,
    timetrackChecklist: timetrackChecklist,
  }),
  editOrderInspectionSuccess: (
    inspection: IQcInspection
  ): OrderActions[typeof EDIT_ORDER_INSPECTION_SUCCESS] => ({
    type: EDIT_ORDER_INSPECTION_SUCCESS,
    inspection: inspection,
  }),
};

export const fetchOrder = (id: number, images: boolean) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(
      images ? `/orders/images/${id}` : `/orders/${id}`
    );
    return status === 200
      ? dispatch(actionCreators.fetchOrderSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
};

export const fetchOrderKitting = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/orders/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchOrderKittingSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
};

export const fetchOrderByNo = (no: string) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/orders/ordersNo/${no}`);
    return status === 200
      ? dispatch(actionCreators.fetchOrderSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
};
export function fetchOrders() {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest("/orders");
    return dispatch(actionCreators.fetchOrdersSuccess(json));
  };
}
export function fetchWelderOrders(welderId: number) {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/orders/welder/${welderId}`);
    return status === 200
      ? dispatch(actionCreators.fetchWelderOrdersSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
}
export const sendOrdersNotification = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest(`/orders/email/${id}`);
    return dispatch(actionCreators.fetchOrdersSuccess(json));
  };
};
export function fetchFilteredOrders(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_ORDERS_SUCCESS));
    const { status, json } = await getRequest(`/orders/filtered?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTERED_ORDERS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredOrdersSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
}
export function fetchWorklistOrders(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_WORKLIST_ORDERS_SUCCESS));
    const { status, json } = await getRequest(`/orders/worklist?${queryStr}`);
    dispatch(requestEnd(FETCH_WORKLIST_ORDERS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchworklistOrdersSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
}
export function fetchFilteredDbOrders(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_DB_ORDERS_SUCCESS));
    const { status, json } = await getRequest(`/orders/dbfiltered?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTERED_DB_ORDERS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredDbOrdersSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
}
export const createOrder = (order: IOrder) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/orders`, order);
    switch (status) {
      case 200:
        dispatch(saveSuccess(order.orderNo.toString()));
        return dispatch(actionCreators.createOrderSuccess(json));
      case 400:
        dispatch(saveFailed(order.orderNo.toString()));
        return dispatch(actionCreators.orderRequestErrors(json));
      default:
        dispatch(saveFailed(order.orderNo.toString()));
        return dispatch(actionCreators.orderRequestFailure(status));
    }
  };
};
export const editOrder = (orderNo: number, order: IOrder) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/orders/${orderNo}`, order);
    switch (status) {
      case 200:
        dispatch(saveSuccess(order.orderNo.toString()));
        return dispatch(actionCreators.editOrderSuccess(json));
      case 400:
        dispatch(saveFailed(order.orderNo.toString()));
        return dispatch(actionCreators.orderRequestErrors(json));
      default:
        dispatch(saveFailed(order.orderNo.toString()));
        return dispatch(actionCreators.orderRequestFailure(status));
    }
  };
};
export const editKittingStatus = (orderNo: number, order: IOrder) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/orders/orderstatus/${orderNo}`,
      order
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(order.orderNo.toString()));
        return dispatch(actionCreators.editKittingStatusSuccess(json));
      case 400:
        dispatch(saveFailed(order.orderNo.toString()));
        return dispatch(actionCreators.orderRequestErrors(json));
      default:
        dispatch(saveFailed(order.orderNo.toString()));
        return dispatch(actionCreators.orderRequestFailure(status));
    }
  };
};

export const editWorkList = (orders: IOrder[]) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/orders/worklist`, orders);
    switch (status) {
      case 200:
        dispatch(saveSuccess("WorkList saved"));
        return dispatch(actionCreators.editWorkListSuccess(json));
      case 400:
        dispatch(saveFailed("Save failed"));
        return dispatch(actionCreators.orderRequestErrors(json));
      default:
        dispatch(saveFailed("Save failed"));
        return dispatch(actionCreators.orderRequestFailure(status));
    }
  };
};
export const orderLabelPrint = (orders: IOrder[]) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/orders/labelprint`, orders);
    switch (status) {
      case 200:
        dispatch(saveSuccess("Printed orders saved"));
        return dispatch(actionCreators.orderLabelPrintSuccess(json));
      case 400:
        dispatch(saveFailed("Save failed"));
        return dispatch(actionCreators.orderRequestErrors(json));
      default:
        dispatch(saveFailed("Save failed"));
        return dispatch(actionCreators.orderRequestFailure(status));
    }
  };
};
export const deleteOrder = (id: number, order: IOrder) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/orders/${id}`, order);
    if (status === 200) {
      dispatch(deleteSuccess(order.orderNo.toString()));
      return dispatch(actionCreators.deleteOrderSuccess());
    }
    dispatch(saveFailed(order.orderNo.toString()));
    return dispatch(actionCreators.orderRequestFailure(status));
  };
};

export const fetchOrderTimeTrack = (orderId: number) => {
  return async (dispatch: Dispatch) => {
    let query = `/orders/timetrack/${orderId}`;
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchOrderTimeTrackSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
};

export const fetchTimeTrackChecklist = (orderId: number) => {
  return async (dispatch: Dispatch) => {
    let query = `/orders/timetrack/checklist/${orderId}`;
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchTimeTrackChecklistSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
};

export function fetchFilteredTimetrackOrders(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_ORDERS_SUCCESS));
    const { status, json } = await getRequest(
      `/orders/timetrack/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_ORDERS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredTimetrackOrdersSuccess(json))
      : dispatch(actionCreators.orderRequestFailure(status));
  };
}

export const editOrderInspection = (
  orderNo: number,
  inspection: IQcInspection
) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/orders/checkpoint/${orderNo}`,
      inspection
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(inspection.qcCheckpointName));
        return dispatch(actionCreators.editOrderInspectionSuccess(json));
      case 400:
        dispatch(saveFailed(inspection.qcCheckpointName));
        return dispatch(actionCreators.orderRequestErrors(json));
      default:
        dispatch(saveFailed(inspection.qcCheckpointName));
        return dispatch(actionCreators.orderRequestFailure(status));
    }
  };
};
