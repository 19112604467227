import {
  JigActionTypes,
  FETCH_JIGG_SUCCESS,
  FETCH_JIGGS_SUCCESS,
  FETCH_FILTEREDJIGGS_SUCCESS,
  CREATE_JIGG_SUCCESS,
  EDIT_JIGG_SUCCESS,
  DELETE_JIGG_SUCCESS,
  JIGG_REQUEST_FAILURE,
  JIGG_REQUEST_ERRORS,
  FETCH_JIGG_CATEGORIES_SUCCESS,
  CREATE_JIGG_IMAGE_SUCCESS,
  CREATE_JIGG_PRODUCT_SUCCESS,
  CREATE_JIG_CATEGORY_SUCCESS,
  EDIT_JIG_CATEGORY_SUCCESS,
} from "../actions/jigs";
import { IJig, IJigCategory, IJigProduct } from "../../models/jig";
import { IJigImage } from "../../models/image";
import { DataList } from "../../utils/DataList";

export interface JigState {
  readonly jigs: IJig[];
  readonly filteredJigs: DataList<IJig>;
  readonly jig: IJig | null;
  readonly errors: any;
  readonly jigCategories: IJigCategory[];
  readonly jigCategory: IJigCategory | null;
  readonly jigImage: IJigImage | null;
  readonly jImage: IJigImage | null;
  readonly jigProduct: IJigProduct | null;
}

export const initialState: JigState = {
  jigs: [],
  filteredJigs: { data: [], total: 0 },
  jig: null,
  errors: null,
  jigCategories: [],
  jigCategory: null,
  jigImage: null,
  jImage: null,
  jigProduct: null,
};

const jigsReducer = (
  state = initialState,
  action: JigActionTypes
): JigState => {
  switch (action.type) {
    case FETCH_JIGG_SUCCESS:
      return { ...state, jig: action.jig };
    case FETCH_JIGGS_SUCCESS:
      return { ...state, jigs: action.jigs };
    case FETCH_FILTEREDJIGGS_SUCCESS:
      return { ...state, filteredJigs: action.filteredJigs };
    case CREATE_JIGG_SUCCESS:
      return {
        ...state,
        jig: action.jig,
        jigs: state.jigs.length > 0 ? [...state.jigs.concat([action.jig])] : [],
      };
    case EDIT_JIGG_SUCCESS:
      if (state.jigs.length > 0) {
        state.jigs.splice(
          state.jigs.findIndex((t) => t.id === action.jig.id),
          1,
          action.jig
        );
      }
      return {
        ...state,
        jig: action.jig,
        jigs: state.jigs.length > 0 ? state.jigs : [],
      };
    case CREATE_JIG_CATEGORY_SUCCESS:
      return {
        ...state,
        jigCategory: action.jigCategory,
        jigCategories:
          state.jigCategories.length > 0
            ? [...state.jigCategories.concat([action.jigCategory])]
            : [],
      };
    case EDIT_JIG_CATEGORY_SUCCESS:
      if (state.jigCategories.length > 0) {
        state.jigCategories.splice(
          state.jigCategories.findIndex((t) => t.id === action.jigCategory.id),
          1,
          action.jigCategory
        );
      }
      return {
        ...state,
        jigCategory: action.jigCategory,
        jigCategories:
          state.jigCategories.length > 0 ? state.jigCategories : [],
      };
    case DELETE_JIGG_SUCCESS:
      return { ...state, jig: null, jigs: [] };
    case JIGG_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case JIGG_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case FETCH_JIGG_CATEGORIES_SUCCESS:
      return { ...state, jigCategories: action.jigCategories };
    case CREATE_JIGG_IMAGE_SUCCESS:
      return { ...state, jImage: action.jigImage };
    case CREATE_JIGG_PRODUCT_SUCCESS:
      return { ...state, jigProduct: action.jigProduct };
    default:
      return state;
  }
};

export default jigsReducer;
