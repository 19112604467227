import React, { useCallback, useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";
import { History } from "history";
import { Button, Col, Container, Row, Card } from "react-bootstrap";
import { connect } from "react-redux";

import IssueForm from "../components/Issues/IssueForm";
import IssueMessage from "../components/Issues/IssueMessage";
import IssueImage from "../components/Issues/IssueImage";
import CloseButton from "components/Common/CloseButton";
import IssueImageDelete from "../components/Issues/IssueImageDelete";
import AuditIssueEntries from "components/Audit/AuditIssueEntries";
import { IGroup } from "../models/group";
import { IImage } from "../models/image";
import { IIssue, ITaggedIssueItems } from "../models/issue";
import { IIssueType } from "../models/issuetype";
import { IMessage } from "../models/message";
import { IPriority } from "../models/priority";
import { IProduct, getProductLanguage } from "../models/product";
import {
  isAdmin,
  isCaseAdmin,
  isHmsUser,
  isHrUser,
  isServicePoint,
  isTechnicalSupport,
  IUser,
} from "../models/user";
import { fetchGroups } from "../store/actions/groups";
import { fetchTags } from "../store/actions/tag";
import { fetchIssueTypes } from "../store/actions/issuetypes";
import { fetchPriorities } from "../store/actions/priorities";
import { getRequest } from "../store/actions/request";
import {
  createIssue,
  editIssue,
  fetchIssue,
  fetchIssueAudits,
  updateUnreadIssue,
  fetchTaggedIssueItems,
  followIssue,
} from "../store/actions/issues";
import {
  fetchFilteredUsers,
  fetchRoleUsers,
  fetchUsers,
} from "../store/actions/users";
import { IRootState } from "../store/index";
import { editMessage, fetchMessagesByIssue } from "../store/actions/messages";
import { useRouteMatch } from "react-router";
import { Paths } from "../routes";
import { ITag, ITagCategory } from "../models/tag";
import { IQcChassi } from "../models/QualityControl/qcChassis";
import {
  joinIssue,
  sendIssueMessage,
  sendUpdatedIssue,
  updateSignalRCase,
} from "../store/actions/signalr";
import { IOrder } from "models/order";
import { IAuditEntry, IAuditGroup } from "models/audit";
import { fetchProduct } from "store/actions/products";
import { fetchTagCategories } from "store/actions/tagCategory";
import ImageModal from "components/Common/ImageModal";
import { fetchFilteredJigs } from "store/actions/jigs";
import { IJig } from "models/jig";
import { dateTimeFormatDay } from "utils/dateTimeFormat";
import { IQcInspection } from "models/QualityControl/qcInspection";
import { ParseUrlLinks } from "components/Issues/ParseUrlLinks";
import { fetchTemplates } from "store/actions/issuetemplates";
import { IIssueTemplate } from "models/issueTemplate";

interface IIssueMessageAudit {
  message: IMessage | null;
  audits: IAuditEntry[] | null;
  time: Date;
}

export interface MatchParams {
  id: string;
}

export interface IProps {
  issueId?: any;
  embed?: boolean;
  history: History;
  groups: IGroup[];
  priorities: IPriority[];
  issueTypes: IIssueType[];
  currentUser: IUser;
  tags: ITag[];
  tagCategories: ITagCategory[];
  checklistIssue?: boolean;
  timeTrackIssue?: boolean;
  qcChassi?: IQcChassi;
  orderNo?: number;
  unitsSCrapped?: number;
  checklistIssueTitle?: string;
  checkpointNote?: string;
  checkpointProds?: string[];
  timetrackProd?: IProduct;
  missingPart?: boolean;
  warranty?: boolean;
  waitingForPartsCase?: boolean;
  reworkWeldsIssue?: boolean;
  scrappedWeldsIssue?: boolean;
  maintenanceJig?: IJig;
  qcRework?: boolean;
  checkpointName?: string;
  orderProducts?: IProduct[];
  signalRInspections: IQcInspection[];
  jigs: DataResult;
  users: IUser[];
  externalUsers: IUser[];
  issueTemplates: IIssueTemplate[];
  fetchIssue: (id: number) => any;
  createIssue: (issue: IIssue) => any;
  editIssue: (id: number, issue: IIssue) => any;
  editMessage: (id: number, message: IMessage) => any;
  fetchProduct: (productNo: string) => any;
  fetchFilteredUsers: (queryStr: string) => any;
  fetchGroups: () => any;
  fetchPriorities: () => any;
  fetchIssueTypes: () => any;
  fetchTags: () => any;
  fetchTagCategories: () => any;
  onSubmit?: () => any;
  joinIssue: (issueId: number) => any;
  sendIssueMessage: (message: IMessage, sendExternal: boolean) => any;
  setChecklistModalShow?: (value: boolean) => any;
  setTimetrackProd?: (product: IProduct) => any;
  fetchMessagesByIssue: (issueId: number) => any;
  fetchIssueAudits: (issueId: number) => any;
  setSavedIssue?: (value: boolean) => any;
  updateUnreadIssue: (issueId: number, userId: number) => any;
  fetchFilteredJigs: (queryStr: string) => any;
  sendUpdatedIssue: (issue: IIssue) => any;
  updateSignalRCase: (issueId: number) => any;
  fetchTaggedIssueItems: (issueId: number) => any;
  fetchUsers: () => any;
  fetchTemplates: () => any;
  followIssue: (issueId: number, userId: number) => any;
  fetchRoleUsers: (role: string) => any;
  signalRmessages: IMessage[];
  watching?: number;
  signalrConnected: boolean;
}

const IssueFormContainer: React.FC<IProps> = (props) => {
  const {
    issueId,
    groups,
    priorities,
    issueTypes,
    currentUser,
    tags,
    tagCategories,
    checklistIssue,
    timeTrackIssue,
    qcChassi,
    orderNo,
    unitsSCrapped,
    checklistIssueTitle,
    checkpointNote,
    checkpointProds,
    timetrackProd,
    missingPart,
    warranty,
    waitingForPartsCase,
    reworkWeldsIssue,
    scrappedWeldsIssue,
    maintenanceJig,
    qcRework,
    history,
    embed,
    signalRmessages,
    watching,
    signalrConnected,
    signalRInspections,
    orderProducts,
    jigs,
    users,
    externalUsers,
    issueTemplates,
    fetchIssue,
    createIssue,
    editIssue,
    editMessage,
    fetchGroups,
    fetchPriorities,
    fetchIssueTypes,
    fetchProduct,
    fetchFilteredUsers,
    fetchTags,
    fetchTagCategories,
    onSubmit,
    joinIssue,
    sendIssueMessage,
    setChecklistModalShow,
    setTimetrackProd,
    fetchMessagesByIssue,
    fetchIssueAudits,
    setSavedIssue,
    updateUnreadIssue,
    fetchFilteredJigs,
    sendUpdatedIssue,
    updateSignalRCase,
    fetchUsers,
    fetchTemplates,
    fetchTaggedIssueItems,
    followIssue,
    fetchRoleUsers,
  } = props;

  const match = useRouteMatch<MatchParams>();

  const [issue, setIssue] = useState<IIssue>({} as IIssue);
  const [issueUsers, setIssueUsers] = useState<IUser[]>([]);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [auditEntries, setAuditEntries] = useState<IAuditEntry[]>([]);
  const [messageAudits, setMessageAudits] = useState<IIssueMessageAudit[]>([]);
  const [didSave, setDidSave] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>({} as IMessage);
  const [replyTo, setReplyTo] = useState<IMessage | undefined>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [messageLoading, setMessageLoading] = useState<boolean>(true);
  const [saveloading, setSaveLoading] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [userFIlterToken, setUserFilterToken] = useState<string>("");
  const [caseView, setCaseView] = useState<number>(4);
  const [modalContent, setModalContent] = useState<string>();
  const [modalImage, setModalImage] = useState<IImage>();
  const [images, setImages] = useState<IImage[]>([]);
  const [imageOnDelete, setImageOnDelete] = useState<{
    image: IImage;
    message: IMessage;
  } | null>(null);
  const [usersData, setUserData] = useState<DataResult>({
    data: [],
    total: 0,
  } as DataResult);

  const [
    userRequestState,
    setUserRequestState,
  ] = useState<DataSourceRequestState>({
    skip: 0,
    take: 10,
  });

  const [
    jigRequestState,
    setJigRequestState,
  ] = useState<DataSourceRequestState>({
    skip: 0,
    take: 10,
  });

  const [combinedMessages, setCombinedMessages] = useState<IMessage[]>([]);

  const getId = useCallback(
    () => issueId ?? Number(match.params.id),
    /* eslint-disable react-hooks/exhaustive-deps */
    [issueId, match]
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const issueJoin = useCallback(joinIssue, [joinIssue]);
  useEffect(() => {
    let id = getId();
    if (signalrConnected) issueJoin(id);
  }, [issueJoin, getId, signalrConnected]);

  useEffect(() => {
    setLoading(true);
    let mounted = true;
    const id = getId();
    if (!isNaN(id) && fetchIssue) {
      fetchIssue(id).then(({ issue }: { issue: IIssue }) => {
        if (mounted) {
          if (
            (issue.issueTypeId !== 2 && issue.issueTypeId !== 6) ||
            ((issue.issueTypeId === 2 || issue.issueTypeId === 6) &&
              (issue.users.some((u) => u.id === currentUser.id) ||
                issue.responsibleId === currentUser.id ||
                issue.userId === currentUser.id ||
                isAdmin(currentUser) ||
                (issue.issueTypeId === 2 && isHrUser(currentUser)) ||
                (issue.issueTypeId === 6 && isHmsUser(currentUser))))
          ) {
            setIssue({ ...issue, inspectionUsers: [] });
            setLoading(false);
            updateUnreadIssue(id, currentUser.id);
            updateSignalRCase(id);
          } else history.replace(Paths.issues);
        }
      });
    } else {
      fetchPriorities().then((data: { priorities: string | any[] }) => {
        if (mounted) {
          const addedOrder = orderNo ? [{ orderNo: orderNo } as IOrder] : [];
          const reWorkTag = tags.find((t) => t.name.toLowerCase() === "rework");
          const waitingForPartsTag = tags.find(
            (t) => t.name.toLowerCase() === "waiting for parts"
          );
          const rejectedTag = tags.find(
            (t) => t.name.toLowerCase() === "rejected"
          );

          setIssue({
            ...issue,
            messages: [],
            products: timetrackProd
              ? [timetrackProd]
              : orderProducts
              ? orderProducts
              : [],
            users: [],
            relIssues: [],
            mainCauseParts: [],
            issues: [],
            jigs: [],
            qcChassis: qcChassi ? [qcChassi] : [],
            priority:
              data.priorities.length > 2
                ? data.priorities[2]
                : data.priorities[0],
            status: "Open",
            responsible: isServicePoint(currentUser)
              ? currentUser
              : maintenanceJig !== undefined
              ? maintenanceJig.responsible
              : undefined,
            title: checklistIssue
              ? (checklistIssueTitle ?? "") +
                (waitingForPartsCase ? " Waiting for parts" : "") +
                (qcRework ? " Chassis rework" : "")
              : reworkWeldsIssue
              ? "Weldment for order " + orderNo + " rejected and set to rework"
              : scrappedWeldsIssue
              ? "Weldment for order " +
                orderNo +
                " rejected and set to scrapped"
              : maintenanceJig !== undefined
              ? "Equipment " +
                maintenanceJig.jignr +
                " - " +
                maintenanceJig.description +
                " maintenance " +
                dateTimeFormatDay.format(new Date())
              : "",
            orders: addedOrder,
            jig: maintenanceJig !== undefined ? maintenanceJig : undefined,
            group:
              maintenanceJig !== undefined
                ? maintenanceJig.maintenanceGroup
                : undefined,
            addedCheckpoints: [],
            userSignatures: [],
            inspectionUsers: [],
            tags:
              qcRework && reWorkTag
                ? [reWorkTag]
                : waitingForPartsCase && waitingForPartsTag
                ? [waitingForPartsTag]
                : (reworkWeldsIssue || scrappedWeldsIssue) && rejectedTag
                ? [rejectedTag]
                : maintenanceJig !== undefined
                ? maintenanceJig.tags
                : [],
          });
          setMessageLoading(false);
          setLoading(false);
        }
      });
      if (match.params.id !== "new") {
        fetchUsers();
      }
    }

    if (checklistIssue && qcChassi) {
      setMessage({
        ...message,
        body:
          `%{${qcChassi.no}} ` +
          (checkpointNote !== undefined ? checkpointNote : ""),
        userId: currentUser.id,
        issueId: issue.id,
        taggedUsers: [],
      } as IMessage);
    } else if (!timeTrackIssue && timetrackProd) {
      setMessage({
        ...message,
        body:
          `Order: \${${orderNo}} ` +
          `\n#{${timetrackProd.productNo} - ${getProductLanguage(
            currentUser,
            timetrackProd
          )}} `,
        userId: currentUser.id,
        issueId: issue.id,
        taggedUsers: [],
      } as IMessage);
    } else if (timeTrackIssue && timetrackProd) {
      setMessage({
        ...message,
        body:
          `#{${timetrackProd.productNo} - ${getProductLanguage(
            currentUser,
            timetrackProd
          )}} ` +
          (unitsSCrapped !== undefined && unitsSCrapped > 0
            ? "\nNumber of units scrapped: " + unitsSCrapped
            : ""),
        userId: currentUser.id,
        issueId: issue.id,
        taggedUsers: [],
      } as IMessage);
    } else if (orderNo) {
      setMessage({
        ...message,
        body:
          `Order: \${${orderNo}} ` +
          (orderProducts !== undefined && orderProducts.length > 0
            ? "\nProducts missing Images: " +
              orderProducts.map((p) => {
                if (p !== undefined) {
                  return `\n#{${p.productNo} - ${getProductLanguage(
                    currentUser,
                    p as IProduct
                  )}} `;
                } else return null;
              })
            : ""),
        userId: currentUser.id,
        issueId: issue.id,
        taggedUsers: [],
      } as IMessage);
    } else {
      setMessage({
        ...message,
        taggedUsers: [],
      });
    }

    fetchUserData();
    if (isInternal() || isServicePoint(currentUser)) fetchTagCategories();
    if (isInternal() || isServicePoint(currentUser)) fetchTags();
    if (isInternal() && externalUsers.length <= 0) fetchRoleUsers("external");
    if (groups.length <= 0) fetchGroups();
    if (priorities.length <= 0) fetchPriorities();
    if (issueTypes.length <= 0) fetchIssueTypes();
    fetchTemplates();
    if (!checklistIssue) {
      localStorage.setItem("products_redir", history.location.pathname);
    }
    if (
      isNaN(id) &&
      ((!timeTrackIssue &&
        !checklistIssue &&
        !reworkWeldsIssue &&
        !scrappedWeldsIssue) ||
        missingPart ||
        warranty ||
        waitingForPartsCase)
    ) {
      const caseType = localStorage.getItem("issueView");
      if (caseType === "partrequest" || waitingForPartsCase || missingPart) {
        setCaseView(1);
      }
      if (caseType === "administration") {
        setCaseView(2);
      }
      if (caseType === "warranty" || warranty) {
        setCaseView(3);
      }
      if (caseType === "orkeldirekte") {
        setCaseView(5);
      }
      if (caseType === "hms") {
        setCaseView(6);
      }
      if (caseType === "maintenance" || maintenanceJig !== undefined) {
        setCaseView(7);
      }
      if (caseType === "technicalsupport") {
        setCaseView(8);
      }
    }
    return () => {
      mounted = false;
    };
  }, [match]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const tempIssue = ParseUrlLinks(
      match.params.id,
      users,
      tags,
      groups,
      issue,
      issueTemplates,
      priorities,
      issueTypes
    );
    if (tempIssue) {
      setIssue(tempIssue);
      setCaseView(tempIssue.issueTypeId);
    }
  }, [tags, match, users]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (issue.id > 0) {
      setCaseView(issue.issueTypeId || 4);
    }
  }, [issue]);

  const fetchMessagesAndAudits = (
    issueId: number,
    isNewMessage: boolean,
    edit: boolean
  ) => {
    fetchMessagesByIssue(issueId).then(
      ({ messages }: { messages: IMessage[] }) => {
        if (!edit) {
          fetchIssueAudits(issueId).then(
            ({ entries }: { entries: IAuditEntry[] }) => {
              setMessages(messages);
              setAuditEntries(entries);
              setMessageLoading(false);
              if (isNewMessage && messages.length > 0) {
                sendHubMessage(messages[messages.length - 1], false);
              }
            }
          );
        } else {
          setMessages(messages);
          setMessageLoading(false);
          if (isNewMessage && messages.length > 0) {
            sendHubMessage(messages[messages.length - 1], false);
          }
        }
      }
    );
  };

  const fetchTaggedItems = (issueId: number) => {
    fetchTaggedIssueItems(issue.id).then(
      ({ taggedItems }: { taggedItems: ITaggedIssueItems }) => {
        setIssue((i) => ({
          ...i,
          products: taggedItems.products,
          qcChassis: taggedItems.qcChassis,
          jigs: taggedItems.jigs,
          relIssues: taggedItems.relIssues,
          salesforceCases: taggedItems.salesforceCases,
          mainCauseParts: taggedItems.mainCauseParts,
          orders: taggedItems.orders,
          users: taggedItems.users,
        }));
        setIssueUsers(taggedItems.users);
      }
    );
  };

  useEffect(() => {
    if (!loading && issue && issue.id > 0) {
      fetchMessagesAndAudits(issue.id, false, false);
      fetchTaggedItems(issue.id);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tempImgages: IImage[] = [];
    if (messages && messages.length > 0) {
      messages
        .filter((m) => !m.deletedAt)
        .sort((a, b) =>
          !a.createdAt
            ? -1
            : !b.createdAt
            ? 1
            : a.createdAt < b.createdAt
            ? 1
            : -1
        )
        .forEach((message) => {
          tempImgages.push(...message.images);
        });
    }
    setImages(tempImgages);
  }, [messages]);

  useEffect(() => {
    if (modalContent) {
      setModalShow(true);
    } else {
      setModalShow(false);
    }
  }, [modalContent]);

  useEffect(() => {
    if (
      issue &&
      issue.group === undefined &&
      groups.length > 0 &&
      caseView === 4
    ) {
      setIssue({
        ...issue,
        group: groups.find((g) => g.id === 5),
      });
    }
  }, [groups, issue, caseView]);

  const updateIssueMessages = useCallback(() => {
    const filteredSignalMessages = signalRmessages
      .filter((m) => !m.deletedAt)
      .filter((x) => messages.findIndex((y) => y.id === x.id) === -1);

    const combined = messages
      .filter((m) => !m.deletedAt)
      .concat(filteredSignalMessages);
    setCombinedMessages(combined);
  }, [signalRmessages, messages]);

  useEffect(() => {
    updateIssueMessages();
  }, [updateIssueMessages]);

  const sendHubMessage = (message: IMessage, sendExternal: boolean = false) => {
    console.log("Sending Issue Message: ", message);
    if (message.id) sendIssueMessage(message, sendExternal);
  };

  useEffect(() => {
    if (orderNo) {
      const addedOrder = { orderNo: orderNo } as IOrder;
      setIssue((i) => ({ ...i, orders: [addedOrder] }));
    }
  }, [orderNo]);

  // get products from checkpoint description when creating issue from checklist
  useEffect(() => {
    if (
      checkpointProds &&
      checkpointProds.length > 0 &&
      issue.status &&
      !isMounted
    ) {
      let prods: IProduct[] = [];
      checkpointProds.forEach((prod) => {
        fetchProduct(prod.slice(1)).then(
          ({ product }: { product: IProduct }) => {
            if (product !== undefined && qcChassi) {
              prods = [...prods, product];
              setIssue((i) => ({ ...i, products: prods }));
              setMessage({
                ...message,
                body:
                  (qcChassi !== undefined ? `%{${qcChassi.no}}  ` : "") +
                  (checkpointNote !== undefined ? checkpointNote : "") +
                  prods.map((p) => {
                    if (p !== undefined) {
                      return `\n#{${p.productNo} - ${getProductLanguage(
                        currentUser,
                        p as IProduct
                      )}} `;
                    } else return null;
                  }),
              });
            }
          }
        );
      });
      setIsMounted(true);
    }
  }, [checkpointProds, issue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const excludedFields = [
      "Products",
      "QcChassis",
      "Orders",
      "Users",
      "Inspections",
      "RelIssues",
      "Jigs",
      "SalesforceCases",
      "MainCauseParts",
      "AddedCheckpoints",
      "userid",
    ];

    var messages = combinedMessages.filter((m) => !m.deletedAt);
    var tempAuditEntries = auditEntries.filter(
      (audit) =>
        !excludedFields.includes(audit.field) &&
        (audit.field !== "Issues" || audit.objectId !== issue.id)
    );
    var grouped = groupAudits(tempAuditEntries);

    var maud = messages.map((m) => {
      var mesgAudit: IIssueMessageAudit = {
        time: m.createdAt,
        message: m,
        audits: null,
      };
      return mesgAudit;
    });

    var auditAudit = grouped.map((a) => {
      var mesgAudit: IIssueMessageAudit = {
        time: a.latestDate,
        message: null,
        audits: a.entries,
      };
      return mesgAudit;
    });

    var messagewAudit = maud.concat(auditAudit);
    setMessageAudits(messagewAudit);
  }, [auditEntries, combinedMessages]); // eslint-disable-line react-hooks/exhaustive-deps

  const groupAudits = (entries: IAuditEntry[]): IAuditGroup[] => {
    if (entries.length <= 0) return [];
    var sorted = [...entries].sort((a, b) =>
      a.actionGUID.localeCompare(b.actionGUID)
    );
    var currentUserGuid = sorted[0].actionGUID;
    var currentUserList: IAuditEntry[] = [];
    var grouped: IAuditGroup[] = [];
    for (var i = 0; i < sorted.length; i++) {
      var entry = sorted[i];
      if (currentUserGuid !== entry.actionGUID) {
        var group: IAuditGroup = {
          actionGUID: currentUserGuid,
          entries: currentUserList,
          latestDate: currentUserList[0].dateTime,
        };
        grouped = [...grouped, group];
        currentUserGuid = entry.actionGUID;
        currentUserList = [];
      }
      currentUserList = [...currentUserList, entry];
    }
    var lastGroup: IAuditGroup = {
      actionGUID: currentUserGuid,
      entries: currentUserList,
      latestDate: currentUserList[0].dateTime,
    };
    grouped = [...grouped, lastGroup];

    return grouped;
  };

  const refetchIssue = (issueId: number) => {
    fetchIssue(issueId).then(({ issue }: { issue: IIssue }) => {
      setIssue(issue);
    });
  };

  const isInternal = () => {
    const internalRoles = process.env.REACT_APP_INTERNAL_ROLES;
    return (
      internalRoles && currentUser?.roles.some((r) => internalRoles.includes(r))
    );
  };

  const fetchUserData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredUsers) {
      if (!request) {
        request = userRequestState;
      }
      return fetchFilteredUsers(toDataSourceRequestString(request));
    }
  };

  const viewImage = (image: IImage) => {
    if (image.id) {
      getRequest(`/images/show/${image.id}`).then((response) => {
        if (response.status !== 200) {
          return;
        }

        const data = response.json;
        if (data.startsWith("data:image") || data.startsWith("data:video")) {
          setModalContent(data);
          setModalImage(image);
        } else {
          fetch(data)
            .then((res) => res.blob())
            .then((blob) => {
              const blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl, "_blank");
            });
        }
      });
    }
  };

  useEffect(() => {
    let canceled = false;

    fetchUserData().then((result: any) => {
      if (!canceled) {
        if (result.type === "FETCH_FILTEREDUSERS_SUCCESS") {
          setUserData(result.usersData);
        }
      }
    });

    return () => {
      canceled = true;
    };
  }, [userRequestState]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUserRequestChange = (token: any) => {
    if (token.length > 1 || userFIlterToken.length > 0) {
      setUserFilterToken(token);
      const request = {
        ...userRequestState,
        filter: {
          filters: [
            {
              field: "fullName",
              operator: "contains",
              value: token,
            },
            {
              field: "inactive",
              operator: "eq",
              value: 0,
            },
          ],
          logic: "and" as const,
        },
      };
      setUserRequestState(request);
    }
  };

  const handleSubmit = (newMessage: IMessage, issueClone: IIssue) => {
    issueClone.messages = messages;
    setSaveLoading(true);
    issueClone.group
      ? (issueClone.groupId = issueClone.group.id)
      : delete issueClone.groupId;
    issueClone.responsible
      ? (issueClone.responsibleId = issueClone.responsible.id)
      : delete issueClone.responsibleId;
    issueClone.priority
      ? (issueClone.priorityId = issueClone.priority.id)
      : delete issueClone.priorityId;
    issueClone.issueType
      ? (issueClone.issueTypeId = issueClone.issueType.id)
      : delete issueClone.issueTypeId;
    if (!issueClone.userId) {
      issueClone.userId = currentUser.id;
    }

    const isNewMessage =
      Object.keys(newMessage).length > 0 &&
      (newMessage.body !== "" || newMessage.images?.length > 0);
    if (isNewMessage) {
      issueClone.messages.push(newMessage);
    }

    if (issueClone.id && checklistIssue !== true && timeTrackIssue !== true) {
      editIssue(issueClone.id, issueClone).then((data) => {
        if (data.errors !== undefined) {
          setSaveLoading(false);
          return;
        }
        if (data.issue) {
          setIssue({
            ...data.issue,
            inspectionUsers: [],
          });
          setAuditEntries(data.issue.auditEntries);
          setSaveLoading(false);
          setMessageLoading(true);
          fetchMessagesAndAudits(data.issue.id, isNewMessage, true);
          fetchTaggedItems(data.issue.id);
        }
        if (onSubmit) {
          onSubmit();
        }
        setDidSave(true);
        updateUnreadIssue(data.issue.id, currentUser.id);
        sendUpdatedIssue(data.issue);
        updateSignalRCase(data.issue.id);
      });
    } else if (createIssue) {
      createIssue(issueClone).then((data) => {
        updateUnreadIssue(data.issue.id, currentUser.id);
        if (checklistIssue || timeTrackIssue || reworkWeldsIssue) {
          if (checklistIssue && qcChassi) {
            qcChassi.issues.push(data.issue);
          } else if (!checklistIssue && setTimetrackProd && timetrackProd) {
            setTimetrackProd({
              ...timetrackProd,
              issues: [...timetrackProd.issues, data.issue],
            });
          }
          if (setChecklistModalShow) setChecklistModalShow(false);
          setSaveLoading(false);
          if ((reworkWeldsIssue || scrappedWeldsIssue) && setSavedIssue)
            setSavedIssue(true);
          if (
            qcRework ||
            waitingForPartsCase ||
            reworkWeldsIssue ||
            scrappedWeldsIssue ||
            maintenanceJig !== undefined
          ) {
            history.push(Paths.gIssuesId(data.issue.id));
          }
        } else {
          if (data.errors !== undefined) {
            setSaveLoading(false);

            return;
          }
          if (data.issue && data.issue.id) {
            setSaveLoading(false);
            setMessageLoading(true);
            fetchMessagesAndAudits(data.issue.id, true, true);
            history.replace(Paths.gIssuesId(data.issue.id));
          } else {
            setSaveLoading(false);
            history.replace(Paths.issues);
          }
          if (onSubmit) {
            onSubmit();
          }
          setDidSave(true);
        }
        sendUpdatedIssue(data.issue);
        updateSignalRCase(data.issue.id);
      });
    }
    setMessage({
      ...newMessage,
      body: "",
      replyToId: undefined,
      replyTo: undefined,
      images: [],
      taggedUsers: [],
    });
    setReplyTo(undefined);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleItemRemoved = (item: any) => {
    setIssueUsers(issueUsers.filter((u) => u !== item));
  };

  const handleEditMessge = (message: IMessage, addedItem: boolean) => {
    if (message.id) {
      editMessage(message.id, message).then((data) => {
        if (data.errors !== undefined) return;
        if (data.message) {
          let tempMessageAudits = [...messageAudits];
          tempMessageAudits.forEach((m) => {
            if (m.message !== null && m.message.id === data.message.id) {
              m.message.body = data.message.body;
              m.message.updatedAt = data.message.updatedAt;
              m.message.deletedAt = data.message.deletedAt;
            }
          });
          setMessageAudits(tempMessageAudits);
        }
      });
      if (addedItem) {
        editIssue(issue.id, issue).then((data) => {
          if (data.errors !== undefined) return;
          if (onSubmit) {
            onSubmit();
          }
        });
      }
    }
  };

  const handleImageDelete = (image: IImage, message: IMessage) => {
    var tempMessage = message;
    tempMessage.images = tempMessage.images.filter((i) => i.id !== image.id);
    handleEditMessge(tempMessage, false);
    setImageOnDelete(null);
  };

  const fetchJigData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredJigs) {
      if (!request) {
        request = jigRequestState;
      }
      return fetchFilteredJigs(toDataSourceRequestString(request));
    }
  };

  const handleJigRequestChange = (token: any) => {
    let field = "jignr";
    const request = {
      ...jigRequestState,
      filter: {
        filters: [
          {
            field: field,
            operator: "startswith",
            value: token,
          },
          {
            field: "hidden",
            operator: "eq",
            value: false,
          },
        ],
        logic: "and" as const,
      },
    };
    setJigRequestState(request);
    return fetchJigData(request);
  };

  const mql = window.matchMedia("(max-width: 1050px)");

  return (
    <Container fluid className="p-0">
      {!embed && <CloseButton onCLick={handleCancel} />}
      {loading ? (
        <p className="text-center m-3">
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        </p>
      ) : (
        <div>
          <Row>
            <Col
              md={
                maintenanceJig !== undefined ||
                checklistIssue ||
                timeTrackIssue ||
                reworkWeldsIssue ||
                scrappedWeldsIssue
                  ? 12
                  : !issue.id && mql.matches
                  ? 9
                  : 6
              }
            >
              <IssueForm
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                followIssue={followIssue}
                onUserRequestChange={handleUserRequestChange}
                onJigRequestChange={handleJigRequestChange}
                setReplyTo={setReplyTo}
                refetchIssue={refetchIssue}
                setDidSave={setDidSave}
                setIssueUsers={setIssueUsers}
                didSave={didSave}
                saveLoading={saveloading}
                usersData={usersData}
                issueUsers={issueUsers}
                jigs={jigs.data}
                groups={groups
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .filter((g) => !g.hide)}
                priorities={priorities}
                issueTypes={issueTypes}
                replyTo={replyTo}
                issue={issue}
                signalRInspections={signalRInspections}
                checklistIssue={checklistIssue}
                timetrackIssue={timeTrackIssue}
                reworkWeldsIssue={reworkWeldsIssue}
                scrappedWeldsIssue={scrappedWeldsIssue}
                message={message}
                messages={messages}
                externalUsers={externalUsers}
                currentUser={currentUser}
                history={history}
                tags={tags
                  .filter(
                    (t) =>
                      !t.hide &&
                      (caseView === 6 ? t.categoryNames.includes("HMS") : true)
                  )
                  .sort((a, b) => {
                    return ("" + a.name).localeCompare(b.name);
                  })}
                tagCategories={tagCategories
                  .filter((tc) =>
                    caseView === 6 ? tc.name.includes("HMS") : true
                  )
                  .sort((a, b) => {
                    return ("" + a.name).localeCompare(b.name);
                  })}
                caseView={caseView}
                watching={watching}
              />
            </Col>
            {(maintenanceJig === undefined ||
              !checklistIssue ||
              !timeTrackIssue ||
              !reworkWeldsIssue ||
              !scrappedWeldsIssue) && (
              <Col md={6}>
                {messageLoading ? (
                  <p className="text-center mx-3 my-5">
                    <FontAwesomeIcon icon={faSpinner} size="3x" spin />
                  </p>
                ) : (
                  <div>
                    {issue.images && issue.images.length > 0 && (
                      <Card bg="secondary" className="border-light mb-2">
                        <Card.Body>
                          {issue.images.map((image) => (
                            <IssueImage
                              editMode={false}
                              currentUser={currentUser}
                              image={image}
                              message={null}
                              setImageShow={(image) => viewImage(image)}
                              onDeleteClick={setImageOnDelete}
                              key={"image-" + image.id}
                            />
                          ))}
                        </Card.Body>
                      </Card>
                    )}
                    <div className="messages">
                      {messageAudits &&
                        messageAudits
                          .sort(
                            (a, b) =>
                              new Date(b.time).getTime() -
                              new Date(a.time).getTime()
                          )
                          .map((m) =>
                            m.message !== null && !m.message.deletedAt ? (
                              <IssueMessage
                                message={m.message}
                                onToggle={(id, message) => {
                                  editMessage(id, message);
                                  if (
                                    !message.roles.findIndex(
                                      (x) => x.name === "external"
                                    )
                                  )
                                    sendHubMessage(message, true);
                                }}
                                onReply={() => setReplyTo(m.message!)}
                                key={"message-" + m.message.id}
                                currentUser={currentUser}
                                setImageShow={(image) => viewImage(image)}
                                products={issue.products}
                                mainCauseParts={issue.mainCauseParts}
                                chassis={issue.qcChassis}
                                relIssues={issue.relIssues}
                                orders={issue.orders}
                                jigs={issue.jigs}
                                history={history}
                                issue={issue}
                                editMessage={handleEditMessge}
                                setIssue={setIssue}
                                onDeleteClick={setImageOnDelete}
                              />
                            ) : m.audits !== null &&
                              !isTechnicalSupport(currentUser) ? (
                              <AuditIssueEntries
                                key={"audit-" + m.audits![0].actionGUID}
                                entries={m.audits!}
                                history={history}
                              />
                            ) : null
                          )}
                    </div>
                    {!isTechnicalSupport(currentUser) &&
                      issueUsers &&
                      issueUsers.map((u, i) => (
                        <Button
                          variant={
                            messages.some((m) =>
                              m.taggedUsers.some((tu) => tu.id === u.id)
                            )
                              ? "outline-success"
                              : "outline-light"
                          }
                          size="sm"
                          title={u.email}
                          className="pt-1 me-1 mb-3"
                          key={"user" + u.id}
                          disabled={
                            !isCaseAdmin(currentUser) &&
                            u.id !== currentUser.id &&
                            issue.users.some((iu) => iu.id === u.id)
                          }
                        >
                          {u.firstName} {u.lastName}
                          {u.title === null ||
                          u.title === "" ||
                          u.title === undefined
                            ? ""
                            : " (" + u.title + ")"}{" "}
                          {isInternal() && (
                            <span
                              className="k-icon k-i-close k-i-x ms-2"
                              onClick={() => {
                                if (
                                  u.id !== currentUser.id &&
                                  issue.users.some((iu) => iu.id === u.id) &&
                                  !isCaseAdmin(currentUser)
                                )
                                  return;
                                handleItemRemoved(u);
                              }}
                            />
                          )}
                        </Button>
                      ))}
                  </div>
                )}
              </Col>
            )}
          </Row>
          <ImageModal
            image={modalImage}
            content={modalContent}
            show={modalShow}
            images={images}
            setImageShow={(image) => viewImage(image)}
            handleHide={() => {
              setModalContent("");
              setModalShow(false);
            }}
          />
          <IssueImageDelete
            image={imageOnDelete?.image}
            message={imageOnDelete?.message}
            show={imageOnDelete !== null}
            handleHide={() => setImageOnDelete(null)}
            onConfirmed={(image, message) => {
              handleImageDelete(image, message);
            }}
          />
          {saveloading && (
            <p className="text-center m-3">
              <FontAwesomeIcon
                icon={faSpinner}
                size="3x"
                spin
                style={{
                  top: "50%",
                  left: "50%",
                  position: "fixed",
                  zIndex: 999,
                }}
              />
            </p>
          )}
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentUser: state.authReducer.user,
  groups: state.groupsReducer.groups,
  priorities: state.prioritiesReducer.priorities,
  issueTypes: state.issueTypesReducer.issuetypes,
  signalRmessages: state.signalReducer.signalRmessages,
  signalrConnected: state.signalReducer.connected,
  watching: state.signalReducer.issueCount,
  tags: state.tagReducer.tags,
  tagCategories: state.tagCategoryReducer.tagCategories,
  jigs: state.jigsReducer.filteredJigs,
  users: state.usersReducer.users,
  signalRInspections: state.signalReducer.issueInspections,
  issueTemplates: state.issueTemplateReducer.templates,
  externalUsers: state.usersReducer.roleUsers,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchIssue: (id: number) => dispatch(fetchIssue(id)),
  createIssue: (_issue: IIssue) => dispatch(createIssue(_issue)),
  editIssue: (id: number, _issue: IIssue) => dispatch(editIssue(id, _issue)),
  editMessage: (id: number, _message: IMessage) =>
    dispatch(editMessage(id, _message)),
  fetchGroups: () => dispatch(fetchGroups()),
  fetchPriorities: () => dispatch(fetchPriorities()),
  fetchIssueTypes: () => dispatch(fetchIssueTypes()),
  fetchTags: () => dispatch(fetchTags()),
  fetchProduct: (prodNo: string) => dispatch(fetchProduct(prodNo)),
  fetchFilteredUsers: (queryStr: string) =>
    dispatch(fetchFilteredUsers(queryStr)),
  joinIssue: (issueId: number) => dispatch(joinIssue(issueId)),
  sendIssueMessage: (message: IMessage, sendExternal: boolean) =>
    dispatch(sendIssueMessage(message, sendExternal)),
  sendUpdatedIssue: (issue: IIssue) => dispatch(sendUpdatedIssue(issue)),
  fetchMessagesByIssue: (issueId: number) =>
    dispatch(fetchMessagesByIssue(issueId)),
  fetchIssueAudits: (issueId: number) => dispatch(fetchIssueAudits(issueId)),
  fetchTagCategories: () => dispatch(fetchTagCategories()),
  updateUnreadIssue: (issueId: number, userId: number) =>
    dispatch(updateUnreadIssue(issueId, userId)),
  fetchFilteredJigs: (query: string) => dispatch(fetchFilteredJigs(query)),
  updateSignalRCase: (issueId: number) => dispatch(updateSignalRCase(issueId)),
  fetchUsers: () => dispatch(fetchUsers()),
  fetchTemplates: () => dispatch(fetchTemplates()),
  fetchTaggedIssueItems: (issueId: number) =>
    dispatch(fetchTaggedIssueItems(issueId)),
  followIssue: (issueId: number, userId: number) =>
    dispatch(followIssue(issueId, userId)),
  fetchRoleUsers: (role: string) => dispatch(fetchRoleUsers(role)),
});

const formContainer = IssueFormContainer as (props: IProps) => JSX.Element;

export default connect(mapStateToProps, mapDispatchToProps)(formContainer);
