import { useEffect, useState } from "react";
import { store } from "../index";
import { FormikErrors } from "formik";

export function useAsyncErrorHandler(errorsFromState: FormikErrors<any>) {
  const [errorTrigger, setErrorTrigger] = useState(false);
  const [errorReceived, setErrorReceived] = useState(false);
  store.subscribe(() => {
    const errors = errorsFromState;
    if (!!errors) {
      setErrorReceived(true);
      setErrorTrigger(true);
    }
  });

  useEffect(() => {
    // Only run after error is received
    if (errorReceived) {
      // Unsubscribe from store
      store.subscribe(() => {});
      throw new Error("Asynchronous error.");
    }
  }, [errorTrigger]); // eslint-disable-line react-hooks/exhaustive-deps
}
