import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../request";
import { IQcAttrType } from "../../../models/QualityControl/qcAttrType";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../alerts";

export const FETCH_QC_ATTR_TYPES_SUCCESS = "FETCH_QC_ATTR_TYPES_SUCCESS";
export const FETCH_QC_ATTR_TYPE_SUCCESS = "FETCH_QC_ATTR_TYPE_SUCCESS";
export const FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS =
  "FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS";
export const CREATE_QC_ATTR_TYPE_SUCCESS = "CREATE_QC_ATTR_TYPE_SUCCESS";
export const EDIT_QC_ATTR_TYPE_SUCCESS = "EDIT_QC_ATTR_TYPE_SUCCESS";
export const EDIT_QC_ATTR_TYPE_ORDERS_SUCCESS =
  "EDIT_QC_ATTR_TYPE_ORDERS_SUCCESS";
export const DELETE_QC_ATTR_TYPE_SUCCESS = "DELETE_QC_ATTR_TYPE_SUCCESS";
export const QC_ATTR_TYPE_REQUEST_FAILURE = "QC_ATTR_TYPE_REQUEST_FAILURE";
export const QC_ATTR_TYPE_REQUEST_ERROR = "QC_ATTR_TYPE_REQUEST_ERROR";

export interface QcAttrTypeActions {
  FETCH_QC_ATTR_TYPES_SUCCESS: {
    type: typeof FETCH_QC_ATTR_TYPES_SUCCESS;
    qcAttrTypes: IQcAttrType[];
  };
  FETCH_QC_ATTR_TYPE_SUCCESS: {
    type: typeof FETCH_QC_ATTR_TYPE_SUCCESS;
    qcAttrType: IQcAttrType;
  };
  FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS: {
    type: typeof FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS;
    qcAttrTypes: DataResult;
  };
  CREATE_QC_ATTR_TYPE_SUCCESS: {
    type: typeof CREATE_QC_ATTR_TYPE_SUCCESS;
    qcAttrType: IQcAttrType;
  };
  EDIT_QC_ATTR_TYPE_SUCCESS: {
    type: typeof EDIT_QC_ATTR_TYPE_SUCCESS;
    qcAttrType: IQcAttrType;
  };
  EDIT_QC_ATTR_TYPE_ORDERS_SUCCESS: {
    type: typeof EDIT_QC_ATTR_TYPE_ORDERS_SUCCESS;
    qcAttrTypes: IQcAttrType[];
  };
  DELETE_QC_ATTR_TYPE_SUCCESS: {
    type: typeof DELETE_QC_ATTR_TYPE_SUCCESS;
  };
  QC_ATTR_TYPE_REQUEST_FAILURE: {
    type: typeof QC_ATTR_TYPE_REQUEST_FAILURE;
    errors: any;
  };
  QC_ATTR_TYPE_REQUEST_ERROR: {
    type: typeof QC_ATTR_TYPE_REQUEST_ERROR;
    errors: any;
  };
}

export type QcAttrTypeActionTypes =
  | QcAttrTypeActions[typeof FETCH_QC_ATTR_TYPES_SUCCESS]
  | QcAttrTypeActions[typeof FETCH_QC_ATTR_TYPE_SUCCESS]
  | QcAttrTypeActions[typeof FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS]
  | QcAttrTypeActions[typeof CREATE_QC_ATTR_TYPE_SUCCESS]
  | QcAttrTypeActions[typeof EDIT_QC_ATTR_TYPE_SUCCESS]
  | QcAttrTypeActions[typeof EDIT_QC_ATTR_TYPE_ORDERS_SUCCESS]
  | QcAttrTypeActions[typeof DELETE_QC_ATTR_TYPE_SUCCESS]
  | QcAttrTypeActions[typeof QC_ATTR_TYPE_REQUEST_FAILURE]
  | QcAttrTypeActions[typeof QC_ATTR_TYPE_REQUEST_ERROR];

export const actionCreators = {
  fetchQcAttrTypesSuccess: (
    qcAttrTypes: IQcAttrType[]
  ): QcAttrTypeActions[typeof FETCH_QC_ATTR_TYPES_SUCCESS] => ({
    type: FETCH_QC_ATTR_TYPES_SUCCESS,
    qcAttrTypes: qcAttrTypes,
  }),
  fetchQcAttrTypeSuccess: (
    qcAttrType: IQcAttrType
  ): QcAttrTypeActions[typeof FETCH_QC_ATTR_TYPE_SUCCESS] => ({
    type: FETCH_QC_ATTR_TYPE_SUCCESS,
    qcAttrType: qcAttrType,
  }),
  fetchFilteredQcAttrTypesSuccess: (
    qcAttrTypes: DataResult
  ): QcAttrTypeActions[typeof FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS] => ({
    type: FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS,
    qcAttrTypes: qcAttrTypes,
  }),
  createQcAttrTypeSuccess: (
    qcAttrType: IQcAttrType
  ): QcAttrTypeActions[typeof CREATE_QC_ATTR_TYPE_SUCCESS] => ({
    type: CREATE_QC_ATTR_TYPE_SUCCESS,
    qcAttrType: qcAttrType,
  }),
  editQcAttrTypeSuccess: (
    qcAttrType: IQcAttrType
  ): QcAttrTypeActions[typeof EDIT_QC_ATTR_TYPE_SUCCESS] => ({
    type: EDIT_QC_ATTR_TYPE_SUCCESS,
    qcAttrType: qcAttrType,
  }),
  editQcAttrTypeOrdersSuccess: (
    qcAttrTypes: IQcAttrType[]
  ): QcAttrTypeActions[typeof EDIT_QC_ATTR_TYPE_ORDERS_SUCCESS] => ({
    type: EDIT_QC_ATTR_TYPE_ORDERS_SUCCESS,
    qcAttrTypes: qcAttrTypes,
  }),
  deleteQcAttrTypeSuccess: (): QcAttrTypeActions[typeof DELETE_QC_ATTR_TYPE_SUCCESS] => ({
    type: DELETE_QC_ATTR_TYPE_SUCCESS,
  }),
  qcAttrTypeRequestFailure: (
    status: number
  ): QcAttrTypeActions[typeof QC_ATTR_TYPE_REQUEST_FAILURE] => ({
    type: QC_ATTR_TYPE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  qcAttrTypeRequestError: (
    errors: any
  ): QcAttrTypeActions[typeof QC_ATTR_TYPE_REQUEST_ERROR] => ({
    type: QC_ATTR_TYPE_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchQcAttrTypes() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_QC_ATTR_TYPES_SUCCESS));
    const { status, json } = await getRequest("/qcAttrTypes");
    dispatch(requestEnd(FETCH_QC_ATTR_TYPES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchQcAttrTypesSuccess(json))
      : dispatch(actionCreators.qcAttrTypeRequestFailure(status));
  };
}

export const fetchQcAttrType = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcAttrTypes/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchQcAttrTypeSuccess(json))
      : dispatch(actionCreators.qcAttrTypeRequestFailure(status));
  };
};

export function fetchFilteredQcAttrTypes(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS));
    const { status, json } = await getRequest(
      `/qcAttrTypes/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_QC_ATTR_TYPES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredQcAttrTypesSuccess(json))
      : dispatch(actionCreators.qcAttrTypeRequestFailure(status));
  };
}

export const createQcAttrType = (qcAttrType: IQcAttrType) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/qcAttrTypes", qcAttrType);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcAttrType.name));
        return dispatch(actionCreators.createQcAttrTypeSuccess(json));
      case 400:
        dispatch(saveFailed(qcAttrType.name));
        return dispatch(actionCreators.qcAttrTypeRequestError(json));
      default:
        dispatch(saveFailed(qcAttrType.name));
        return dispatch(actionCreators.qcAttrTypeRequestFailure(status));
    }
  };
};

export const editQcAttrType = (id: number, qcAttrType: IQcAttrType) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/qcAttrTypes/${id}`, qcAttrType);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcAttrType.name));
        return dispatch(actionCreators.editQcAttrTypeSuccess(json));
      case 400:
        dispatch(saveFailed(qcAttrType.name));
        return dispatch(actionCreators.qcAttrTypeRequestError(json));
      default:
        dispatch(saveFailed(qcAttrType.name));
        return dispatch(actionCreators.qcAttrTypeRequestFailure(status));
    }
  };
};

export const editQcAttrTypeOrders = (qcAttrTypes: IQcAttrType[]) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/qcAttrTypes/order`,
      qcAttrTypes
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess("QcAttributeType List"));
        return dispatch(actionCreators.editQcAttrTypeOrdersSuccess(json));
      case 400:
        dispatch(saveFailed("QcAttributeType List"));
        return dispatch(actionCreators.qcAttrTypeRequestError(json));
      default:
        dispatch(saveFailed("QcAttributeType List"));
        return dispatch(actionCreators.qcAttrTypeRequestFailure(status));
    }
  };
};

export const deleteQcAttrType = (id: number, qcAttrType: IQcAttrType) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/qcAttrTypes/${id}`, qcAttrType);
    if (status === 200) {
      dispatch(deleteSuccess(qcAttrType.name));
      return dispatch(actionCreators.deleteQcAttrTypeSuccess());
    }
    dispatch(saveFailed(qcAttrType.name));
    return dispatch(actionCreators.qcAttrTypeRequestFailure(status));
  };
};
