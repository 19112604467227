import { faFile, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButton } from "components/Common/CloseButton";
import { isInternal, IUser } from "models/user";
import React, { useEffect, useRef, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { IProductImage } from "../../models/image";
import { getRequest } from "../../store/actions/request";
import ReactToPrint from "react-to-print";

export interface IProps {
  image: IProductImage;
  currentUser: IUser;
  checkForProductImage: (productNo: string) => any;
}

const ProductImage: React.FC<IProps> = (props) => {
  const { image, currentUser, checkForProductImage } = props;

  const [imageContent, setImageContent] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [CheckingForImage, setCheckingForImage] = useState<boolean>(false);
  const [showNoImagesFound, setShowNoImagesFound] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const componentRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    setLoading(true);
    let mounted = false;
    if (image.id) {
      getRequest(`/productsimages/${image.id}`).then((response) => {
        if (response.json && response.status !== 500) {
          if (!mounted) setImageContent(JSON.parse(response.json));
        } else {
          if (!mounted) recheckForImage(false);
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    return () => {
      mounted = true;
    };
  }, [image]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showNoImagesFound) {
      setTimeout(() => setShowNoImagesFound(false), 2000);
    }
  }, [showNoImagesFound]);

  const recheckForImage = (showNotFound: boolean) => {
    checkForProductImage(image.productNo.toString()).then(
      ({ productImages }: { productImages: IProductImage[] }) => {
        if (productImages && productImages.length > 0) {
          const foundImage = productImages.find(
            (i) => i.revision === image.revision
          );
          if (foundImage !== undefined) {
            getRequest(`/productsimages/${foundImage.id}`).then((response) => {
              if (response.json && response.status !== 500) {
                setImageContent(JSON.parse(response.json));
              } else {
                setImageContent(undefined);
                if (showNotFound) setShowNoImagesFound(true);
              }
              setLoading(false);
            });
          }
          setCheckingForImage(false);
        } else {
          setCheckingForImage(false);
          if (showNotFound) setShowNoImagesFound(true);
        }
      }
    );
  };

  const handleCheckForImageClick = () => {
    setCheckingForImage(true);
    recheckForImage(true);
  };

  return (
    <div>
      {loading ? (
        <p className="m-4">
          <FontAwesomeIcon icon={faSpinner} size="2x" spin />
        </p>
      ) : (
        <div className="mb-4">
          <span
            className="d-inline-block"
            style={{ cursor: "pointer" }}
            onClick={() => setShowModal(true)}
          >
            {imageContent ? (
              imageContent.map((content, index) => (
                <Image
                  src={content}
                  key={"image-" + index}
                  className="img-fluid bg-white mb-3"
                />
              ))
            ) : (
              <div>
                <FontAwesomeIcon icon={faFile} size="2x" className="m-2" />
                <p className="mb-0">Not found</p>
                <Button
                  className="mt-0"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleCheckForImageClick();
                  }}
                >
                  Recheck images for product
                </Button>
              </div>
            )}
          </span>
        </div>
      )}

      <div>
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header>
            <span className="text-truncate" style={{ maxWidth: "500px" }}>
              Product Image
            </span>
            <CloseButton onCLick={() => setShowModal(false)} />
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {imageContent ? (
              <div id="print-images" ref={componentRef}>
                {imageContent.map((content, index) => (
                  <div key={"image-" + index}>
                    <Image
                      src={content}
                      className="img-fluid bg-white images"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <FontAwesomeIcon icon={faFile} size="2x" className="m-2" />
            )}
          </Modal.Body>
          {isInternal(currentUser) && (
            <Modal.Footer>
              <ReactToPrint
                trigger={() => <Button>Print Image</Button>}
                content={() => componentRef.current}
              />
            </Modal.Footer>
          )}
        </Modal>
      </div>
      <Modal
        show={showNoImagesFound}
        onHide={() => setShowNoImagesFound(false)}
        centered
      >
        <Modal.Body>
          <h5 className="px-4 py-2">No images found</h5>
        </Modal.Body>
      </Modal>
      {CheckingForImage && (
        <p className="text-center m-3">
          <FontAwesomeIcon
            icon={faSpinner}
            size="3x"
            spin
            style={{
              top: "50%",
              left: "50%",
              position: "fixed",
              zIndex: 999,
            }}
          />
        </p>
      )}
    </div>
  );
};

export default ProductImage;
