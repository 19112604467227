import React, { useEffect, useState } from "react";
import Aux from "../../utils/auxiliary";

export interface IProps {
  handleChange: (value: string) => any;
  handleEnterClick?: (event: any) => any;
  searchTerm: string;
  placeholder: string;
  disabled?: boolean;
  type?: string;
  pattern?: string;
  inputMode?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
}

const SearchForm: React.FC<IProps> = (props) => {
  const [searchValue, setSearchValue] = useState<string>(props.searchTerm);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        props.handleChange(searchValue);
      }, 200)
    );
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchValue(props.searchTerm);
  }, [props.searchTerm]);

  const onKeyDown = (event: any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      if (props.handleEnterClick) {
        event.preventDefault();
        props.handleEnterClick(event);
      }
    }
  };

  return (
    <Aux>
      <div className="form-group m-0">
        <input
          type={props.type ?? "text"}
          pattern={props.pattern}
          placeholder={props.placeholder}
          inputMode={props.inputMode}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="form-control"
          disabled={props.disabled}
          onKeyDown={onKeyDown}
        />
      </div>
    </Aux>
  );
};
export default SearchForm;
