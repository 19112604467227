import {
  RequestActionTypes,
  REQUEST_BEGIN,
  REQUEST_END,
} from "../../store/actions/request";

export type LoadingState = {
  readonly inProgress: string[];
};

export const initialState: LoadingState = {
  inProgress: [],
};

const loadingReducer = (
  state = initialState,
  action: RequestActionTypes
): LoadingState => {
  switch (action.type) {
    case REQUEST_BEGIN:
      return {
        ...state,
        inProgress: [...state.inProgress, action.request],
      };
    case REQUEST_END:
      return {
        ...state,
        inProgress: state.inProgress.filter(
          (request) => request !== action.request
        ),
      };
    default:
      return state;
  }
};

export default loadingReducer;
