import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DrawerItem as DrawerItemKendo,
  DrawerItemProps,
} from "@progress/kendo-react-layout";
import React from "react";

export interface IProps extends DrawerItemProps {
  "data-expanded"?: boolean;

  smallText?: string;

  parentId?: number;
  id?: number;

  roles?: string[];

  icon?: string;
  faIcon?: IconDefinition;
  hideExpand?: boolean;
}

const DrawerItem = (props: IProps) => {
  const {
    visible,
    faIcon,
    parentId,
    smallText,
    roles,
    hideExpand,
    ...others
  } = props;

  const arrowDir = props["data-expanded"]
    ? "k-i-arrow-chevron-down"
    : "k-i-arrow-chevron-right";

  const mql = window.matchMedia("(min-width: 1000px)");

  return (
    <React.Fragment>
      {visible === false ? null : (
        <DrawerItemKendo {...others}>
          <div
            style={!parentId ? {} : { paddingLeft: "15px" }}
            className={mql.matches ? "mb-1" : "mb-1"}
          >
            {props.icon !== undefined && (
              <span className={"k-icon " + props.icon} />
            )}
            {faIcon !== undefined && (
              <span className="me-2">
                <FontAwesomeIcon icon={faIcon} />
              </span>
            )}
            <span>
              {props.text}
              {smallText && (
                <small className="text-warning"> {smallText}</small>
              )}
            </span>
          </div>
          {props["data-expanded"] !== undefined && !hideExpand && (
            <span
              className={"k-icon " + arrowDir}
              style={{
                position: "relative",
                marginLeft: "auto",
                marginRight: 0,
                color: "white",
              }}
            />
          )}
        </DrawerItemKendo>
      )}
    </React.Fragment>
  );
};

export default DrawerItem;
