import { useState } from "react";
import { connect } from "react-redux";
import { History } from "history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataResult } from "@progress/kendo-data-query";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import Aux from "../utils/auxiliary";
import ResourceGrid from "../components/Resource/ResourceGrid";
import { IResource } from "models/resource";
import { Paths } from "../routes";
import { IRootState } from "../store";
import { fetchFilteredResources } from "../store/actions/resources";
import { isResourceAdmin, IUser } from "models/user";
import AddNewButton from "components/Common/AddNewButton";

export interface IProps {
  history: History;
  resources: DataResult;
  currentUser: IUser;
  fetchFilteredResources: (queryStr: string) => any;
}

export const ResourceContainer = (props: IProps) => {
  const { history, resources, currentUser, fetchFilteredResources } = props;

  const [viewHidden, setViewHidden] = useState<boolean>(
    localStorage.getItem("view_hidden_resources") !== null
  );

  const handleFetch = (query: string) => {
    fetchFilteredResources(query);
  };

  const viewResource = (resource: IResource) => {
    if (viewHidden) {
      localStorage.setItem("view_hidden_resources", "true");
    } else {
      localStorage.removeItem("view_hidden_resources");
    }
    history.push(Paths.gResourcesId(resource.id));
  };

  const newResource = () => {
    history.push(Paths.resourcesCreate);
  };

  return (
    <Aux>
      <FontAwesomeIcon
        icon={faEyeSlash}
        size="2x"
        title="Toggle view hidden"
        className={`cursor${viewHidden ? "" : " text-muted"}`}
        onClick={() => {
          setViewHidden(!viewHidden);
        }}
      />
      <h2>Licenses</h2>
      <br />
      <ResourceGrid
        resources={resources}
        viewHidden={viewHidden}
        handleFetch={handleFetch}
        onResourceSelect={viewResource}
      />
      {isResourceAdmin(currentUser) && (
        <AddNewButton onCLick={() => newResource()} />
      )}
    </Aux>
  );
};

const mapStateToProps = (state: IRootState) => ({
  resources: state.resourceReducer.filteredResources,
  currentUser: state.authReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchFilteredResources: (queryStr: string) =>
    dispatch(fetchFilteredResources(queryStr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceContainer);
