import {
  faFilter,
  faLock,
  faLockOpen,
  faUser,
  faUserCheck,
  faUsers,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  CompositeFilterDescriptor,
  DataSourceRequestState,
  FilterDescriptor,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";
import { History } from "history";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import {
  isAdmin,
  isHmsUser,
  isHrUser,
  isInternal,
  isServicePoint,
  isSupplier,
  isTechnicalSupport,
  IUser,
} from "../../models/user";
import { IIssue } from "../../models/issue";
import IssueAccordionCard from "./IssueAccordionCard";
import IssueFilterButton, { IIssueFilterButton } from "./IssueFilterButton";
import SearchForm from "../Common/SearchForm";
import Aux from "../../utils/auxiliary";
import PageNavigation from "components/Common/PageNavigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IActiveFilters {
  userOwn: boolean;
  userAll: boolean;
  userGroup: boolean;
  noUser: boolean;
  status?: string;
  toggle: boolean;
}

export interface IProps {
  issues: IIssue[];
  totalIssues: number;
  history: History;
  current: IUser;
  caseView: number;
  handleFetch: (query: string) => void;
  onUserEdit: (user: IUser) => Promise<any>;
  editIssue: (c: IIssue) => void;
}

interface IssueAccordionLocalStorage {
  selectedIssue?: number;
  requestState?: DataSourceRequestState;
  searchTerm?: string;
  userId?: number;
  supplierNo?: number;
  activeFiltersState?: IActiveFilters;
  filterState?: CompositeFilterDescriptor;
  userFilterState?: CompositeFilterDescriptor | FilterDescriptor;
}

const IssueAccordion: React.FC<IProps> = (props) => {
  const {
    issues,
    history,
    current,
    totalIssues,
    caseView,
    onUserEdit,
    handleFetch,
  } = props;

  const internalRoles: string[] = process.env.REACT_APP_INTERNAL_ROLES
    ? JSON.parse(process.env.REACT_APP_INTERNAL_ROLES)
    : [];

  const storage: IssueAccordionLocalStorage | undefined = (() => {
    const stringJson = localStorage.getItem("issueaccordion");
    if (!stringJson) return;
    try {
      const loaded = JSON.parse(stringJson) as IssueAccordionLocalStorage;
      if (loaded.requestState) {
        loaded.requestState = {
          ...loaded.requestState,
          take: current.userPrefs[0]?.issueAccordionLength || 10,
        };
      }
      return JSON.parse(stringJson) as IssueAccordionLocalStorage;
    } catch (err) {
      console.error(
        "(Issue Acoordion) Failed to parse LocalStorage JSON;\n{0}",
        err
      );
    }
  })();

  const userFilter: CompositeFilterDescriptor = {
    filters: [{ field: "responsibleId", operator: "eq", value: current.id }],
    logic: "or" as const,
  };

  const statusFilter: FilterDescriptor = {
    field: "status",
    operator: "eq",
    value: "Open",
  };

  const [init, setInit] = useState<boolean>(true);
  const [caseViewState, setCaseViewState] = useState<number>(0);
  const [userId, setUserId] = useState<number>(storage?.userId || 0);
  const [supplierNo, setSupplierNo] = useState<number>(
    storage?.supplierNo || 0
  );
  const [searchTerm, setSearchTerm] = useState(storage?.searchTerm || "");
  const [activeFiltersState, setActiveFiltersState] = useState<IActiveFilters>(
    storage?.activeFiltersState || {
      userOwn: true,
      userAll: false,
      userGroup: false,
      noUser: false,
      status: "Open",
      toggle: true,
    }
  );
  const [filterState, setFilterState] = useState<CompositeFilterDescriptor>(
    storage?.filterState || {
      filters: [statusFilter],
      logic: "and" as const,
    }
  );
  const [userFilterState, setUserFilterState] = useState<
    CompositeFilterDescriptor | FilterDescriptor | undefined
  >(() => {
    return current?.roles.some((r) => internalRoles.includes(r))
      ? storage === undefined
        ? userFilter
        : storage?.userFilterState
      : undefined;
  });
  const [requestState, setRequestState] = useState<DataSourceRequestState>(
    storage?.requestState || {
      skip: 0,
      take: current.userPrefs[0]?.issueAccordionLength || 10,
      sort: [
        {
          field: "id",
          dir: "desc",
        },
      ],
    }
  );

  const [selectedIssue, setSelectedIssue] = useState<number>(
    storage?.selectedIssue || 0
  );

  const saveStorage = () => {
    const storage: IssueAccordionLocalStorage = {
      requestState: requestState,
      searchTerm: searchTerm,
      activeFiltersState: activeFiltersState,
      filterState: filterState,
      userFilterState: userFilterState,
      selectedIssue: selectedIssue,
      userId: userId,
      supplierNo: supplierNo,
    };
    localStorage.setItem("issueaccordion", JSON.stringify(storage));
  };
  useEffect(() => {
    if (caseView !== caseViewState) {
      setSelectedIssue(0);
      setCaseViewState(caseView);
      if (caseView === 2 || caseView === 6) {
        setUserId(current.id);
      }
      if (caseView === 5 && !isServicePoint(current)) {
        setActiveFiltersState({
          ...activeFiltersState,
          userOwn: true,
          userGroup: false,
          userAll: false,
          toggle: true,
        });
        setUserFilterState(userFilter);
        setUserId(0);
      } else if (caseView === 5 && isServicePoint(current)) {
        setActiveFiltersState({
          ...activeFiltersState,
          userOwn: false,
          userGroup: false,
          userAll: true,
          toggle: true,
        });
        setUserFilterState(undefined);
        setUserId(current.id);
      }
    }
  }, [caseView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (init || requestState.filter) {
      saveStorage();
      handleFetch(requestString);
      if (init) {
        setInit(false);
      }
    }
  }, [requestState, searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    saveStorage();
  }, [selectedIssue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!init) {
      const filters = [...filterState.filters];
      if (userFilterState && caseView !== 2 && caseView !== 6) {
        filters.push(userFilterState);
      }
      setRequestState({
        ...requestState,
        filter: {
          filters: filters,
          logic: "and" as const,
        },
        skip: 0,
      });
    }
  }, [filterState, userFilterState, userId, supplierNo, caseView]); // eslint-disable-line react-hooks/exhaustive-deps

  const requestString = `${toDataSourceRequestString(requestState)}&user=${
    (caseView === 2 && !isAdmin(current) && !isHrUser(current)) ||
    (caseView === 6 && !isAdmin(current) && !isHmsUser(current))
      ? current.id
      : userId
  }&term=${searchTerm}&supplier=${supplierNo}&caseView=${
    isTechnicalSupport(current) ? 8 : caseView
  }`;

  const makeGroupFilter = (): CompositeFilterDescriptor => {
    const my_groups: number[] = current.groups.map((group) => group.id);
    my_groups.push(0);

    const groupFilterDescriptor: FilterDescriptor[] = [];
    my_groups.forEach((group) => {
      groupFilterDescriptor.push({
        field: "groupId",
        operator: "eq",
        value: group,
      });
    });
    return {
      filters: groupFilterDescriptor,
      logic: "or" as const,
    } as CompositeFilterDescriptor;
  };

  const issueUserFilterButtons: IIssueFilterButton[] = [
    {
      title: "Cases involving me",
      icons: [faUserCheck],
      active: activeFiltersState.userAll,
      roles: [...internalRoles, "service"],
      handleClick: () => {
        setSelectedIssue(0);
        setUserFilterState(undefined);
        if (activeFiltersState.userAll) {
          setUserId(0);
          setActiveFiltersState({
            ...activeFiltersState,
            userAll: false,
          });
        } else {
          setUserId(current.id);
          setActiveFiltersState({
            ...activeFiltersState,
            userOwn: false,
            userAll: true,
            userGroup: false,
            toggle: true,
          });
        }
      },
    },
  ];

  if (
    isSupplier(current) &&
    !isInternal(current) &&
    current.supplierNo !== (null || undefined)
  ) {
    issueUserFilterButtons.push(
      {
        title: "My cases",
        icons: [faUser],
        active: activeFiltersState.userOwn,
        roles: ["supplier"],
        handleClick: () => {
          setSelectedIssue(0);
          if (activeFiltersState.userOwn) {
            setActiveFiltersState({
              ...activeFiltersState,
              userOwn: false,
            });

            setUserFilterState(undefined);
          } else {
            setActiveFiltersState({
              ...activeFiltersState,
              userOwn: true,
              userGroup: false,
              userAll: false,
              toggle: true,
            });
            setSupplierNo(0);
            setUserId(current.id);
            setFilterState({
              ...filterState,
            });
          }
        },
      },
      {
        title: "Supplier cases",
        icons: [faUsers],
        active: activeFiltersState.userGroup,
        roles: ["supplier"],
        handleClick: () => {
          setSelectedIssue(0);
          if (activeFiltersState.userGroup) {
            setUserFilterState(undefined);
            setSupplierNo(0);
            setActiveFiltersState({
              ...activeFiltersState,
              userGroup: false,
            });
          } else {
            setSupplierNo(current.supplierNo || 0);
            setUserFilterState(undefined);
            setUserId(0);
            setActiveFiltersState({
              ...activeFiltersState,
              userOwn: false,
              userGroup: true,
              toggle: true,
            });
            setFilterState({
              ...filterState,
            });
          }
        },
      }
    );
  } else {
    issueUserFilterButtons.unshift({
      title: "My cases",
      icons: [faUser],
      active: activeFiltersState.userOwn,
      roles: [...internalRoles, "service"],
      handleClick: () => {
        setSelectedIssue(0);
        if (activeFiltersState.userOwn) {
          setActiveFiltersState({
            ...activeFiltersState,
            userOwn: false,
          });
          setUserFilterState(undefined);
        } else {
          setActiveFiltersState({
            ...activeFiltersState,
            userOwn: true,
            userGroup: false,
            userAll: false,
            toggle: true,
          });
          setUserFilterState(userFilter);
          setUserId(0);
        }
      },
    });
    if (caseView !== 2 && caseView !== 3) {
      issueUserFilterButtons.push({
        title: `${current.groups.map((group) => group.name)}`,
        icons: [faUsers],
        active: activeFiltersState.userGroup,
        roles: internalRoles,
        handleClick: () => {
          setSelectedIssue(0);
          if (activeFiltersState.userGroup) {
            setUserFilterState(undefined);
            setActiveFiltersState({
              ...activeFiltersState,
              userGroup: false,
            });
          } else {
            setUserFilterState(makeGroupFilter());
            setActiveFiltersState({
              ...activeFiltersState,
              userOwn: false,
              userAll: false,
              userGroup: true,
              toggle: true,
            });
            setUserId(0);
          }
        },
      });
    }
  }

  const issueFilterButtons: IIssueFilterButton[] = [
    {
      title: "No responsible user",
      icons: [faUserSlash],
      active: activeFiltersState.noUser,
      roles: internalRoles,
      handleClick: () => {
        setSelectedIssue(0);
        const filter: FilterDescriptor = {
          field: "responsibleId",
          operator: "isnull",
        };
        let filters = filterState.filters;
        if (activeFiltersState.noUser) {
          filters = filters.filter(
            (f) => JSON.stringify(f) !== JSON.stringify(filter)
          );
          setActiveFiltersState({
            ...activeFiltersState,
            noUser: false,
          });
        } else {
          filters.push(filter);
          setActiveFiltersState({
            ...activeFiltersState,
            noUser: true,
            toggle: true,
          });
        }
        setFilterState({
          ...filterState,
          filters: filters,
        });
      },
    },
    {
      title: activeFiltersState.status,
      icons: [faLockOpen, faLock],
      active: activeFiltersState.status,
      roles: [],
      handleClick: () => {
        setSelectedIssue(0);
        let filters = filterState.filters;
        if (
          !activeFiltersState.status ||
          activeFiltersState.status === "Open"
        ) {
          statusFilter.value = "Open";
          filters = filters.filter(
            (f) => JSON.stringify(f) !== JSON.stringify(statusFilter)
          );
          statusFilter.value = "Closed";
          filters.push(statusFilter);
          setActiveFiltersState({
            ...activeFiltersState,
            status: "Closed",
            toggle: true,
          });
        } else if (
          !activeFiltersState.status ||
          activeFiltersState.status === "Closed"
        ) {
          statusFilter.value = "Closed";
          filters = filters.filter(
            (f) => JSON.stringify(f) !== JSON.stringify(statusFilter)
          );
          statusFilter.value = "Open";
          filters.push(statusFilter);
          setActiveFiltersState({
            ...activeFiltersState,
            status: "Open",
            toggle: true,
          });
        }
        setFilterState({
          ...filterState,
          filters: filters,
        });
      },
    },
  ];

  if (isInternal(current)) {
    issueFilterButtons.push({
      title: "Filter on/off",
      icons: [faFilter],
      active: activeFiltersState.toggle,
      className: "me-0",
      roles: internalRoles,
      handleClick: () => {
        if (activeFiltersState.toggle) {
          setUserId(caseView === 2 || caseView === 6 ? current.id : 0);
          setSelectedIssue(0);
          setSearchTerm("");
          setUserFilterState(undefined);
          setFilterState({
            ...filterState,
            filters: [statusFilter],
          });
          setActiveFiltersState({
            userOwn: false,
            userAll: caseView === 2 || caseView === 6 ? true : false,
            userGroup: false,
            noUser: false,
            status: "Open",
            toggle: false,
          });
        }
      },
    });
  } else {
    issueFilterButtons.push({
      title: "Filter on/off",
      icons: [faFilter],
      active: activeFiltersState.toggle,
      className: "me-0",
      roles: ["external"],
      handleClick: () => {
        if (activeFiltersState.toggle) {
          setSearchTerm("");
          setSupplierNo(0);
          setSelectedIssue(0);
          setUserFilterState(undefined);
          setUserId(current.id);
          setFilterState({
            ...filterState,
            filters: [statusFilter],
          });
          setActiveFiltersState({
            userOwn: false,
            userAll: false,
            userGroup: false,
            noUser: false,
            status: "Open",
            toggle: false,
          });
        }
      },
    });
  }

  const allFilterButtons = [...issueUserFilterButtons, ...issueFilterButtons];

  const handleSearchChange = (value: string) => {
    if (value !== searchTerm) {
      setSearchTerm(value);
      setSelectedIssue(0);
      if (value !== "") {
        setActiveFiltersState({
          ...activeFiltersState,
          toggle: true,
        });
        resetPage();
      }
    }
  };

  const handleIssueSubmit = () => {
    handleFetch(requestString);
  };

  const onLengthChanged = (event) => {
    const user = { ...current };
    user.userPrefs[0].issueAccordionLength = event.value ? event.value : 15;
    onUserEdit(user).then(() => {
      setRequestState({
        ...requestState,
        take: current.userPrefs[0].issueAccordionLength,
      });
    });
  };

  const resetPage = () => {
    setRequestState({
      ...requestState,
      skip: 0,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      if (issues.length === 1) {
        setSelectedIssue(selectedIssue !== issues[0].id ? issues[0].id : 0);
      } else {
        event.preventDefault();
      }
    }
  };

  return (
    <Aux>
      <Row>
        <Col xs={6} className="mt-0 pt-0">
          {caseView !== 2 && caseView !== 6 ? (
            issueUserFilterButtons.map(
              (button, i) =>
                (button.roles.length === 0 ||
                  current?.roles.some((r) => button.roles.includes(r))) && (
                  <IssueFilterButton
                    key={`issue-user-filter-button-${i}`}
                    {...button}
                  />
                )
            )
          ) : (
            <button
              title="Cases involving me"
              className={`issue-filter-button border-0 py-2 px-0 text-${
                userId === current.id ? "white" : "muted"
              }`}
              onClick={() => {
                if (caseView === 2 && (isAdmin(current) || isHrUser(current))) {
                  setUserId(userId === current.id ? 0 : current.id);
                } else if (
                  caseView === 6 &&
                  (isAdmin(current) || isHmsUser(current))
                ) {
                  setUserId(userId === current.id ? 0 : current.id);
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faUserCheck} size="2x" />
            </button>
          )}
        </Col>
        <Col xs={6} className="text-end mt-0 pt-0">
          {issueFilterButtons.map(
            (button, i) =>
              (button.roles.length === 0 ||
                current?.roles.some((r) => button.roles.includes(r))) && (
                <IssueFilterButton
                  key={`issue-filter-button-${i}`}
                  {...button}
                />
              )
          )}
        </Col>
      </Row>
      <SearchForm
        handleChange={handleSearchChange}
        searchTerm={searchTerm}
        placeholder="Search"
        handleEnterClick={handleKeyDown}
        disabled={false}
      />
      {caseView !== 2 && caseView !== 6 ? (
        <div className="text-muted text-truncate small">
          {allFilterButtons.map(
            (button, i) =>
              i < allFilterButtons.length - 1 &&
              button.active && (
                <em key={`issue-filter-title-${i}`}>
                  {i < allFilterButtons.length - 2
                    ? button.title + ", "
                    : button.title}
                </em>
              )
          )}
          &nbsp;
        </div>
      ) : (
        <div className="text-muted text-truncate small">
          {current.id === userId && <em>Cases involving me</em>}
          {issueFilterButtons.map(
            (button, i) =>
              i < issueFilterButtons.length - 1 &&
              button.active && (
                <em key={`issue-filter-title-${i}`}>{", " + button.title}</em>
              )
          )}
          &nbsp;
        </div>
      )}
      <Accordion>
        {issues &&
          issues.map((issue) => (
            <IssueAccordionCard
              key={`issue-card-${issue.id}`}
              issue={issue}
              history={history}
              caseView={caseView}
              internal={current?.roles.some((r) => internalRoles.includes(r))}
              handleIssueSubmit={handleIssueSubmit}
              selectedIssue={selectedIssue}
              setSelectedIssue={setSelectedIssue}
            />
          ))}
        {issues && issues.length === 0 && current.id > 0 && (
          <p>No cases found matching this search/filter</p>
        )}
      </Accordion>
      <br />
      <Row className="mb-4" xs={1} md={3} lg={3} xl={3}>
        <Col />
        <Col>
          {issues?.length > 0 && (
            <PageNavigation
              requestState={requestState}
              arrayLength={issues.length}
              total={totalIssues}
              setRequestState={setRequestState}
            />
          )}
        </Col>
        <Col>
          <div className="float-end">
            {"Page length: "}
            <DropDownList
              data={[5, 10, 20, 40, 80]}
              value={current.userPrefs[0].issueAccordionLength}
              onChange={onLengthChanged}
            />
          </div>
        </Col>
      </Row>
      <br />
    </Aux>
  );
};

export default IssueAccordion;
