import { getRequest } from "./request";
import { IPriority } from "../../models/priority";

export const FETCH_PRIORITIES_SUCCESS = "FETCH_PRIORITIES_SUCCESS";

export type PriorityActions = {
  FETCH_PRIORITIES_SUCCESS: {
    type: typeof FETCH_PRIORITIES_SUCCESS;
    priorities: IPriority[];
  };
};

export type PriorityActionsTypes = PriorityActions[typeof FETCH_PRIORITIES_SUCCESS];

export const actionCreators = {
  fetchPrioritiesSuccess: (
    priorities: IPriority[]
  ): PriorityActions[typeof FETCH_PRIORITIES_SUCCESS] => ({
    type: FETCH_PRIORITIES_SUCCESS,
    priorities: priorities,
  }),
};

export function fetchPriorities() {
  return async (dispatch) => {
    const { json } = await getRequest("/priorities");
    return dispatch(actionCreators.fetchPrioritiesSuccess(json));
  };
}
