import { Dispatch } from "redux";
import { ILink } from "../../models/link";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "./request";

export const FETCH_LINKS_SUCCESS = "FETCH_LINKS_SUCCESS";
export const FETCH_LINK_SUCCESS = "FETCH_LINK_SUCCESS";
export const CREATE_LINK_SUCCESS = "CREATE_LINK_SUCCESS";
export const EDIT_LINK_SUCCESS = "EDIT_LINK_SUCCESS";
export const DELETE_LINK_SUCCESS = "DELETE_LINK_SUCCESS";
export const LINK_REQUEST_ERRORS = "LINK_REQUEST_ERRORS";
export const LINK_REQUEST_FAILURE = "LINK_REQUEST_FAILURE";

export interface LinkActions {
  FETCH_LINKS_SUCCESS: {
    type: typeof FETCH_LINKS_SUCCESS;
    links: ILink[];
  };
  FETCH_LINK_SUCCESS: {
    type: typeof FETCH_LINK_SUCCESS;
    link: ILink;
  };
  CREATE_LINK_SUCCESS: {
    type: typeof CREATE_LINK_SUCCESS;
    link: ILink;
  };
  EDIT_LINK_SUCCESS: {
    type: typeof EDIT_LINK_SUCCESS;
    link: ILink;
  };
  DELETE_LINK_SUCCESS: {
    type: typeof DELETE_LINK_SUCCESS;
  };
  LINK_REQUEST_ERRORS: {
    type: typeof LINK_REQUEST_ERRORS;
    errors: any;
  };
  LINK_REQUEST_FAILURE: {
    type: typeof LINK_REQUEST_FAILURE;
    errors: any;
  };
}

export type LinkActionTypes =
  | LinkActions[typeof FETCH_LINKS_SUCCESS]
  | LinkActions[typeof FETCH_LINK_SUCCESS]
  | LinkActions[typeof CREATE_LINK_SUCCESS]
  | LinkActions[typeof EDIT_LINK_SUCCESS]
  | LinkActions[typeof DELETE_LINK_SUCCESS]
  | LinkActions[typeof LINK_REQUEST_ERRORS]
  | LinkActions[typeof LINK_REQUEST_FAILURE];

export const actionCreators = {
  fetchLinksSuccess: (
    links: ILink[]
  ): LinkActions[typeof FETCH_LINKS_SUCCESS] => ({
    type: FETCH_LINKS_SUCCESS,
    links: links,
  }),
  fetchLinkSuccess: (link: ILink): LinkActions[typeof FETCH_LINK_SUCCESS] => ({
    type: FETCH_LINK_SUCCESS,
    link: link,
  }),
  createLinkSuccess: (
    link: ILink
  ): LinkActions[typeof CREATE_LINK_SUCCESS] => ({
    type: CREATE_LINK_SUCCESS,
    link: link,
  }),
  editLinkSuccess: (link: ILink): LinkActions[typeof EDIT_LINK_SUCCESS] => ({
    type: EDIT_LINK_SUCCESS,
    link: link,
  }),
  deleteLinkSuccess: (): LinkActions[typeof DELETE_LINK_SUCCESS] => ({
    type: DELETE_LINK_SUCCESS,
  }),
  linkRequestErrors: (
    errors: any
  ): LinkActions[typeof LINK_REQUEST_ERRORS] => ({
    type: LINK_REQUEST_ERRORS,
    errors: errors,
  }),
  linkRequestFailure: (
    status: number
  ): LinkActions[typeof LINK_REQUEST_FAILURE] => ({
    type: LINK_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export function fetchLinks() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_LINKS_SUCCESS));
    const { status, json } = await getRequest("/links");
    dispatch(requestEnd(FETCH_LINKS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchLinksSuccess(json))
      : null;
  };
}

export const fetchLink = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/links/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchLinkSuccess(json))
      : dispatch(actionCreators.linkRequestFailure(status));
  };
};

export const createLink = (link: ILink) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/links", link);
    switch (status) {
      case 200:
        dispatch(saveSuccess(link.title));
        return dispatch(actionCreators.createLinkSuccess(json));
      case 400:
        dispatch(saveFailed(link.title));
        return dispatch(actionCreators.linkRequestErrors(json));
      default:
        dispatch(saveFailed(link.title));
        return dispatch(actionCreators.linkRequestFailure(status));
    }
  };
};

export const editLink = (id: number, link: ILink) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/links/${id}`, link);
    switch (status) {
      case 200:
        dispatch(saveSuccess(link.title));
        return dispatch(actionCreators.editLinkSuccess(json));
      case 400:
        dispatch(saveFailed(link.title));
        return dispatch(actionCreators.linkRequestErrors(json));
      default:
        dispatch(saveFailed(link.title));
        return dispatch(actionCreators.linkRequestFailure(status));
    }
  };
};

export const deleteLink = (id: number, link: ILink) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/links/${id}`, link);
    if (status === 200) {
      dispatch(deleteSuccess(link.title));
      return dispatch(actionCreators.deleteLinkSuccess());
    }
    dispatch(saveFailed(link.title));
    return dispatch(actionCreators.linkRequestFailure(status));
  };
};
