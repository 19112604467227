import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserProfile from "../components/Users/UserProfile";
import { IGroup } from "../models/group";
import { isAdmin, isHmsUser, isInternal, IUser } from "../models/user";
import { updateUser } from "../store/actions/auth";
import { fetchGroups } from "../store/actions/groups";
import {
  editUser,
  fetchTrainingProgress,
  fetchUserTrainings,
} from "../store/actions/users";
import { IRootState } from "../store/index";
import { Container } from "react-bootstrap";
import { History } from "history";
import { ITag } from "models/tag";
import { IIssueType } from "models/issuetype";
import { fetchTags } from "store/actions/tag";
import { fetchIssueTypes } from "store/actions/issuetypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ITraining, ITrainingProgress } from "models/training";

export interface IProps {
  updateUser: () => any;
  fetchGroups: () => any;
  fetchTags: () => any;
  fetchIssueTypes: () => any;
  editUser: (id: number, user: IUser) => any;
  fetchTrainingProgress: (userId: number, stationId: number | null) => any;
  fetchUserTrainings: (userId: number) => any;
  groups: IGroup[];
  tags: ITag[];
  issueTypes: IIssueType[];
  currentUser: IUser;
  history: History;
}

export const UserProfileContainer: React.FC<IProps> = (props) => {
  const {
    updateUser,
    fetchGroups,
    fetchTags,
    fetchIssueTypes,
    editUser,
    fetchTrainingProgress,
    fetchUserTrainings,
    groups,
    tags,
    issueTypes,
    currentUser,
    history,
  } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [trainingProgress, setTrainingProgress] = useState<ITrainingProgress[]>(
    []
  );
  const [userTrainings, setUserTrainings] = useState<ITraining[]>([]);

  useEffect(() => {
    if (groups.length <= 0) fetchGroups();
    if (isInternal(currentUser) && tags.length <= 0) fetchTags();
    if (isInternal(currentUser) && issueTypes.length <= 0) fetchIssueTypes();
    updateUser().then((result) => {
      if (result.user !== undefined) {
        setLoading(false);
      }
    });
    if (isInternal(currentUser)) {
      fetchTrainingProgress(currentUser.id, null).then((result) => {
        if (result.type === "FETCH_TRAINING_PROGRESS_SUCCESS") {
          setTrainingProgress(result.trainingProgress);
        }
      });
      fetchUserTrainings(currentUser.id).then((result) => {
        if (result.type === "FETCH_USER_TRAININGS_SUCCESS") {
          setUserTrainings(result.userTrainings);
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (user: IUser) => {
    if (user.id) {
      const result = await editUser(user.id, user);
      if (result) {
        if (result.errors !== undefined) return;
        updateUser();
      }
    }
  };

  return (
    <Container>
      {loading ? (
        <p className="text-center m-3">
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        </p>
      ) : (
        <UserProfile
          user={currentUser}
          groups={groups.filter((g) => !g.hide)}
          tags={
            isAdmin(currentUser)
              ? tags.filter((t) => !t.hide)
              : isHmsUser(currentUser)
              ? tags.filter(
                  (t) =>
                    !t.hide &&
                    !t.categories.some(
                      (c) => c.name === "Administrative checklists"
                    )
                )
              : tags.filter(
                  (t) =>
                    !t.hide &&
                    !t.categories.some(
                      (c) =>
                        c.name === "Administrative checklists" ||
                        c.name === "HMS"
                    )
                )
          }
          issueTypes={
            isAdmin(currentUser)
              ? issueTypes
              : isHmsUser(currentUser)
              ? issueTypes.filter((it) => it.id !== 2)
              : issueTypes.filter((it) => it.id !== 2 && it.id !== 6)
          }
          history={history}
          trainingProgress={trainingProgress}
          userTrainings={userTrainings}
          onSubmit={onSubmit}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentUser: state.authReducer.user,
  groups: state.groupsReducer.groups,
  tags: state.tagReducer.tags,
  issueTypes: state.issueTypesReducer.issuetypes,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: () => dispatch(updateUser()),
  fetchGroups: () => dispatch(fetchGroups()),
  fetchTags: () => dispatch(fetchTags()),
  fetchIssueTypes: () => dispatch(fetchIssueTypes()),
  editUser: (id: any, user: any) => dispatch(editUser(id, user)),
  fetchTrainingProgress: (userId: number, stationId: number | null) =>
    dispatch(fetchTrainingProgress(userId, stationId)),
  fetchUserTrainings: (userId: number) => dispatch(fetchUserTrainings(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileContainer);
