import React, { useEffect } from "react";
import { connect } from "react-redux";
import { startAuthentication } from "../store/actions/auth";
import { IRootState } from "../store/index";

export interface IProps {
  startAuthentication: () => any;
}

const LoginContainer: React.FC<IProps> = (props) => {
  useEffect(() => props.startAuthentication);
  return null;
};

const mapStateToProps = (state: IRootState) => ({
  navigationActive: state.uiReducer.navigationActive,
});

export default connect(mapStateToProps, {
  startAuthentication,
})(LoginContainer);
