import {
  FETCH_TIMETRACKS_SUCCESS,
  FETCH_FILTERED_TIMETRACKS_SUCCESS,
  TIMETRACK_REQUEST_FAILURE,
  FETCH_TIMETRACK_SUCCESS,
  TIMETRACK_REQUEST_ERRORS,
  CREATE_TIMETRACK_SUCCESS,
  DELETE_TIMETRACK_SUCCESS,
  EDIT_TIMETRACK_SUCCESS,
} from "../actions/timetracks";
import { ITimeTrack } from "../../models/timetrack";
import { DataList } from "utils/DataList";

export interface State {
  readonly timeTracks: ITimeTrack[];
  readonly timeTrack: ITimeTrack | null;
  readonly filteredTimeTracks: DataList<ITimeTrack>;
  readonly errors: any;
}

export const initialState: State = {
  timeTracks: [],
  timeTrack: null,
  filteredTimeTracks: { data: [], total: 0 },
  errors: null,
};

const timeTracksReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case FETCH_TIMETRACKS_SUCCESS:
      return { ...state, timeTracks: action.timeTracks };
    case FETCH_FILTERED_TIMETRACKS_SUCCESS:
      return { ...state, filteredTimeTracks: action.timeTracks };
    case TIMETRACK_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case FETCH_TIMETRACK_SUCCESS:
      return { ...state, timeTrack: action.timeTrack };
    case TIMETRACK_REQUEST_ERRORS:
      return { ...state, errors: action.errorrs };
    case CREATE_TIMETRACK_SUCCESS:
      return {
        ...state,
        timeTrack: action.timeTrack,
        timeTracks:
          state.timeTracks.length > 0
            ? [...state.timeTracks.concat([action.timeTrack])]
            : [],
      };
    case DELETE_TIMETRACK_SUCCESS:
      return { ...state, timeTrack: null, timeTacks: [] };
    case EDIT_TIMETRACK_SUCCESS:
      if (state.timeTracks.length > 0) {
        state.timeTracks.splice(
          state.timeTracks.findIndex((t) => t.id === action.timeTrack.id),
          1,
          action.timeTrack
        );
      }
      return {
        ...state,
        timeTrack: action.timeTrack,
        timeTracks: state.timeTracks.length > 0 ? state.timeTracks : [],
      };
    default:
      return state;
  }
};

export default timeTracksReducer;
