import {
  StationActionsTypes,
  FETCH_STATIONS_SUCCESS,
  FETCH_FILTERED_STATIONS_SUCCESS,
  STATION_REQUEST_FAILURE,
  FETCH_STATION_SUCCESS,
  STATION_REQUEST_ERRORS,
  CREATE_STATION_SUCCESS,
  DELETE_STATION_SUCCESS,
  EDIT_STATION_SUCCESS,
  EDIT_STATION_ORDER_SUCCESS,
} from "../actions/stations";
import { IStation } from "../../models/station";
import { DataList } from "../../utils/DataList";

export interface StationState {
  readonly stations: IStation[];
  readonly filteredStations: DataList<IStation>;
  readonly station: IStation | null;
  readonly errors: any;
}

export const initialState: StationState = {
  stations: [],
  filteredStations: { data: [], total: 0 },
  station: null,
  errors: null,
};

const stationsReducer = (
  state = initialState,
  action: StationActionsTypes
): StationState => {
  switch (action.type) {
    case FETCH_STATIONS_SUCCESS:
      return { ...state, stations: action.stations };
    case FETCH_FILTERED_STATIONS_SUCCESS:
      return { ...state, filteredStations: action.filteredStations };
    case STATION_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case FETCH_STATION_SUCCESS:
      return { ...state, station: action.station };
    case STATION_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case CREATE_STATION_SUCCESS:
      return {
        ...state,
        station: action.station,
        stations:
          state.stations.length > 0
            ? [...state.stations.concat([action.station])]
            : [],
      };
    case EDIT_STATION_ORDER_SUCCESS:
      return { ...state, stations: action.stations };
    case DELETE_STATION_SUCCESS:
      return { ...state, station: null, stations: [] };
    case EDIT_STATION_SUCCESS:
      if (state.stations.length > 0) {
        state.stations.splice(
          state.stations.findIndex((t) => t.id === action.station.id),
          1,
          action.station
        );
      }
      return {
        ...state,
        station: action.station,
        stations: state.stations.length > 0 ? state.stations : [],
      };
    default:
      return state;
  }
};

export default stationsReducer;
