import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "./request";
import { IOrderLine } from "../../models/orderline";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";

export const FETCH_ORDERLINE_SUCCESS = "FETCH_ORDERLINE_SUCCESS";
export const FETCH_ORDERLINES_SUCCESS = "FETCH_ORDERLINES_SUCCESS";
export const FETCH_FILTERED_ORDERLINES_SUCCESS =
  "FETCH_FILTERED_ORDERLINES_SUCCESS";
export const ORDERLINE_REQUEST_FAILURE = "ORDERLINE_REQUEST_FAILURE";
export const CREATE_ORDERLINE_SUCCESS = "CREATE_ORDERLINE_SUCCESS";
export const ORDERLINE_REQUEST_ERRORS = "ORDERLINE_REQUEST_ERRORS";
export const DELETE_ORDERLINE_SUCCESS = "DELETE_ORDERLINE_SUCCESS";
export const EDIT_ORDERLINE_SUCCESS = "EDIT_ORDERLINE_SUCCESS";

export type OrderLineActions = {
  FETCH_ORDERLINE_SUCCESS: {
    type: typeof FETCH_ORDERLINE_SUCCESS;
    orderLine: IOrderLine;
  };
  FETCH_ORDERLINES_SUCCESS: {
    type: typeof FETCH_ORDERLINES_SUCCESS;
    orderLines: IOrderLine[];
  };
  FETCH_FILTERED_ORDERLINES_SUCCESS: {
    type: typeof FETCH_FILTERED_ORDERLINES_SUCCESS;
    orderLines: DataResult;
  };
  CREATE_ORDERLINE_SUCCESS: {
    type: typeof CREATE_ORDERLINE_SUCCESS;
    orderLine: IOrderLine;
  };
  DELETE_ORDERLINE_SUCCESS: {
    type: typeof DELETE_ORDERLINE_SUCCESS;
  };
  EDIT_ORDERLINE_SUCCESS: {
    type: typeof EDIT_ORDERLINE_SUCCESS;
    orderLine: IOrderLine;
  };
  ORDERLINE_REQUEST_ERRORS: {
    type: typeof ORDERLINE_REQUEST_ERRORS;
    errors: any;
  };
  ORDERLINE_REQUEST_FAILURE: {
    type: typeof ORDERLINE_REQUEST_FAILURE;
    errors: any;
  };
};

export type OrderLineActionTypes =
  | OrderLineActions[typeof FETCH_ORDERLINE_SUCCESS]
  | OrderLineActions[typeof FETCH_ORDERLINES_SUCCESS]
  | OrderLineActions[typeof FETCH_FILTERED_ORDERLINES_SUCCESS]
  | OrderLineActions[typeof CREATE_ORDERLINE_SUCCESS]
  | OrderLineActions[typeof DELETE_ORDERLINE_SUCCESS]
  | OrderLineActions[typeof EDIT_ORDERLINE_SUCCESS]
  | OrderLineActions[typeof ORDERLINE_REQUEST_ERRORS]
  | OrderLineActions[typeof ORDERLINE_REQUEST_FAILURE];

export const actionCreators = {
  fetchOrderLineSuccess: (
    orderLine: IOrderLine
  ): OrderLineActions[typeof FETCH_ORDERLINE_SUCCESS] => ({
    type: FETCH_ORDERLINE_SUCCESS,
    orderLine: orderLine,
  }),
  fetchOrderLinesSuccess: (
    orderLines: IOrderLine[]
  ): OrderLineActions[typeof FETCH_ORDERLINES_SUCCESS] => ({
    type: FETCH_ORDERLINES_SUCCESS,
    orderLines: orderLines,
  }),
  fetchFilteredOrderLinesSuccess: (
    orderLines: DataResult
  ): OrderLineActions[typeof FETCH_FILTERED_ORDERLINES_SUCCESS] => ({
    type: FETCH_FILTERED_ORDERLINES_SUCCESS,
    orderLines: orderLines,
  }),
  createOrderLineSuccess: (
    orderLine: IOrderLine
  ): OrderLineActions[typeof CREATE_ORDERLINE_SUCCESS] => ({
    type: CREATE_ORDERLINE_SUCCESS,
    orderLine: orderLine,
  }),
  deleteOrderLineSuccess: (): OrderLineActions[typeof DELETE_ORDERLINE_SUCCESS] => ({
    type: DELETE_ORDERLINE_SUCCESS,
  }),
  editOrderLineSuccess: (
    orderLine: IOrderLine
  ): OrderLineActions[typeof EDIT_ORDERLINE_SUCCESS] => ({
    type: EDIT_ORDERLINE_SUCCESS,
    orderLine: orderLine,
  }),
  orderLineRequestErrors: (
    errors: any
  ): OrderLineActions[typeof ORDERLINE_REQUEST_ERRORS] => ({
    type: ORDERLINE_REQUEST_ERRORS,
    errors: errors,
  }),
  orderLineRequestFailure: (
    status: number
  ): OrderLineActions[typeof ORDERLINE_REQUEST_FAILURE] => ({
    type: ORDERLINE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};
export const fetchOrderLine = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/orderLines/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchOrderLineSuccess(json))
      : dispatch(actionCreators.orderLineRequestFailure(status));
  };
};
export function fetchOrderLines() {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest("/orderLines");
    return dispatch(actionCreators.fetchOrderLinesSuccess(json));
  };
}
export function fetchFilteredOrderLines(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_ORDERLINES_SUCCESS));
    const { status, json } = await getRequest(
      `/orderLines/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_ORDERLINES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredOrderLinesSuccess(json))
      : dispatch(actionCreators.orderLineRequestFailure(status));
  };
}
export const createOrderLine = (orderLine: IOrderLine) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/orderLines`, orderLine);
    switch (status) {
      case 200:
        dispatch(saveSuccess(orderLine.orderNo.toString()));
        return dispatch(actionCreators.createOrderLineSuccess(json));
      case 400:
        dispatch(saveFailed(orderLine.orderNo.toString()));
        return dispatch(actionCreators.orderLineRequestErrors(json));
      default:
        dispatch(saveFailed(orderLine.orderNo.toString()));
        return dispatch(actionCreators.orderLineRequestFailure(status));
    }
  };
};
export const editOrderLine = (id: number, orderLine: IOrderLine) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/orderLines/${id}`, orderLine);
    switch (status) {
      case 200:
        dispatch(saveSuccess(orderLine.orderNo.toString()));
        return dispatch(actionCreators.editOrderLineSuccess(json));
      case 400:
        dispatch(saveFailed(orderLine.orderNo.toString()));
        return dispatch(actionCreators.orderLineRequestErrors(json));
      default:
        dispatch(saveFailed(orderLine.orderNo.toString()));
        return dispatch(actionCreators.orderLineRequestFailure(status));
    }
  };
};
export const deleteOrderLine = (id: number, orderLine: IOrderLine) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/orderLines/${id}`, orderLine);
    if (status === 200) {
      dispatch(deleteSuccess(orderLine.orderNo.toString()));
      return dispatch(actionCreators.deleteOrderLineSuccess());
    }
    dispatch(saveFailed(orderLine.orderNo.toString()));
    return dispatch(actionCreators.orderLineRequestFailure(status));
  };
};
