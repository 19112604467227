import React from "react";
import { userManager } from "../../store/actions/auth";

type Props = {};

const SilentRefresh: React.FC<Props> = (props) => {
  React.useEffect(() => {
    userManager.signinSilentCallback().catch((err: any) => {
      console.log(err);
    });
  }, []);

  return <div />;
};

export default SilentRefresh;
