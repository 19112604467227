import {
  IssueActionsTypes,
  FETCH_ISSUES_SUCCESS,
  FETCH_FILTERED_ISSUES_SUCCESS,
  FETCH_ISSUE_SUCCESS,
  CREATE_ISSUE_SUCCESS,
  EDIT_ISSUE_SUCCESS,
  DELETE_ISSUE_SUCCESS,
  ISSUE_REQUEST_ERRORS,
  ISSUE_REQUEST_FAILURE,
  FETCH_ISSUE_CHECKLIST_SUCCESS,
  FETCH_FILTERED_REL_ISSUES_SUCCESS,
  FETCH_UNREAD_ISSUES_SUCCESS,
  FETCH_RECENT_ISSUES_SUCCESS,
  UPDATE_UNREAD_ISSUE_SUCCESS,
  UPDATE_ALL_UNREAD_ISSUES_SUCCESS,
} from "../actions/issues";
import { IIssue, IIssueChecklist } from "../../models/issue";
import { DataList } from "../../utils/DataList";

export interface IssueState {
  readonly issues: IIssue[];
  readonly unreadIssues: IIssue[];
  readonly recentlyReadIssues: IIssue[];
  readonly filteredIssues: DataList<IIssue>;
  readonly relIssues: DataList<IIssue>;
  readonly issue: IIssue | null;
  readonly errors: any;
  readonly checklist: IIssueChecklist | null;
}

export const initialState: IssueState = {
  issues: [],
  unreadIssues: [],
  recentlyReadIssues: [],
  filteredIssues: { data: [], total: 0 },
  relIssues: { data: [], total: 0 },
  issue: null,
  errors: null,
  checklist: null,
};

const issuesReducer = (
  state = initialState,
  action: IssueActionsTypes
): IssueState => {
  switch (action.type) {
    case FETCH_ISSUES_SUCCESS:
      return { ...state, issues: action.issues };
    case FETCH_UNREAD_ISSUES_SUCCESS:
      return { ...state, unreadIssues: action.unreadIssues };
    case FETCH_RECENT_ISSUES_SUCCESS:
      return { ...state, recentlyReadIssues: action.recentlyReadIssues };
    case UPDATE_UNREAD_ISSUE_SUCCESS:
      return {
        ...state,
        unreadIssues:
          state.unreadIssues.length > 0
            ? [...state.unreadIssues.filter((i) => i.id !== action.issue.id)]
            : [],
        recentlyReadIssues: [
          action.issue,
          ...state.recentlyReadIssues.filter((i) => i.id !== action.issue.id),
        ],
      };
    case UPDATE_ALL_UNREAD_ISSUES_SUCCESS:
      return { ...state, unreadIssues: [] };
    case FETCH_FILTERED_ISSUES_SUCCESS:
      return { ...state, filteredIssues: action.issues };
    case FETCH_FILTERED_REL_ISSUES_SUCCESS:
      return { ...state, relIssues: action.issues };
    case FETCH_ISSUE_SUCCESS:
      return { ...state, issue: action.issue };
    case CREATE_ISSUE_SUCCESS:
      return { ...state, issue: action.issue };
    case EDIT_ISSUE_SUCCESS:
      return { ...state, issue: action.issue };
    case DELETE_ISSUE_SUCCESS:
      return { ...state, issue: null };
    case ISSUE_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case ISSUE_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case FETCH_ISSUE_CHECKLIST_SUCCESS:
      return { ...state, checklist: action.checklist };
    default:
      return state;
  }
};

export default issuesReducer;
