import { history } from '../../index';
import { Paths } from '../../routes';


export async function handleResponse(response: Response) {
  switch (response.status) {
    case 401:
      history.push(Paths.unauthorized);
      return {
        status: response.status,
      };
    case 403:
      history.push(Paths.forbidden);
      return {
        status: response.status,
      };
    case 404:
      return {
        status: response.status,
      };
    default:
      break;
  }
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("json")) {
    const json = await response.json();
    if (json.errors) {
      for (var error in json.errors) {
        throw new Error(error);
      }
    } else {
      return {
        status: response.status,
        json,
      };
    }
  }
  return {
    status: response.status,
  };
}

export const API = process.env.REACT_APP_API + '/api';
export const API_AUTH = process.env.REACT_APP_AUTH + '/idp';

const acceptJsonHeader = {
  Accept: 'application/json',
  'Accept-Charset': 'utf-8'
};

const contentTypeJsonHeader = {
  'Content-Type': 'application/json'
};

export const authorizationHeader = () => {
  const tokenStorage = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH}:orkel-pi-app`
  );
  if (!tokenStorage)
    return {
      Authorization: ``,
    };
  const token = JSON.parse(tokenStorage).access_token;

  return {
    Authorization: `Bearer ${token}`,
  };
};

export async function tryAjax(func: () => any) {
  try {
    const response = await func();
    return handleResponse(response);
  } catch (error) {
    return error;
  }
}

export const REQUEST_BEGIN = 'REQUEST_BEGIN';
export const REQUEST_END = 'REQUEST_END';

export type RequestActions = {
  REQUEST_BEGIN: {
    type: typeof REQUEST_BEGIN;
    request: string;
  };
  REQUEST_END: {
    type: typeof REQUEST_END;
    request: string;
  };
};

export type RequestActionTypes =
  | RequestActions[typeof REQUEST_BEGIN]
  | RequestActions[typeof REQUEST_END];

export const requestBegin = (
  request: string
): RequestActions[typeof REQUEST_BEGIN] => ({
  type: REQUEST_BEGIN,
  request: request
});

export const requestEnd = (
  request: string
): RequestActions[typeof REQUEST_END] => ({
  type: REQUEST_END,
  request: request
});

export const getRequest = (url: string): Promise<any> =>
  tryAjax(() => {
    return fetch(`${API}${url}`, {
      method: 'GET',
      headers: {
        ...acceptJsonHeader,
        ...authorizationHeader()
      }
    });
  });

export const getMediaRequest = (url: string): Promise<any> => {
  return fetch(`${API}${url}`, {
    method: 'GET',
    headers: {
      ...authorizationHeader()
    }
  });
};

export const postRequest = (url: string, data: any): Promise<any> =>
  tryAjax(() =>
    fetch(`${API}${url}`, {
      method: 'POST',
      headers: {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader,
        ...authorizationHeader()
      },
      body: JSON.stringify(data)
    })
  );

export const putRequest = (url: string, data: any): Promise<any> =>
  tryAjax(() =>
    fetch(`${API}${url}`, {
      method: 'PUT',
      headers: {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader,
        ...authorizationHeader()
      },
      body: JSON.stringify(data)
    })
  );

export const deleteRequest = (url: string, data: any): Promise<any> =>
  tryAjax(() =>
    fetch(`${API}${url}`, {
      method: 'DELETE',
      headers: {
        ...acceptJsonHeader,
        ...authorizationHeader()
      },
      body: JSON.stringify(data)
    })
  );
