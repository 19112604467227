import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../request";
import { IQcAttrValue } from "../../../models/QualityControl/qcAttrValue";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../alerts";

export const FETCH_QC_ATTR_VALUES_SUCCESS = "FETCH_QC_ATTR_VALUES_SUCCESS";
export const FETCH_QC_ATTR_VALUE_SUCCESS = "FETCH_QC_ATTR_VALUE_SUCCESS";
export const FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS =
  "FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS";
export const CREATE_QC_ATTR_VALUE_SUCCESS = "CREATE_QC_ATTR_VALUE_SUCCESS";
export const EDIT_QC_ATTR_VALUE_SUCCESS = "EDIT_QC_ATTR_VALUE_SUCCESS";
export const DELETE_QC_ATTR_VALUE_SUCCESS = "DELETE_QC_ATTR_VALUE_SUCCESS";
export const QC_ATTR_VALUE_REQUEST_FAILURE = "QC_ATTR_VALUE_REQUEST_FAILURE";
export const QC_ATTR_VALUE_REQUEST_ERROR = "QC_ATTR_VALUE_REQUEST_ERROR";

export interface QcAttrValueActions {
  FETCH_QC_ATTR_VALUES_SUCCESS: {
    type: typeof FETCH_QC_ATTR_VALUES_SUCCESS;
    qcAttrValues: IQcAttrValue[];
  };
  FETCH_QC_ATTR_VALUE_SUCCESS: {
    type: typeof FETCH_QC_ATTR_VALUE_SUCCESS;
    qcAttrValue: IQcAttrValue;
  };
  FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS: {
    type: typeof FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS;
    qcAttrValues: DataResult;
  };
  CREATE_QC_ATTR_VALUE_SUCCESS: {
    type: typeof CREATE_QC_ATTR_VALUE_SUCCESS;
    qcAttrValue: IQcAttrValue;
  };
  EDIT_QC_ATTR_VALUE_SUCCESS: {
    type: typeof EDIT_QC_ATTR_VALUE_SUCCESS;
    qcAttrValue: IQcAttrValue;
  };
  DELETE_QC_ATTR_VALUE_SUCCESS: {
    type: typeof DELETE_QC_ATTR_VALUE_SUCCESS;
  };
  QC_ATTR_VALUE_REQUEST_FAILURE: {
    type: typeof QC_ATTR_VALUE_REQUEST_FAILURE;
    errors: any;
  };
  QC_ATTR_VALUE_REQUEST_ERROR: {
    type: typeof QC_ATTR_VALUE_REQUEST_ERROR;
    errors: any;
  };
}

export type QcAttrValueActionTypes =
  | QcAttrValueActions[typeof FETCH_QC_ATTR_VALUES_SUCCESS]
  | QcAttrValueActions[typeof FETCH_QC_ATTR_VALUE_SUCCESS]
  | QcAttrValueActions[typeof FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS]
  | QcAttrValueActions[typeof CREATE_QC_ATTR_VALUE_SUCCESS]
  | QcAttrValueActions[typeof EDIT_QC_ATTR_VALUE_SUCCESS]
  | QcAttrValueActions[typeof DELETE_QC_ATTR_VALUE_SUCCESS]
  | QcAttrValueActions[typeof QC_ATTR_VALUE_REQUEST_FAILURE]
  | QcAttrValueActions[typeof QC_ATTR_VALUE_REQUEST_ERROR];

export const actionCreators = {
  fetchQcAttrValuesSuccess: (
    qcAttrValues: IQcAttrValue[]
  ): QcAttrValueActions[typeof FETCH_QC_ATTR_VALUES_SUCCESS] => ({
    type: FETCH_QC_ATTR_VALUES_SUCCESS,
    qcAttrValues: qcAttrValues,
  }),
  fetchQcAttrValueSuccess: (
    qcAttrValue: IQcAttrValue
  ): QcAttrValueActions[typeof FETCH_QC_ATTR_VALUE_SUCCESS] => ({
    type: FETCH_QC_ATTR_VALUE_SUCCESS,
    qcAttrValue: qcAttrValue,
  }),
  fetchFilteredQcAttrValuesSuccess: (
    qcAttrValues: DataResult
  ): QcAttrValueActions[typeof FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS] => ({
    type: FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS,
    qcAttrValues: qcAttrValues,
  }),
  createQcAttrValueSuccess: (
    qcAttrValue: IQcAttrValue
  ): QcAttrValueActions[typeof CREATE_QC_ATTR_VALUE_SUCCESS] => ({
    type: CREATE_QC_ATTR_VALUE_SUCCESS,
    qcAttrValue: qcAttrValue,
  }),
  editQcAttrValueSuccess: (
    qcAttrValue: IQcAttrValue
  ): QcAttrValueActions[typeof EDIT_QC_ATTR_VALUE_SUCCESS] => ({
    type: EDIT_QC_ATTR_VALUE_SUCCESS,
    qcAttrValue: qcAttrValue,
  }),
  deleteQcAttrValueSuccess: (): QcAttrValueActions[typeof DELETE_QC_ATTR_VALUE_SUCCESS] => ({
    type: DELETE_QC_ATTR_VALUE_SUCCESS,
  }),
  qcAttrValueRequestFailure: (
    status: number
  ): QcAttrValueActions[typeof QC_ATTR_VALUE_REQUEST_FAILURE] => ({
    type: QC_ATTR_VALUE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  qcAttrValueRequestError: (
    errors: any
  ): QcAttrValueActions[typeof QC_ATTR_VALUE_REQUEST_ERROR] => ({
    type: QC_ATTR_VALUE_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchQcAttrValues() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_QC_ATTR_VALUES_SUCCESS));
    const { status, json } = await getRequest("/qcAttrValues");
    dispatch(requestEnd(FETCH_QC_ATTR_VALUES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchQcAttrValuesSuccess(json))
      : dispatch(actionCreators.qcAttrValueRequestFailure(status));
  };
}

export const fetchQcAttrValue = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcAttrValues/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchQcAttrValueSuccess(json))
      : dispatch(actionCreators.qcAttrValueRequestFailure(status));
  };
};

export function fetchFilteredQcAttrValues(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS));
    const { status, json } = await getRequest(
      `/qcAttrValues/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredQcAttrValuesSuccess(json))
      : dispatch(actionCreators.qcAttrValueRequestFailure(status));
  };
}

export const createQcAttrValue = (qcAttrValue: IQcAttrValue) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/qcAttrValues", qcAttrValue);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcAttrValue.name));
        return dispatch(actionCreators.createQcAttrValueSuccess(json));
      case 400:
        dispatch(saveFailed(qcAttrValue.name));
        return dispatch(actionCreators.qcAttrValueRequestError(json));
      default:
        dispatch(saveFailed(qcAttrValue.name));
        return dispatch(actionCreators.qcAttrValueRequestFailure(status));
    }
  };
};

export const editQcAttrValue = (id: number, qcAttrValue: IQcAttrValue) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/qcAttrValues/${id}`,
      qcAttrValue
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcAttrValue.name));
        return dispatch(actionCreators.editQcAttrValueSuccess(json));
      case 400:
        dispatch(saveFailed(qcAttrValue.name));
        return dispatch(actionCreators.qcAttrValueRequestError(json));
      default:
        dispatch(saveFailed(qcAttrValue.name));
        return dispatch(actionCreators.qcAttrValueRequestFailure(status));
    }
  };
};

export const deleteQcAttrValue = (id: number, qcAttrValue: IQcAttrValue) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/qcAttrValues/${id}`, qcAttrValue);
    if (status === 200) {
      dispatch(deleteSuccess(qcAttrValue.name));
      return dispatch(actionCreators.deleteQcAttrValueSuccess());
    }
    dispatch(saveFailed(qcAttrValue.name));
    return dispatch(actionCreators.qcAttrValueRequestFailure(status));
  };
};
