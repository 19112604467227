import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Button,
  Form,
  ButtonToolbar,
  Col,
  ButtonGroup,
  Card,
  Accordion,
  Row,
} from "react-bootstrap";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faChevronDown,
  faChevronUp,
  faFileArchive,
  faPaperclip,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Prompt } from "react-router";
import { History } from "history";
import {
  process as filterProcess,
  DataResult,
} from "@progress/kendo-data-query";

import { IJig, IJigCategory } from "../../models/jig";
import { IImage } from "../../models/image";
import { IUser } from "../../models/user";
import { IProduct } from "../../models/product";
import { IIssue } from "models/issue";
import { Paths } from "../../routes";
import { IGroup } from "models/group";
import Upload from "components/Common/Upload";
import Aux from "../../utils/auxiliary";
import { CreateIssueModal } from "components/QualityControl/QcChecklist/CreateIssueModal";
import dateTimeFormat from "utils/dateTimeFormat";
import AuditModal from "components/Audit/AuditModal";
import { ITag } from "models/tag";

type Props = {
  jig: IJig;
  editable: boolean;
  categories: IJigCategory[];
  files: UploadFileInfo[];
  history: History;
  products: IProduct[];
  current: IUser;
  embeded: boolean;
  usersData: DataResult;
  groups: IGroup[];
  tags: ITag[];
  onSubmit: (Jig: IJig) => any;
  onCancel: () => any;
  onFileAdd: (file: IImage) => void;
  setFiles: (files: UploadFileInfo[]) => void;
  onProductRequestChange: (event: any) => void;
  onUserRequestChange: (token: any) => Promise<any>;
};

const JigForm: React.FC<Props> = (props) => {
  const {
    jig,
    editable,
    categories,
    history,
    products,
    current,
    embeded,
    files,
    usersData,
    groups,
    tags,
    setFiles,
    onSubmit,
    onCancel,
    onFileAdd,
    onProductRequestChange,
    onUserRequestChange,
  } = props;

  const [attachmentState, setAttachmentState] = useState<boolean>(false);
  const [accordionState, setAccordionState] = useState<boolean>(false);
  const [images, setImages] = React.useState<IImage[]>([]);
  const [showIssues, setShowIssues] = useState<boolean>(false);
  const [showMaintenance, setShowMaintenance] = useState<boolean>(false);
  const [showIssueModal, setShowIssueModal] = useState<boolean>(false);
  const [showAudits, setShowAudits] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [filteredGroups, setFilteredGroups] = useState<IGroup[]>(groups);
  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);

  useEffect(() => {
    setFilteredGroups(groups);
  }, [groups]);

  useEffect(() => {
    setFilteredTags(tags ?? []);
  }, [tags]);

  const onGroupFilterChange = (event: any) => {
    const tempFilteredGroups = filterProcess(groups, event);
    if (tempFilteredGroups.total > 0) {
      setFilteredGroups(tempFilteredGroups.data);
    }
  };

  const onTagsFilterChange = (event: any) => {
    const filteredTags = filterProcess(tags ?? [], event);
    if (filteredTags.total > 0) {
      setFilteredTags(filteredTags.data);
    }
  };

  const handleProductClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    product: IProduct
  ) => {
    event.preventDefault();
    history.push(Paths.gProductsId(product.productNo));
  };

  const handleIssueClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    issue: IIssue
  ) => {
    event.preventDefault();
    history.push(Paths.gIssuesId(issue.id));
  };

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={jig}
        onSubmit={(values: IJig) => {
          onSubmit(values as IJig);
        }}
      >
        {({
          values,
          dirty,
          isSubmitting,
          isValidating,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          handleReset,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Prompt
              when={dirty && !isSubmitting}
              message="Are you sure you don't want to save before exiting?"
            />
            {jig.createdBy ? (
              <p className=" text-muted pb-0 mb-4">
                {"Created by " +
                  jig.createdBy.fullNameWithTitle +
                  " at " +
                  dateTimeFormat.format(new Date(jig.createdAt))}
              </p>
            ) : (
              <p className="mb-4">&nbsp;</p>
            )}
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Equipment number</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="jignr"
                    placeholder={!values.id ? "Automatically generated" : "no"}
                    value={values.jignr || ""}
                  />
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    disabled={!editable}
                    type="text"
                    name="description"
                    placeholder="Description"
                    value={values.description || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    disabled={!editable}
                    type="text"
                    name="location"
                    placeholder="Location"
                    value={values.location || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    disabled={!editable}
                    type="text"
                    name="note"
                    placeholder="Note"
                    value={values.note || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {categories && categories.length > 0 && (
                <Col className="col-12 col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <ComboBox
                      className="w-100"
                      placeholder="Category"
                      name="category"
                      data={categories}
                      textField="name"
                      dataItemKey="id"
                      value={values.category || null}
                      disabled={!editable}
                      required
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col className={"col-12 col-md-6"}>
                <Form.Group className="mb-3">
                  <div className="clearfix">
                    <Form.Label>Products</Form.Label>
                    <MultiSelect
                      name="products"
                      placeholder="products"
                      textField="productNo"
                      dataItemKey="productNo"
                      value={
                        values.products
                          ? values.products.filter(
                              (p) => p.productNo !== null || "" || " "
                            )
                          : []
                      }
                      data={products}
                      filterable={true}
                      autoClose={false}
                      onFilterChange={onProductRequestChange}
                      onChange={handleChange}
                      disabled={!editable}
                    />
                  </div>
                  <p className="text-muted">
                    <small>search for product number</small>
                  </p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Maintenance Responsible</Form.Label>
                  <ComboBox
                    className="w-100"
                    placeholder="Maintenance Responsible"
                    name="responsible"
                    {...usersData}
                    textField="fullNameWithTitle"
                    dataItemKey="id"
                    value={values.responsible}
                    filterable
                    onChange={handleChange}
                    onFilterChange={(e) => onUserRequestChange(e.filter.value)}
                  />
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Maintenance Group</Form.Label>
                  <ComboBox
                    className="w-100"
                    placeholder="Maintenance Group"
                    name="maintenanceGroup"
                    data={filteredGroups}
                    textField="name"
                    dataItemKey="id"
                    value={values.maintenanceGroup}
                    filterable
                    onChange={handleChange}
                    onFilterChange={onGroupFilterChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Maintenance Interval (days)</Form.Label>
                  <Form.Control
                    type="number"
                    name="maintenanceInterval"
                    min={
                      values.maintenanceTime !== undefined &&
                      values.maintenanceTime > 0
                        ? values.maintenanceTime + 1
                        : 0
                    }
                    value={values.maintenanceInterval}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              {values.maintenanceInterval !== undefined &&
                values.maintenanceInterval > 0 && (
                  <Col className="col-12 col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>Maintenance Time (days)</Form.Label>
                      <Form.Control
                        type="number"
                        name="maintenanceTime"
                        min={0}
                        max={values.maintenanceInterval - 1}
                        value={values.maintenanceTime}
                        onChange={handleChange}
                        required={
                          values.maintenanceInterval !== undefined &&
                          values.maintenanceInterval > 0
                        }
                      />
                    </Form.Group>
                  </Col>
                )}
            </Row>
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Maintenance Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="maintenanceStart"
                    defaultValue={
                      values.maintenanceStart
                        ? new Date(values.maintenanceStart).getFullYear() +
                          "-" +
                          zeroPad(
                            new Date(values.maintenanceStart).getMonth() + 1,
                            2
                          ) +
                          "-" +
                          zeroPad(
                            new Date(values.maintenanceStart).getDate(),
                            2
                          )
                        : undefined
                    }
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        const newTimeDate = values.maintenanceStart
                          ? new Date(values.maintenanceStart)
                          : new Date(Date.now());
                        const newDate = new Date(e.target.value);
                        newTimeDate.setFullYear(newDate.getFullYear());
                        newTimeDate.setMonth(newDate.getMonth());
                        newTimeDate.setDate(newDate.getDate());
                        e.target.value = newTimeDate.toISOString().slice(0, 10);
                        handleChange(e);
                      } else {
                        handleChange(e);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              {editable && jig.id > 0 && (
                <Col className="col-12 col-md-6">
                  <Form.Group className="mb-3">
                    <div style={{ paddingRight: "25px" }}>
                      <Form.Label>Add Image</Form.Label>
                      <br />
                      <Button
                        variant="secondary"
                        onClick={() => setAttachmentState(!attachmentState)}
                      >
                        <FontAwesomeIcon icon={faPaperclip} />
                      </Button>
                    </div>
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Tags</Form.Label>
                  <MultiSelect
                    className="w-100"
                    placeholder="Tags"
                    name="tags"
                    textField="name"
                    dataItemKey="id"
                    data={filteredTags}
                    defaultValue={values.tags}
                    filterable={true}
                    onFilterChange={onTagsFilterChange}
                    onChange={handleChange}
                    autoClose={false}
                    disabled={!editable}
                  />
                </Form.Group>
              </Col>
            </Row>
            {attachmentState && (
              <Form.Group className="mb-3">
                <Upload
                  images={images}
                  currentUser={current}
                  files={files}
                  disabled={!editable}
                  jigId={jig.id}
                  setUploading={setUploading}
                  setFiles={setFiles}
                  setImages={setImages}
                  handleFileAdd={onFileAdd}
                />
              </Form.Group>
            )}
            <br />
            {!embeded && (
              <Col className="col-12 col-md-6 ms-0 ps-0">
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Accordion.Header
                      eventKey="a"
                      as={Card.Header}
                      onClick={() => setAccordionState(!accordionState)}
                    >
                      <div className="container-fluid">
                        Products
                        <span className="float-end">
                          <FontAwesomeIcon
                            icon={accordionState ? faChevronUp : faChevronDown}
                          />{" "}
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Collapse
                      eventKey="a"
                      as={Card.Body}
                      in={accordionState}
                      className="p-0"
                    >
                      {values.products && values.products.length > 0 ? (
                        <div>
                          {values.products.map((p) => {
                            return (
                              <Card key={p.productNo}>
                                <a
                                  href={"/products/" + p.productNo}
                                  onClick={(e) => handleProductClick(e, p)}
                                  style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                  }}
                                  title={p.productNo}
                                >
                                  Product No: {p.productNo}
                                </a>
                              </Card>
                            );
                          })}
                        </div>
                      ) : (
                        <Card.Body>No related products</Card.Body>
                      )}
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            )}
            <Col className="col-12 col-md-6 ms-0 ps-0">
              <Accordion defaultActiveKey="1">
                <Card className="mx-0 px-0">
                  <Accordion.Header
                    eventKey={jig.jignr}
                    as={Card.Header}
                    onClick={() => setShowIssues(!showIssues)}
                  >
                    <div className="container-fluid">
                      Cases: {jig.issues ? jig.issues.length : 0}
                      <span className="float-end">
                        <FontAwesomeIcon
                          icon={showIssues ? faChevronUp : faChevronDown}
                        />{" "}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Collapse
                    eventKey={jig.jignr}
                    as={Card.Body}
                    in={showIssues}
                    className="p-0"
                  >
                    {jig.issues && jig.issues.length > 0 ? (
                      <div>
                        {jig.issues.map((i) => {
                          return (
                            <Card key={i.id}>
                              <a
                                href={"/issues/" + i.id}
                                onClick={(e) => handleIssueClick(e, i)}
                                style={{
                                  textDecoration: "none",
                                  padding: "20px",
                                }}
                                title={i.title}
                              >
                                {i.title}{" "}
                                <span
                                  style={{ float: "right" }}
                                  className={
                                    i.status === "Open" ? "text-warning" : ""
                                  }
                                >
                                  Status: {i.status}
                                </span>
                              </a>
                            </Card>
                          );
                        })}
                      </div>
                    ) : (
                      <Card.Body className="p-4">No related Cases</Card.Body>
                    )}
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            <Col className="col-12 col-md-6 ms-0 ps-0">
              <Accordion defaultActiveKey="1">
                <Card className="mx-0 px-0">
                  <Accordion.Header
                    eventKey={jig.jignr}
                    as={Card.Header}
                    onClick={() => setShowMaintenance(!showMaintenance)}
                    className="p-0"
                  >
                    <div className="container-fluid">
                      Maintenance Cases:{" "}
                      {jig.maintenanceIssues ? jig.maintenanceIssues.length : 0}
                      <span className="float-end">
                        <FontAwesomeIcon
                          icon={showMaintenance ? faChevronUp : faChevronDown}
                        />{" "}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Collapse
                    eventKey={jig.jignr}
                    as={Card.Body}
                    in={showMaintenance}
                  >
                    {jig.maintenanceIssues &&
                    jig.maintenanceIssues.length > 0 ? (
                      <div>
                        {jig.maintenanceIssues.map((i) => {
                          return (
                            <Card key={i.id}>
                              <a
                                href={"/issues/" + i.id}
                                onClick={(e) => handleIssueClick(e, i)}
                                style={{
                                  textDecoration: "none",
                                  padding: "20px",
                                }}
                                title={i.title}
                              >
                                {i.title}{" "}
                                <span
                                  style={{ float: "right" }}
                                  className={
                                    i.status === "Open" ? "text-warning" : ""
                                  }
                                >
                                  Status: {i.status}
                                </span>
                              </a>
                            </Card>
                          );
                        })}
                      </div>
                    ) : (
                      <Card.Body className="p-4">No related Cases</Card.Body>
                    )}
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            {jig.id > 0 && (
              <Form.Group className="mb-3">
                <Button onClick={() => setShowIssueModal(!showIssueModal)}>
                  Create new maintenace case
                </Button>
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <br />
            </Form.Group>
            {!embeded && (
              <Aux>
                <Row>
                  <Col className="col-12 col-md-6">
                    <Checkbox
                      label="Hidden"
                      name="hidden"
                      value={values.hidden}
                      onChange={(e) => setFieldValue("hidden", e.value)}
                      disabled={!editable}
                    />
                  </Col>
                </Row>
                <br></br>
              </Aux>
            )}
            <ButtonToolbar className="mb-4">
              <ButtonGroup>
                {editable && (
                  <Button
                    type="submit"
                    variant="success"
                    disabled={
                      !dirty || isSubmitting || isValidating || uploading
                    }
                  >
                    <FontAwesomeIcon icon={faSave} /> Save
                  </Button>
                )}
                {!embeded && values.id && (
                  <Button
                    onClick={handleReset}
                    variant="primary"
                    disabled={!dirty || isSubmitting || isValidating}
                  >
                    Reset changes
                  </Button>
                )}
                {!embeded && (
                  <Button onClick={onCancel} variant="secondary">
                    <FontAwesomeIcon icon={faBan} /> Cancel
                  </Button>
                )}
                {!embeded && (
                  <Button
                    onClick={() => setShowAudits(true)}
                    variant="secondary"
                  >
                    <FontAwesomeIcon icon={faFileArchive} /> View Log
                  </Button>
                )}
              </ButtonGroup>
            </ButtonToolbar>
            <CreateIssueModal
              show={showIssueModal}
              maintenanceJig={jig}
              history={history}
              setModalShow={setShowIssueModal}
              onHide={() => {
                setShowIssueModal(false);
              }}
              setProduct={undefined}
            />
          </Form>
        )}
      </Formik>
      <AuditModal
        entries={jig.auditEntries}
        onClose={() => setShowAudits(false)}
        history={history}
        show={showAudits}
        title={"Equipment " + jig.jignr + " Audit Log"}
      />
    </div>
  );
};

export default JigForm;
