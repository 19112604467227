import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../../store";
import { Route, RouteProps, Redirect } from "react-router";
import { validateAuth, startAuthentication } from "../../store/actions/auth";
import qs from "query-string";
import { IUser } from "../../models/user";
import { Paths } from "../../routes";
import Unauthorized from "./Unauthorized";

type Props = {
  isAuthenticated: boolean;
  requestComplete: boolean;
  component: any;
  validateAuth: () => any;
  startAuthentication: () => any;
  current: IUser | null;
  authFailed: boolean;

  roles?: string[];
  fallbackComponent?: any;
  fallbackRoles?: string[];
} & RouteProps;

const PrivateRoute: React.FC<Props> = (props) => {
  React.useEffect(() => {
    props.validateAuth();
    return () => {};
  }, [props.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    component: Component,
    isAuthenticated,
    requestComplete,
    startAuthentication,
    current,
    authFailed,

    roles,
    fallbackComponent,
    fallbackRoles,
    ...rest
  } = props;


  return (
    <Route
      {...rest}
      render={(props) => {
        if (requestComplete) {
          if (isAuthenticated) {
            const redirectTo = localStorage.getItem("redirect_to");
            if (redirectTo && redirectTo !== Paths.logout) {
              localStorage.removeItem("redirect_to");
              return <Redirect to={redirectTo} />;
            } else {
              if (current === null) {
                return null;
              }
              
              if(roles === undefined || (roles.length > 0 && current.roles.findIndex(r => roles.includes(r)) > -1)) {
                return <Route {...rest} component={Component} />; 
              } else {
                if(fallbackRoles === undefined || (fallbackRoles.length > 0 && current.roles.findIndex(r => fallbackRoles.includes(r)) > -1)) {
                  return <Route {...rest} component={fallbackComponent} />;
                } else {
                  return <Route component={() => <Unauthorized />} />
                }
              }
            }
          } else {
            const params = qs.parse(window.location.search);
            if (!params.code) {
              localStorage.setItem("redirect_to", props.location.pathname);
              startAuthentication();
            } else {
              return null;
            }
          }
        } else {
          return authFailed ? (
            <Route component={() => <Unauthorized />} />
          ) : null;
        }
      }}
    />
  );
};

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  requestComplete: state.authReducer.requestComplete,
  current: state.authReducer.user,
  authFailed: state.authReducer.authFailed,
});

const mapDispatchToProps = (dispatch: any) => ({
  validateAuth: () => dispatch(validateAuth()),
  startAuthentication: () => dispatch(startAuthentication()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
