import {
  faAngleLeft,
  faAngleRight,
  faRedo,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IImage } from "models/image";
import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Image, Modal } from "react-bootstrap";
export interface IProps {
  image?: IImage;
  content?: string;
  show: boolean;
  images: IImage[];
  handleHide: () => void;
  setImageShow: (image: IImage) => void;
}

const IssueModal: React.FC<IProps> = (props) => {
  const { image, content, show, images, handleHide, setImageShow } = props;
  const [rotation, setRotation] = useState<number>(0);
  const ref = useRef<HTMLImageElement>(null);
  const [initialHeight, setInitialHeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [initialWidth, setInitialWidth] = useState(0);
  const [width, setWidth] = useState(0);

  const imageContent = (
    <Image
      src={content}
      className="img-fluid"
      style={{
        transform: `rotate(${rotation}deg)`,
      }}
    />
  );

  const rotatedImageContent = (
    <Image
      src={content}
      className="img-fluid"
      style={{
        transform: `rotate(${rotation}deg) scaleX(${
          height > width ? 1.63 : 1
        }) translateX(${
          width > height
            ? rotation === 90
              ? -(width - height) / 12
              : (width - height) / 12
            : rotation === 90
            ? 20
            : -20
        }%) translateY(${
          width > height
            ? rotation === 90
              ? -(width - height) / 21.6
              : (width - height) / 21.6
            : rotation === 90
            ? 0
            : 0
        }%)`,
        width: height,
        height: width,
      }}
    />
  );

  useEffect(() => {
    setInitialHeight(0);
    setInitialWidth(0);
    setHeight(0);
    setWidth(0);
    setRotation(0);
  }, [image]);

  const videoContent = <video controls src={content} />;

  const imageIndex = images.findIndex((img) => img.id === image?.id);

  const rotate = (turnRight: boolean) => {
    let newRotation = rotation + (turnRight ? 90 : -90);
    if (newRotation === 360 || newRotation === -360) {
      newRotation = 0;
    }
    if (ref.current !== null) {
      setHeight(
        [0, 180, -180].includes(newRotation)
          ? initialHeight > 0
            ? initialHeight
            : ref.current.clientHeight
          : initialWidth > 0
          ? initialWidth
          : ref.current.clientWidth
      );
      setWidth(
        [0, 180, -180].includes(newRotation)
          ? initialWidth > 0
            ? initialWidth
            : ref.current.clientWidth
          : initialHeight > 0
          ? initialHeight
          : ref.current.clientHeight
      );
      if (initialWidth === 0) {
        setInitialWidth(ref.current.clientWidth);
      }
      if (initialHeight === 0) {
        setInitialHeight(ref.current.clientHeight);
      }
    }
    setRotation(newRotation);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setInitialHeight(0);
        setInitialWidth(0);
        setHeight(0);
        setWidth(0);
        setRotation(0);
        handleHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-truncate">
          {image ? image.name : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="m-0 p-0"
        ref={ref}
        style={
          height > 0 && width > 0
            ? { width: width + "px", height: height + "px" }
            : {}
        }
      >
        {content && content.startsWith("data:video")
          ? videoContent
          : [0, 180, -180].includes(rotation)
          ? imageContent
          : rotatedImageContent}
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col className="text-start">
          <FontAwesomeIcon
            icon={faAngleLeft}
            size="2x"
            className="mx-3"
            onClick={() =>
              imageIndex > 0 ? setImageShow(images[imageIndex - 1]) : null
            }
            style={imageIndex > 0 ? { cursor: "pointer" } : { opacity: 0.5 }}
          />
        </Col>
        <Col className="text-center">
          <ButtonGroup>
            <Button
              onClick={() => {
                rotate(false);
              }}
              style={{ backgroundColor: "transparent" }}
              variant="defalut"
            >
              <FontAwesomeIcon icon={faUndo} />
            </Button>
            <Button
              onClick={() => {
                rotate(true);
              }}
              style={{ backgroundColor: "transparent" }}
              variant="defalut"
            >
              <FontAwesomeIcon icon={faRedo} />
            </Button>
          </ButtonGroup>
        </Col>
        <Col className="text-end">
          <FontAwesomeIcon
            icon={faAngleRight}
            size="2x"
            className="mx-3"
            onClick={() =>
              imageIndex !== images.length - 1
                ? setImageShow(images[imageIndex + 1])
                : null
            }
            style={
              imageIndex !== images.length - 1
                ? { cursor: "pointer" }
                : { opacity: 0.5 }
            }
          />
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default IssueModal;
