import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { faBan, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Form,
  ButtonToolbar,
  Col,
  Row,
  ButtonGroup,
} from "react-bootstrap";
import { process } from "@progress/kendo-data-query";
import { Prompt } from "react-router";
import { Checkbox } from "@progress/kendo-react-inputs";

import { IResource } from "../../models/resource";
import { IUser } from "models/user";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";

export interface Props {
  resource: IResource;
  users: IUser[];
  canEdit: boolean;
  onSubmit: (resource: IResource) => any;
  onCancel: () => any;
}

const ResourceForm: React.FC<Props> = (props) => {
  const { resource, users, canEdit, onSubmit, onCancel } = props;

  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [filteredAdmins, setFilteredAdmins] = useState<IUser[]>([]);
  const [showCopyMessage, setShowCopyMessage] = useState<boolean>(false);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    if (showCopyMessage) {
      setTimeout(() => setShowCopyMessage(false), 1000);
    }
  }, [showCopyMessage]);

  const onUsersFilterChange = (event: any) => {
    const tempFilteredUsers = process(users, event);
    if (tempFilteredUsers.total > 0) {
      setFilteredUsers(tempFilteredUsers.data);
    }
  };

  const onAdminsFilterChange = (event: any) => {
    const tempFilteredAdmins = process(users, event);
    if (tempFilteredAdmins.total > 0) {
      setFilteredAdmins(tempFilteredAdmins.data);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={resource}
      onSubmit={(values) => {
        onSubmit(values as IResource);
      }}
    >
      {({
        values,
        dirty,
        isSubmitting,
        isValidating,
        handleSubmit,
        handleChange,
        handleReset,
        setFieldValue,
        handleBlur,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Prompt
            when={dirty && !isSubmitting}
            message="Are you sure you don't want to save before exiting?"
          />
          <Row>
            <Col className="col-12 col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>License Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="License Name"
                  value={values.name || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  minLength={3}
                  disabled={!canEdit}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>Number of licenses</Form.Label>
                <Form.Control
                  type="number"
                  name="quantity"
                  placeholder="Number of licenses"
                  value={values.quantity || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  disabled={!canEdit}
                />
              </Form.Group>
            </Col>
          </Row>
          {values.tag && (
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Matching Tag</Form.Label>
                  <ComboBox
                    className="w-100"
                    placeholder="tag"
                    name="tag"
                    textField="name"
                    defaultValue={values.tag}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          {users && users.length > 0 && (
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>PI Users</Form.Label>
                  <MultiSelect
                    name="users"
                    placeholder="Users"
                    textField="fullNameWithTitle"
                    dataItemKey="id"
                    data={filteredUsers}
                    value={values.users || []}
                    onChange={handleChange}
                    autoClose={false}
                    filterable={true}
                    onFilterChange={onUsersFilterChange}
                    disabled={!canEdit}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-12 col-md-6">
              <Form.Group className="mb-3">
                <Form.Label>Other Users</Form.Label>
                <MultiSelect
                  name="resourcePlaceholders"
                  placeholder="Name"
                  textField="name"
                  dataItemKey="name"
                  value={values.resourcePlaceholders || []}
                  onChange={handleChange}
                  filterable={true}
                  disabled={!canEdit}
                  allowCustom
                  popupSettings={{ popupClass: "k-custom-popup--hidden" }}
                />
              </Form.Group>
            </Col>
          </Row>
          {users && users.length > 0 && (
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Administrators</Form.Label>
                  <MultiSelect
                    name="administrators"
                    placeholder="Administrators"
                    textField="fullNameWithTitle"
                    dataItemKey="id"
                    data={filteredAdmins}
                    value={values.administrators || []}
                    onChange={handleChange}
                    autoClose={false}
                    filterable={true}
                    onFilterChange={onAdminsFilterChange}
                    disabled={!canEdit}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          {resource.id > 0 && (
            <Row>
              <Col className="col-12 col-md-6 mb-4">
                <Checkbox
                  label="Hide"
                  name="hide"
                  value={values.hide}
                  onChange={(e) => setFieldValue("hide", e.value)}
                  disabled={!canEdit}
                />
              </Col>
            </Row>
          )}
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                type="submit"
                variant="success"
                disabled={!dirty || isSubmitting || isValidating}
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </Button>
              <Button onClick={onCancel} variant="secondary">
                <FontAwesomeIcon icon={faBan} /> Cancel
              </Button>
              <Button
                onClick={handleReset}
                variant="primary"
                disabled={!dirty || isSubmitting || isValidating}
              >
                Reset changes
              </Button>
              {resource.id > 0 && (
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      resource.users
                        .map((value, index) => {
                          return value.email
                            ? (index > 0 ? "\n" : "") + value.email
                            : null;
                        })
                        .toString()
                        .replace(/,/g, "")
                        .trim()
                    );
                    setShowCopyMessage(true);
                  }}
                  variant="secondary"
                >
                  <span className="k-icon k-i-copy" style={{ top: "-9%" }} />{" "}
                  Copy User Emails
                </Button>
              )}
              {showCopyMessage && <p className="ps-2"> Copied!</p>}
            </ButtonGroup>
          </ButtonToolbar>
        </Form>
      )}
    </Formik>
  );
};

export default ResourceForm;
